import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { tokenHeaders, urlBackend } from 'app/main/data/LocationContext';
import { showMessage } from '../../../store/fuse/messageSlice';

export const getRealTimeSns = createAsyncThunk(
	'kpis/getRealTimeSns/getRealTimeSns',
	async (mon_id, { dispatch, getState }) => {
		const response = await axios.get(
			`${urlBackend}/api/monitor_social/get_realtime_sns/?mon_id=${mon_id}`,
			tokenHeaders()
		);
		const { success, data, message } = response.data;

		if (message) {
			dispatch(
				showMessage({
					message,
					autoHideDuration: 7000,
					variant: success ? 'success' : data ? 'info' : 'error'
				})
			);
		}

		if (success) {
			return JSON.parse(data);
		}
		return false
	}
);

const initialState = {
	tp: 'medio',
	data: null
};

const realTimeSnsSlice = createSlice({
	name: 'kpis/realTimeSnsSlice',
	initialState,
	reducers: {
		setTpRealTime: (state, action) => {
			state.tp = action.payload;
		},
		setDataRealTime: (state, action) => {
			state.data = action.payload;
		},
		resetSnsRealTime: (state, action) => {
			state = initialState;
		}
	},
	extraReducers: {
		[getRealTimeSns.fulfilled]: (state, action) => {
			state.data = action.payload;
		},
		[getRealTimeSns.rejected]: (state, action) => {
			state.data = null;
		}
	}
});

export const { setTpRealTime, setDataRealTime, resetSnsRealTime } = realTimeSnsSlice.actions;

export default realTimeSnsSlice.reducer;
