import { createContext } from 'react';

const AppContext = createContext({
	facebookAppId: process.env.REACT_APP_FACEBOOK_APPID,
	facebookAppSecret: process.env.REACT_APP_FACEBOOK_APPSECRET
});

export const facebookAppId = process.env.REACT_APP_FACEBOOK_APPID;
export const facebookAppSecret = process.env.REACT_APP_FACEBOOK_APPSECRET;

export default AppContext;
