import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { setDefaultSettings } from 'app/store/fuse/settingsSlice';
import { navbarToggleMobile, navbarToggle } from 'app/store/fuse/navbarSlice';
import _ from '@lodash';

function NavbarToggleButton(props) {
	const dispatch = useDispatch();
	const theme = useTheme();
	const mdDown = useMediaQuery(theme.breakpoints.down('lg'));
	const settings = useSelector(({ fuse }) => fuse.settings.current);
	const { config } = settings.layout;
	const navbar = useSelector(state => state.fuse.navbar);

	useEffect(() => {
		let isMounted = true;

		return () => {
			isMounted = false; // Set the mounted flag to false when the component is unmounted
		};
	}, []); // Empty dependency array means this effect runs only once (componentDidMount) and cleans up on unmount

	const handleClick = ev => {
		if (mdDown) {
			dispatch(navbarToggleMobile());
		} else if (config.navbar.style === 'style-2') {
			dispatch(
				setDefaultSettings(_.set({}, 'layout.config.navbar.folded', !settings.layout.config.navbar.folded))
			);
		} else {
			dispatch(navbarToggle());
		}
	};

	// Render the appropriate icon based on the `navbar` state
	const getNavbarIcon = () => {
		if (navbar.open) {
			return (
				<Icon fontSize="inherit" className="text-16">
					menu_open
				</Icon>
			);
		}
		return (
			<Icon fontSize="inherit" className="text-16">
				menu
			</Icon>
		);
	};

	return (
		<IconButton className={props.className} color="inherit" size="small" onClick={handleClick}>
			{/* {React.cloneElement(props.children, { navbarState: navbar })} /!* Pass navbarState prop *!/ */}
			{getNavbarIcon()}
		</IconButton>
	);
}

// NavbarToggleButton.defaultProps = {
//   children: (
//     <Icon fontSize="inherit" className="text-16">
//       default_icon
//     </Icon>
//   ),
// };

export default NavbarToggleButton;
