import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Icon from '@mui/material/Icon';
import FormLabel from '@mui/material/FormLabel';
import Chip from '@mui/material/Chip';
import { useEffect, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Box } from '@mui/system';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 300
		}
	}
};

function getStyles(ctx, ctxName, theme) {
	return {
		fontWeight: ctxName.indexOf(ctx) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
	};
}
function ConcorrenteDisputeNestedTab(props) {
	const theme = useTheme();

	const {
		form,
		methods,
		concorrente,
		locationState,
		setLocationState,
		listaPaises,
		listaEstados,
		listCitiesBorn,
		loading,
		setMarketsharePlayerInfo,
		marketsharePlayerInfo
	} = props;

	const { reset, watch, register, control, onChange, setValue, getValues, formState } = methods;
	const { errors } = formState;
	const { partidos, contextos, tipos } = useSelector(({ fillData }) => fillData?.data?.political);
	const { editable } = useSelector(({ entidade }) => entidade.analise_comparativa.concorrente);
	const [emailValue, setEmailValue] = useState(form.email);
	const [localNotes, setLocalNotes] = useState(marketsharePlayerInfo.notes);
	const [nrurna, setNrUrna] = useState(form.nr_urna);

	const listaContextos = Object.values(contextos).map(obj => obj.nm);

	const [ctxName, setCtx] = useState(
		marketsharePlayerInfo && marketsharePlayerInfo.adicional_contexts
			? marketsharePlayerInfo.adicional_contexts
			: []
	);

	useEffect(() => {
		if (marketsharePlayerInfo.adicional_contexts !== ctxName) {
			setMarketsharePlayerInfo({
				...marketsharePlayerInfo,
				adicional_contexts: ctxName
			});
		}
	}, [ctxName]);

	const handleChangeCtxAdicional = (event: SelectChangeEvent<typeof ctxName>) => {
		const {
			target: { value }
		} = event;
		setCtx(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value
		);
	};

	return (
		<div className="mt-24">
			<FormLabel component="legend">Localização/Residência candidato </FormLabel>
			{/* linha 1 */}
			<div className="w-full flex mt-10">
				<Controller
					name="pais_nascimento"
					control={control}
					defaultValue={locationState.pais_nascimento}
					render={({ field }) => (
						<FormControl variant="standard" className="w-full sm:w-1/3 pr-10">
							<InputLabel id="pais_nascimento-label">Pais</InputLabel>
							<Select
								{...field}
								labelId="pais_nascimento-label"
								id="pais_nascimento"
								disabled={!editable}
								label="Pais Nascimento"
								onChange={e => {
									field.onChange(e.target.value);
									setLocationState({
										...locationState,
										pais_nascimento: e.target.value,
										estado_nascimento: '',
										municipio_nascimento: null
									});
								}}
								onBlur={e => {
									setValue('pais_pais_nascimento', e.target.value);
								}}
							>
								{listaPaises
									? Object.values(listaPaises).map(opt => (
											<MenuItem key={opt.nome} value={opt.sigla}>
												{opt.nome}
											</MenuItem>
									  ))
									: null}
							</Select>
						</FormControl>
					)}
				/>

				<Controller
					name="estado_nascimento"
					control={control}
					defaultValue={locationState.estado_nascimento}
					render={({ field }) => (
						<FormControl variant="standard" className="w-full sm:w-2/3 pr-10">
							<InputLabel id="uf_nascimento-label">UF</InputLabel>
							<Select
								{...field}
								labelId="uf_nascimento-label"
								id="estado_nascimento"
								disabled={!editable}
								label="UF Nascimento"
								onChange={e => {
									field.onChange(e.target.value); // make sure to call this before updating the locationState
									setLocationState({
										...locationState,
										estado_nascimento: e.target.value,
										municipio_nascimento: null
									});
								}}
								onBlur={e => {
									setValue('estado_nascimento', e.target.value);
								}}
							>
								{locationState.pais_nascimento === 'BR' ? (
									listaEstados ? (
										Object.values(listaEstados).map(opt => (
											<MenuItem key={opt.uf} value={opt.uf}>
												{opt.nome}
											</MenuItem>
										))
									) : null
								) : (
									<MenuItem key="ZZ" value="ZZ">
										Exterior
									</MenuItem>
								)}
							</Select>
						</FormControl>
					)}
				/>

				{locationState.estado_nascimento && listCitiesBorn ? (
					<Controller
						name="municipio_nascimento"
						control={control}
						defaultValue={locationState.municipio_nascimento}
						render={({ field: { onChange, value } }) => (
							<Autocomplete
								{...(listCitiesBorn && listCitiesBorn)}
								id="municipio_nascimento"
								disabled={!listCitiesBorn}
								className="w-full sm:w-2/3"
								value={value || ''}
								onChange={(event, newValue) => {
									onChange(newValue);
									setLocationState({
										...locationState,
										municipio_nascimento: newValue
									});
								}}
								onBlur={e => {
									setValue('municipio_nascimento', e.target.value);
								}}
								renderInput={params => (
									<TextField
										{...params}
										label="Município Nascimento"
										variant="standard"
										disabled={!editable}
										InputProps={{
											...params.InputProps,
											startAdornment: loading ? (
												<InputAdornment position="start">
													<Icon className="animate-spin">cached</Icon>
												</InputAdornment>
											) : null
										}}
									/>
								)}
							/>
						)}
					/>
				) : (
					<TextField
						label="Município Nascimento"
						variant="standard"
						disabled
						value={form.municipio_nascimento}
						className="w-full sm:w-3/3"
					/>
				)}
			</div>
			{/*  linha 2 */}
			<FormLabel className="mt-28" component="legend">
				Complemento Disputa
			</FormLabel>
			<div className="w-full flex mt-10">
				<Controller
					name="partido"
					control={control}
					defaultValue="" // Set the default value
					disabled={!editable}
					render={({ field }) => (
						<FormControl variant="standard" className="w-full sm:w-1/3 pr-10">
							<InputLabel id="partido">Partido</InputLabel>
							<Select
								{...field} // Including field props from Controller
								labelId="partido-candidato"
								id="partido"
								label="Partido"
							>
								{partidos
									? Object.values(partidos).map(opt => (
											<MenuItem key={opt.nr} value={opt.sigla}>
												{opt.nr} - {opt.sigla}
											</MenuItem>
									  ))
									: null}
							</Select>
						</FormControl>
					)}
				/>

				<Controller
					name="nr_urna"
					control={control}
					render={({ field }) => (
						<TextField
							{...field}
							className="w-full sm:w-1/3 pr-10"
							label="Nº Urna"
							id="nr_urna"
							variant="standard"
							value={nrurna}
							onChange={e => {
								setNrUrna(e.target.value);
							}}
							onBlur={e => {
								setValue('nr_urna', e.target.value);
							}}
							disabled={!editable}
							fullwidth
						/>
					)}
				/>

				<Controller
					name="email"
					control={control}
					render={({ field }) => (
						<TextField
							{...field}
							className="w-full sm:w-1/3"
							label="Email"
							id="email"
							variant="standard"
							value={emailValue}
							onChange={e => setEmailValue(e.target.value)}
							onBlur={e => setValue('email', e.target.value)}
							disabled={!editable}
							fullwidth
						/>
					)}
				/>
			</div>
			{/* linha 3 */}
			<div className="w-full flex mt-28">
				<FormControl variant="standard" className="w-full sm:w-1/2 pr-10">
					<InputLabel id="tipo_player-label">Tipo</InputLabel>
					<Select
						labelId="tipo_player-label"
						id="tipo_player"
						defaultValue="concorrente"
						value={marketsharePlayerInfo.type}
						onChange={e =>
							setMarketsharePlayerInfo({
								...marketsharePlayerInfo,
								type: e.target.value
							})
						}
						label="Tipo"
					>
						{tipos
							? Object.values(tipos).map(opt => (
									<MenuItem key={opt.nm} value={opt.nm}>
										{opt.desc}
									</MenuItem>
							  ))
							: null}
					</Select>
				</FormControl>

				<FormControl variant="standard" className="w-full sm:w-1/2 pr-10">
					<InputLabel id="ctx_player-label">Tipo</InputLabel>
					<Select
						labelId="ctx_player-label"
						id="tctx_player"
						value={marketsharePlayerInfo.context}
						onChange={e =>
							setMarketsharePlayerInfo({
								...marketsharePlayerInfo,
								context: e.target.value
							})
						}
						label="Contexto"
					>
						{tipos
							? Object.values(contextos).map(opt => (
									<MenuItem key={opt.nm} value={opt.nm}>
										{opt.desc}
									</MenuItem>
							  ))
							: null}
					</Select>
				</FormControl>
			</div>
			{/* linha 4 */}
			<div className="w-full flex mt-28">
				<FormControl className="w-full sm:w-1/2 pr-10">
					<InputLabel id="adicional-ctx-label">Contextos Adicionais</InputLabel>
					<Select
						labelId="adicional-ctx-label"
						id="adicional-ctx"
						multiple
						value={ctxName}
						onChange={handleChangeCtxAdicional}
						input={<OutlinedInput id="select-multiple-ctx" label="Contextos Adicionais" />}
						renderValue={selected => (
							<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
								{selected.map(value => (
									<Chip key={value} label={value} />
								))}
							</Box>
						)}
						MenuProps={MenuProps}
					>
						{listaContextos.map(ctx => (
							<MenuItem key={ctx} value={ctx} style={getStyles(ctx, ctxName, theme)}>
								{ctx}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<TextField
					className="w-full sm:w-1/2"
					id="outlined-multiline-static"
					label="Multiline"
					multiline
					rows={4}
					value={localNotes}
					onChange={e => setLocalNotes(e.target.value)}
					onBlur={e =>
						setMarketsharePlayerInfo({
							...marketsharePlayerInfo,
							notes: e.target.value
						})
					}
				/>
			</div>
		</div>
	);
}

export default ConcorrenteDisputeNestedTab;
