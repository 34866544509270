import React, { lazy } from 'react';
import { authRoles } from '../../../../auth';

const IndividualResearch = lazy(() => import('./IndividualResearch'));
// import IndividualResearch from "./IndividualResearch";

const IndividualResearchConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.researcher,
	routes: [
		{
			path: '/pesquisa/individual',
			element: <IndividualResearch />
		},
		{
			path: '/pesquisa/individual/:cpf',
			element: <IndividualResearch />
		}
	]
};

export default IndividualResearchConfig;
