import React, { useState } from 'react';

export const ScopoContext = React.createContext(null);

const scopoInicialState = {
	entityId: null,
	stakeholders: null
};

export const ScopoProvider = ({ children }) => {
	const [scopoState, setScopoState] = useState(scopoInicialState);
	return <ScopoContext.Provider value={{ scopoState, setScopoState }}>{{ children }}</ScopoContext.Provider>;
};
