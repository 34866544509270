import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Slide, Tab } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import {
	PersonSearch,
	Replay,
	Biotech,
	PersonAdd,
	PersonAddAltTwoTone,
	PersonRemove,
	ReduceCapacityRounded,
	FolderShared,
	Edit,
	Face
} from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import { useSelector, useDispatch } from 'react-redux';
import Link from '@mui/material/Link';
import { useLocation, useNavigate } from 'react-router-dom';
import ScopoSwal from 'sweetalert2';
import { useForm, Controller, useFormContext } from 'react-hook-form';
import Fab from '@mui/material/Fab';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import CandidatoBasicInfoNestedTab from 'app/shared-components/dialogs/political/ConcorrenteInfoNestedTab';

import {
	IncludeUpdateMarketsharePlayer,
	novoConcorrente,
	resetConcorrente,
	setConcorrenteData,
	setConcorrenteEditable,
	setConcorrenteLoading,
	setModalConcorrenteOpen
} from 'app/main/store/entidade/analiseComparativaSlice/concorrenteSlice/concorrenteSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import {
	addSocialMonitorPlayer,
	removeSocialMonitorPlayer
} from '../../../main/store/IndicatorsSlices/monitorSocialSlice';
import {
	removeSelectedParticipanteId,
	selectParticipanteById
} from '../../../main/store/entidade/politico/participantesSlice';
import { candidatoInitialState, getConcorrente } from '../../../main/store/entidade/politico/candidatoSlice';
import { tokenHeaders, urlBackend } from '../../../main/data/LocationContext';
import ConcorrenteDisputeNestedTab from './ConcorrenteDisputeNestedTab';
import { setRemoteLoading } from '../../../store/fuse/environmentSlice';
import { getFillData } from '../../../main/store/actions/fillDataActions';
import { updateNavigationItem } from '../../../store/fuse/navigationSlice';

function PaperComponent(props) {
	return (
		<Draggable handle="#candidate-info-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	minWidth: '460',
	width: '480',
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2)
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1)
	}
}));

const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

function BootstrapDialogTitle(props: DialogTitleProps) {
	const { children, onClose, ...other } = props;
	return (
		<DialogTitle sx={{ m: 0, p: 0, backgroundColor: '#252e3e', color: '#ffffff', width: '100%' }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: theme => theme.palette.grey[500]
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
}

export interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	onClose: () => void;
}

const digitsOnly = value => /^\d+$/.test(value);
const schema = yup.object().shape({
	nome: yup
		.string()
		.required('Vc precisa incluir o nome do candidato')
		.min(5, 'O nome precisa de pelo menos 5 caracteres'),
	nm_urna: yup.string().required('Um nome de urna é obrigatório').min(4, 'O nome precisa de pelo menos 4 caracteres'),
	cpf: yup
		.string()
		.required('Vc precisa incluir o CPF do candidato')
		.min(11, 'CPF precisa de pelo menos 11 caracteres')
	// titulo_eleitor: yup.string().test('Apenas dígitos', 'Digite apenas os números', digitsOnly)
});

const concorrenteStatusInicialState = {
	monitorId: '',
	entityId: '',
	limit: 12,
	monitoring: 0,
	remaining: 0,
	userCanAddCandidates: false,
	nr_candidates_left: 0,
	action_range_location: '',
	added_players_monitor: 1
};

function ConcorrenteInfoDialog(props) {
	// there are 3 potencial actions: view, edit and new
	// view is default. If there an selected candidato/entity,
	// allows to add or remove as adversario only. Not allow to edit the entity
	// edit allows to change the opponent data. And the new allows to research
	// and include a new marketshare player
	const fillData = useSelector(state => state.fillData);
	const [loading, setLoading] = useState(false);
	const environment = useSelector(({ fuse }) => fuse.environment);
	const { concorrente } = useSelector(({ entidade }) => entidade.analise_comparativa);
	const idCandidatoConcorrente = useSelector(({ entidade }) => entidade.politico?.candidato?.id);
	const { participantes } = useSelector(({ entidade }) => entidade.politico);

	// para atualizar state de entidade em edição
	const { selectedMonitorId, data, loaded } = useSelector(({ entidade }) => entidade.indicators.monitor_social);
	const { userCanAddCandidates, nr_candidates_left } = useSelector(({ fuse }) => fuse.backendStatus);

	// fora de edição de entidades - adicionando para candidato selecionado

	// em edição de entidades
	const { entityStatus } = useSelector(({ entidade }) => entidade);
	const [entityState, setEntityState] = useState(concorrenteStatusInicialState);
	const [listaContextos, setListaContextos] = useState([]);

	const dispatch = useDispatch();
	const [tab, setTab] = useState(0);
	const unicodeMaleSign = '&#9794';
	const unicodeFemaleSign = '&#9792';
	const navigate = useNavigate();
	// const listaContextos = useSelector(({ fillData }) => fillData.data?.political?.contextos);
	// const listaContextos = fillData.data?.political?.contextos
	// const { limit, monitoring, remaining } = useSelector(({ entidade }) => entidade.entityStatus.stakeholders);
	const [firstButtonOption, setFirstButtonOption] = useState('new');
	const [firstButtonTooltip, setFirstButtonTooltip] = useState(
		`Adicionar novo player no comparativo. ${entityState.remaining} restantes`
	);

	const inputRef = useRef();
	const handleClick = () => {
		inputRef.current.click();
	};

	const [avatar, setAvatar] = useState(
		'https://ecom-scopo.sfo2.digitaloceanspaces.com/shared_images/unknow_candidate_gender.png'
	);
	// const { ballot, position } = useSelector(({ entidade }) => entidade.indicators.monitor_social.data);
	const ballot = useSelector(({ entidade }) => entidade.indicators?.monitor_social?.data?.ballot);
	const position = useSelector(({ entidade }) => entidade.indicators?.monitor_social?.data?.position);
	const disputa_eleitoral = useSelector(({ entidade }) => entidade.politico?.candidato?.disputa_eleitoral);
	const userIsLoggedIn = useSelector(({ auth }) => auth.login.success);
	const [bloqueiaVisualizarDisputa, setBloqueiaVisualizarDisputa] = useState(true);
	const [actualStakeLimit, setActualStakeLimit] = useState({ monitoring: 0, limit: 12 });

	const methods = useForm({
		mode: 'onBlur',
		defaultValues: candidatoInitialState,
		resolver: yupResolver(schema)
	});
	const { reset, watch, register, control, onChange, setValue, getValues, formState } = methods;
	const form = watch();
	const { isValid, dirtyFields } = formState;

	if (Object.keys(dirtyFields).length > 0 && firstButtonOption === 'remove') {
		setFirstButtonOption('edit');
		setFirstButtonTooltip(`Alterar dados do concorrente`);
	}

	if (Object.keys(dirtyFields).length === 0 && firstButtonOption === 'edit') {
		setFirstButtonOption('remove');
		setFirstButtonTooltip('Remover player da análise');
	}

	const location = useLocation();

	const onSubmit = dtForm => {
		if (isValid) {
			const complementData = dtForm;
			complementData.marketshare_player = marketsharePlayerInfo;
			dispatch(IncludeUpdateMarketsharePlayer(complementData));
			dispatch(setModalConcorrenteOpen(false));
		}
		setTimeout(() => {
			setLoading(false);
		}, 300); // Delay of 2 seconds
	};

	// console.log('isValid:', isValid);
	// console.log('dirtyFields:', dirtyFields);

	const [docToAvail, setDocToAvail] = useState(null);
	const [docValido, setDocValido] = useState(null);

	const [cpfStatus, setCpfStatus] = useState({
		documentIsValid: false,
		cpfAsError: false,
		errorMessage: null,
		loading: false
	});

	const [fieldError, setFieldError] = useState({
		nome: null,
		nm_urna: null
	});

	const [locationState, setLocationState] = useState({
		pais_nascimento: 'BR',
		estado_nascimento: '',
		municipio_nascimento: null
	});

	const [emailsConcorrente, setEmailsConcorrente] = useState([]);

	const concorrenteEmailsProps = {
		options: emailsConcorrente
	};

	const [listCitiesBorn, setListCitiesBorn] = useState({ options: ['Curitiba'] });

	const [marketsharePlayerInfo, setMarketsharePlayerInfo] = useState({
		id: null,
		type: 'concorrente',
		context: 'eleitoral',
		adicional_contexts: ['eleitoral', 'politico'],
		notes: 'Observações e Notas'
	});

	const checkConcorrenteId = () => {
		if (concorrente.data && concorrente.data.id) {
			const entIdArr = Object.values(participantes.entities)
				.filter(obj => obj.concorrente.id === concorrente.data.id)
				.map(obj => obj.id);
			if (entIdArr.length > 0) {
				const objPlyr = Object.values(participantes.entities)
					.filter(obj => obj.concorrente.id === concorrente.data.id)
					.map(obj => obj)[0];

				const { type, context, adicional_contexts, notes } = objPlyr;

				setMarketsharePlayerInfo({
					id: entIdArr[0],
					type,
					context,
					adicional_contexts,
					notes
				});

				return entIdArr[0];
			}
			return null;
		}
		return null;
	};

	const FirstButtonIcon = () => {
		switch (firstButtonOption) {
			case 'new':
				return <FolderShared className="w-26 h-26 text-white" />;
			case 'self':
				return <Face className="w-26 h-26 text-white" />;
			case 'remove':
				return <PersonRemove className="w-26 h-26 text-white" />;
			case 'include':
				return <PersonAdd className="w-26 h-26 text-white" />;
			case 'edit':
				return <Edit className="w-26 h-26 text-white" />;
			default:
				return <PersonAdd className="w-26 h-26 text-white" />;
		}
	};

	useEffect(() => {
		// console.log(location.pathname);
		function updateStakeholdersNotify(nrMonitoring, maxLimit) {
			const getStakeNotifyColor = () => {
				let newColor = '#49caeb';
				if (nrMonitoring <= maxLimit / 3) {
					newColor = '#044F01FF';
				} else if (nrMonitoring <= maxLimit / 2) {
					newColor = '#b8401c';
				} else {
					newColor = '#7a1313';
				}
				return newColor;
			};

			const updatedStakeholderMenu = {
				id: 'stakeholders-entidade',
				title: 'Stakeholders',
				type: 'item',
				url: '/stakeholders',
				icon: 'groups3',
				badge: {
					title: nrMonitoring < maxLimit ? nrMonitoring : '!!',
					bg: getStakeNotifyColor(),
					fg: '#FFFFFF'
				}
			};
			dispatch(updateNavigationItem('stakeholders-entidade', updatedStakeholderMenu));
		}

		if (location.pathname.includes('entidade')) {
			// console.log('EM EDICAO DE ENTIDADE');
			if (entityStatus.loaded && entityStatus.loading === 'idle') {
				const newStateEnt = {
					monitorId: entityStatus.selectedMonitorSocialId,
					entityId: entityStatus.selectedEntityId,
					limit: entityStatus.stakeholders.limit,
					monitoring: entityStatus.stakeholders.monitoring,
					remaining: entityStatus.stakeholders.remaining,
					added_players_monitor: entityStatus?.selectedMonitorSocialData?.added_players_monitor,
					userCanAddCandidates,
					nr_candidates_left,
					action_range_location: entityStatus?.selectedMonitorSocialData?.action_range?.location
				};
				console.log('NEW STATUS FOR SEARCH BY CPF!!');
				console.log(newStateEnt);
				console.log('NEW STATUS FOR SEARCH BY CPF!!');

				if (entityState !== newStateEnt) {
					setEntityState(newStateEnt);
				}

				updateStakeholdersNotify(entityStatus.stakeholders.monitoring, entityStatus.stakeholders.limit);
			}
		} else {
			// console.log('FORA DE EDICAO DE ENTIDADE');
			// console.log(loaded);
			// console.log(data);

			if (loaded && data) {
				const newLocalState = {
					monitorId: data.id,
					entityId: data.entidade,
					limit: entityStatus.stakeholders.limit,
					monitoring: entityStatus.stakeholders.monitoring,
					remaining: entityStatus.stakeholders.remaining,
					added_players_monitor: data.added_players_monitor,
					userCanAddCandidates,
					nr_candidates_left,
					action_range_location: data.action_range.location
				};
				if (entityState !== newLocalState) {
					setEntityState(newLocalState);
				}
				if (
					actualStakeLimit.monitoring !== entityStatus.stakeholders.monitoring ||
					actualStakeLimit.limit !== entityStatus.stakeholders.limit
				) {
					setActualStakeLimit({
						monitoring: entityStatus.stakeholders.monitoring,
						limit: entityStatus.stakeholders.limit
					});
				}
				updateStakeholdersNotify(entityStatus.stakeholders.monitoring, entityStatus.stakeholders.limit);
			} else if (actualStakeLimit.monitoring !== 0 || actualStakeLimit.limit !== 12) {
				setActualStakeLimit({
					monitoring: 0,
					limit: 12
				});
				updateStakeholdersNotify(0, 12);
			}
		}
	}, [
		entityStatus.loading,
		entityStatus.loaded,
		entityStatus.selectedMonitorSocialId,
		entityStatus.selectedEntityId,
		entityStatus.stakeholders,
		entityStatus.selectedMonitorSocialData,
		userCanAddCandidates,
		nr_candidates_left,
		loaded,
		selectedMonitorId,
		data
	]);

	useEffect(() => {
		if (!fillData.data && !environment.remoteLoading && userIsLoggedIn) {
			setLoading(true);
			dispatch(getFillData());
		} else {
			setLoading(false);
		}
	}, [fillData, environment, userIsLoggedIn]);

	useEffect(() => {
		if (fillData && fillData.data && fillData.data.political) {
			setListaContextos(fillData.data.political.contextos);
		}
		// const listaContextos = useSelector(({ fillData }) => fillData.data?.political?.contextos);
	}, [fillData]);

	useEffect(() => {
		if (locationState.estado_nascimento && locationState.estado_nascimento !== '') {
			setLoading(true);
			axios
				.get(`${urlBackend}/api/municipios/justCities/?uf=${locationState.estado_nascimento}`, tokenHeaders())
				.then(r => {
					setListCitiesBorn({ options: r.data.map(item => item.nome) });
					setLoading(false);
				});
		}
	}, [locationState.estado_nascimento]);

	useEffect(() => {
		if (concorrente.data) {
			reset(concorrente.data);

			if (checkConcorrenteId()) {
				setMarketsharePlayerInfo({
					...marketsharePlayerInfo,
					id: checkConcorrenteId()
				});
			}

			setCpfStatus({
				...cpfStatus,
				loading: false
			});

			if (concorrente.data.id !== null && concorrente.data.id === idCandidatoConcorrente) {
				if (location.pathname.includes('entidade')) {
					setFirstButtonOption('self');
					setFirstButtonTooltip(`Candidato Central Análise. ${entityState.remaining} restantes`);
					setBloqueiaVisualizarDisputa(false);
				} else if (participantes.monitoredConcorrentesIds.includes(concorrente.data.id)) {
					setFirstButtonOption('remove');
					setFirstButtonTooltip('Remover player da análise');
					setBloqueiaVisualizarDisputa(false);
					dispatch(setConcorrenteEditable(true));
				} else {
					setFirstButtonOption('include');
					setFirstButtonTooltip(`Adicionar player ao comparativo. ${entityState.remaining} restantes`);
				}
			} else if (participantes.ids.length > 0) {
				if (participantes.monitoredConcorrentesIds) {
					if (participantes.monitoredConcorrentesIds.includes(concorrente.data.id)) {
						setFirstButtonOption('remove');
						setFirstButtonTooltip('Remover player da análise');
						setBloqueiaVisualizarDisputa(false);
						dispatch(setConcorrenteEditable(true));
					} else {
						setFirstButtonOption('include');
						setFirstButtonTooltip(`Adicionar player ao comparativo. ${entityState.remaining} restantes`);
					}
				}
			}
		} else {
			dispatch(setConcorrenteEditable(true));
			setBloqueiaVisualizarDisputa(false);
			setFirstButtonOption('new');
			setFirstButtonTooltip(
				`Cadastrar e Adicionar novo player no comparativo. ${entityState.remaining} restantes`
			);
		}

		if (concorrente.data) {
			if (concorrente.data.img_tse) {
				setAvatar(concorrente.data.img_tse);
			} else if (concorrente.data.image_url) {
				setAvatar(concorrente.data.image_url);
			} else if (concorrente.data.foto) {
				setAvatar(concorrente.data.foto);
			} else if (concorrente.data.genero === 'Masculino') {
				setAvatar('https://ecom-scopo.sfo2.digitaloceanspaces.com/shared_images/perfilHomem.jpeg');
			} else if (concorrente.data.genero === 'Feminino') {
				setAvatar('https://ecom-scopo.sfo2.digitaloceanspaces.com/shared_images/perfilMulher.svg');
			}
		}

		if (concorrente.loading) {
			dispatch(setConcorrenteLoading(false));
		}
	}, [concorrente.data, concorrente.loading, entityState.remaining]);

	useEffect(() => {
		if (form.cpf.length === 0) {
			setCpfStatus({
				...setCpfStatus,
				cpfAsError: false,
				errorMessage: 'O CPF do player é obrigatório',
				loading: false
			});
		} else if (form.cpf.length < 14) {
			setCpfStatus({
				...setCpfStatus,
				cpfAsError: true,
				errorMessage: 'O CPF deve ter 11 caracteres!',
				loading: false
			});
		} else {
			const loadingNew = !concorrente.data;

			setCpfStatus({
				...setCpfStatus,
				cpfAsError: false,
				errorMessage: null,
				loading: loadingNew
			});

			if (!concorrente.data) {
				setDocToAvail(form.cpf);
			}
		}
	}, [form.cpf]);

	useEffect(() => {
		if (docToAvail) {
			// dispatch(novoConcorrente());
			fetch(`${urlBackend}/api/validar_doc?cpf=${docToAvail}`, tokenHeaders())
				.then(resp => resp.json())
				.then(valido => {
					if (valido) {
						setCpfStatus({
							documentIsValid: true,
							cpfAsError: false,
							errorMessage: null,
							loading: true
						});
					} else {
						setCpfStatus({
							documentIsValid: false,
							cpfAsError: true,
							errorMessage: 'Número de CPF Inválido',
							loading: false
						});
					}
					setDocValido(valido);
				});
		}
	}, [docToAvail]);

	useEffect(() => {
		const fetchData = async () => {
			let result = null;
			if (docValido) {
				setLoading(true);
				result = await dispatch(getConcorrente({ tp: 'cpf', id: docToAvail }));

				console.log('RESULT PESQUISA CPF CONCORRENTE');
				console.log(result);
				console.log('DOC TO AVAIL', docToAvail);
				console.log('RESULT PESQUISA CPF CONCORRENTE');
				setLoading(false);
				return result;
			}
			return result;
		};

		if ((!concorrente.data || concorrente.data.cpf !== docToAvail) && docValido) {
			console.log('BUSCANDO DADOS');
			fetchData().then(dt_player => {
				console.log(dt_player);
				if (dt_player && dt_player.payload) {
					reset(dt_player.payload);
					setValue('ballot', ballot);
					setValue('disputa_eleitoral', disputa_eleitoral);
					setValue('position', position);
					dispatch(setConcorrenteData(dt_player.payload));
				}
				setCpfStatus({
					...cpfStatus,
					loading: false
				});
			});
		}
	}, [docValido, docToAvail]);

	useEffect(() => {
		if ((!form.nome || form.nome.length < 4) && fieldError.nome !== 'O nome é obrigatório') {
			setFieldError({
				...fieldError,
				nome: 'O nome é obrigatório'
			});
		} else if (fieldError.nome !== null) {
			setFieldError({
				...fieldError,
				nome: null
			});
		}

		if ((!form.nm_urna || form.nm_urna.length < 4) && form.nm_urna !== 'O nome de disputa é obrigatório') {
			setFieldError({
				...fieldError,
				nm_urna: 'O nome de disputa é obrigatório'
			});
		} else if (fieldError.nm_urna !== null) {
			setFieldError({
				...fieldError,
				nm_urna: null
			});
		}
	}, [form.nome, form.nm_urna]);

	useEffect(() => {
		return () => {
			dispatch(setModalConcorrenteOpen(false));
			dispatch(removeSelectedParticipanteId());
			dispatch(resetConcorrente());
			reset(candidatoInitialState);
		};
	}, []);

	const IncludePlayer = () => {
		let inputOptions = {};
		if (listaContextos && typeof listaContextos === 'object') {
			inputOptions = Object.values(listaContextos).reduce((options, ctx) => {
				options[ctx.nm] = ctx.desc;
				return options;
			}, {});
		}

		ScopoSwal.fire({
			title: 'Adicionar Concorrente no Monitoramento?',
			text: `Adicionar novo player no comparativo em ${entityState.action_range_location}?
            \n\n O processamento dos dados comparativos de análise não são imediatos e podem
            variar durante coleta e avaliações do sistema de IA. \n\nAssim que os dados estiverem
            completamente processados você será avisado pelo painel e por email. Dados eventualmente
            apresentados na inclusão podem não estar exatos. Deseja prosseguir?`,
			icon: 'question',
			input: 'select',
			inputOptions,
			inputPlaceholder: 'Defina um Contexto',
			showCancelButton: true,
			cancelButtonText: 'Não, mudei de idéia!',
			confirmButtonColor: '#044f01',
			confirmButtonText: 'Adicionar',
			footer: `Limite Atores: ${entityState.limit} | Monitorando: ${entityState.monitoring} | Restantes: ${entityState.remaining}`
		}).then(r => {
			if (r.isConfirmed) {
				setLoading(true);
				// dispatch(setRemoteLoading(true)); # no slice
				let dt = { monitorSocialId: entityState.monitorId };

				const plyrId = checkConcorrenteId();

				if (plyrId) {
					dt = { monitorSocialId: entityState.monitorId, playerId: plyrId, contexto: r.value };
				} else if (concorrente.id) {
					dt = { monitorSocialId: entityState.monitorId, concorrenteId: concorrente.id, contexto: r.value };
				} else {
					dt = { monitorSocialId: entityState.monitorId, cpfStr: concorrente.data.cpf, contexto: r.value };
				}

				dispatch(addSocialMonitorPlayer({ tp: 'player', ctx: 'disputa', dt }));
				setLoading(false);
			}
		});
	};

	const removePlayer = () => {
		ScopoSwal.fire({
			title: `Remover ${
				concorrente.data.nm_urna ? concorrente.data.nm_urna : concorrente.data.nome
			} do monitoramento?`,
			text:
				'Ao remover o ator do monitoramento, as análises e avaliações cruzadas' +
				'não serão mais efetuadas. Gostaria de prosseguir?',
			icon: 'info',
			confirmButtonColor: '#044f01',
			confirmButtonText: 'Sim. Remover',
			footer: `Limite Atores: ${entityState.limit} | Monitorando: ${entityState.monitoring} | Restantes: ${entityState.remaining}`
		}).then(r => {
			if (r.isConfirmed) {
				setLoading(true);
				const plyerId = checkConcorrenteId();
				const tp = plyerId ? 'playerId' : 'concorrenteId';
				const tpId = plyerId || concorrente.id;
				dispatch(removeSocialMonitorPlayer({ tp, id: tpId, monitorSocialId: entityState.monitorId }));
			}
			setLoading(false);
		});
	};

	const LimitToIncludeReached = () => {
		ScopoSwal.fire({
			icon: 'error',
			title: 'Limite Alcançado',
			text: `O limite de ${entityState.limit} monitoramentos para esta entidade foi alcançado. 
			Exclua algum dos monitoramentos existentes ou <a>adquira mais monitoramentos.</a>`,
			footer: `Limite Atores: <strong>${entityState.limit}</strong> | Monitorando: <strong>${entityState.monitoring}</strong> | Restantes: <strong>${entityState.remaining}</strong>`
		});
	};

	const LimitToAddPlayersReached = () => {
		ScopoSwal.fire({
			icon: 'warning',
			title: 'Limite de Inserções Alcançado',
			text: `A adição de novos players pode impactar diretamente a leitura e predição de resultados
			potenciais para o entidade. Se você realmente acha que este novo stakeholder tem relevância
			à campanha do candidato, contate nossa equipe para auxiliar nesta inclusão.`,
			footer: `Limite Atores: <strong>${entityState.limit}</strong> | Monitorando: <strong>${entityState.monitoring}</strong> | Restantes: <strong>${entityState.remaining}</strong>`
		});
	};

	const handleFirstBtn = () => {
		// dispatch(setModalConcorrenteOpen(false));
		console.log('BTNTNTNTNTNNTTN');
		console.log('BTNTNTNTNTNNTTN');
		console.log(firstButtonOption);
		console.log('BTNTNTNTNTNNTTN');
		console.log('BTNTNTNTNTNNTTN');
		switch (firstButtonOption) {
			case 'self':
				if (concorrente.further_action) {
					const editPath = concorrente.further_action.split(':')[1].trim();
					dispatch(setModalConcorrenteOpen(false));
					navigate(editPath);
				}
				break;
			case 'remove':
				// eslint-disable-next-line no-case-declarations
				removePlayer();
				dispatch(setModalConcorrenteOpen(false));
				break;
			case 'include':
				if (entityState.added_players_monitor > entityState.limit) {
					LimitToAddPlayersReached();
				} else if (entityState.remaining > 0) {
					// console.log('Including player');
					IncludePlayer();
				} else {
					LimitToIncludeReached();
				}
				dispatch(setModalConcorrenteOpen(false));
				break;
			case 'new':
				// setLoading(true);
				console.log('new');
				console.log('new');
				console.log(form);
				break;
			default:
				console.log('default');
		}
	};

	function toTitleCase(str) {
		return str.replace(/\w\S*/g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	}

	const handlePesquisaCPF = () => {
		const linkPesquisa = concorrente.data ? `/pesquisa/individual/${concorrente.data.cpf}` : '/pesquisa/individual';

		navigate(linkPesquisa);
	};

	const handleAddAsCandidate = () => {
		const linkCandidato = concorrente.data.cpf ? `/candidato/${concorrente.data.cpf}` : '/candidato/';
		// console.log(`Adicionar candidato CPF ${concorrente.data.cpf}`);
		navigate(linkCandidato);
		dispatch(setModalConcorrenteOpen(false));
	};

	const MaleSign = () => String.fromCodePoint(parseInt(unicodeMaleSign.substr(2), 10));
	const FemaleSign = () => String.fromCodePoint(parseInt(unicodeFemaleSign.substr(2), 10));

	const handleClose = () => {
		dispatch(setModalConcorrenteOpen(false));
		dispatch(removeSelectedParticipanteId());
		dispatch(resetConcorrente());
		reset(candidatoInitialState);
		setAvatar('https://ecom-scopo.sfo2.digitaloceanspaces.com/shared_images/unknow_candidate_gender.png');
		setLoading(false);
	};

	// eslint-disable-next-line no-shadow
	function TabPanel(props) {
		const { children, value, index, ...other } = props;
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box sx={{ p: 0, width: '520px', height: '400px' }}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}

	TabPanel.propTypes = {
		children: PropTypes.node,
		index: PropTypes.number.isRequired,
		value: PropTypes.number.isRequired
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`
		};
	}

	const handleChangeTab = (ev, nv) => {
		setTab(nv);
	};

	const handleConcorrente = () => {
		console.log('concorrente');
	};
	const avatarHandlerConcorrente = e => {
		const reader = new FileReader();
		reader.onload = () => {
			if (reader.readyState === 2) {
				setAvatar(reader.result);
				setValue('foto', reader.result);
			}
		};
		reader.readAsDataURL(e.target.files[0]);

		if (firstButtonOption === 'remove') {
			setFirstButtonOption('edit');
			setFirstButtonTooltip(`Alterar dados do concorrente`);
		}
	};

	return (
		<form onSubmit={methods.handleSubmit(onSubmit)} encType="multipart/form-data">
			<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="candidate-info-dialog-title"
				open={concorrente.modalOpen}
				PaperComponent={PaperComponent}
				TransitionComponent={Transition}
				// keepMounted
			>
				<BootstrapDialogTitle id="candidate-info-dialog-title" onClose={handleClose}>
					<figure className="flex rounded-xl p-0 m-0">
						<div className="flex w-full min-w-full align-middle">
							<label htmlFor="upload-avatar" onClick={handleClick}>
								<input
									{...register('foto')}
									ref={inputRef}
									type="file"
									id="upload-avatar"
									name="upload-avatar"
									accept="image/*"
									className="hidden"
									style={{ display: 'none', cursor: 'pointer' }}
									onChange={avatarHandlerConcorrente}
								/>
								<img className="w-48 md:w-64 h-full rounded-none" src={avatar} alt="concorrente" />
							</label>
							<figcaption className="ml-16 mt-12 p-2">
								<Typography className="font-semibold" variant="h5">
									{concorrente.data
										? concorrente.data.nm_urna
											? toTitleCase(concorrente.data.nm_urna)
											: concorrente.data.nome
										: 'Novo Candidato'}
								</Typography>
								<Stack className="mt-4" direction="row" spacing={2}>
									<Typography variant="h6">
										CPF: {concorrente.data ? concorrente.data.cpf : ''}
									</Typography>
									<Typography variant="subtitle1">
										{concorrente.data ? `${concorrente.data.idade} anos` : ''}
									</Typography>
									<div>
										{concorrente.data ? (
											concorrente.data.genero === 'Feminino' ? (
												<FemaleSign />
											) : concorrente.data.genero === 'Masculino' ? (
												<MaleSign />
											) : (
												''
											)
										) : (
											''
										)}
									</div>
								</Stack>
							</figcaption>
						</div>
					</figure>
				</BootstrapDialogTitle>
				{concorrente.loading && <LinearProgress color="inherit" />}

				{concorrente.loading ? (
					<FuseLoading />
				) : (
					<DialogContent dividers>
						<TabPanel value={tab} index={0}>
							<CandidatoBasicInfoNestedTab
								form={form}
								methods={methods}
								concorrente={concorrente}
								cpfStatus={cpfStatus}
								listaOccupation={
									fillData.data && fillData.data.political ? fillData.data.political.ocupacoes : []
								}
								fieldError={fieldError}
								firstButtonOption={firstButtonOption}
							/>
						</TabPanel>
						<TabPanel value={tab} index={1}>
							<ConcorrenteDisputeNestedTab
								form={form}
								methods={methods}
								concorrente={concorrente}
								locationState={locationState}
								setLocationState={setLocationState}
								listaPaises={fillData.data ? fillData.data.paises : null}
								listaEstados={fillData.data ? fillData.data.estados : null}
								listCitiesBorn={listCitiesBorn}
								loading={loading}
								marketsharePlayerInfo={marketsharePlayerInfo}
								setMarketsharePlayerInfo={setMarketsharePlayerInfo}
							/>
						</TabPanel>
						<TabPanel value={tab} index={2}>
							<span>Histórico</span>
						</TabPanel>
						<TabPanel value={tab} index={3}>
							<span>Projeções e Impacto</span>
						</TabPanel>
						<Tabs
							value={tab}
							onChange={handleChangeTab}
							aria-label="candidate-info-tabs"
							centered
							variant="fullWidth"
							className="pt-0 mt-0"
						>
							<Tab label="Dados Básicos" {...a11yProps(0)} />
							<Tab
								disabled={bloqueiaVisualizarDisputa || !entityState.monitorId}
								register={register || ''}
								label="Disputa"
								{...a11yProps(1)}
							/>
							<Tab disabled label="Histórico" {...a11yProps(2)} />
							<Tab disabled label="Projeções e Impacto" {...a11yProps(3)} />
						</Tabs>
					</DialogContent>
				)}

				<DialogActions sx={{ m: 0, p: 0, backgroundColor: '#252e3e', color: '#ffffff', width: '100%' }}>
					<Stack direction="row" alignItems="center" spacing={1}>
						<Tooltip title="Pesquisar" placement="top" arrow>
							<IconButton
								aria-label="research"
								size="large"
								disabled={concorrente.loading}
								className={`bg-indigo-800 ${
									concorrente.loading && 'animate-spin'
								} hover:bg-orange-600 hover:animate-pulse`}
								onClick={handlePesquisaCPF}
							>
								{concorrente.loading ? (
									<Replay className="w-26 h-26 text-white" />
								) : (
									<PersonSearch className="w-26 h-26 text-white" />
								)}
							</IconButton>
						</Tooltip>

						{entityState.monitorId && (
							<Tooltip title={firstButtonTooltip} placement="top" arrow>
								{firstButtonOption === 'new' || firstButtonOption === 'edit' ? (
									<form onSubmit={methods.handleSubmit(onSubmit)}>
										<IconButton
											aria-label={firstButtonOption}
											size="large"
											disabled={concorrente.loading || !isValid}
											className="bg-lime-900 hover:bg-orange-600 hover:animate-pulse"
											type="submit"
											onClick={handleFirstBtn}
										>
											<FirstButtonIcon />
										</IconButton>
									</form>
								) : (
									<IconButton
										aria-label={firstButtonOption}
										size="large"
										disabled={concorrente.loading}
										className="bg-lime-900 hover:bg-orange-600 hover:animate-pulse"
										onClick={handleFirstBtn}
									>
										<FirstButtonIcon />
									</IconButton>
								)}
							</Tooltip>
						)}
						{userCanAddCandidates && (
							<Tooltip
								title={
									nr_candidates_left > 0
										? `Adicionar como Candidato. Instâncias disponíveis: ${nr_candidates_left}`
										: 'Adicionar como Candidato. Sem instâncias reservadas.'
								}
								placement="top"
								arrow
							>
								<IconButton
									aria-label="add_as_candidate"
									size="large"
									disabled={nr_candidates_left <= 0}
									className="bg-lime-900 hover:bg-orange-600 hover:animate-pulse"
									onClick={handleAddAsCandidate}
								>
									<PersonAddAltTwoTone className="w-26 h-26 text-white" />
								</IconButton>
							</Tooltip>
						)}
					</Stack>
				</DialogActions>
			</BootstrapDialog>
		</form>
	);
}

export default ConcorrenteInfoDialog;
