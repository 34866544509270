import { GET_FILL_DATA, APPEND_FILL_DATA } from '../actions/actionTypes';

export const fillDataInitialState = {
	data: null,
	loaded: false
};

export default function (state = fillDataInitialState, action) {
	switch (action.type) {
		case GET_FILL_DATA:
			return {
				data: action.payload,
				loaded: true
			};
		case APPEND_FILL_DATA:
			return {
				data: { ...state.data, political: action.payload },
				loaded: true
			};
		default:
			return state;
	}
}
