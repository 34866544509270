import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import {
	InputLabel,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip
} from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller, useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';
import InputAdornment from '@mui/material/InputAdornment';
import Icon from '@mui/material/Icon';
import { candidatoInitialState, resetCandidato } from '../../../main/store/entidade/politico/candidatoSlice';

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: '#252e3e',
	...theme.typography.body1,
	padding: theme.spacing(1),
	maxWidth: '90px',
	textAlign: 'center',
	fontWeight: '900',
	color: '#FFFFFF'
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	...theme.typography.subtitle1,
	textAlign: 'center'
}));
export default function (props) {
	const {
		form,
		methods,
		concorrente,
		cpfStatus,
		listaOccupation,
		fieldError,
		firstButtonOption
	} = props;

	const fillData = useSelector(state => state.fillData.data);
	const { editable } = useSelector(({ entidade }) => entidade.analise_comparativa.concorrente);

	const { reset, watch, register, control, onChange, setValue, getValues, formState } = methods;
	const { errors } = formState;

	const ocuppationOptions = Object.assign(listaOccupation.map(x => x.desc));
	const [ocuppationInputValue, setOcuppationInputValue] = useState('');

	const [concCPF, setConcCPF] = useState(form.cpf);
	const [concorrenteTitEleitor, setConcorrenteTitEleitor] = useState(form.titulo_eleitor);
	const [concorrenteNome, setConcorrenteNome] = useState(form.nome);
	const [concorrenteNomeUrna, setConcorrenteNomeUrna] = useState(form.nm_urna);

	const getDescOcupacao = cod => {
		const result_desc = listaOccupation.find(item => item.cod === cod);
		if (result_desc) {
			return result_desc.desc;
		}
		return '';
	};

	const getCodOcupacao = desc => {
		const result_cod = listaOccupation.find(item => item.desc === desc);
		if (result_cod) {
			return result_cod.cod;
		}
		return null;
	};

	function toTitleCase(str) {
		return str.replace(/\w\S*/g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	}

	return (
		<>
			<div className="flex flex-row md:overflow-hidden">
				<Controller
					name="cpf"
					control={control}
					rules={{
						required: true // Add validation rules as needed
					}}
					render={({ field }) => (
						<InputMask
							mask="999.999.999-99"
							maskChar=""
							disabled={!editable || firstButtonOption !== 'new'}
							{...field}
							onChange={e => {
								setConcCPF(e.target.value); // your custom handler
							}}
							onBlur={e => {
								field.onChange(e);
								field.onBlur(e);
								setValue('cpf', e.target.value);
							}}
							value={concCPF}
						>
							{(inputProps: any) => (
								<TextField
									{...inputProps}
									className="flex-1 mr-4 p-4"
									required
									error={cpfStatus.cpfAsError}
									helperText={cpfStatus.errorMessage}
									label="CPF"
									id="concorrente.data"
									variant="standard"
									fullwidth
									InputProps={{
										startAdornment: cpfStatus.loading ? (
											<InputAdornment position="start">
												<Icon className="animate-spin">cached</Icon>
											</InputAdornment>
										) : null,
										endAdornment:
											editable && !concorrente.data && form.cpf ? (
												<InputAdornment position="start">
													<Tooltip title="Limpar CPF Selecionado">
														<Icon
															className="text-20"
															onClick={_ => {
																reset(candidatoInitialState);
															}}
															color="action"
														>
															delete
														</Icon>
													</Tooltip>
												</InputAdornment>
											) : null
									}}
								/>
							)}
						</InputMask>
					)}
				/>

				<Controller
					name="nome"
					control={control}
					rules={{
						required: true // Add validation rule as needed
					}}
					render={({ field }) => (
						<TextField
							{...field}
							className="flex-1 ml-4 p-4"
							error={!!fieldError.nome}
							value={concorrenteNome}
							required
							helperText={fieldError.nome}
							label="Nome Candidato"
							id="nome"
							variant="standard"
							disabled={!editable}
							fullwidth
							onChange={e => {
								// field.onChange(e); // The default handler from Controller
								setConcorrenteNome(e.target.value); // Your custom handler
							}}
							onBlur={e => {
								field.onChange(e);
								field.onBlur(e); // The default handler from Controller
								setValue('nome', e.target.value); // Your custom handler
							}}
						/>
					)}
				/>
			</div>
			<div className="flex flex-row mt-16 md:overflow-hidden">
				<Controller
					name="data_nascimento"
					control={control}
					defaultValue="01/01/1970"
					render={({ field }) => (
						<TextField
							{...field}
							type="date"
							label="Data de Nascimento"
							className="flex-1 mr-4 p-4"
							disabled={!editable}
							variant="standard"
						/>
					)}
				/>

				<Controller
					name="nm_urna"
					control={control}
					render={({ field }) => (
						<TextField
							{...field}
							className="flex-1 mr-4 p-4"
							required
							onChange={e => {
								setConcorrenteNomeUrna(e.target.value);
							}}
							onBlur={e => {
								field.onChange(e);
								field.onBlur(e);
								setValue('nm_urna', e.target.value);
							}}
							value={concorrenteNomeUrna}
							error={!!fieldError.nm_urna}
							helperText={!!fieldError.nm_urna}
							label="Nome Urna"
							id="nm_urna"
							variant="standard"
							disabled={!editable}
							fullwidth
						/>
					)}
				/>

				<TextField
					className="flex-1 ml-4 p-4"
					error={!!errors.titulo_eleitor}
					value={concorrenteTitEleitor}
					onChange={e => setConcorrenteTitEleitor(e.target.value)}
					onBlur={e => setValue('titulo_eleitor', e.target.value)}
					helperText={errors?.titulo_eleitor?.message}
					label="Titulo Eleitor"
					id="titulo_eleitor"
					variant="standard"
					disabled={!editable}
					fullwidth
				/>
			</div>
			<div className="flex flex-row mt-16 md:overflow-hidden">
				<Controller
					name="genero"
					control={control}
					defaultValue="" // Set the default value
					render={({ field }) => (
						<FormControl variant="standard" className="w-full sm:w-1/3 pr-10">
							<InputLabel id="genero-candidato">Gênero</InputLabel>
							<Select
								{...field} // Including field props from Controller
								labelId="genero-candidato"
								id="genero"
								disabled={!editable}
								label="Gênero"
							>
								<MenuItem key={0} value="ND">
									<em>Não Definido</em>
								</MenuItem>
								<MenuItem key={1} value="Masculino">
									Masculino
								</MenuItem>
								<MenuItem key={2} value="Feminino">
									Feminino
								</MenuItem>
							</Select>
						</FormControl>
					)}
				/>

				<Controller
					name="raca"
					control={control}
					render={({ field }) => (
						<FormControl variant="standard" className="w-full sm:w-1/3 pr-10">
							<InputLabel id="raca">Cor/Raça</InputLabel>
							<Select
								{...field}
								labelId="raca-concorrente"
								id="raca_concorrente"
								label="Cor/Raça"
								disabled={!editable}
							>
								{fillData && fillData.political
									? Object.values(fillData.political.racas).map(opt => (
											<MenuItem key={opt.cod} value={opt.cod}>
												{opt.desc}
											</MenuItem>
									  ))
									: null}
							</Select>
						</FormControl>
					)}
				/>

				<FormControl variant="standard" className="w-full sm:w-1/3">
					<InputLabel id="estado_civil">Estado Civil</InputLabel>
					<Controller
						name="estado_civil"
						control={control}
						rules={{
							required: true // Add validation rule as needed
						}}
						render={({ field }) => (
							<Select
								{...field}
								labelId="estado_civil"
								id="estado_civil"
								label="Estado Civil"
								disabled={!editable}
							>
								{fillData && fillData.political
									? Object.values(fillData.political.civil_status).map(opt => (
											<MenuItem key={opt.cod} value={opt.cod}>
												{opt.desc}
											</MenuItem>
									  ))
									: null}
							</Select>
						)}
					/>
				</FormControl>
			</div>

			<div className="flex flex-row mt-16 md:overflow-hidden">
				<Autocomplete
					freeSolo
					value={form.ocupacao ? getDescOcupacao(form.ocupacao) : ''}
					onChange={(event, newValue) => {
						// setValue('ocupacao', newValue)
						setValue('ocupacao', getCodOcupacao(newValue));
					}}
					inputValue={ocuppationInputValue}
					onInputChange={(event, newInputValue) => {
						setOcuppationInputValue(newInputValue);
					}}
					id="ocupacao"
					options={ocuppationOptions}
					className="w-full sm:w-1/2 pr-10"
					renderInput={params => (
						<TextField
							{...params}
							name="ocupation"
							label="Ocupação"
							disabled={!editable}
							variant="standard"
						/>
					)}
				/>

				<Controller
					name="instrucao"
					control={control}
					rules={{
						required: true // Add validation rule as needed
					}}
					render={({ field }) => (
						<FormControl variant="standard" className="w-full sm:w-1/2 pr-10">
							<InputLabel id="instrucao">Grau de Instrução</InputLabel>
							<Select
								{...field}
								labelId="instrucao"
								id="instrucao"
								label="Grau de Instrução"
								disabled={!editable}
							>
								{fillData && fillData.political
									? Object.values(fillData.political.education).map(opt => (
											<MenuItem key={opt.cod} value={opt.cod}>
												{opt.desc}
											</MenuItem>
									  ))
									: null}
							</Select>
						</FormControl>
					)}
				/>
			</div>


			<div className="flex flex-row mt-16 md:overflow-hidden">
				<TableContainer className="flex w-[90%] text-center mx-auto items-center" component={Paper}>
					<Table aria-label="simple table">
						<TableHead>
							<TableRow className="bg-gray-300">
								<StyledTableCell>Campanhas</StyledTableCell>
								<StyledTableCell>Eleito</StyledTableCell>
								<StyledTableCell>2º Turno</StyledTableCell>
								<StyledTableCell>Suplente</StyledTableCell>
								<StyledTableCell>Majorit,</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<StyledTableCell>
									<Item>{form && form.nr_pleitos ? form.nr_pleitos : 0}</Item>
								</StyledTableCell>
								<StyledTableCell>
									<Item>{form && form.nr_pleitos_eleito ? form.nr_pleitos_eleito : 0}</Item>
								</StyledTableCell>
								<StyledTableCell>
									<Item>{form && form.nr_pleitos_sec_round ? form.nr_pleitos_sec_round : 0}</Item>
								</StyledTableCell>
								<StyledTableCell>
									<Item>{form && form.nr_pleitos_suplente ? form.nr_pleitos_suplente : 0}</Item>
								</StyledTableCell>
								<StyledTableCell>
									<Item>{form && form.nr_majoritarias ? form.nr_majoritarias : 0}</Item>
								</StyledTableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</>
	);
}
