import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { BackspaceOutlined } from '@mui/icons-material';
import Button from '@mui/material/Button';


const StyledLink = styled(Link)({
	color: '#f66011 !important'

});


const idxItems = {
	sobre: '/',
	termos: '/terms/access',
	privacidade: '/terms/privacy',
	contato: '/contact'
};

function TermsRightSideIndex(props) {
	const { navigation } = props;
	const [hoverKey, setHoverKey] = useState(null);
	const navigate = useNavigate();

	const goBack = () => {
		navigate(-1);
	};

	return (
		<div>
			{Object.keys(idxItems).map(key => (
				<div className="pt-6 pb-6">
					<Typography variant="button">
						{key === navigation ? (
							<>
								<ChevronRightIcon />
								{key}
							</>
						) : (
							<StyledLink
								to={idxItems[key]}
								className={key === hoverKey ? 'animate-pulse' : ''}
								onMouseEnter={() => setHoverKey(key)}
								onMouseLeave={() => setHoverKey(null)}
							>
								{key}
							</StyledLink>
						)}
					</Typography>
				</div>
			))}
			<div className="absolute bottom-4 w-full text-center">
				<Button onClick={goBack} variant="outlined" startIcon={<BackspaceOutlined />}>
					Voltar
				</Button>
			</div>

		</div>
	);
}

export default TermsRightSideIndex;
