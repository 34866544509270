import {authRoles} from "../../../../auth";
import Opposition from "./Opposition";

const OppositionConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: authRoles.user,
    routes: [
        {
            path: '/dashboards/concorrentes',
            component: Opposition
        }
    ]
}

export default OppositionConfig
