import { lazy } from 'react';
import {authRoles} from "../../../../auth";

const Social = lazy(()=>import("./Social"))

const SocialConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: authRoles.user,
    routes: [
        {
            path: '/dashboards/social',
            element: <Social />
        }
    ]
}

export default SocialConfig;