// import i18next from 'i18next';
// import { authRoles } from 'app/auth';
// import DocumentationNavigation from '../main/documentation/DocumentationNavigation';
import NeurobotsNavigation from 'app/main/scopo-apps/neurobots/NeurobotsNavigation';
// import EnqueteNavigation from 'app/main/scopo-apps/enquetes/EnqueteNavigation';
// import ar from './navigation-i18n/ar';
// import en from './navigation-i18n/en';
// import tr from './navigation-i18n/tr';
import SocialNavigation from '../main/social-networks/SocialNavigation';
// import DashboardNavigation from '../main/scopo-apps/dashboards/DashboardNavigation';
// import MensagensNavigation from '../main/scopo-apps/mensagens/MensagensNavigation';
import ResearchNavigation from '../main/scopo-apps/research/ResearchNavigation';
import testesNavigation from '../main/testes/testesNavigation';

// i18next.addResourceBundle('en', 'navigation', en);
// i18next.addResourceBundle('tr', 'navigation', tr);
// i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
	{
		id: 'war-room',
		title: 'SALA DE GUERRA',
		type: 'item',
		icon: 'leaderboard',
		url: '/war-room'
	},
	{
		type: 'divider',
		id: 'divider-1'
	},
	{
		id: 'monitoring',
		title: 'monitoring',
		translate: 'MONITORAMENTO',
		type: 'group',
		icon: 'apps',
		children: [
			{
				id: 'alerts',
				title: 'Avisos',
				type: 'item',
				url: '/avisos',
				icon: 'notifications',
				badge: {
					title: 0,
					bg: '#7a1313',
					fg: '#FFFFFF'
				}
			},
			{
				id: 'convites',
				title: 'Convites',
				type: 'item',
				url: '/convites',
				icon: 'how_to_reg',
				badge: {
					title: 0,
					bg: '#044f01',
					fg: '#FFFFFF'
				}
			},
			{
				id: 'entities',
				title: 'Entidades',
				type: 'item',
				url: '/entidades',
				icon: 'domain',
				badge: {
					title: 0,
					bg: '#e35c00',
					fg: '#FFFFFF'
				}
			},
			{
				id: 'news-clipping',
				title: 'Notícias',
				type: 'item',
				icon: 'rss_feed',
				url: '/app/clipping'
			},
			{
				id: 'reports-dashboard',
				title: 'Relatórios',
				type: 'item',
				icon: 'sticky_note_2',
				url: '/reports'
			}
		]
	},
	{
		type: 'divider',
		id: 'divider-2'
	},
	{
		id: 'entidade-session',
		title: 'CENTRAL ENTIDADE',
		type: 'item',
		url: '/war-room'
	},
	{
		id: 'chat-entidade',
		title: 'Chat',
		type: 'item',
		url: 'entidade/chat',
		icon: 'chat'
	},
	{
		id: 'equipe-entidade',
		title: 'Equipe',
		type: 'item',
		url: 'entidade/equipe',
		icon: 'contacts',
		badge: {
			title: 0,
			bg: '#7a7a79',
			fg: '#FFFFFF'
		}
	},
	{
		id: 'stakeholders-entidade',
		title: 'Stakeholders',
		type: 'item',
		url: '/stakeholders',
		icon: 'groups3'
	},
	{
		id: 'estrutura_analise-entidade',
		title: 'Estrutura/Análise',
		type: 'item',
		url: 'entidade/brain',
		icon: 'psychology'
	},
	{
		id: 'ferramentas-gestao',
		title: 'Ferramentas/Gestão',
		type: 'collapse',
		children: [NeurobotsNavigation, ResearchNavigation, SocialNavigation]
	},

	// SocialNavigation,
	{
		type: 'divider',
		id: 'divider-3'
	},
	{
		id: 'support',
		title: 'Solicitar Suporte',
		type: 'item',
		url: '/support',
		icon: 'support'
	}
	// testesNavigation
];

export default navigationConfig;
