import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { tokenHeaders, urlBackend } from 'app/main/data/LocationContext';

const distribInitialState = {
	radar: {
		categorias: ['Emprego', 'Saúde', 'Educação', 'Segurança', 'Inovação', 'Transporte', 'Transparência', 'Renda'],
		grpValues: [],
		genderValues: [],
		socialValues: [],
		ageValues: [],
		educationValues: [],
		civilStatusValues: [],
		social_networks: [],
		title: '',
		gender: '',
		social: '',
		etario: ''
	}
};

export const getGroupDecisionFactors = createAsyncThunk(
	'kpis/distribGroups/getDecisionFactors',
	async (decisionGroupsToAvail, { dispatch, getState }) => {
		const { monitor_social } = getState().entidade.indicators;
		const action_range = monitor_social.data ? monitor_social.data.action_range : null;

		const pathDecisionFactors = () => {
			if (action_range) {
				const { country, region, state, city, neighborhood, range } = action_range;
				switch (range) {
					case 'nacional':
						return `/?group=${decisionGroupsToAvail}`;
					case 'estadual':
						return `/?uf=${state}&group=${decisionGroupsToAvail}`;
					case 'municipal':
						return `/?uf=${state}&city=${city}&group=${decisionGroupsToAvail}`;
					default:
						return `/?group=${decisionGroupsToAvail}`;
				}
			}
			return null;
		};

		const response = await axios.get(
			`${urlBackend}/api/evaluation/get_decision_factors${pathDecisionFactors()}`,
			tokenHeaders()
		);

		const { data } = await response;

		const grpCategories = Object.keys(data.grupo.contexto_grupo);
		const grpValues = Object.values(data.grupo.contexto_grupo);
		const genderValues = Object.values(data.grupo.contexto_genero);
		const socialValues = Object.values(data.grupo.contexto_social);
		const ageValues = Object.values(data.grupo.contexto_etario);
		const title = `${data.grupo.Genero}, ${data.grupo.Etario}: ${data.grupo.Social}`;
		const social_networks = data.grupo.rep_social_networks;
		const gender = data.grupo.Genero;
		const social = data.grupo.Social;
		const etario = data.grupo.Etario;

		dispatch(
			radarSetData({
				categorias: grpCategories,
				grpValues,
				genderValues,
				socialValues,
				ageValues,
				title,
				social_networks,
				gender,
				social,
				etario
			})
		);

		console.log(monitor_social);
	}
);

const distriGroupsSlice = createSlice({
	name: 'kpis/distribGroups',
	initialState: distribInitialState,
	reducers: {
		radarSetData: (state, action) => {
			const {
				categorias,
				grpValues,
				genderValues,
				socialValues,
				ageValues,
				title,
				social_networks,
				gender,
				social,
				etario
			} = action.payload;
			state.radar.categorias = categorias;
			state.radar.grpValues = grpValues;
			state.radar.genderValues = genderValues;
			state.radar.socialValues = socialValues;
			state.radar.ageValues = ageValues;
			state.radar.title = title;
			state.radar.social_networks = social_networks;
			state.radar.gender = gender;
			state.radar.social = social;
			state.radar.etario = etario;
		},
		radarSetDataset: (state, action) => {
			state.radar.dataset = action.payload;
		},
		radarReset: (state, action) => {
			state.radar = distribInitialState.radar;
		}
	}
});

export const { radarSetData, radarSetDataset, radarReset } = distriGroupsSlice.actions;

export default distriGroupsSlice.reducer;
