import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from 'app/store/fuse/messageSlice';
import { tokenHeaders, urlBackend } from '../../data/LocationContext';
import idbKeyval from '../scopoTempDb';

export const getEvolutionEntityIndicators = createAsyncThunk(
	'entidade/indicators/evolution/getEntityEvolution',
	async (arrId, { dispatch, getState }) => {
		dispatch(setEvolutionLoading(true));
		const response = await axios.get(
			`${urlBackend}/api/evaluation/leituras/?${arrId[0]}=${arrId[1]}`,
			tokenHeaders()
		);
		const { success, message, data } = await response.data;

		const cacheEvName = `cacheEvName${arrId[0]}_${arrId[1]}`;

		if (message) {
			dispatch(
				showMessage({
					message,
					autoHideDuration: 4000,
					anchorOrigin: {
						vertical: 'bottom', // top bottom
						horizontal: 'left' // left center right
					},
					variant: success ? 'success' : 'warning'
				})
			);
		}
		if (success) {
			// return data;
			const dataStore = JSON.parse(data);
			dataStore.id = cacheEvName;
			await idbKeyval
				.set(dataStore)
				.then(() => console.log('dados de evolução em cache'))
				.catch(err =>
					dispatch(
						showMessage({
							message: `Erro ao registrar evolução em cache: ${err}`,
							autoHideDuration: 4000,
							anchorOrigin: {
								vertical: 'bottom', // top bottom
								horizontal: 'right' // left center right
							},
							variant: 'error'
						})
					)
				);

			return JSON.parse(data);
		}
		return null;
	}
);

const evolutionInitialState = {
	loading: false,
	data: null
};

const evolutionSlice = createSlice({
	name: 'entidade/indicators/evolution',
	initialState: evolutionInitialState,
	reducers: {
		setEvolutionLoading: (state, action) => {
			state.loading = action.payload;
		},
		setEvolution: (state, action) => {
			state.loading = false;
			state.data = action.payload.data;
		},
		clearEvolution: () => evolutionInitialState
	},
	extraReducers: {
		[getEvolutionEntityIndicators.fulfilled]: (state, action) => {
			state.data = action.payload;
			state.loading = false;
		},
		[getEvolutionEntityIndicators.rejected]: (state, action) => {
			state = evolutionInitialState;
		}
	}
});

export const { setEvolutionLoading, setEvolution, clearEvolution } = evolutionSlice.actions;

export default evolutionSlice.reducer;
