import React, { lazy } from 'react';
import { authRoles } from 'app/auth';

const Entidade = lazy(() => import('./entidade/Entidade'));
const Entidades = lazy(() => import('./entidades/Entidades'));

const EstruturaConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/entidade/:id',
			element: <Entidade />
		},
		{
			path: '/entidades',
			element: <Entidades />
		}
	]
};

export default EstruturaConfig;
