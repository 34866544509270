import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { tokenHeaders, urlBackend } from '../../data/LocationContext';
import { showMessage } from '../../../store/fuse/messageSlice';
import idbKeyval from '../scopoTempDb';

const oldDisputeAddr = '/api/candidato/get_candidate_dispute_at_smscopo/?id=';
const detalhesMonSocialElectionsURL = '/api/eleicoes/detalhes/?mon_id=';

const disputeInitialState = {
	loading: false,
	data: null
};

// OLD getDisputeData request
// .get(`${urlBackend}/api/candidato/get_candidate_dispute_at_smscopo/?id=${entId}`,
//   tokenHeaders())
export const getDisputeData = createAsyncThunk(
	'entidade/indicators/dispute/getDispute',
	async (monId, { dispatch, getState }) => {
		const response = await axios.get(`${urlBackend}${detalhesMonSocialElectionsURL}${monId}`, tokenHeaders());

		const { success, message, data } = await response.data;
		console.log('PEGANDO DISPUTA MON ID ', monId)

		// saving cache
		const fileKeyName = `previousDisputeMonId_${monId}`;
		const dataStore = JSON.parse(data);
		dataStore.id = fileKeyName;
		await idbKeyval
			.set(dataStore)
			.then(dispatch(showMessage('Histórico de Disputa adicionado em cache')))
			.catch(err =>
				dispatch(
					showMessage({
						message: `Erro ao registrar disputa em cache: ${err}`,
						autoHideDuration: 4000,
						anchorOrigin: {
							vertical: 'bottom', // top bottom
							horizontal: 'right' // left center right
						},
						variant: 'error'
					})
				)
			);

		if (message) {
			dispatch(
				showMessage({
					message,
					autoHideDuration: 4000,
					anchorOrigin: {
						vertical: 'bottom', // top bottom
						horizontal: 'center' // left center right
					},
					variant: success ? 'success' : 'warning'
				})
			);
		}
		if (success) {
			console.log('TEM SUCESSO ', data)
			return JSON.parse(data);
		}
		console.log('NAO TEM SUCESSO ', data);
	}
);

const disputeSlice = createSlice({
	name: 'entidade/indicators/dispute',
	initialState: disputeInitialState,
	reducers: {
		setDisputeLoading: (state, action) => {
			state.loading = action.payload;
		},
		setDispute: (state, action) => {
			state.data = action.payload.data;
			state.loading = false;
		},
		clearDispute: () => disputeInitialState

	},
	extraReducers: {
		[getDisputeData.fulfilled]: (state, action) => {
			state.data = action.payload;
			state.loading = false;
		},
		[getDisputeData.rejected]: (state, action) => {
			state = disputeInitialState;
		},
		[getDisputeData.pending]: (state, action) => {
			state.loading = true;
		}
	}
});

export const { setDisputeLoading, setDispute, clearDispute } = disputeSlice.actions;

export default disputeSlice.reducer;
