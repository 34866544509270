import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// import { showMessage } from "../../../store/fuse/messageSlice";
import FuseUtils from '@fuse/utils';
import { addNotification } from 'app/fuse-layouts/shared-components/notificationPanel/store/dataSlice';
import { tokenHeaders, urlBackend } from '../../data/LocationContext';
import { showMessage } from '../../../store/fuse/messageSlice';

const pathGetScenarios = '/api/strategy/get_scenarios/?';

/**
 *  before I was using key, value in the async entInf.
 *  not needed anymore. All entities are integrated in entidade
 *  async (entInfo,{dispatch}) =>{
 *     const entityScenariosUrl = () => {
 *       const ent_type = Object.keys(entInfo)[0]
 *       const ent_id= Object.values(entInfo)[0]
 *       return `${urlBackend}${pathGetScenarios}${ent_type}=${ent_id}`;
 *     }
 *
 *  const entityScenariosUrl = () => {
 *       const ent_type = Object.keys(entInfo)[0]
 *       const ent_id= Object.values(entInfo)[0]
 *       return `${urlBackend}${pathGetScenarios}${ent_type}=${ent_id}`;
 *     }
 *     const response = await axios.get(entityScenariosUrl(),tokenHeaders());
 *
 *
 *  Was smart, but over complicated
 *
 * @type {AsyncThunk<unknown, void, {}>}
 */

export const getEntityScenarios = createAsyncThunk(
	'entidade/indicators/scenarios/getScenarios',
	async (params, { dispatch }) => {
		const key = Object.keys(params)[0];
		const value = Object.values(params)[0];
		const response = await axios.get(`${urlBackend}${pathGetScenarios}${key}=${value}`, tokenHeaders());

		const { success, message, as_scenarios, tipo, data, requested } = await response.data;

		if (as_scenarios || !success) {
			const mstTemplate = {
				id: FuseUtils.generateGUID(),
				message,
				options: { variant: 'success' }
			};

			dispatch(addNotification(mstTemplate));

			// dispatch(
			//   showMessage({
			//     message: message,
			//     autoHideDuration: 3000,
			//     anchorOrigin: {
			//       vertical  : 'top',//top bottom
			//       horizontal: 'center'//left center right
			//     },
			//     variant: success ? 'success' : 'warning'
			//   })
			// )
		}

		if (success) {
			dispatch(setLoadedScenariosState(true));
			dispatch(setRequestedScenarios(JSON.parse(requested)));
			return JSON.parse(data);
		}
		dispatch(clearScenarios);
		return [];

		// return await response.data;
	}
);

export const archiveScenario = createAsyncThunk(
	'entidade/indicators/scenarios/archiveScenario',
	async (scenario_id, { dispatch, getState }) => {
		// const scenarioState = getState().entidade.indicators.scenarios;
		const response = await axios.get(
			`${urlBackend}/api/strategy/archiveScenario/?scenario_id=${scenario_id}`,
			tokenHeaders()
		);
		const { success, data } = response.data;
		if (success) {
			return JSON.parse(data);
		}
	}
);

export const revalidateScenario = createAsyncThunk(
	'entidade/indicators/scenarios/revalidateScenario',
	async (scenario_id, { dispatch, getState }) => {
		const { selectedMonitorId } = getState().entidade.indicators.monitor_social;
		const response = await axios.get(
			`${urlBackend}/api/strategy/revalidateScenario/?scenario_id=${scenario_id}&mon_id=${selectedMonitorId}`,
			tokenHeaders()
		);
		const { success, data, message } = response.data;
		if (message) {
			dispatch(
				showMessage({
					message,
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center'
					},
					variant: success ? 'success' : 'warning'
				})
			);
		}

		if (success) {
			return JSON.parse(data);
		}
	}
);

export const approveRequestedScenarios = createAsyncThunk(
	'entidade/indicators/scenarios/approveRequestScenarios',
	async (scenarios_list, { dispatch, getState }) => {
		const { selectedMonitorId } = getState().entidade.indicators.monitor_social;
		const data = {
			selectedMonitorId,
			scenarios_list
		};
		const response = await axios.post(
			`${urlBackend}/api/strategy/approve_pending_scenario_requests/`,
			data,
			tokenHeaders()
		);
		dispatch(setRequestedScenarios(JSON.parse(response.data)));
	}
);

export const removeRequestScenarios = createAsyncThunk(
	'entidade/indicators/scenarios/removeRequestScenarios',
	async (scenarios_list, { dispatch, getState }) => {
		const { selectedMonitorId } = getState().entidade.indicators.monitor_social;
		const data = {
			selectedMonitorId,
			scenarios_list
		};
		const response = await axios.post(
			`${urlBackend}/api/strategy/remove_pending_scenario_requests/`,
			data,
			tokenHeaders()
		);

		dispatch(setRequestedScenarios(JSON.parse(response.data)));
	}
);

export const deleteScenario = createAsyncThunk(
	'entidade/indicators/scenarios/deleteScenario',
	async (selectedScenarioId, { dispatch, getState }) => {
		const { selectedMonitorId } = getState().entidade.indicators.monitor_social;
		const dataScenario = {
			selectedScenarioId,
			selectedMonitorId
		};
		const response = await axios.post(`${urlBackend}/api/strategy/delete_scenario/`, dataScenario, tokenHeaders());

		const { success, message, variant, data } = response.data;

		if (message) {
			dispatch(
				showMessage({
					message,
					autoHideDuration: 5000,
					anchorOrigin: {
						vertical: 'top', // top bottom
						horizontal: 'center' // left center right
					},
					variant
				})
			);
		}

		if (success) {
			dispatch(getEntityScenarios({ mon_id: selectedMonitorId }));
		}
	}
);

export const requestNewScenario = createAsyncThunk(
	'entidade/indicators/scenarios/requestNewScenario',
	async (dataForm, { dispatch, getState }) => {
		const { selectedMonitorId } = getState().entidade.indicators.monitor_social;
		if (selectedMonitorId) {
			console.log(dataForm);

			const dataJson = JSON.stringify(dataForm);

			const response = await axios.post(
				`${urlBackend}/api/strategy/request_scenario/?monSocialId=${selectedMonitorId}`,
				dataJson,
				tokenHeaders()
			);

			const { message, variant, success, data } = response.data;
			if (message) {
				dispatch(
					showMessage({
						message,
						autoHideDuration: 7000,
						anchorOrigin: {
							vertical: 'top', // top bottom
							horizontal: 'right' // left center right
						},
						variant
					})
				);
			}
			if (success) {
				const parsedData = JSON.parse(data);
				dispatch(setRequestedScenarios(parsedData));
			}
		}
	}
);

const scenarioAdapter = createEntityAdapter({});

export const {
	selectAll: selectScenarios,
	removeAll: removeScenarios,
	selectEntities: selectScenariosEntities,
	selectById: selectScenarioById
} = scenarioAdapter.getSelectors(state => state.entidade.indicators.scenarios);

const inicialRequestScenarioFields = {
	id: null,
	cenario: '',
	contexto: ['eleitoral'],
	local: [],
	ator: [],
	notes: '',
	insertNow: false
};

const resetState = {
	loaded: false,
	selectedScenarioId: null,
	requested: [],
	requestedDialogOpen: false,
	modalRequestedDialogOpen: false,
	scenarioRequest: inicialRequestScenarioFields
};

const scenariosSlice = createSlice({
	name: 'entidade/indicators/scenarios',
	initialState: scenarioAdapter.getInitialState(resetState),
	reducers: {
		setSelectedScenarioId: (state, action) => {
			state.selectedScenarioId = action.payload;
		},
		removeSelectedScenarioId: (state, action) => {
			state.selectedScenarioId = null;
		},
		setLoadedScenariosState: (state, action) => {
			state.loaded = action.payload;
		},
		clearScenarios: (state, action) => {
			state.loaded = false;
			state.selectedScenarioId = null;
		},
		removeAllScenarios: state => {
			scenarioAdapter.removeAll(state);
		},
		setRequestedScenarios: (state, action) => {
			state.requested = action.payload;
		},
		handleRequestedDialogOpen: (state, action) => {
			state.requestedDialogOpen = action.payload;
		},
		handleModalRequestedDialogOpen: (state, action) => {
			state.modalRequestedDialogOpen = action.payload;
		},
		setScenarioRequest: (state, action) => {
			state.scenarioRequest = action.payload;
		},
		resetScenarioRequest: state => {
			state.scenarioRequest = inicialRequestScenarioFields;
		}
	},
	extraReducers: {
		[getEntityScenarios.fulfilled]: (state, action) => {
			scenarioAdapter.setAll(state, action.payload);
		},
		[getEntityScenarios.rejected]: () => null,
		[archiveScenario.fulfilled]: (state, action) => {
			state.selectedScenarioId = null;
			scenarioAdapter.setAll(state, action.payload);
		},
		[revalidateScenario.fulfilled]: (state, action) => {
			state.selectedScenarioId = null;
			scenarioAdapter.setAll(state, action.payload);
		}
	}
});

export const {
	setSelectedScenarioId,
	removeSelectedScenarioId,
	setLoadedScenariosState,
	clearScenarios,
	removeAllScenarios,
	setRequestedScenarios,
	handleRequestedDialogOpen,
	handleModalRequestedDialogOpen,
	setScenarioRequest,
	resetScenarioRequest
} = scenariosSlice.actions;

export default scenariosSlice.reducer;
