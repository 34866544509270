import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { authRoles } from '../../../auth';

const TeamApp = lazy(() => import('./TeamApp'));

const TeamAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: 'entidade/equipe/:tp',
			element: <TeamApp />
		},
		{
			path: 'entidade/equipe',
			element: <Navigate to="/entidade/equipe/all" />
		}
	]
};

export default TeamAppConfig;
