import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateNavigationItem } from '../../store/fuse/navigationSlice';
import { handleFacebookConnection } from '../../store/fuse/socialNetworksSlice';

function SocialNetworksControl(props) {
	const { facebook, twitter } = useSelector(({ fuse }) => fuse.SocialNetwork);
	const routeParams = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const networks_avail = ['facebook', 'twitter'];
	const { sn } = routeParams;

	const statusConnected = {
		title: 'Conectado',
		bg: '#105314',
		fg: '#FFFFFF'
	};

	const statusDisconnected = {
		title: 'Conectar..',
		bg: '#d74a0c',
		fg: '#FFFFFF'
	};

	useEffect(() => {
		if (facebook) {
			dispatch(updateNavigationItem('facebook-socialNetworks', { badge: statusConnected }));
		} else {
			dispatch(updateNavigationItem('facebook-socialNetworks', { badge: statusDisconnected }));
		}

		if (twitter) {
			dispatch(updateNavigationItem('twitter-socialNetworks', { badge: statusConnected }));
		} else {
			dispatch(updateNavigationItem('twitter-socialNetworks', { badge: statusDisconnected }));
		}

		if (sn){
			if (networks_avail.includes(sn)){
				dispatch(handleFacebookConnection('sn'));
				navigate(-1);
			} else if (sn === 'view') {
				console.log('Acerte visualização networks');
			}
		}

	}, [facebook, twitter, dispatch, sn]);

	return null;
}

export default SocialNetworksControl;
