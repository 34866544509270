import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { tokenHeaders, urlBackend } from '../../data/LocationContext';
import { showMessage } from '../../../store/fuse/messageSlice';
import idbKeyval from '../scopoTempDb';

const patchCountries = country => `${urlBackend}/api/countries/detalhes/?country=${country}`;

const patchRegion = region => `${urlBackend}/api/regioes/detalhes/?id=${region}`;

const pathState = uf => `${urlBackend}/api/estados/detalhes/?uf=${uf}`;

const pathCity = (uf, city) => `${urlBackend}/api/municipios/detalhes/?uf=${uf}&cidade=${city}`;

const pathBairro = (uf, city, neighbourhood) =>
	`${urlBackend}/api/municipios/detalhes/?uf=${uf}&cidade=${city}&bairro=${bairro}`;

const getPathUrl = (dictData, dispatch) => {
	let { range, country, region, uf, city, neighbourhood } = dictData;

	// gambiarra para evitar erro no backend
	if (!('uf' in dictData)) {
		range = 'nacional';
	}

	switch (range) {
		case 'nacional':
			dispatch(setSelectedLocation(country));
			return patchCountries(country);
		case 'regional':
			dispatch(setSelectedLocation(region));
			return patchRegion(region);
		case 'estadual':
			dispatch(setSelectedLocation(uf));
			return pathState(uf);
		case 'municipal':
			dispatch(setSelectedLocation(city));
			return pathCity(uf, city);
		case 'bairro':
			dispatch(setSelectedLocation(neighbourhood));
			return pathBairro(uf, city, neighbourhood);
		default:
			return null;
	}
};

export const getLocationIndicators = createAsyncThunk(
	'entidade/indicators/location/getLocationIndicators',
	async (dictData, { dispatch, getState }) => {
		try {
			dispatch(changeLoadingLocationStatus(true));
			const { selectedMonitorId } = getState().entidade.indicators.monitor_social;
			const fileKeyName = `rangeMonId_${selectedMonitorId}`;

			const { range, country, region, uf, city, neighbourhood } = dictData;

			const config = tokenHeaders();
			config.method = 'get';
			config.url = getPathUrl(dictData, dispatch);

			if (config.url) {
				const response = await axios(config);
				console.log('API Response:', response.data);

				// Ensure response.data contains the 'id' property
				// const dataToStore = { ...response.data, id: fileKeyName };
				const dataToStore = response.data;
				dataToStore.id = fileKeyName;

				await idbKeyval
					.set(dataToStore)
					.then(value => console.log('IDB Set Value:', value))
					.catch(error => console.error('IDB Set Error:', error));

				console.log(response.data);
				return response.data;
			}
			throw new Error('Invalid URL');
		} catch (error) {
			console.error('Error in getLocationIndicators:', error);
			dispatch(changeLoadingLocationStatus(false));
			throw error; // Re-throw the error to be handled by createAsyncThunk's rejected action
		} finally {
			dispatch(changeLoadingLocationStatus(false));
		}
	}
);

// the classification indexa can be populacao, eleitores or idhm
const locationInitialState = {
	loading: false,
	range: 'nacional',
	classificationIndex: 'populacao',
	selected_location: 'Brasil',
	country: 'Brasil',
	region: null,
	uf: null,
	city: null,
	neighbourhood: null,
	data: null
};

const locationIndicatorsSlice = createSlice({
	name: 'entidade/indicators/locationIndicators',
	initialState: locationInitialState,
	reducers: {
		setRangeLocation: (state, action) => {
			state.range = action.payload.data;
		},
		setLocation: (state, action) => {
			const { country, region, uf, city, neighbourhood } = action.payload.data;
			state.country = country;
			state.region = region;
			state.uf = uf;
			state.city = city;
			state.neighbourhood = neighbourhood;
		},
		setSelectedLocation: (state, action) => {
			state.selected_location = action.payload;
		},
		setDataLocation: (state, action) => {
			state.data = action.payload.data;
			state.loading = false;
		},
		resetLocation: () => locationInitialState,
		changeClassificationIndex: (state, action) => {
			state.classificationIndex = action.payload;
		},
		changeLoadingLocationStatus: (state, action) => {
			state.loading = action.payload;
		}
	},
	extraReducers: {
		[getLocationIndicators.fulfilled]: (state, action) => {
			state.data = action.payload;
			state.loading = false;
		}
	}
});

export const {
	setRangeLocation,
	setLocation,
	setSelectedLocation,
	setDataLocation,
	resetLocation,
	changeClassificationIndex,
	changeLoadingLocationStatus
} = locationIndicatorsSlice.actions;

export default locationIndicatorsSlice.reducer;
