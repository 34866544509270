import { lazy } from 'react';
import { authRoles } from "app/auth";

const Brain = lazy(()=>import("./Brain"))

const BrainConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'entidade/brain',
      element: <Brain />
    }
  ]
}

export default BrainConfig