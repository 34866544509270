import {lazy} from 'react';
import {authRoles} from "../../../../auth";
const SegmentResearch = lazy(()=>import("./SegmentResearch"))


const SegmentResearchConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: authRoles.user,
    routes: [
        {
            path: '/pesquisa/segmento',
            element: <SegmentResearch/>
        }
    ]
}

export default SegmentResearchConfig