import './db/todo-db';
import './db/notification-panel-db';
import './db/knowledge-base-db'
import history from '@history';
import mock from './mock';

mock.onAny().passThrough();

if (module?.hot?.status() === 'apply') {
	const { pathname } = history.location;
	history.push('/loading');
	history.push({ pathname });
}
