import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { authRoles } from 'app/auth';

const Neurobots = lazy(() => import('./neurobots/Neurobots'));
const Neurobot = lazy(() => import('./neurobot/Neurobot'));

const NeurobotsConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.strategy,
	routes: [
		{
			path: '/neurobots/:tp',
			element: <Neurobots />
		},
		{
			path: '/neurobots',
			element: <Navigate to="/neurobots/all" />
		},
		{
			path: '/neurobot/:id',
			element: <Neurobot />
		}
	]
};

export default NeurobotsConfig;
