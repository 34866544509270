import { combineReducers } from '@reduxjs/toolkit';
import mapsReducer from 'app/main/store/kpis/maps';
import distribGroups from './distribGroupsRepSlice';
import realTimeSns from './realTimeSnsSlice';

const kpiReducer = combineReducers({
	distribGroups,
	maps: mapsReducer,
	realTimeSns
});

export default kpiReducer;
