import {
    GET_ASYNC_DATA,
    GET_PROGRESS,
    GET_PROGRESS_CELULARES,
    UPDATE_DATA,
    GET_PROGRESS_EMPRESAS,
    GET_PROGRESS_FIXOS,
    GET_PROGRESS_PESSOAS,
    GET_PROGRESS_POLITICO,
    GET_PROGRESS_PROCESSOS,
    GET_PROGRESS_TESTE, UPDATE_EMPRESAS,
    RESET_INDIVIDUAL_RESEARCH,
    RESET_INDIVIDUAL_PROGRESS
} from '../actions/actionTypes';

const individualResearchTemplate = {
    celulares_progress : null,
    empresas_progress : null,
    fixos_progress : null,
    pessoas_progress : null,
    politico_progress : null,
    processos_progress : null,
    teste_progress : null,
    researches : null,
    dados : null
}

export default function(state=individualResearchTemplate, action){
    switch(action.type){
        case GET_PROGRESS:
            return {
                ...state,
                celulares_progress: action.payload.progress,
            };
        case GET_PROGRESS_CELULARES:
            return {
                ...state,
                celulares_progress: action.payload.progress,
            };
        case GET_PROGRESS_EMPRESAS:
            return {
                ...state,
                empresas_progress: action.payload.progress,
            };
        case GET_PROGRESS_FIXOS:
            return {
                ...state,
                fixos_progress: action.payload.progress,
            };
        case GET_PROGRESS_PESSOAS:
            return {
                ...state,
                pessoas_progress: action.payload.progress,
            };
        case GET_PROGRESS_POLITICO:
            return {
                ...state,
                politico_progress: action.payload.progress,
            };
        case GET_PROGRESS_PROCESSOS:
            return {
                ...state,
                processos_progress: action.payload.progress,
            };
        case GET_PROGRESS_TESTE:
            console.log(action.payload.progress)
            return {
                ...state,
                teste_progress: action.payload.progress,
            };
        case GET_ASYNC_DATA:
            return {
                ...state,
                dados: action.payload,
            };
        case UPDATE_DATA:
            return {
                ...state,
                researches: action.payload.pesquisas,
                dados: action.payload.dados
            }
        case UPDATE_EMPRESAS:
            console.log(action.payload)
            return {
                ...state,
                dados: {
                    ...state.dados,
                    empresas: action.payload
                }
            }
        case RESET_INDIVIDUAL_RESEARCH:
            return {
                ...state,
                celulares_progress : null,
                empresas_progress : null,
                fixos_progress : null,
                pessoas_progress : null,
                politico_progress : null,
                processos_progress : null,
                // teste_progress : null,
                researches : null,
                dados : null
            }
        case RESET_INDIVIDUAL_PROGRESS:
            return {
                ...state,
                celulares_progress: null,
                empresas_progress: null,
                fixos_progress: null,
                pessoas_progress: null,
                politico_progress: null,
                processos_progress: null,
                teste_progress: null,
            }
        default:
            return state;
    }
}

