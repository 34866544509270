import { lazy } from 'react';
import { authRoles} from "../../../auth";


const MailConfirm = lazy(()=>import("./MailConfirm"))

const MailConfirmConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: '/register/email-confirm/:email',
            element: <MailConfirm />
        }
    ]
};

export default MailConfirmConfig;