import React, { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { batch, useDispatch, useSelector } from 'react-redux';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Clear, Update } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
// import { LicenseInfo } from '@mui/x-license-pro'; # it is in layout1
import { tokenHeaders, urlBackend } from 'app/main/data/LocationContext';
import { getEntityDataById, resetEntityType, updateEntityState } from 'app/main/store/entidade/SelectedEntitySlice';
import { clearSelectedEntity, getEntitiesList, resetEntityStatus } from 'app/main/store/entidade/entityStatusSlice';
import { restoreUserDefaultRoles, updateUserRole, updateUserSettings } from 'app/auth/store/userSlice';
import { cleanBookmarks } from 'app/main/scopo-apps/research/store/bookmarksSlice';
import {
	changeLoadingLocationStatus,
	getLocationIndicators,
	resetLocation,
	setDataLocation,
	setLocation,
	setRangeLocation
} from 'app/main/store/IndicatorsSlices/locationIndicatorsSlice';
import { setLockSystem } from 'app/store/fuse/environmentSlice';
import {
	clearCompetition,
	getEntityCompetitions,
	removeAllCompetition,
	selectCompetitions
} from 'app/main/store/IndicatorsSlices/competitionSlice';
import { cleanNetworks, removeAllSocialNetworks } from 'app/main/store/entidade/socialNetworkSlice';
import { getEntityChatList, resetChatSlice } from 'app/main/store/entidade/chatListSlice';
import { clearMarkers, getMonSocialSNSMap, setOfflineMarkersData } from 'app/main/store/kpis/maps/markersSlice';
import { updateNavigationItem } from 'app/store/fuse/navigationSlice';
import {
	clearSocialMonitors,
	getEntityMonitoresSociais,
	getMonitorSocialId,
	removeSelectedMonitorSocialId,
	setSelectedMonitorSocialId
} from 'app/main/store/IndicatorsSlices/monitorSocialSlice';
import { radarReset } from 'app/main/store/kpis/distribGroupsRepSlice';
import { clearPartidos, getPoliticalPartyInfo, setPartidos } from 'app/main/store/IndicatorsSlices/partidosSlice';
import {
	resetPartidosComp,
	setPartidosComp
} from 'app/main/store/entidade/analiseComparativaSlice/partidosComparacaoSlice/partidosComparacaoSlice';
import {
	resetActionRangeComparativoState,
	updateActionRangeComparativo
} from 'app/main/store/entidade/analiseComparativaSlice/actionRangeComparativoSlice';
import { clearVoters, getVoters, setVoters } from '../../store/IndicatorsSlices/votersSlice';
import {
	clearEvolution,
	getEvolutionEntityIndicators,
	setEvolution
} from '../../store/IndicatorsSlices/evolutionSlice';
import { clearDispute, getDisputeData, setDispute } from '../../store/IndicatorsSlices/disputeSlice';
import {
	clearScenarios,
	getEntityScenarios,
	removeAllScenarios,
	selectScenarios
} from '../../store/IndicatorsSlices/scenariosSlice';
import { cleanOperators, getOperators, removeAllOperators } from '../../store/entidade/operatorsSlice';
import {
	getPoliticalEvaluations,
	resetPoliticalEvaluationData,
	setPoliticalEvaluationData
} from '../../store/entidade/politico/politicalEvaluationsSlice';
import {
	cleanParticipantes,
	removeTodosParticipantes,
	resetMonitoredIds
} from '../../store/entidade/politico/participantesSlice';
import idbKeyval from '../../store/scopoTempDb';
import {
	resetVotacaoSelecionado,
	setVotacao
} from '../../store/entidade/analiseComparativaSlice/partidosComparacaoSlice/votacaoSelecionadoSlice';
import {
	defineUsuarioClipping,
	defineUsuarioClippingSettings,
	getClippings,
	setSelectedClippingMonitorId,
	setSelectedClippingUserId,
	setSelectedClippingUserImg
} from '../../store/news/clippingSlice';
import { resetMediaFonts } from '../../store/entidade/analiseComparativaSlice/fontsMediaLocationSlice';
import {
	setLocalVotersDialog,
	setValuesNmShorNameEntityVoters,
	setVotersGrpAno,
	setVotersGrpNrEleitores, setVotersGrpPrecisionRate
} from '../../../store/fuse/dialogSlice';

function EntitySelectScopo(props) {
	const dispatch = useDispatch();
	const urlUserUpdate = `${urlBackend}/api/auth/login`;

	const user = useSelector(state => state.auth.user);
	const entidade = useSelector(state => state.entidade);
	const selectedScenarioId = useSelector(state => state.entidade.indicators.scenarios.selectedScenarioId);
	const selectedCompetitionId = useSelector(state => state.entidade.indicators.competition.selectedCompetitionId);
	const entityIndicators = useSelector(state => state.entidade.indicators);
	const selectedEntityId = useSelector(state => state.entidade.SelectedEntity.id);
	// const partidos = useSelector(state => state.entidade.analise_comparativa.partidos);
	const backendStatus = useSelector(state => state.fuse.backendStatus);

	const [listEntities, setListEntities] = useState('');
	const [isSelectOpen, setIsSelectOpen] = useState(false);
	const [userRoles, setUserRoles] = useState(user.role);
	const [selectedMonSocialId, setSelectedMonSocialId] = useState('');

	const stateSlice = useSelector(state => state);
	const kpiMaps = useSelector(({ kpis }) => kpis.maps);

	const scenarios = stateSlice.entidade.indicators.scenarios ? useSelector(selectScenarios) : null;
	const competition = useSelector(selectCompetitions);

	// const { socketNotifications, scopoNotificationsStatus, pushMode } = useSelector(({ fuse }) => fuse.backendStatus);

	const { locked, offlineMode } = useSelector(({ fuse }) => fuse.environment);

	const getUserDefaultRoles = () => {
		axios.get(`${urlBackend}/api/account/?only_roles`, tokenHeaders()).then(response => {
			setUserRoles(response.data);
		});
	};

	const replace_title = {
		candidato: 'Candidato',
		pj: 'Pessoa Jurídica',
		pf: 'Pessoa Física'
	};

	// const partidoSelecionadoId = useSelector(({ entidade }) => entidade.analise_comparativa.partidos.selectedPartyId);

	// const partidoSelecionado = useSelector((state)=>selectPartidoById(state, state.entidade.analise_comparativa))

	// disabling the footer in some pages
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const settings = useSelector(({ fuse }) => fuse.settings.current);

	const [footerDisplay, setFooterDisplay] = useState(config.footer.display);
	const [envSettings, setEnvSettings] = useState(settings);
	const [currentPath, setCurrentPath] = useState('/');

	const location = useLocation();

	// Effect to handle path changes, locking system, and footer display updates
	useEffect(() => {
		if (currentPath !== location.pathname) {
			setCurrentPath(location.pathname);
			if (location.pathname === '/entidade' && !locked) {
				dispatch(setLockSystem(true));
			} else if (locked) {
				dispatch(setLockSystem(false));
			}
		}

		if (currentPath === '/war-room' && entidade.SelectedEntity.entityType && footerDisplay) {
			setEnvSettings(prev => ({
				...prev,
				layout: {
					...prev.layout,
					config: {
						...prev.layout.config,
						footer: { display: false, style: 'fixed' }
					}
				}
			}));
		} else if (!footerDisplay) {
			setEnvSettings(prev => ({
				...prev,
				layout: {
					...prev.layout,
					config: {
						...prev.layout.config,
						footer: { display: true, style: 'fixed' }
					}
				}
			}));
		}

		if (envSettings !== settings) {
			dispatch(updateUserSettings(envSettings));
		}
	}, [location.pathname, locked, currentPath, footerDisplay, envSettings, dispatch]);

	// Effect to update the user roles and reset entity status on user data change
	useEffect(() => {
		if (!backendStatus.entityInManagementMode && !backendStatus.entityInEditingMode) {
			if (user.role !== userRoles) {
				dispatch(updateUserRole(userRoles));
			}
		}
	}, [user.role, userRoles, backendStatus, dispatch]);

	// Effect to fetch entity list and update navigation items
	useEffect(() => {
		if (!backendStatus.entityInManagementMode && !backendStatus.entityInEditingMode) {
			if (entidade.entityStatus.entities) {
				const dataArray = Object.values(entidade.entityStatus.entities);
				const newData = dataArray
					.filter(obj => obj.show_entity_in_dropdown === true)
					.map(obj => ({
						id: obj.id,
						origem_entidade: obj.origem_entidade,
						tp_entidade: obj.tp_entidade,
						ctx_entidade: obj.ctx_entidade,
						name: obj.name,
						brain: obj.brain,
						short_name: obj.short_name,
						entity_image: obj.entity_image,
						status: obj.status
					}));
				setListEntities(generateMenuItems(newData));
			}

			dispatch(
				updateNavigationItem('entities', {
					id: 'entities',
					title: 'Entidades',
					type: 'item',
					url: '/entidades',
					icon: 'domain',
					badge: {
						title: entidade.entityStatus.ids.length,
						bg: entidade.entityStatus.ids.length > 0 ? '#044f01' : '#7a7a79',
						fg: '#FFFFFF'
					}
				})
			);
		}
	}, [entidade.entityStatus.entities, backendStatus, dispatch]);

	// Effect to handle selected entity changes and load related data
	useEffect(() => {
		if (entidade.SelectedEntity.id && location.pathname !== '/entidade') {
			// if (pushMode && scopoNotificationsStatus === 'Connected') {
			// 	console.log('Buscando pelo websock');
			// 	dispatch(changeLoadingLocationStatus(true));
			// 	dispatch(setEvolutionLoading(true));
			// 	dispatch(setRemoteLoading());
			// 	socketNotifications.send(
			// 		JSON.stringify({
			// 			type: 'getEntityById',
			// 			origem: user.data.displayName,
			// 			content: { entity_id: entidade.SelectedEntity.id }
			// 		})
			// 	);
			// } else {

			dispatch(getEntityDataById(entidade.SelectedEntity.id));
			dispatch(getEntityChatList(entidade.SelectedEntity.id));
			dispatch(getEntityMonitoresSociais(entidade.SelectedEntity.id));

			// }

			if (user.role !== userRoles) {
				dispatch(updateUserRole(userRoles));
			}
			if (
				entidade.SelectedEntity.id &&
				!backendStatus.entityInManagementMode &&
				!backendStatus.entityInEditingMode
			) {
				batch(() => {
					dispatch(getOperators({ id: entidade.SelectedEntity.id, tp: 'all' }));
					dispatch(getEntityCompetitions(entidade.SelectedEntity.id));
				});
			}
		}
	}, [entidade.SelectedEntity.id]);

	// Effect to monitor selected scenarios and competitions and update location data
	useEffect(() => {
		if (selectedScenarioId || selectedCompetitionId) {
			const action_range =
				(selectedScenarioId && scenarios.find(scenario => scenario.id === selectedScenarioId)?.action_range) ||
				(selectedCompetitionId && competition.find(comp => comp.id === selectedCompetitionId)?.action_range) ||
				{};

			if (action_range?.range) {
				dispatch(
					setRangeLocation({
						type: 'entidade/indicators/locationIndicators',
						data: action_range.range
					})
				);
				dispatch(
					setLocation({
						type: 'entidade/indicators/locationIndicators',
						data: {
							country: action_range.country || 'Brasil',
							region: action_range.region || null,
							uf: action_range.state || null,
							city: action_range.city || null,
							neighbourhood: action_range.neighborhood || null
						}
					})
				);
			} else {
				dispatch(
					setRangeLocation({
						type: 'entidade/indicators/locationIndicators',
						data: 'nacional'
					})
				);
				dispatch(
					setLocation({
						type: 'entidade/indicators/locationIndicators',
						data: {
							country: 'Brasil',
							region: null,
							uf: null,
							city: null,
							neighbourhood: null
						}
					})
				);
			}
		}
	}, [selectedScenarioId, selectedCompetitionId, scenarios, competition, dispatch]);

	// Effect to monitor Monitores Sociais and set selected monitor ID
	useEffect(() => {
		const { selectedMonitorId, data, loaded } = entityIndicators.monitor_social;

		if (!backendStatus.entityInEditingMode && !backendStatus.entityInManagementMode) {
			if (loaded && !selectedMonitorId && !data) {
				const keyWithPrincipalTrue = Object.entries(entityIndicators.monitor_social.entities).find(
					([key, value]) => value.principal === true
				);
				if (keyWithPrincipalTrue) {
					dispatch(setSelectedMonitorSocialId(keyWithPrincipalTrue[0]));
				} else {
					dispatch(setSelectedMonitorSocialId(entityIndicators.monitor_social.entities[0].id));
				}
			}

			if (
				entityIndicators.monitor_social.data &&
				entityIndicators.monitor_social.data.action_range &&
				selectedEntityId
			) {
				const { range, country, region, state, city, neighborhood } =
					entityIndicators.monitor_social.data.action_range;

				dispatch(setLocalVotersDialog(entityIndicators.monitor_social.data.action_range.location));

				const dData = {
					range,
					country,
					region,
					uf: state,
					city,
					neighborhood
				};

				const alcance = range || 'nacional';

				batch(() => {
					dispatch(
						setRangeLocation({
							type: 'entidade/indicators/locationIndicators',
							data: alcance
						})
					);
					dispatch(
						setLocation({
							type: 'entidade/indicators/locationIndicators',
							data: dData
						})
					);
				});

				const fileKeyName = `rangeMonId_${selectedMonitorId}`;
				idbKeyval.exists(fileKeyName).then(existLocationCache => {
					if (existLocationCache) {
						dispatch(changeLoadingLocationStatus(true));
						idbKeyval.get(fileKeyName).then(val => {
							dispatch(
								setDataLocation({
									type: 'entidade/indicators/locationIndicators',
									data: val
								})
							);
						});
					} else {
						dispatch(getLocationIndicators(dData));
					}
				});

				const reqEvInd = selectedMonitorId ? ['mon_id', selectedMonitorId] : ['id', selectedEntityId];
				const cacheEvName = `cacheEvName${reqEvInd[0]}_${reqEvInd[1]}`;

				idbKeyval.exists(cacheEvName).then(existEvCache => {
					if (existEvCache) {
						idbKeyval.get(cacheEvName).then(val => {
							dispatch(
								setEvolution({
									type: 'entidade/indicators/evolution',
									data: val
								})
							);
							dispatch(setVotersGrpPrecisionRate(val.leituras.relevancia_media))
						});
					}
				});

				if (!offlineMode) {
					if (selectedMonitorId || selectedEntityId) {
						dispatch(getEvolutionEntityIndicators(reqEvInd));
					}
				}

				const votersCacheName = `votersCacheMonId_${selectedMonitorId}`;
				idbKeyval.exists(votersCacheName).then(existVoters => {
					if (existVoters) {
						idbKeyval.get(votersCacheName).then(val => {
							dispatch(
								setVoters({
									type: 'entidade/indicators/voters',
									data: val
								})
							);
							batch(() => {
								dispatch(setVotersGrpAno(val.ano_referencia));
								dispatch(setVotersGrpNrEleitores(val.total_eleitores));
							});
						});
					} else {
						dispatch(getVoters(dData));
					}
				});

				const partidosCacheName = `partidosCacheMonId_${selectedMonitorId}`;
				idbKeyval.exists(partidosCacheName).then(existParties => {
					if (existParties) {
						idbKeyval.get(partidosCacheName).then(val => {
							dispatch(
								setPartidosComp({
									type: 'entidade/analise_comparativa/partidos',
									data: val
								})
							);
							delete val['1st'].votacao;
							dispatch(
								setPartidos({
									type: 'entidade/indicators/partidos',
									data: val
								})
							);
							dispatch(updateActionRangeComparativo(val.action_range));
						});
					} else {
						dispatch(getPoliticalPartyInfo({ mon_id: selectedMonitorId }));
					}
				});
			}
			if (selectedMonitorId && !entityIndicators.scenarios.loaded) {
				dispatch(getEntityScenarios({ mon_id: selectedMonitorId }));
			}
		}
	}, [entityIndicators.monitor_social, selectedEntityId, offlineMode, backendStatus, dispatch]);

	// Effect to handle changes in selected monitor social ID
	useEffect(() => {
		if (
			selectedEntityId &&
			entityIndicators.monitor_social.selectedMonitorId &&
			entityIndicators.monitor_social.selectedMonitorId !== selectedMonSocialId &&
			location.pathname !== '/entidade'
		) {
			setSelectedMonSocialId(entityIndicators.monitor_social.selectedMonitorId);

			const clippingEntityId = `entity_id_${selectedEntityId}`;
			dispatch(defineUsuarioClippingSettings({ tp: 'entity_id', id: selectedEntityId }));

			if (entityIndicators.monitor_social.selectedMonitorId) {
				batch(() => {
					// nao. defineUsuarioClipping vai pegar noticias. Define
					// manualmente para pegar noticias do monitor social
					// dispatch(defineUsuarioClipping(selectedEntityId));
					dispatch(setSelectedClippingUserId(clippingEntityId));
					dispatch(setSelectedClippingUserImg(entidade.SelectedEntity.img));
					// dispatch(checkUpdateEntityMonSocial(selectedEntityId));
					dispatch(setSelectedClippingMonitorId(entityIndicators.monitor_social.selectedMonitorId));
					dispatch(getClippings({ tp: 'mon_id', id: entityIndicators.monitor_social.selectedMonitorId }));

					dispatch(getMonitorSocialId(entityIndicators.monitor_social.selectedMonitorId));
				});

				const cacheDisputeKeyName = `previousDisputeMonId_${entityIndicators.monitor_social.selectedMonitorId}`;

				idbKeyval.exists(cacheDisputeKeyName).then(existsD => {
					if (existsD) {
						idbKeyval.get(cacheDisputeKeyName).then(val => {
							dispatch(
								setDispute({
									type: 'entidade/indicators/dispute',
									data: val
								})
							);
						});
					} else {
						dispatch(getDisputeData(entityIndicators.monitor_social.selectedMonitorId));
					}
				});

				const politicalEvalCache = `politicalEvalCacheMonId_${entityIndicators.monitor_social.selectedMonitorId}`;
				idbKeyval.exists(politicalEvalCache).then(existsPolEval => {
					if (existsPolEval) {
						idbKeyval.get(politicalEvalCache).then(val => {
							dispatch(
								setPoliticalEvaluationData({
									type: 'entidade/politico/evaluations',
									data: val
								})
							);
						});
					}
				});

				if (!offlineMode) {
					dispatch(getPoliticalEvaluations(entityIndicators.monitor_social.selectedMonitorId));
				}

				const cacheSNSMon = `cacheSNSMon_${entityIndicators.monitor_social.selectedMonitorId}`;
				idbKeyval.exists(cacheSNSMon).then(existsPreviousSNS => {
					if (existsPreviousSNS) {
						idbKeyval.get(cacheSNSMon).then(val => {
							dispatch(
								setOfflineMarkersData({
									type: 'kpis/map/markers',
									data: val
								})
							);
						});
					}
				});

				if (entidade.SelectedEntity.origem === 'candidato' && !kpiMaps.markers.loading && !offlineMode) {
					dispatch(getMonSocialSNSMap(entityIndicators.monitor_social.selectedMonitorId));
				}
			} else {
				dispatch(defineUsuarioClipping(clippingEntityId));
			}
		}
	}, [
		entityIndicators.monitor_social.selectedMonitorId,
		selectedEntityId,
		location.pathname,
		selectedMonSocialId,
		offlineMode,
		dispatch,
		entidade.SelectedEntity.origem,
		kpiMaps.markers.loading
	]);

	// Effect to manage the selected votacao if necessary
	useEffect(() => {
		if (
			!entidade.analise_comparativa.votacao_selecionado.loaded &&
			!!entidade.analise_comparativa.votacao_selecionado.loading
		) {
			if (
				entidade.analise_comparativa.partidos.selectedPartySigla &&
				entidade.analise_comparativa.action_range.id
			) {
				const cacheVotPartidaria = `CacheVotPart_${entidade.analise_comparativa.action_range.id}_${entidade.analise_comparativa.partidos.selectedPartySigla}`;
				idbKeyval.exists(cacheVotPartidaria).then(existVot => {
					if (existVot) {
						idbKeyval.get(cacheVotPartidaria).then(val => {
							dispatch(
								setVotacao({
									type: 'entidade/analise_comparativa/votacao',
									data: val
								})
							);
						});
					}
				});
			}
		}
	}, [
		entidade.analise_comparativa.votacao_selecionado,
		entidade.analise_comparativa.partidos.selectedPartySigla,
		entidade.analise_comparativa.action_range.id,
		dispatch
	]);

	// Cleanup effect to reset entity status when the component unmounts
	useEffect(() => {
		return () => {
			if (entidade.entityStatus.loaded && !user.data.id) {
				dispatch(resetEntityStatus());
			}
		};
	}, [entidade.entityStatus.loaded, dispatch]);

	// refactored user effects

	function clearSelection() {
		batch(() => {
			dispatch(resetEntityType());
			dispatch(clearSelectedEntity());
			dispatch(restoreUserDefaultRoles());
			dispatch(clearScenarios());
			dispatch(removeAllScenarios());
			dispatch(clearCompetition());
			dispatch(removeAllCompetition());
			dispatch(cleanOperators());
			dispatch(removeAllOperators());
			dispatch(cleanNetworks());
			dispatch(removeAllSocialNetworks());
			dispatch(resetChatSlice());

			dispatch(clearSocialMonitors());
			dispatch(removeSelectedMonitorSocialId());

			dispatch(clearVoters());
			dispatch(clearPartidos());
			dispatch(resetPartidosComp());
			dispatch(cleanBookmarks());
			dispatch(clearDispute());
			dispatch(resetLocation());
			dispatch(clearMarkers());
			dispatch(radarReset());
			dispatch(resetPoliticalEvaluationData());
			dispatch(resetMonitoredIds());
			dispatch(cleanParticipantes());
			dispatch(removeTodosParticipantes());

			dispatch(resetActionRangeComparativoState());
			dispatch(resetVotacaoSelecionado());
			dispatch(resetMediaFonts());

			dispatch(clearEvolution());

			const clipping_user = `usuario_id_${user?.data?.id}`;

			dispatch(defineUsuarioClipping(clipping_user));
			dispatch(defineUsuarioClippingSettings({ tp: 'usuario_id', id: user?.data?.id }));

			getUserDefaultRoles();

			// dispatch(resetEntityStatus());
		});

		// dispatch(resetAnalysis());
		// );
		// dispatch(clearDispute());
		// dispatch(resetLocation());
		// dispatch(clearMarkers());
		// dispatch(setRangeLocation(null));
		// dispatch(setLocation(null));
		// dispatch(setDataLocation(null));
	}

	function generateMenuItems(data) {
		const categories = {};

		// Group objects by 'origem_entidade' category
		data.forEach(obj => {
			const category = obj.origem_entidade;
			if (!categories[category]) {
				categories[category] = [];
			}
			categories[category].push(obj);
		});

		// Generate <MenuItem> components for each category and filteredData
		const menuItems = Object.entries(categories).map(([category]) => ({ category }));
		const filteredData = Object.values(categories).flat();

		return { menuItems, filteredData };
	}

	const handleEntityChange = (origem, tp, ctx, id, img, nm, brain, short_name) => {
		setIsSelectOpen(false);

		dispatch(setValuesNmShorNameEntityVoters({ nm, short_name }));

		if (selectedEntityId && selectedEntityId !== id) {
			clearSelection();
		}

		if (origem) {
			dispatch(updateEntityState(origem, tp, ctx, id, img, nm, brain));
		}

		// console.log(stateSlice)

		if (id && entidade.entityStatus.entities[id]) {
			setUserRoles(entidade.entityStatus.entities[id].functions_list);
		}
	};

	const subItemList = item => {
		if (item.tp_entidade && item.ctx_entidade) {
			const subtitle = `${item.tp_entidade} - ${item.ctx_entidade}`;
			return subtitle;
		}
		if (item.tp_entidade) {
			const subtitle = item.tp_entidade;
			return subtitle;
		}
		return '---';
	};

	function returnMenuEntities(lstEntities) {
		const arr_lst = [];
		Object.values(lstEntities.menuItems)
			.sort((a, b) => a.category.localeCompare(b.category))
			.map(mi => {
				const filteredItems = Object.values(lstEntities.filteredData)
					.filter(et => et.origem_entidade === mi.category)
					.sort((a, b) => a.short_name.localeCompare(b.short_name));
				arr_lst.push(
					<MenuItem disabled value="" key={mi.category}>
						<em>{replace_title[mi.category]}</em>
					</MenuItem>,
					...filteredItems.map(et => (
						<MenuItem
							key={et.id}
							value={et.id}
							name={et.short_name}
							disabled={et.status !== 'approved'}
							onClick={event =>
								handleEntityChange(
									et.origem_entidade,
									et.tp_entidade,
									et.ctx_entidade,
									et.id,
									et.entity_image,
									et.name,
									et.brain,
									et.short_name
								)
							}
						>
							{isSelectOpen && et.id !== selectedEntityId ? (
								<ListItemIcon className="min-w-40">
									<img
										src={et.entity_image}
										alt={et.short_name}
										className="h-24 w-24 rounded-full outline outline-offset-2 outline-1 outline-green-900 m-8"
									/>
								</ListItemIcon>
							) : (
								''
							)}
							<ListItemText
								className={et.status === 'approved' ? 'text-inherit' : 'text-red-700'}
								primary={et.short_name}
								secondary={subItemList(et)}
							/>
						</MenuItem>
					))
				);
			});
		return arr_lst;
	}

	return (
		<FormControl className="ml-0" variant="standard" sx={{ m: 1, minWidth: 230 }}>
			<InputLabel id="select-entity-label">
				{entidade.SelectedEntity.origem
					? replace_title[entidade.SelectedEntity.origem]
					: 'Selecione Entidade de Análise'}
			</InputLabel>
			<Select
				labelId="select-entity-label"
				id="select-entity"
				value={selectedEntityId || ''}
				open={isSelectOpen}
				onOpen={_ => setIsSelectOpen(true)}
				onClose={_ => setIsSelectOpen(false)}
				disabled={locked}
				variant="standard"
			>
				{listEntities ? returnMenuEntities(listEntities) : ''}

				<Divider />
				<MenuItem id="update_entities">
					<ListItemIcon>
						<Update fontSize="small" />
					</ListItemIcon>
					<ListItemText onClick={() => dispatch(getEntitiesList())}>Atualizar</ListItemText>
				</MenuItem>
				<MenuItem id="clear_selection">
					<ListItemIcon>
						<Clear fontSize="small" />
					</ListItemIcon>
					<ListItemText onClick={_ => clearSelection()}>Limpar Seleção</ListItemText>
				</MenuItem>
			</Select>
		</FormControl>
	);
}

export default EntitySelectScopo;
