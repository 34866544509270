import React, { useEffect, useMemo, useState, forwardRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { TableVirtuoso, Virtuoso } from 'react-virtuoso';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FuseLoading from '@fuse/core/FuseLoading';
import SearchLIstMultiSelectMenu from './SearchLIstMultiSelectMenu';
import ScopoTableNoPaginationNbots from '../../../main/components/ScopoTableNoPaginationNbots';
import { tokenHeaders, urlBackend } from '../../../main/data/LocationContext';
import { setRemoteLoaded, setRemoteLoading } from '../../../store/fuse/environmentSlice';

const tempData = [
	{
		cpf: '976.065.296-04',
		nome: 'ELIANE MOREIRA DE AGUILAR',
		ano: 2022,
		cargo: 'DEPUTADO FEDERAL',
		partido: 'PT',
		uf: 'MG',
		cidade: 'MINAS GERAIS',
		resultado: 'SUPLENTE',
		img: 'https://monitor.scopo.online/media/candidatos/2022/fotos/MG/FMG130001607271_div.jpg'
	},
	{
		cpf: '080.525.726-81',
		nome: 'MAGNA GIL AGUILAR',
		ano: 2022,
		cargo: 'DEPUTADO ESTADUAL',
		partido: 'PSD',
		uf: 'MG',
		cidade: 'MINAS GERAIS',
		resultado: 'SUPLENTE',
		img: 'https://monitor.scopo.online/media/candidatos/2022/fotos/MG/FMG130001604362_div.jpg'
	},
	{
		cpf: '141.916.876-20',
		nome: 'GILBERTO GONÇALVES DE AGUILAR',
		ano: 2020,
		cargo: 'PREFEITO',
		partido: 'PSDB',
		uf: 'MG',
		cidade: 'RIO DO PRADO',
		resultado: 'NÃO ELEITO',
		img: 'https://monitor.scopo.online/media/candidatos/2020/fotos/MG/FMG130000772713_div.jpg'
	}
];

function SearchListTable(props) {
	const { params, handleGetCandidateInfo } = props;

	const { remoteLoading } = useSelector(({ fuse }) => fuse.environment);

	const [filteredData, setFilteredData] = useState(null);

	const dispatch = useDispatch();

	// if (nome && nome.trim()){
	// 	console.log('TEM NOME. PROCURE!')
	// }

	useEffect(() => {
		if (params && !remoteLoading) {
			dispatch(setRemoteLoading());
			const { nome, inclui_nm_urna, tp } = params;
			console.log(nome, inclui_nm_urna, tp);

			axios
				.post(`${urlBackend}/api/research/busca_candidatos/`, params, tokenHeaders())
				.then(r => {
					console.log(r);
					const { success, message, data } = r.data;
					console.log(success, message, data);
					console.log(data);

					const formatedList = Object.entries(data).map(([key, value]) => {
						return {
							cpf: key,
							nome: value[0],
							ano: value[1],
							cargo: value[2],
							partido: value[3],
							uf: value[4],
							cidade: value[5],
							resultado: value[6],
							img: value[7]
						};
					});

					setFilteredData(formatedList);

					dispatch(setRemoteLoaded());
				})
				.catch(error => {
					console.log(error);
					dispatch(setRemoteLoaded());
				});
		}
	}, [params]);

	const TableComponents = {
		Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
		Table: props => <Table {...props} style={{ borderCollapse: 'separate' }} />,
		TableHead,
		TableRow,
		TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />)
	};

	if (remoteLoading) {
		return (
			<div className="w-full h-full flex items-center justify-center text-center">
				<FuseLoading />
			</div>
		);
	}
	if (!filteredData) {
		return (
			<div className="w-full h-full flex items-center justify-center text-center">
				<Typography variant="h5">Nenhum candidato na lista</Typography>
			</div>
		);
	}
	console.log(filteredData);

	return (
		<div className="flex w-full h-full items-center justify-center text-center">
			<TableVirtuoso
				style={{ height: '90%', width: '100%', margin: 'auto' }}
				data={filteredData}
				components={TableComponents}
				fixedHeaderContent={() => (
					<TableRow>
						<TableCell className="w-16 bg-white items-center justify-center mx-auto" />
						<TableCell style={{ width: 200, background: 'white' }}>Nome</TableCell>
						<TableCell style={{ width: 80, background: 'white' }}>Ano</TableCell>
						<TableCell style={{ width: 200, background: 'white' }}>Cargo</TableCell>
						<TableCell style={{ width: 120, background: 'white' }}>Resultado</TableCell>
						<TableCell style={{ background: 'white' }}>UF</TableCell>
						<TableCell style={{ background: 'white' }}>Cidade</TableCell>
						<TableCell style={{ background: 'white' }}>Partido</TableCell>
					</TableRow>
				)}
				itemContent={(index, candidato) => (
					<>
						<TableCell
							className="cursor-pointer w-16 bg-white items-center justify-center mx-auto"
							onClick={() => handleGetCandidateInfo(candidato.cpf)}
						>
							<Avatar alt={candidato.nome} src={candidato.img} />
						</TableCell>
						<TableCell
							className="cursor-pointer w-48 bg-white"
							onClick={() => handleGetCandidateInfo(candidato.cpf)}
						>
							{candidato.nome}
						</TableCell>
						<TableCell style={{ width: 80, background: 'white' }}>{candidato.ano}</TableCell>
						<TableCell style={{ width: 200, background: 'white' }}>{candidato.cargo}</TableCell>
						<TableCell style={{ width: 120, background: 'white' }}>{candidato.resultado}</TableCell>
						<TableCell style={{ background: 'white' }}>{candidato.uf}</TableCell>
						<TableCell style={{ background: 'white' }}>{candidato.cidade}</TableCell>
						<TableCell style={{ background: 'white' }}>{candidato.partido}</TableCell>
					</>
				)}
			/>
		</div>
	);
}

export default SearchListTable;
