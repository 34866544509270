import React, { lazy } from 'react';
import { authRoles } from '../../../auth';

const Candidato = lazy(() => import('./candidate/Candidate'));
const Entidades = lazy(() => import('app/main/scopo-apps/estrutura/entidades/Entidades'));

const ElectionsConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/candidato/:id',
			element: <Candidato />
		},
		{
			path: '/candidatos',
			element: <Entidades />
			// component: lazy(()=>import('./candidates/CandidatoTeste'))
		}
	]
};

export default ElectionsConfig;
