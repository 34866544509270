import React, { useEffect, useState } from 'react';
import '@fake-db';
import { SnackbarProvider } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import TagManager from 'react-gtm-module';
import { datadogRum } from '@datadog/browser-rum';
import { FacebookProvider } from 'react-facebook';
import BrowserRouter from '@fuse/core/BrowserRouter';
import FuseAuthorization from '@fuse/core/FuseAuthorization';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import { selectCurrLangDir } from 'app/store/i18nSlice';
import withAppProviders from './withAppProviders';
import { Auth } from './auth';
import { smscopoInfoASCII } from './main/data/LocationContext';
// import { ScopoProvider, ScopoContext } from './main/data/ScopoContext';
// import backendStatusWebSock from './main/components/backend/backendStatusWebSock';

if (process.env.NODE_ENV === 'production') {
	datadogRum.init({
		applicationId: '8f1200e7-ae5c-4d82-b532-fe25a42467c9',
		clientToken: 'pubd6d005dd12bd4f31dd7e404e13041fd4',
		site: 'us5.datadoghq.com',
		service: 'sm_scopo_monitor-frontend',
		env: 'production',
		version: '3.6',
		sessionSampleRate: 100,
		sessionReplaySampleRate: 20,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: 'mask-user-input'
	});

	console.log(smscopoInfoASCII);
	console.log('\n\n desativando log do browser para ambiente de produção');
	// console.log('\n\n logs ativados para debug!');
	console.log = () => {};
	console.debug = () => {};
	console.error = () => {};
} else {
	console.log(`${process.env.REACT_APP_COPYRIGHT} - Ambiente de Desenvolvimento`);
}

// import JSC from "./main/components/JSC";

const tagManagerArgs = {
	gtmId: 'GTM-WSH7HVH'
};

TagManager.initialize(tagManagerArgs);

const emotionCacheOptions = {
	rtl: {
		key: 'muirtl',
		stylisPlugins: [rtlPlugin],
		insertionPoint: document.getElementById('emotion-insertion-point')
	},
	ltr: {
		key: 'muiltr',
		stylisPlugins: [],
		insertionPoint: document.getElementById('emotion-insertion-point')
	}
};

const facebookAppId = process.env.REACT_APP_FACEBOOK_APPID;
// const facebookAppId = '253032921939778';

const App = () => {
	const langDirection = useSelector(selectCurrLangDir);
	const { user, login } = useSelector(({ auth }) => auth);
	const { socketNotifications } = useSelector(({ fuse }) => fuse.backendStatus);
	const [socketNotificationsStarting, setSocketNotificationsStarting] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		setTimeout(dispatch({ type: 'backendStatus/startWebSocket' }), 800);

		const _mtm = window._mtm = window._mtm || [];
		_mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
		const d = document;
		const g = d.createElement('script');
		const s = d.getElementsByTagName('script')[0];
		g.async = true; g.src='https://analytics.scopo.online/matomo/js/container_BbNVIoOn.js'; s.parentNode.insertBefore(g,s);

		return () => {
			dispatch({ type: 'backendStatus/stopWebSocket' });
		};
	}, []);

	useEffect(() => {
		if (user.data.id && login.success && !socketNotifications && !socketNotificationsStarting) {
			console.log('Usuário Entrou!! Ative o socket de notificação por usuário');
			setSocketNotificationsStarting(true);
			dispatch({ type: 'backendStatus/startNotificationsSocket' });
			setTimeout(() => setSocketNotificationsStarting(false), 4000);
		} else if (!user.data.id && socketNotifications) {
			console.log('Usuário saiu. Feche o socket de notificações');
			dispatch({ type: 'backendStatus/stopNotificationsSocket' });
		}
	}, [user, login]);

	return (
		<CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
			<FacebookProvider appId={facebookAppId}>
				<Auth>
					<BrowserRouter>
						<FuseAuthorization>
							<FuseTheme>
								<SnackbarProvider
									maxSnack={5}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'right'
									}}
									classes={{
										containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99'
									}}
								>
									<FuseLayout />
								</SnackbarProvider>
							</FuseTheme>
						</FuseAuthorization>
					</BrowserRouter>
				</Auth>
			</FacebookProvider>
		</CacheProvider>
	);
};

export default withAppProviders(App)();
