import React, { useState } from 'react';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function SearchLIstMultiSelectMenu(props) {
	const [anchorEl, setAnchorEl] = useState(null);
	function openSelectedMenu(event) {
		setAnchorEl(event.currentTarget);
	}

	function closeSelectedMenu() {
		setAnchorEl(null);
	}

	return (
		<div className="w-0.5 items-center content-center text-center">
			<IconButton
				className="p-0"
				aria-owns={anchorEl ? 'selectedContactsMenu' : null}
				aria-haspopup="true"
				onClick={() => {
					openSelectedMenu();
				}}
				size="large"
			>
				<Icon>more_horiz</Icon>
			</IconButton>
			<Menu id="selectedMenu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => closeSelectedMenu()}>
				<MenuItem
					onClick={() => {
						closeSelectedMenu();
					}}
				>
					<ListItemIcon className="min-w-40">
						<Icon>power</Icon>
					</ListItemIcon>
					<ListItemText primary="Conecta à Entidade" />
				</MenuItem>
			</Menu>
		</div>
	);
}

export default SearchLIstMultiSelectMenu;
