// for location reducer
export const GET_UFS = 'GET_UFS';
export const GET_CITIES = 'GET_CITIES';
export const UPDATE_UF = 'UPDATE_UF';
export const UPDATE_CITY = 'UPDATE_CITY';
export const CLEAR_CITIES = 'CLEAR_CITIES';

// for Company Reducer
export const SET_COMPANY = 'SET_COMPANY';
export const CLEAR_SELECTED_COMPANY = 'CLEAR_SELECTED_COMPANY';

// for User settings and reducer
export const SET_USER_ROLE = 'SET_USER_ROLE';

// for operators and dialogs
export const OPEN_NEW_OPERATOR_DIAG = 'OPEN_NEW_OPERATOR_DIAG';
export const CLOSE_NEW_OPERATOR_DIAG = 'CLOSE_NEW_OPERATOR_DIAG';
export const OPEN_EDIT_OPERATOR_DIAG = 'CLOSE_NEW_OPERATOR_DIAG';
export const CLOSE_EDIT_OPERATOR_DIAG = 'CLOSE_EDIT_OPERATOR_DIAG';
export const INVITE_NEW_OPERATOR = 'INVITE_NEW_OPERATOR';

// for Weather
export const GET_WEATHER = 'GET_WEATHER';

// for the forms fill data
export const GET_FILL_DATA = 'GET_FILL_DATA';
export const APPEND_FILL_DATA = 'APPEND_FILL_DATA';
