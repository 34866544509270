import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { tokenHeaders, urlBackend } from '../../data/LocationContext';
import idbKeyval from '../scopoTempDb';
import { batch } from 'react-redux';
import { setVotersGrpAno, setVotersGrpNrEleitores } from '../../../store/fuse/dialogSlice';

const patchVotersCountry = country => `${urlBackend}/api/countries/getEleitores/?country=${country}`;
const patchVotersUf = uf => `${urlBackend}/api/estados/getEleitores/?uf=${uf}`;
const patchVotersCity = (uf, city) => `${urlBackend}/api/municipios/getEleitores/?uf=${uf}&city=${city}`;

const votersInitialState = {
	loading: false,
	data: null
};

export const getVoters = createAsyncThunk(
	'entidade/indicators/voters/getVoters',
	async (dictData, { dispatch, getState }) => {
		dispatch(setVotersLoading(true));

		const { selectedMonitorId } = getState().entidade.indicators.monitor_social;

		const { range, country, region, uf, city, neighbourhood } = dictData;
		const config = tokenHeaders();
		switch (range) {
			case 'estadual':
				config.url = patchVotersUf(uf);
				break;
			case 'municipal':
				config.url = patchVotersCity(uf, city);
				break;
			case 'nacional':
				config.url = patchVotersCountry(country);
				break;
			default:
				config.url = null;
		}

		if (config.url) {
			const response = await axios(config);

			const votersCacheName = `votersCacheMonId_${selectedMonitorId}`;
			const dataStore = response.data;
			dataStore.id = votersCacheName;
			await idbKeyval
				.set(dataStore)
				.then(() => console.log('voters cached'))
				.catch(err => console.log('voters cached error:', err));

			batch(() => {
				dispatch(setVotersGrpAno(response.data.ano_referencia));
				dispatch(setVotersGrpNrEleitores(response.data.total_eleitores));
			});

			return response.data;
		}
	}
);

const votersSlice = createSlice({
	name: 'entidade/indicators/voters',
	initialState: votersInitialState,
	reducers: {
		setVotersLoading: (state, action) => {
			state.loading = action.payload;
		},
		setVoters: (state, action) => {
			state.data = action.payload.data;
		},
		clearVoters: () => votersInitialState
	},
	extraReducers: {
		[getVoters.fulfilled]: (state, action) => {
			state.loading = false;
			state.data = action.payload;
		},
		[getVoters.rejected]: (state, action) => {
			state = votersInitialState;
		}
	}
});

export const { setVotersLoading, setVoters, clearVoters } = votersSlice.actions;

export default votersSlice.reducer;
