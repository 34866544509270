import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import FuseUtils from '@fuse/utils';
import appsConfigs from 'app/main/apps/appsConfigs';
import CallbackConfig from 'app/main/callback/CallbackConfig';
import pagesConfigs from 'app/main/pages/pagesConfigs';
import UserInterfaceConfig from 'app/main/user-interface/UserInterfaceConfig';
import InDevConfig from 'app/main/inDev/inDevConfig';
import FuseLoading from '@fuse/core/FuseLoading';
import AccountConfigs from '../main/account/AccountConfigs';
import scopoAppsConfigs from '../main/scopo-apps/scopoAppsConfigs';
import SMScopoDocsConfig from '../main/pages/documentation/SMScopoDocsConfig';
import AuthConfigs from '../main/auth/AuthConfigs';
import { urlBackend } from '../main/data/LocationContext';

// import RegisterConfig from 'app/main/register/RegisterConfig';

const routeConfigs = [
	...appsConfigs,
	...pagesConfigs,
	...AuthConfigs,
	...AccountConfigs,
	...scopoAppsConfigs,
	...SMScopoDocsConfig,
	InDevConfig,
	UserInterfaceConfig,
	CallbackConfig
];

const routes = [
	// if you want to make whole app auth protected by default change defaultAuth for example:
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
	// The individual route configs which has auth option won't be overridden.
	...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),
	{
		path: '/',
		element: <Navigate to="war-room" />
	},
	{
		path: 'loading',
		element: <FuseLoading />
	},
	{
		path: '*',
		element: <Navigate to="/errors/error-404" />
	}
];

export default routes;
