import AnalyticsConfig from "./analytics/AnalyticsConfig";
import OppositionConfig from "./oposition/OppositionConfig";
import SocialConfig from "./social-networks/SocialConfig";

const dashboardsConfigs = [
    AnalyticsConfig,
    OppositionConfig,
    SocialConfig
]

export default dashboardsConfigs