import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';

function Opposition(props){
  return (
    <div>
      <h3>Opposition</h3>
    </div>
  )
}
export default Opposition