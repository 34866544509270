import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { tokenHeaders, urlBackend } from 'app/main/data/LocationContext';
import { addNotification } from 'app/fuse-layouts/shared-components/notificationPanel/store/dataSlice';
import FuseUtils from '@fuse/utils';
import { batch } from 'react-redux';

const pathTrending = '/api/news/get_trending/';

export const getTrendingNews = createAsyncThunk(
	'news/trendingNews/getTrendingNews',
	async (_, { dispatch, getState }) => {
		const { max_news, lapse_days } = getState().news.trendingNews;

		dispatch(setTrendingNewsLoading(true));

		const response = await axios.get(
			`${urlBackend}${pathTrending}?lapse_days=${lapse_days}&max_news=${max_news}`,
			tokenHeaders()
		);

		const { success, data, message, variant, word_cloud, contexts } = await response.data;
		const news_data = JSON.parse(data);
		const { timed_out, hits } = news_data;

		// let message = 'Manchetes carregadas com sucesso!';
		// let variante = 'success';

		// if (timed_out === true) {
		// 	message = 'Timeout ao carregar notícias';
		// } else if (trendingNews.data) {
		// 	message = 'Manchetes atualizadas com sucesso!';
		// 	variante = 'info';
		// }
		//
		// if (!('hits' in hits)) {
		// 	message = 'Erro ao carregar manchetes';
		// 	variante = 'error';
		// }

		if (word_cloud) {
			dispatch(setWordCloud(JSON.parse(word_cloud)));
			dispatch(setContexts(JSON.parse(contexts)));
		}

		dispatch(
			addNotification({
				id: FuseUtils.generateGUID(),
				message,
				options: { variant }
			})
		);

		const uniqueSources = [...new Set(hits.hits.map(article => article._source.source))];

		batch(() => {
			dispatch(setLoadedSources(uniqueSources));
		});

		return hits.hits;
	}
);

export const getMonitorSocialNews = createAsyncThunk(
	'news/trendingNews/getMonitorSocialNews',
	async (mon_id, { dispatch, getState }) => {
		dispatch(setTrendingNewsLoading(true));
		const response = await axios.get(`${urlBackend}/api/news/get_news/?mon_id=${mon_id}`, tokenHeaders());
		const { data } = await response;
		const news_data = JSON.parse(data);
		const { timed_out, hits } = news_data;
		dispatch(setTrendingNews(hits.hits));
	}
);

const initialState = {
	loading: false,
	data: null,
	word_cloud: null,
	contexts: null,
	last_updated: null,
	max_news: 50,
	lapse_days: 3,
	loadedSources: [], // all loaded news sources
	readListSources: [] // for filtering, removed sources from right news slide
};

const trendingNewsSlice = createSlice({
	name: 'news/trendingNews',
	initialState,
	reducers: {
		setTrendingNews: (state, action) => {
			state.loading = false;
			state.data = action.payload;
			state.last_updated = new Date().toISOString();
		},
		resetNewsTrendingNews: state => initialState,
		setTrendingNewsLoading: (state, action) => {
			state.loading = action.payload;
		},
		setWordCloud: (state, action) => {
			state.word_cloud = action.payload;
		},
		setContexts: (state, action) => {
			state.contexts = action.payload;
		},
		setTrendingNewsCount: (state, action) => {
			state.max_news = action.payload;
		},
		setTrendingNewsDays: (state, action) => {
			state.lapse_days = action.payload;
		},
		setLoadedSources: (state, action) => {
			state.loadedSources = action.payload;
			state.readListSources = action.payload;
		},
		addReadListSource: (state, action) => {
			if (!state.readListSources.includes(action.payload)) {
				state.readListSources.push(action.payload);
			}
		},
		removeReadListSource: (state, action) => {
			state.readListSources = state.readListSources.filter(source => source !== action.payload);
		}
	},
	extraReducers: {
		[getTrendingNews.fulfilled]: (state, action) => {
			state.loading = false;
			state.data = action.payload;
			state.last_updated = new Date();
		},
		[getTrendingNews.rejected]: (state, action) => {
			state.loading = false;
			state.data = null;
			state.last_updated = new Date();
		}
	}
});

export const {
	setTrendingNews,
	resetNewsTrendingNews,
	setWordCloud,
	setContexts,
	setTrendingNewsLoading,
	setTrendingNewsCount,
	setTrendingNewsDays,
	setLoadedSources,
	addReadListSource,
	removeReadListSource
} = trendingNewsSlice.actions;

export default trendingNewsSlice.reducer;
