import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { tokenHeaders, urlBackend } from '../../data/LocationContext';
import { resetReceiver } from '../../scopo-apps/chat/store/receiverSlice';
import { showMessage } from '../../../store/fuse/messageSlice';

const pathEntityChat = '/api/chat/entity/';

export const getEntityChatList = createAsyncThunk('entidade/chatList/getChatList', async id => {
	const url_get_chatlist = `${urlBackend}${pathEntityChat}?id=${id}`;
	console.log(url_get_chatlist);
	const response = await axios.get(url_get_chatlist, tokenHeaders());

	console.log(await response);
	return await response.data.entityChatList;
});

export const updateEntityChatList = (chatlist, selectedId) => {
	const ids = chatlist.map(item => item.id);
	const dictItems = {};
	for (const i in Object.entries(chatlist)) {
		dictItems[chatlist[i].id] = chatlist[i];
	}
	return dispatch => {
		dispatch(
			setChatList({
				ids,
				entities: dictItems,
				selectedChatListId: selectedId
			})
		);
	};
};

export const resetChatSlice = () => {
	return dispatch => {
		dispatch(clearChatList());
		dispatch(resetReceiver());
	};
};

const chatListAdapter = createEntityAdapter({});

export const { selectAll: selectChatList, selectById: selectChatListById } = chatListAdapter.getSelectors(
	state => state.entidade.chatList
);

const chatListSlice = createSlice({
	name: 'entidade/chatList',
	initialState: chatListAdapter.getInitialState({ selectedChatListId: null }),
	reducers: {
		setSelectedChatListId: (state, action) => {
			state.selectedChatListId = action.payload;
		},
		removeSelectedChatListId: (state, action) => {
			state.selectedChatListId = null;
		},
		setChatList: (state, action) => {
			chatListAdapter.setAll(state, action.payload);
		},
		clearChatList: (state, action) =>
			chatListAdapter.getInitialState({
				selectedChatListId: null
			})
	},
	extraReducers: {
		[getEntityChatList.fulfilled]: chatListAdapter.setAll
	}
});

export const {
	setSelectedChatListId,
	removeSelectedChatListId,
	setChatList,
	clearChatList } = chatListSlice.actions;

export default chatListSlice.reducer;
