import { configureStore, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { tokenHeaders, urlBackend } from 'app/main/data/LocationContext';
import { setMarkersData } from 'app/main/store/kpis/maps/markersSlice';

const pathVotosPartidos = `${urlBackend}/api/eleicoes/votos_partido/`;
const votacaoSelecionadoAdapter = createEntityAdapter({});

export const getVotacao = createAsyncThunk(
	'entidade/analise_comparativa/votacao_selecionado',
	async (params, { dispatch, getState }) => {
		dispatch(setVotacaoLoading(true));

		let { action_range } = getState().entidade.analise_comparativa;

		let { selectedPartySigla } = getState().entidade.analise_comparativa.partidos;

		if (params && 'siglaPartido' in params) {
			selectedPartySigla = params.siglaPartido;
		}

		if (params && 'action_range' in params) {
			action_range = params.action_range;
		}

		if (selectedPartySigla) {
			let urlVotos = `${pathVotosPartidos}?partido=${selectedPartySigla}`;

			// if (params && 'state' in params && params['state']){
			//   urlVotos+=`&uf=${params.state}`
			// }

			if (action_range && action_range.state) {
				urlVotos += `&uf=${action_range.state}`;
			}

			// if(params && 'city' in params && params['city']){
			if (action_range && action_range.city) {
				urlVotos += `&city=${action_range.city}`;
			}

			const config = tokenHeaders();
			config.url = urlVotos;
			const response = await axios(config);

			const { tp, resultado, markers } = response.data;

			if (tp === 'municipal') {
				dispatch(setMarkersData(markers));
				dispatch(setVotacaoLoading(true));
				const entidades = getState().entidade.analise_comparativa.votacao_selecionado.entities;
				return entidades;
			}
			return resultado;
		}
	}
);

export const {
	selectAll: selectTodasVotacoes,
	removeAll: removeTodasVotacoes,
	selectById: selectVotacaoById
} = votacaoSelecionadoAdapter.getSelectors(state => state.entidade.analise_comparativa.votacao);


const initialStateVotacaoSelecionado = {
	loading: false,
	loaded: false,
	tp: null,
	sigla: null,
	markers: [],
	searchText: '',
	tp_avail: 'ranking',
	min_value: 0,
	max_value: 30
}

const votacaoSelecionadoSlice = createSlice({
	name: 'entidade/analise_comparativa/votacao',
	initialState: votacaoSelecionadoAdapter.getInitialState(initialStateVotacaoSelecionado),
	reducers: {
		setVotacaoLoading: (state, action) => {
			state.loading = action.payload;
			state.loaded = false;
		},
		setVotacao: (state, action) => {
			const { tp, sigla, resultado, markers } = action.payload.data;
			const maxTotalVotos = Math.max(...resultado.map(item => item.turno_1.total_votos));
			const minTotalVotos = Math.min(...resultado.map(item => item.turno_1.total_votos));

			state.loading = false;
			state.loaded = false;
			state.tp = tp;
			state.sigla = sigla;
			state.markers = markers;
			state.tp_avail = 'votos';
			state.min_value = minTotalVotos;
			state.max_value = maxTotalVotos;
			votacaoSelecionadoAdapter.setAll(state, resultado)
		},
		resetVotacaoSelecionado: () => votacaoSelecionadoAdapter.getInitialState(initialStateVotacaoSelecionado)
	},
	extraReducers: {
		[getVotacao.fulfilled]: (state, action) => {
			state.loading = false;
			state.loaded = true;
			votacaoSelecionadoAdapter.setAll(state, action.payload);
		}
	}
});

export const {
	setVotacaoLoading,
	setVotacao,
	resetVotacaoSelecionado
} = votacaoSelecionadoSlice.actions;

export default votacaoSelecionadoSlice.reducer;
