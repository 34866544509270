import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { tokenHeaders, urlBackend } from '../../data/LocationContext';
import idbKeyval from '../scopoTempDb';
import { showMessage } from '../../../store/fuse/messageSlice';
import { setPartidosComp } from '../entidade/analiseComparativaSlice/partidosComparacaoSlice/partidosComparacaoSlice';

const pathPoliticalPartyInfo = `${urlBackend}/api/eleicoes/partidos/`;

export const getPoliticalPartyInfo = createAsyncThunk(
	'entidade/indicators/partidos/getPartidos',
	async (dictParty, { dispatch }) => {
		dispatch(setPartidosLoading(true));
		const { mon_id, partido, uf, city } = dictParty;
		let partidosCacheName = `partidosCacheMonId_${mon_id}`;
		const config = tokenHeaders();
		if (mon_id) {
			config.url = `${pathPoliticalPartyInfo}?mon_id=${mon_id}`;
			partidosCacheName = `partidosCacheMonId_${mon_id}`;
		} else if (partido) {
			if (city) {
				config.url = `${pathPoliticalPartyInfo}?uf=${uf}&city=${city}&partido=${partido}`;
				partidosCacheName = `partidosCacheCity_${city}_${uf}_${partido}`;
			} else if (uf) {
				config.url = `${pathPoliticalPartyInfo}?uf=${uf}&partido=${partido}`;
				partidosCacheName = `partidosCacheUf_${uf}_${partido}`;
			} else {
				config.url = `${pathPoliticalPartyInfo}?partido=${partido}`;
				partidosCacheName = `partidosCache_${partido}`;
			}
		}

		if (config.url) {
			const response = await axios(config);
			const { success, data, message } = response.data;

			const dataStore = JSON.parse(data);
			dataStore.id = partidosCacheName;
			await idbKeyval
				.set(dataStore)
				.then(() => console.log('partidos armazenados em cache'))
				.catch(err =>
					dispatch(
						showMessage({
							message: `Erro ao registrar partidos em cache: ${err}`,
							autoHideDuration: 4000,
							anchorOrigin: {
								vertical: 'bottom', // top bottom
								horizontal: 'right' // left center right
							},
							variant: 'error'
						})
					)
				);
			delete dataStore.id;
			dispatch(
				setPartidosComp({
					type: 'entidade/analise_comparativa/partidos',
					data: dataStore
				})
			);
			delete dataStore['1st'].votacao;
			return dataStore;
		}
	}
);

const partidosInicialState = {
	loading: false,
	data: null
};

const partidosSlice = createSlice({
	name: 'entidade/indicators/partidos',
	initialState: partidosInicialState,
	reducers: {
		setPartidosLoading: (state, action) => {
			state.loading = action.payload;
		},
		setPartidos: (state, action) => {
			state.data = action.payload.data;
		},
		clearPartidos: () => partidosInicialState
	},
	extraReducers: {
		[getPoliticalPartyInfo.fulfilled]: (state, action) => {
			state.loading = false;
			state.data = action.payload;
		},
		[getPoliticalPartyInfo.rejected]: () => partidosInicialState
	}
});

export const { setPartidosLoading, setPartidos, clearPartidos } = partidosSlice.actions;

export default partidosSlice.reducer;
