import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useGlobalFilter, useRowSelect, useSortBy, useTable, useExpanded, useFlexLayout } from 'react-table';
import Tooltip from '@mui/material/Tooltip';
import { TableVirtuoso } from 'react-virtuoso';
import React, { forwardRef, Fragment, useEffect, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Icon from '@mui/material/Icon';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
	const defaultRef = useRef();
	const resolvedRef = ref || defaultRef;

	useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate;
	}, [resolvedRef, indeterminate]);

	return (
		<>
			<Checkbox ref={resolvedRef} {...rest} />
		</>
	);
});

function ScopoTableNoPaginationNbots(props) {
	const { columns, data, onRowClick } = props;

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		allColumns
	} = useTable(
		{
			columns,
			data
		},
		useGlobalFilter,
		useSortBy,
		useRowSelect,
		useFlexLayout,
		hooks => {
			// Let's make a column for selection
			hooks.allColumns.push(_columns => [
				// Let's make a column for selection
				{
					id: 'selection',
					width: 64,
					minWidth: 40,
					maxWidth: 128,
					disableSortBy: true,
					// The header can use the table's getToggleAllRowsSelectedProps method
					// to render a checkbox.  Pagination is a problem since this will select all
					// rows even though not all rows are on the current page.  The solution should
					// be server side pagination.  For one, the clients should not download all
					// rows in most cases.  The client should only download data for the current page.
					// In that case, getToggleAllRowsSelectedProps works fine.
					Header: ({ getToggleAllRowsSelectedProps }) => (
						<div>
							<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
						</div>
					),
					// The cell can use the individual row's getToggleRowSelectedProps method
					// to the render a checkbox
					Cell: ({ row }) => (
						<div className="flex h-full items-center justify-center min-w-40 max-w-128">
							<IndeterminateCheckbox
								{...row.getToggleRowSelectedProps()}
								onClick={ev => ev.stopPropagation()}
							/>
						</div>
					)
				},
				..._columns
			]);
		}
	);

	function fixedHeaderContent() {
		return headerGroups.map(headerGroup => (
			<tr {...headerGroup.getHeaderGroupProps()} style={{ background: '#EEECE9' }}>
				{headerGroup.headers.map(column => (
					<th
						{...column.getHeaderProps([
							// Add the necessary props for flex layout
							{ style: { minWidth: column.minWidth, maxWidth: column.maxWidth } },
							column.getSortByToggleProps()
						])}
						className={column.isSorted ? (column.isSortedDesc ? 'sort-desc' : 'sort-asc') : ''}
						style={{
							minWidth: `${column.width}px`,
							maxWidth: `${column.width}px`
						}}
					>
						{column.render('Header')}
						<span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
					</th>
				))}
			</tr>
		));
	}

	// Config to TableVirtuoso component
	const TableComponents = {
		Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
		Table: ({ style, ...props }) => (
			<table {...getTableProps()} {...props} style={{ ...style, width: '90%', tableLayout: 'fixed' }} />
		),
		TableRow: ({ style, ...props }) => {
			const index = props['data-index'];
			const row = rows[index];
			return <tr {...props} {...row.getRowProps()} style={{ ...style, width: '90%' }} />;
		},
		TableBody: React.forwardRef(({ style, ...props }, ref) => (
			<tbody {...getTableBodyProps()} {...props} ref={ref} />
		))
	};

	return (
		<div className="flex relative w-full min-h-full justify-center items-center sm:border-1 sm:rounded-16 overflow-hidden">
			<TableVirtuoso
				style={{ height: '100%', width: '80%', margin: 'auto' }}
				data={data}
				totalCount={rows.length}
				components={TableComponents}
				fixedHeaderContent={fixedHeaderContent}
				itemContent={(index, user) => {
					const row = rows[index];
					prepareRow(row);

					return (
						<>
							<tr {...row.getRowProps()}>
								{row.cells.map((cell, cellIndex) => (
									<td
										{...cell.getCellProps()}
										style={{
											minWidth: `${headerGroups[0].headers[cellIndex].width}px`,
											maxWidth: `${headerGroups[0].headers[cellIndex].width}px`
										}}
									>
										{cell.render('Cell')}
									</td>
								))}
							</tr>

							{row.isExpanded && (
								<>
									<tr className="align-left">
										<td colSpan={allColumns.length}>
											<div style={{ paddingLeft: '2rem' }}>
												{/** Your collapsible content here */}
												<Box sx={{ margin: 1 }}>
													<Typography variant="h6" gutterBottom component="div">
														Dados Complementares
													</Typography>
													<Table size="small" aria-label="adicional">
														<TableHead>
															<TableRow {...row.getRowProps()} className="bg-gray-300">
																<TableCell>
																	<strong>Nome Completo</strong>
																</TableCell>
																<TableCell className="ml-48 mr-48">
																	<strong>CPF</strong>
																</TableCell>
																<TableCell className="ml-16 mr-64">
																	<strong>RG</strong>
																</TableCell>
																<TableCell className="ml-12 mr-12">
																	<strong>Estado Civil</strong>
																</TableCell>
																<TableCell>
																	<strong>Email</strong>
																</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															<TableRow {...row.getRowProps()}>
																<TableCell>
																	<Typography
																		variant="subtitle2"
																		className="w-128 text-left"
																	>
																		{row.original.full_name}
																	</Typography>
																</TableCell>
																<TableCell>
																	<Typography
																		variant="subtitle2"
																		className="text-left"
																	>
																		{row.original.cpf}
																	</Typography>
																</TableCell>
																<TableCell>
																	<Typography
																		variant="subtitle2"
																		className="w-128 text-left"
																	>
																		{row.original.rg}
																	</Typography>
																</TableCell>
																<TableCell>
																	<Typography
																		variant="subtitle2"
																		className="text-left"
																	>
																		{row.original.marital_status}
																	</Typography>
																</TableCell>
																{/* <TableCell> */}
																{/*   <Typography */}
																{/*     variant="subtitle2" */}
																{/*     className="text-right"> */}
																{/*     {row.original.sexo} */}
																{/*   </Typography> */}
																{/* </TableCell> */}
																<TableCell>
																	<Typography
																		variant="subtitle2"
																		className="text-left"
																	>
																		{row.original.email}
																	</Typography>
																</TableCell>
															</TableRow>
														</TableBody>
														<TableHead>
															<TableRow {...row.getRowProps()} className="bg-gray-300">
																<TableCell className="mr-16">
																	<strong>Sexo</strong>
																</TableCell>
																<TableCell className="ml-16 mr-16">
																	<strong>Signo</strong>
																</TableCell>
																<TableCell className="ml-16 mr-16">
																	<strong>Nº Contatos</strong>
																</TableCell>
																<TableCell>
																	<strong>Influenciados Indiretamente</strong>
																</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															<TableRow {...row.getRowProps()}>
																<TableCell className="w-100">
																	<Typography
																		variant="subtitle2"
																		className="text-left"
																	>
																		{row.original.sexo === 'M'
																			? 'Masculino'
																			: row.original.sexo === 'F'
																			? 'Feminino'
																			: 'Não Def.'}
																	</Typography>
																</TableCell>
																<TableCell className="w-80 mr-32">
																	<Typography
																		variant="subtitle2"
																		className="text-left"
																	>
																		{row.original.signo}
																	</Typography>
																</TableCell>
																<TableCell className="mr-64">
																	<Typography
																		variant="subtitle2"
																		className="text-left"
																	>
																		{row.original.nr_contatos.toLocaleString(
																			'pt-BR'
																		)}
																	</Typography>
																</TableCell>
																<TableCell>
																	<Typography
																		variant="subtitle2"
																		className="text-left"
																	>
																		{row.original.nr_influencia_indireta.toLocaleString(
																			'pt-BR'
																		)}
																	</Typography>
																</TableCell>
															</TableRow>
														</TableBody>
													</Table>
												</Box>
											</div>
										</td>
									</tr>
									<hr className="w-8 h-8 mx-auto my-8 bg-green-900 border-0 rounded md:my-12 dark:bg-green-900 animate-pulse" />
								</>
							)}

							{/* Empty row to ensure consistent size */}
							<tr style={{ height: 0 }}>
								<td colSpan={allColumns.length} style={{ padding: 0, border: 0 }} />
							</tr>
						</>
					);
				}}
			/>
		</div>
	);
}

export default ScopoTableNoPaginationNbots;
