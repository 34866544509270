import { createSlice } from '@reduxjs/toolkit';

export const votersGrpDataInicialState = {
	local: null,
	segmento: null,
	grupo: null,
	ctxSubtitle: 'Eleições 2024',
	entityName: null,
	entityShortName: null,
	valuesRef: null,
	ano_ref: 2024,
	nr_eleitores: null,
	precision_rate: 0.95
};

const dialogSlice = createSlice({
	name: 'dialog',
	initialState: {
		state: false,
		dialogConcorrenteByNameOpen: false,
		votersGrpDialogOpen: false,
		votersGrpData: votersGrpDataInicialState,
		options: {
			children: 'Sistema de IA Ativo.'
		}
	},
	reducers: {
		openDialog: (state, action) => {
			state.dialogConcorrenteByNameOpen = false;
			state.state = true;
			state.options = action.payload;
		},
		closeDialog: (state, action) => {
			state.state = false;
		},
		openDialogConcorrenteByName: (state, action) => {
			state.state = false;
			state.dialogConcorrenteByNameOpen = true;
		},
		closeDialogConcorrenteByName: (state, action) => {
			state.dialogConcorrenteByNameOpen = false;
		},
		handleChangeVotersDialogOpen: (state, action) => {
			state.votersGrpDialogOpen = action.payload;
		},
		setLocalVotersDialog: (state, action) => {
			state.votersGrpData.local = action.payload;
		},
		setSegmentoVotesDialog: (state, action) => {
			state.votersGrpData.segmento = action.payload;
		},
		setGroupVotesDialog: (state, action) => {
			state.votersGrpData.grupo = action.payload;
		},
		setCtxSubtitle: (state, action) => {
			state.votersGrpData.ctxSubtitle = action.payload;
		},
		setValuesRef: (state, action) => {
			console.log('dialogSlice')
			console.log(action.payload)
			state.votersGrpData.valuesRef = action.payload;
		},
		setValuesNmShorNameEntityVoters: (state, action) => {
			const { nm, short_name } = action.payload;
			state.votersGrpData.entityName = nm;
			state.votersGrpData.entityShortName = short_name;
		},
		setVotersGrpAno: (state, action) => {
			state.votersGrpData.ano_ref = action.payload;
		},
		setVotersGrpNrEleitores: (state, action) => {
			state.votersGrpData.nr_eleitores = action.payload;
		},
		setVotersGrpPrecisionRate: (state, action) => {
			state.votersGrpData.precision_rate = action.payload;
		},
		resetVotersDialogData: (state, action) => {
			state.votersGrpData = votersGrpDataInicialState;
		}
	}
});

export const {
	openDialog,
	closeDialog,
	openDialogConcorrenteByName,
	closeDialogConcorrenteByName,
	handleChangeVotersDialogOpen,
	setLocalVotersDialog,
	setSegmentoVotesDialog,
	setGroupVotesDialog,
	setCtxSubtitle,
	setValuesRef,
	setValuesNmShorNameEntityVoters,
	setVotersGrpAno,
	setVotersGrpNrEleitores,
	setVotersGrpPrecisionRate,
	resetVotersDialogData
} = dialogSlice.actions;

export default dialogSlice.reducer;
