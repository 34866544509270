import LoginConfig from './login/LoginConfig';
import LogoutConfig from './logout/logoutConfig';
import RegisterConfig from './register/RegisterConfig';
import ForgotPasswordConfig from './forgot-password/ForgotPasswordConfig';
import MailConfirmConfig from './mail-confirm/MailConfirmConfig';
import RegisterSuccessConfig from './register/success/RegisterSuccessConfig';
import TokenFailConfig from './register/token-fail/TokenFailConfig';
import LockPageConfig from './lock/LockPageConfig';

const AuthConfigs = [
	LoginConfig,
	LogoutConfig,
	LockPageConfig,
	RegisterConfig,
	ForgotPasswordConfig,
	MailConfirmConfig,
	RegisterSuccessConfig,
	TokenFailConfig
];

export default AuthConfigs;
