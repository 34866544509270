import { combineReducers } from '@reduxjs/toolkit';
import auth from 'app/auth/store';
import newsReducer from 'app/main/store/news';
import fuse from './fuse';
import i18n from './i18nSlice';
import localReducer from '../main/store/reducers/localReducer';
import fillDataReducer from '../main/store/reducers/fillDataReducer';
import entityReducers from '../main/store/entidade';
import firebasePush from '../main/components/pushNotifications/store/firebasePushSlice';
import researchReducer from '../main/scopo-apps/research/store/index';
import kpiReducer from '../main/store/kpis';

const createReducer = asyncReducers => (state, action) => {
	const combinedReducer = combineReducers({
		auth,
		fuse,
		i18n,
		firebasePush,
		locations: localReducer,
		entidade: entityReducers,
		fillData: fillDataReducer,
		research: researchReducer,
		kpis: kpiReducer,
		news: newsReducer,
		...asyncReducers
	});

	/*
	Reset the redux store when user logged out
	 */
	if (action.type === 'auth/user/userLoggedOut') {
		// state = undefined;
	}

	return combinedReducer(state, action);
};

export default createReducer;
