import { createSlice } from '@reduxjs/toolkit';

//type ex('operador'/'topico') - kind ex.('empresa'/candidato')
const initialState = {
  type: null,
  id: null,
  topic_kind: null
}

const receiverSlice = createSlice({
  name: 'chatApp/receiver',
  initialState: initialState,
  reducers:{
    setReceiver: (state,action) => action.payload,
    setReceiverType: (state, action) => {
      state.type = action.payload;
    },
    setReceiverId: (state, action) => {
      state.id = action.payload;
    },
    setReceiverTopic: (state, action) => {
      state.topic_kind = action.payload;
    },
    resetReceiver: (state,action) => initialState
  }
})

export const {setReceiver,setReceiverType,
  setReceiverId,setReceiverTopic,resetReceiver} = receiverSlice.actions;

export default receiverSlice.reducer;