const prodConfig = {
    apiKey: "AIzaSyCPbzSNNYT8svDiI0BA2jNIIcfAmfUlxxE",
    authDomain: "ecom-scopo-frontend.firebaseapp.com",
    projectId: "ecom-scopo-frontend",
    storageBucket: "ecom-scopo-frontend.appspot.com",
    messagingSenderId: "341510126881",
    appId: "1:341510126881:web:887c35012e83c43b0c9260",
    measurementId: "G-VC3ZFMXD2L",
};

const devConfig = {
    apiKey: "AIzaSyCPbzSNNYT8svDiI0BA2jNIIcfAmfUlxxE",
    authDomain: "ecom-scopo-frontend.firebaseapp.com",
    projectId: "ecom-scopo-frontend",
    storageBucket: "ecom-scopo-frontend.appspot.com",
    messagingSenderId: "341510126881",
    appId: "1:341510126881:web:887c35012e83c43b0c9260",
    measurementId: "G-VC3ZFMXD2L",
  // apiKey           : "YOUR_API_KEY",
  // authDomain       : "your-app.firebaseapp.com",
  // databaseURL      : "https://your-app.firebaseio.com",
  // projectId        : "your-app",
  // storageBucket    : "your-app.appspot.com",
  // messagingSenderId: "YOUR_MESSAGING_SENDER_ID"
};

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

export default config;
