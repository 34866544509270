import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { tokenHeaders, urlBackend } from 'app/main/data/LocationContext';
import { showMessage } from 'app/store/fuse/messageSlice';
import { IncluiParticipantesMarketshare } from './politico/participantesSlice';
import { setEntitiesClippingUsers } from '../news/clippingSlice';
// import { setEntitiesClippingUsers } from '../news/clippingSlice';

export const getEntitiesList = createAsyncThunk(
	'entidade/entityStatus/getEntitiesList',
	async (_, { dispatch, getState }) => {
		const response = await axios.get(`${urlBackend}/api/entidade/`, tokenHeaders());
		const { data } = response;
		const usuario = getState().auth.user.data;
		const { entitiesSelectOption } = getState().news.clipping;

		const usrDt = {
			id: `usuario_id_${usuario.id}`,
			sid: usuario.id,
			stp: 'usuario_id',
			origem: 'usuario',
			tp: 'usuario',
			ctx: 'usuario',
			nm: usuario.firstName ? `${usuario.firstName} ${usuario.lastName}` : usuario.displayName,
			img: usuario.photoURL
		};

		// Determine filter criteria based on entitiesSelectOption
		const filterCriteria =
			entitiesSelectOption === 'monitored'
				? j => j.show_entity_in_dropdown === true
				: j => j.signed_for_updates === true;

		// signed_for_updates
		const entLst = data.filter(filterCriteria).map(i => ({
			id: `entity_id_${i.id}`,
			sid: i.id,
			stp: 'entity_id',
			origem: i.origem_entidade,
			tp: i.tp_entidade,
			ctx: i.ctx_entidade,
			nm: i.short_name,
			img: i.entity_image
		}));

		entLst.unshift(usrDt);
		dispatch(setEntitiesClippingUsers(entLst.flat()));

		return data;
	}
);

function isJSON(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

export const getEntityStatusData = createAsyncThunk(
	'entidade/entityStatus/getEntityStatusData',
	async (entity_id, { dispatch }) => {
		const config = tokenHeaders();
		config.method = 'get';
		config.url = `${urlBackend}/api/entidade/data/?id=${entity_id}`;
		const response = await axios(config);

		const { success, message, data } = response.data;
		// console.log(response)
		const parsedData = JSON.parse(data);
		if (message) {
			dispatch(
				showMessage({
					message,
					autoHideDuration: 4000,
					anchorOrigin: {
						vertical: 'bottom', // top bottom
						horizontal: 'center' // left center right
					},
					variant: success ? 'success' : 'warning'
				})
			);
		}

		// if (success) {
		// 	return parsedData;
		// }
		return parsedData;
	}
);

// for comparing entities
export const getMonitoresSociaisSelectedEntity = createAsyncThunk(
	'entidade/entityStatus/getMonitoresSociaisSelectedEntity',
	async (entity_id, { dispatch }) => {
		const response = await axios.get(`${urlBackend}/api/monitor_social/data/?id=${entity_id}`, tokenHeaders());
		const { success, data } = await response.data;
		if (success) {
			const resultJson = JSON.parse(data);
			const mainMonitorSocial = resultJson.filter(obj => obj.principal === true).map(obj => obj.id);
			if (mainMonitorSocial.length > 0) {
				dispatch(setSelectedMonitorSocialId(mainMonitorSocial[0]));
				dispatch(getMonitorSocialIdSelectedEntity(mainMonitorSocial[0]));
			}
			return resultJson;
		}
		return [];
	}
);

export const getMonitorSocialIdSelectedEntity = createAsyncThunk(
	'entidade/entityStatus/getMonitorSocialIdSelectedEntity',
	async (monId, { dispatch, getState }) => {
		const response = await axios.get(`${urlBackend}/api/monitor_social/data/?id_monitor=${monId}`, tokenHeaders());
		const { success, data } = await response.data;
		if (success) {
			const parsedData = isJSON(data) ? JSON.parse(data) : data;

			const mktPlayersLimit = getState().entidade.entityStatus.data?.marketshare_players_limit
				? getState().entidade.entityStatus.data.marketshare_players_limit
				: getState().entidade.entityStatus.stakeholders.limit
				? getState().entidade.entityStatus.stakeholders.limit
				: 12;

			if (parsedData) {
				const { players } = parsedData;
				if (players) {
					if (mktPlayersLimit) {
						const monitoringPlayersIds = players
							.filter(obj => obj.monitoring === true)
							.map(obj => obj?.concorrente?.id)
							.filter(Boolean);

						dispatch(
							setStakeHolders({
								limit: mktPlayersLimit,
								monitoring: monitoringPlayersIds.length,
								remaining: mktPlayersLimit - monitoringPlayersIds.length,
								ids: monitoringPlayersIds
							})
						);
					}
					// dispatch(setParticipantes(players));
					dispatch(IncluiParticipantesMarketshare(players));
					delete parsedData.players;
				}
			}
			return parsedData;
		}
		return null;
	}
);

export const acceptEntityOpInvite = createAsyncThunk(
	'entidade/entityStatus/acceptEntityOpInvite',
	// eslint-disable-next-line camelcase
	async (invite_id, { dispatch, getState }) => {
		const response = await axios.get(`${urlBackend}/api/account/?accept_invite&invite_id=${invite_id}`);

		if (response.data.message) {
			dispatch(
				showMessage({
					message: response.data.message,
					autoHideDuration: 4000, // ms
					anchorOrigin: {
						vertical: 'top', // top bottom
						horizontal: 'center' // left center right
					},
					variant: response.data.success ? 'success' : 'error'
				})
			);
		}
		return response.data.entidades;
	}
);

export const excluiEntidadeFromBackend = createAsyncThunk(
	'entidade/entityStatus/excluiEntidade',
	async (entity_id, { dispatch, getState }) => {
		const response = await axios.get(
			`${urlBackend}/api/entidade/exclui_entidade/?entity_id=${entity_id}`,
			tokenHeaders()
		);

		const entityState = getState().entidate.entityStatus;

		const { success, message, data } = response.data;

		console.log(success, message, data, response);

		if (message) {
			dispatch(
				showMessage({
					message,
					autoHideDuration: 4000,
					variant: success ? 'success' : 'error'
				})
			);
		}
		if (success) {
			const parsedData = JSON.parse(data);
			dispatch(entitiesAdapter.setAll(entityState, parsedData));
			dispatch(getEntitiesList());
		}
	}
);

// export const getEntityCandidatoToEditByMonitorID = createAsyncThunk(
// 	'entidade/entityStatus/getEntityCandidatoToEditByMonitorID',
// 	async (monId, { dispatch, getState }) => {
// 		const { selectedMonitorId } = getState().entidade.indicators?.monitor_social;
// 		if (selectedMonitorId && parseInt(selectedMonitorId) === parseInt(monId)) {
// 		}
// 	}
// );

export const changeEntityDropdownView = (lst, action) => {
	const urlChange = `${urlBackend}/api/operators/change_dropdow_view/?action_op=${action}`;
	const data = { op_list_ids: lst };
	return dispatch => {
		axios
			.post(urlChange, data, tokenHeaders())
			.then(r => {
				console.log(r);
				dispatch(getEntitiesList());
			})
			.catch(err => console.error(err));
	};
};

export const changeEntityStatus = (lst, newStatus) => {
	const urlChange = `${urlBackend}/api/operators/change_entity_status/?new_status=${newStatus}`;
	const data = { entity_lst_ids: lst };
	return dispatch => {
		axios
			.post(urlChange, data, tokenHeaders())
			.then(r => {
				console.log(r);
				dispatch(getEntitiesList());
			})
			.catch(err => console.error(err));
	};
};

export const changeEntityUpdates = (lst, newUpdateStatus) => {
	const urlChange = `${urlBackend}/api/operators/change_entity_updates/?updated_status=${newUpdateStatus}`;
	const data = { entity_lst_ids: lst };
	return dispatch => {
		axios
			.post(urlChange, data, tokenHeaders())
			.then(r => {
				dispatch(getEntitiesList());
			})
			.catch(err => console.error(err));
	};
};

// eslint-disable-next-line camelcase
export const handleEntityAccessToggle = (entity_id, entity_status) => {
	return dispatch => {
		// eslint-disable-next-line camelcase
		if (entity_status === 'approved') {
			dispatch(changeEntityStatus([entity_id], 'denied'));
		} else {
			// eslint-disable-next-line camelcase
			dispatch(changeEntityStatus([entity_id], 'approved'));
		}
	};
};

// eslint-disable-next-line camelcase
export const handleUpdatesToggle = (entity_id, updated_status) => {
	return dispatch => {
		// eslint-disable-next-line camelcase
		if (updated_status) {
			// eslint-disable-next-line camelcase
			dispatch(changeEntityUpdates([entity_id], 0));
		} else {
			// eslint-disable-next-line camelcase
			dispatch(changeEntityUpdates([entity_id], 1));
		}
	};
};

// eslint-disable-next-line camelcase
export const createEntityReport = (entity_id, tp_report) => {
	const urlReport = `${urlBackend}/api/operators/generate_entity_report/?id=${entity_id}&tp_report=${tp_report}`;
	return dispatch => {
		axios
			.get(urlReport, tokenHeaders())
			.then(r => {
				dispatch(
					showMessage({
						message: r.data.message,
						autoHideDuration: 4000, // ms
						anchorOrigin: {
							vertical: 'top', // top bottom
							horizontal: 'center' // left center right
						},
						variant: r.data.success ? 'success' : 'error'
					})
				);
			})
			.catch(err => dispatch(showMessage({ message: err.message })));
	};
};

const entitiesAdapter = createEntityAdapter({});

export const {
	selectAll: selectEntities,
	selectById: selectEntityById,
	removeAll: removeEntities,
	removeById: removeEntityById
} = entitiesAdapter.getSelectors(state => (state && state.entidade ? state.entidade?.entityStatus : undefined));

export const setEntitiesLoadedStatus = status => {
	return dispatch => {
		dispatch(setSelectedEntityLoaded(status));
	};
};

const defaultStakeholdersLimits = {
	limit: 12,
	monitoring: 0,
	remaining: 12,
	ids: []
};

const resetState = {
	ids: [],
	entities: [],
	loaded: false,
	loading: 'idle',
	selectedEntityId: null,
	data: null,
	searchText: '',
	stakeholders: defaultStakeholdersLimits,
	monitores_sociais: {},
	selectedMonitorSocialData: null,
	selectedMonitorSocialId: null
};

function isJson(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

const entitySlice = createSlice({
	name: 'entidade/entityStatus',
	initialState: entitiesAdapter.getInitialState(resetState),
	reducers: {
		setSelectedEntityId: (state, action) => {
			state.selectedEntityId = action.payload;
		},
		setSelectedEntityData: (state, action) => {
			state.data = action.payload;
		},
		setSelectedEntityLoading: (state, action) => {
			state.loaded = false;
			state.loading = 'loading';
		},
		setSelectedEntityLoaded: (state, action) => {
			state.loaded = true;
			state.loading = 'idle';
		},
		setStakeHolders: (state, action) => {
			state.stakeholders = action.payload;
		},
		setEntitySearchText: {
			reducer: (state, action) => {
				state.searchText = action.payload;
			},
			prepare: event => ({ payload: event.target.value || '' })
		},
		clearSelectedEntity: (state, action) => {
			state.selectedEntityId = null;
			state.data = null;
			state.searchText = '';
			state.stakeholders = defaultStakeholdersLimits;
		},
		setSelectedMonitorSocialId: (state, action) => {
			state.selectedMonitorSocialId = action.payload;
		},
		resetEntityStatus: (state, action) => entitiesAdapter.getInitialState(resetState),
		setNewEntityImg: (state, action) => {
			state.data.image = action.payload;
		},
		setScopoNewsInSelectedMonitorSocialData: (state, action) => {
			state.selectedMonitorSocialData = { ...state.selectedMonitorSocialData, scopo_news: action.payload };
		},
		updateNewsFontsInSelectedMonitorSocialData: (state, action) => {
			state.selectedMonitorSocialData.scopo_news = {
				...state.selectedMonitorSocialData.scopo_news,
				scopo_fonts: action.payload
			};
		},
		updateRequestedFontsInSelectedMonitorSocialData: (state, action) => {
			state.selectedMonitorSocialData = {
				...state.selectedMonitorSocialData,
				requested_news_fonts: action.payload
			};
		},
		setEntitiesList: (state, action) => {
			entitiesAdapter.setAll(state, action.payload);
			state.loading = 'idle';
			state.loaded = true;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getEntitiesList.pending, state => {
				state.loading = 'loading';
				state.loaded = false;
			})
			.addCase(getEntitiesList.fulfilled, (state, action) => {
				entitiesAdapter.setAll(state, action.payload);
				state.loading = 'idle';
				state.loaded = true;
			})
			.addCase(getEntitiesList.rejected, (state, action) => resetState)
			.addCase(getEntityStatusData.pending, (state, action) => {
				state.loading = 'loading';
				state.loaded = false;
			})
			.addCase(getEntityStatusData.fulfilled, (state, action) => {
				state.loading = 'idle';
				state.loaded = true;

				const payloadData = isJson(action.payload) ? JSON.parse(action.payload) : action.payload;
				state.data = payloadData;
				state.selectedEntityId = parseInt(payloadData.id, 10);
			})
			.addCase(acceptEntityOpInvite.fulfilled, (state, action) => {
				state.data = action.payload;
			})
			.addCase(getMonitoresSociaisSelectedEntity.pending, (state, action) => {
				state.loaded = false;
				state.loading = 'loading';
			})
			.addCase(getMonitoresSociaisSelectedEntity.fulfilled, (state, action) => {
				state.loaded = true;
				state.loading = 'idle';
				// state.monitores_sociais.push(action.payload);
				state.monitores_sociais = action.payload;
			})
			.addCase(getMonitoresSociaisSelectedEntity.rejected, (state, action) => {
				state.monitores_sociais = {};
			})
			.addCase(getMonitorSocialIdSelectedEntity.pending, (state, action) => {
				state.loaded = false;
				state.loading = 'loading';
			})
			.addCase(getMonitorSocialIdSelectedEntity.fulfilled, (state, action) => {
				state.loaded = true;
				state.loading = 'idle';
				state.selectedMonitorSocialData = action.payload;
			})
			.addCase(getMonitorSocialIdSelectedEntity.rejected, (state, action) => {
				state.loaded = false;
				state.loading = 'idle';
				state.selectedMonitorSocialData = null;
				state.selectedMonitorSocialId = null;
			});
	}
});

export const {
	setSelectedEntityType,
	setSelectedEntityId,
	setSelectedEntityData,
	setSelectedEntityLoading,
	setSelectedEntityLoaded,
	setStakeHolders,
	setEntitySearchText,
	clearSelectedEntity,
	setSelectedMonitorSocialId,
	resetEntityStatus,
	setNewEntityImg,
	setScopoNewsInSelectedMonitorSocialData,
	setEntitiesList,
	updateNewsFontsInSelectedMonitorSocialData,
	updateRequestedFontsInSelectedMonitorSocialData
} = entitySlice.actions;

export default entitySlice.reducer;
