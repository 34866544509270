import { authRoles } from '../../auth';

const testesNavigation = {
	id: 'testes-testes',
	title: 'Testes Interface/Dev',
	type: 'collapse',
	icon: 'settings_input_component',
	auth: authRoles.tester,
	children: [
		{
			id: 'testes-component',
			title: 'Example',
			type: 'item',
			auth: authRoles.tester,
			icon: 'whatshot',
			url: '/example'
		},
		{
			id: 'locals-component',
			title: 'Search Cities',
			type: 'item',
			auth: authRoles.tester,
			icon: 'search',
			url: '/localSearch'
		},
		{
			id: 'todo-component',
			title: 'Todos',
			type: 'item',
			auth: authRoles.tester,
			icon: 'add_task',
			url: '/todos'
		}
	]
};

export default testesNavigation;
