import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	type: 'new',
	props: {
		open: false
	},
	data: null
};

export const openNewOpDialog = () => {
	const newState = {
		type: 'new',
		props: {
			open: true
		},
		data: null
	};
	return dispatch => dispatch(changeDialog(newState));
};

export const closeNewOpDialog = () => {
	return dispatch => dispatch(changeDialog(initialState));
};

export const closeEditOpDialog = () => {
	const newState = {
		type: 'edit',
		props: {
			open: false
		},
		data: null
	};
	return dispatch => dispatch(changeDialog(newState));
};

export const openEditOpDiag = data => {
	console.log(data);
	const newState = {
		type: 'edit',
		props: {
			open: true
		},
		data
	};
	console.log(newState);
	return dispatch => dispatch(changeDialog(newState));
};

const operatorSlice = createSlice({
	name: 'entidade/operatorDialog',
	initialState,
	reducers: {
		changeDialog: (state, action) => action.payload
	}
});

export const { changeDialog } = operatorSlice.actions;
export default operatorSlice.reducer;
