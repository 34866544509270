import { configureStore, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { tokenHeaders, urlBackend } from 'app/main/data/LocationContext';

import { updateActionRangeComparativo } from 'app/main/store/entidade/analiseComparativaSlice/actionRangeComparativoSlice';
import idbKeyval from '../../../scopoTempDb';
import { showMessage } from '../../../../../store/fuse/messageSlice';

const pathPartidos = `${urlBackend}/api/eleicoes/partidos/`;

export const setSelectdIdSiglaPartido = createAsyncThunk(
	'entidade/analise_comparativa/partidos/setIdPartido',
	async (sigla, { dispatch, getState }) => {
		const { entities } = getState().entidade.analise_comparativa.partidos;
		let resultKey;
		// let resultValue
		for (const [k, v] of Object.entries(entities)) {
			if (v.partido.sigla === sigla) {
				resultKey = k;
			}
		}
		dispatch(setSiglaPartidaria({ chave: parseInt(resultKey, 10), sigla }));
	}
);

export const getPartidosComp = createAsyncThunk(
	'entidade/analise_comparativa/partidos/getPartidos',
	async (params, { dispatch, getState }) => {
		dispatch(setPartidosCompLoading(true));

		const { mon_id, partido, uf, city } = params;

		let partidosCacheName = 'partidosCache';

		const config = tokenHeaders();
		if (mon_id) {
			config.url = `${pathPartidos}?mon_id=${mon_id}`;
			partidosCacheName = `partidosCacheMonId_${mon_id}`;
		} else if (city) {
			config.url = `${pathPartidos}?uf=${uf}&city=${city}`;
			partidosCacheName = `partidosCacheCity_${city}_${uf}`;
		} else if (uf) {
			config.url = `${pathPartidos}?uf=${uf}`;
			partidosCacheName = `partidosCacheUf_${uf}`;
		}

		if (partido) {
			if (uf || city) {
				config.url += `&partido=${partido}`;
				partidosCacheName = `${partidosCacheName}_${partido}`;
			} else {
				config.url = `${pathPartidos}?partido=${partido}`;
				partidosCacheName = `${partidosCacheName}_${partido}`;
			}
		}

		const response = await axios(config);
		const { success, data, message } = response.data;

		const dataStore = JSON.parse(data);

		dataStore.id = partidosCacheName;

		await idbKeyval
			.set(dataStore)
			.then(() => console.log('partidos armazenados em cache'))
			.catch(err =>
				dispatch(
					showMessage({
						message: `Erro ao registrar partidos em cache: ${err}`,
						autoHideDuration: 4000,
						anchorOrigin: {
							vertical: 'bottom', // top bottom
							horizontal: 'right' // left center right
						},
						variant: 'error'
					})
				)
			);

		console.log('PARTIDDOOOOOOOOO')
		console.log(dataStore)
		console.log(dataStore.action_range)
		console.log(data)
		dispatch(updateActionRangeComparativo(dataStore.action_range));


		console.log('PARTIDDOOOOOOOOO');

		return JSON.parse(data);
	}
);

const partidosAdapter = createEntityAdapter({});
export const {
	selectAll: selectPartidos,
	removeAll: removePartidos,
	selectById: selectPartidoById
} = partidosAdapter.getSelectors(state => state.entidade.analise_comparativa.partidos);

const initialStatePartidosComp = {
	loading: false,
	votacaoMedia: null,
	maiorVotacao: null,
	menorVotacao: null,
	ballot: null,
	secRound: null,
	selectedPartyId: null,
	selectedPartySigla: null,
	searchText: ''
};

const partidosComparacaoSlice = createSlice({
	name: 'entidade/analise_comparativa/partidos',
	initialState: partidosAdapter.getInitialState(initialStatePartidosComp),
	reducers: {
		setPartidosCompLoading: (state, action) => {
			state.loading = action.payload;
		},
		setSelectedPartyId: (state, action) => {
			state.selectedPartyId = action.payload;
		},
		setVotacaoMedia: (state, action) => {
			state.votacaoMedia = action.payload;
		},
		setBallot: (state, action) => {
			state.ballot = action.payload;
		},
		resetPartidosComp: () => partidosAdapter.getInitialState(initialStatePartidosComp),
		setSiglaPartidaria: (state, action) => {
			const { chave, sigla } = action.payload;
			state.selectedPartyId = chave;
			state.selectedPartySigla = sigla;
		},
		setPartidosComp: (state, action) => {
			state.votacaoMedia = action.payload.data['1st'].votacao_media;
			state.maiorVotacao = action.payload.data['1st'].maior_votacao;
			state.menorVotacao = action.payload.data['1st'].menor_votacao;
			state.ballot = action.payload.data.ballot;
			partidosAdapter.setAll(state, action.payload.data['1st'].votacao);
			if ('2st' in action.payload.data) {
				state.secRound = action.payload.data['2st'];
			}
			if (action.payload.data['1st'].partido_selecionado) {
				state.selectedPartyId = action.payload.data['1st'].partido_selecionado.id;
				state.selectedPartySigla = action.payload.data['1st'].partido_selecionado.partido.sigla;
			}
		}
	},
	extraReducers: {
		// [getPartidos.fulfilled]: partidosAdapter.setAll,
		[getPartidosComp.fulfilled]: (state, action) => {
			state.loading = false;
			state.votacaoMedia = action.payload['1st'].votacao_media;
			state.maiorVotacao = action.payload['1st'].maior_votacao;
			state.menorVotacao = action.payload['1st'].menor_votacao;
			state.ballot = action.payload.ballot;

			// sorting partidos by number of votes, direcly in slice
			// const sortedPartidos = [...action.payload['1st'].votacao].sort((a,b)=>{
			//   if(a.total_votos < b.total_votos) return -1;
			//   if(a.total_votos > b.total_votos) return 1;
			//   return 0
			// })
			// sorting partidos by number of votes, direcly in slice
			// partidosAdapter.setAll(state, sortedPartidos)

			partidosAdapter.setAll(state, action.payload['1st'].votacao);

			if ('2st' in action.payload) {
				state.secRound = action.payload['2st'];
			}
			if (action.payload['1st'].partido_selecionado) {
				state.selectedPartyId = action.payload['1st'].partido_selecionado.id;
				state.selectedPartySigla = action.payload['1st'].partido_selecionado.partido.sigla;
			}
		},
		[getPartidosComp.rejected]: (state, action) => {
			return partidosAdapter.getInitialState(initialStatePartidosComp);
		}
	}
});

export const {
	setPartidosCompLoading,
	setSelectedPartyId,
	setVotacaoMedia,
	setBallot,
	resetPartidosComp,
	setPartidosComp,
	setSiglaPartidaria
} = partidosComparacaoSlice.actions;

export default partidosComparacaoSlice.reducer;
