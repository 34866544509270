import React from 'react';
import FusionCharts from 'fusioncharts';
import { LicenseInfo } from '@mui/x-license-pro';
import axios from 'axios';

const getBackendServerName = () => {
	return `${window.location.protocol}//${window.location.hostname}`;
};

/** Unified configuration - same server
export const optionalUrlJustOnDev = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : null;
export const urlBackend = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : getBackendServerName();

export const wsBackend =
	process.env.NODE_ENV === 'development' ? 'ws://localhost:8000/ws/' : 'wss://ecom.scopo.online/ws/';

// export const wsBackend = 'wss://ecom.scopo.online/ws/'; # just for macbook dev purposes

// export const urlElectionsBackend = process.env.NODE_ENV === 'development' ? 'http://localhost:9000': 'https://painel.scopo.online';
export const urlElectionsBackend =
	process.env.NODE_ENV === 'development' ? 'http://192.168.15.2:9000' : 'https://painel.scopo.online';
* */

/** Distributed configuration - DNS servers at development at production**/
export const optionalUrlJustOnDev = process.env.NODE_ENV === 'development' ? 'https://devback.scopo.online' : null;
export const urlBackend =
	process.env.NODE_ENV === 'development' ? 'https://devback.scopo.online' : 'https://monitor.scopo.online';
export const wsBackend =
	process.env.NODE_ENV === 'development' ? 'wss://devback.scopo.online/ws/' : 'wss://ecom.scopo.online/ws/';
export const urlElectionsBackend = 'https://painel.scopo.online';

/** local configuration
export const optionalUrlJustOnDev = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : null;
export const urlBackend =
	process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://monitor.scopo.online';
export const wsBackend =
	process.env.NODE_ENV === 'development' ? 'ws://localhost:8000/ws/' : 'wss://ecom.scopo.online/ws/';
export const urlElectionsBackend =
	process.env.NODE_ENV === 'development' ? 'http://192.168.15.2:9000' : 'https://painel.scopo.online';*/

/** Regular Configuration with .env*
export const optionalUrlJustOnDev = process.env.NODE_ENV === 'development' ? 'https://devback.scopo.online' : null;
export const urlBackend = process.env.REACT_APP_BACKEND_URL;
export const wsBackend = process.env.REACT_APP_BACKEND_SOCK;
*/

// API User on elections. Different from the user smscopo_id on form
const tokenElectionsBackend = '7cdada912a73a0ea7cd0bceacd204ef5b50caa96';

export const geoIpKey = process.env.REACT_APP_GEOIP_KEY;

export const XRapidAPI_Key = process.env.REACT_APP_XRAPIDAPI_KEY;

const fusionChartsLicense = process.env.REACT_APP_FUSIONCHARTS_LICENSE;

const muiXLicensePro = process.env.REACT_APP_MUI_PRO_LICENSE;

export const activateFusionChartsLicense = () => {
	FusionCharts.options.license({
		key: fusionChartsLicense,
		creditLabel: false
	});
};





export const dataLocation = {
	uf: null,
	city: null,
	urlBackendServer: urlBackend,
	selected_company: null
};

export const getAccessToken = () => {
	return window.localStorage.getItem('jwt_access_token');
};

export const getAccessTokenAsync = async () => {
	return Promise.resolve(window.localStorage.getItem('jwt_access_token'));
};

export const backendSystemTokenHeaders = {
	headers: {
		'Content-Type': 'application/json',
		Authorization: `Token ${process.env.REACT_APP_BACKEND_TOKEN}`
	}
};

export const noAuthBackendHeader = {
	headers: {
		'Content-Type': 'application/json'
	}
};

export const tokenHeaders = () => {
	const token = getAccessToken();

	// Headers
	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	};

	if (token) {
		config.headers.Authorization = `Token ${token}`;
	}

	return config;
};

export function isJSON(str) {
	try {
		JSON.parse(str);
		return true;
	} catch (e) {
		return false;
	}
}

export async function checkIsBackendIsOnline() {
	try {
		const isBackendOnline = await axios.get(`${urlBackend}/api/controle/system_is_online/`);
		return isBackendOnline.data;
	} catch (error) {
		// console.error(error);
		return false;
	}
}

const outTitleDefault = 'Sistema em Manutenção!';
const outMsgDefault = 'Pedimos desculpas pelo inconveniente. <br/> Tente mais tarde em alguns momentos';
export async function getOfflineBackendReason() {
	try {
		const response = await axios.get(`${urlBackend}/api/controle/get_status_reason/`);
		return response.data;
	} catch {
		return { title: outTitleDefault, msg: outMsgDefault };
	}
}
export const defaultTimeToDisplayAlerts = 6500;
export const NOTIFICATION_SOCKET_ADDRESS = `${wsBackend}notifications/`;
export const BACKEND_SOCKET_ADDRESS = `${wsBackend}backend_status/`;

export const statusArr = [
	{
		title: 'Online',
		value: 'online'
	},
	{
		title: 'Away',
		value: 'away'
	},
	{
		title: 'Do not disturb',
		value: 'do-not-disturb'
	},
	{
		title: 'Offline',
		value: 'offline'
	}
];

export const smscopoInfoASCII =
	'.d88b. 8b   d8    .d88b. .d88b .d88b. 888b. .d88b.\n' +
	'YPwww. 8YbmdP8    YPwww. 8P    8P  Y8 8  .8 8P  Y8\n' +
	'    d8 8  "  8        d8 8b    8b  d8 8wwP\' 8b  d8\n' +
	"`Y88P' 8     8    `Y88P' `Y88P `Y88P' 8     `Y88P'\n" +
	'\n' +
	'Instância de Monitoramento de monitor.scopo.online' +
	'\n' +
	`      Copyright 2024/2026 - ${process.env.REACT_APP_COPYRIGHT}` +
	'\n' +
	`            by ${process.env.REACT_APP_CREDITS}` +
	'\n\n' +
	'   logs desativados em produção.';

export const tokenHeadersElectionsBackend = {
	headers: {
		'Content-Type': 'application/json',
		Authorization: `Token ${tokenElectionsBackend}`
	}
};

export const getDuiId = () => {
	if (localStorage.getItem('du') !== null) {
		// console.log('accessing same device')
		return localStorage.getItem('du');
	}
	const du = new DeviceUUID().get();
	window.localStorage.setItem('du', du);
	// console.log('accessing new device')
	return du;
};

export const getMoreUserData = () => {
	const du = new DeviceUUID().parse();
	const dua = [
		du.language,
		du.platform,
		du.os,
		du.cpuCores,
		du.isAuthoritative,
		du.silkAccelerated,
		du.isKindleFire,
		du.isDesktop,
		du.isMobile,
		du.isTablet,
		du.isWindows,
		du.isLinux,
		du.isLinux64,
		du.isMac,
		du.isiPad,
		du.isiPhone,
		du.isiPod,
		du.isSmartTV,
		du.pixelDepth,
		du.isTouchScreen
	];
	const uuid = du.hashMD5(dua.join(':'));
	console.log(uuid);
	console.log(du);
	console.log(dua);
	return du;
};

export const brazilListUfIndexes = [
	{ nome: 'Acre', sigla: 'AC', id: '001', populacao: 881935, eleitores: 561261, idhm: 0.5860909090909091 },
	{ nome: 'Alagoas', sigla: 'AL', id: '002', populacao: 3337357, eleitores: 2219318, idhm: 0.5635 },
	{ nome: 'Amapá', sigla: 'AP', id: '003', populacao: 845731, eleitores: 517102, idhm: 0.6428124999999999 },
	{ nome: 'Amazonas', sigla: 'AM', id: '004', populacao: 4144597, eleitores: 2503269, idhm: 0.5651129032258064 },
	{ nome: 'Bahia', sigla: 'BA', id: '005', populacao: 14873064, eleitores: 10893320, idhm: 0.5939040767386092 },
	{ nome: 'Ceará', sigla: 'CE', id: '006', populacao: 9132078, eleitores: 6567760, idhm: 0.6168641304347824 },
	{
		nome: 'Distrito Federal',
		sigla: 'DF',
		id: '007',
		populacao: 3015268,
		eleitores: 2084356,
		idhm: 0.8240000000000001
	},
	{
		nome: 'Espírito Santo',
		sigla: 'ES',
		id: '008',
		populacao: 4018650,
		eleitores: 2810132,
		idhm: 0.6921923076923077
	},
	{ nome: 'Goiás', sigla: 'GO', id: '009', populacao: 7018354, eleitores: 4606112, idhm: 0.6949837398373981 },
	{ nome: 'Maranhão', sigla: 'MA', id: '010', populacao: 7075181, eleitores: 4758629, idhm: 0.5761843317972352 },
	{ nome: 'Mato Grosso', sigla: 'MT', id: '011', populacao: 3484466, eleitores: 2317102, idhm: 0.684205673758865 },
	{
		nome: 'Mato Grosso do Sul',
		sigla: 'MS',
		id: '012',
		populacao: 2778986,
		eleitores: 1932293,
		idhm: 0.6795316455696202
	},
	{
		nome: 'Minas Gerais',
		sigla: 'MG',
		id: '013',
		populacao: 21168791,
		eleitores: 15889559,
		idhm: 0.6678944900351695
	},
	{ nome: 'Pará', sigla: 'PA', id: '014', populacao: 8602865, eleitores: 5758119, idhm: 0.5829166666666664 },
	{ nome: 'Paraíba', sigla: 'PB', id: '015', populacao: 4018127, eleitores: 2966759, idhm: 0.5883497757847531 },
	{ nome: 'Paraná', sigla: 'PR', id: '016', populacao: 11433957, eleitores: 8152710, idhm: 0.701959899749374 },
	{ nome: 'Pernambuco', sigla: 'PE', id: '017', populacao: 9557071, eleitores: 6732607, idhm: 0.5970216216216219 },
	{ nome: 'Piauí', sigla: 'PI', id: '018', populacao: 3273227, eleitores: 2456056, idhm: 0.5710491071428567 },
	{
		nome: 'Rio de Janeiro',
		sigla: 'RJ',
		id: '019',
		populacao: 17264943,
		eleitores: 12455812,
		idhm: 0.7089130434782609
	},
	{
		nome: 'Rio Grande do Norte',
		sigla: 'RN',
		id: '020',
		populacao: 3506853,
		eleitores: 2447178,
		idhm: 0.6122874251497005
	},
	{
		nome: 'Rio Grande do Sul',
		sigla: 'RS',
		id: '021',
		populacao: 11377239,
		eleitores: 8423308,
		idhm: 0.7134346076458754
	},
	{ nome: 'Rondônia', sigla: 'RO', id: '022', populacao: 1777225, eleitores: 1190505, idhm: 0.6440384615384619 },
	{ nome: 'Roraima', sigla: 'RR', id: '023', populacao: 605761, eleitores: 342651, idhm: 0.6102 },
	{
		nome: 'Santa Catarina',
		sigla: 'SC',
		id: '024',
		populacao: 7164788,
		eleitores: 5205931,
		idhm: 0.7312033898305079
	},
	{ nome: 'São Paulo', sigla: 'SP', id: '025', populacao: 45919049, eleitores: 33565294, idhm: 0.7393720930232563 },
	{ nome: 'Sergipe', sigla: 'SE', id: '026', populacao: 2298696, eleitores: 1610407, idhm: 0.5969333333333334 },
	{ nome: 'Tocantins', sigla: 'TO', id: '027', populacao: 1572866, eleitores: 1035289, idhm: 0.6399280575539567 }
];

// the lowest idhm in Brazil is .55
// in the world, 0.37
export const rangeBrazilIndexes = {
	populacao: { min: 605761, max: 45919049 },
	eleitores: { min: 342651, max: 33565294 },
	idhm: { min: 0.35, max: 1 }
};

export const googleMapStype = [
	{
		featureType: 'administrative',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#444444'
			}
		]
	},
	{
		featureType: 'landscape',
		elementType: 'all',
		stylers: [
			{
				color: '#f2f2f2'
			}
		]
	},
	{
		featureType: 'poi',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'road',
		elementType: 'all',
		stylers: [
			{
				saturation: -100
			},
			{
				lightness: 45
			}
		]
	},
	{
		featureType: 'road.highway',
		elementType: 'all',
		stylers: [
			{
				visibility: 'simplified'
			}
		]
	},
	{
		featureType: 'road.arterial',
		elementType: 'labels.icon',
		stylers: [
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'transit',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'water',
		elementType: 'all',
		stylers: [
			{
				color: '#039be5'
			},
			{
				visibility: 'on'
			}
		]
	}
];

const LocationContext = React.createContext(null);

export default LocationContext;
