import BrainConfig from 'app/main/scopo-apps/brain/BrainConfig';
import EntidadesConfig from 'app/main/scopo-apps/estrutura/EstruturaConfig';
import ConvitesConfig from 'app/main/scopo-apps/convites/ConvitesConfig';
import NeurobotsConfig from 'app/main/scopo-apps/neurobots/NeurobotsConfig';
import ChatAppScopoConfig from './chat/ChatAppEntityConfig';
import TeamAppConfig from './equipe/TeamChatConfig';
import ElectionsConfig from './elections/ElectionsConfig';
import researchConfigs from './research/researchConfigs';
import WarRoomConfig from './war-room/WarRoomConfig';
import AvisosConfig from './avisos/AvisosConfig';
import Dashboards from './dashboards/dashboardsConfigs';
import SocialNetworksConfig from '../social-networks/SocialNetworksConfig';
import StakeholdersConfig from './stakeholders/StakeholdersConfig';
import ClippingAppConfig from './clipping/ClippingConfig';

const scopoAppsConfigs = [
	ChatAppScopoConfig,
	TeamAppConfig,
	ElectionsConfig,
	WarRoomConfig,
	AvisosConfig,
	BrainConfig,
	EntidadesConfig,
	ConvitesConfig,
	StakeholdersConfig,
	NeurobotsConfig,
	SocialNetworksConfig,
	ClippingAppConfig,
	...researchConfigs,
	...Dashboards
];

export default scopoAppsConfigs;
