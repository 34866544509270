import { combineReducers } from '@reduxjs/toolkit';
import scenarios from './scenariosSlice';
import competition from './competitionSlice';
import location from './locationIndicatorsSlice';
import evolution from './evolutionSlice';
import dispute from './disputeSlice';
import voters from './votersSlice';
import monitor_social from './monitorSocialSlice';
import partidos from './partidosSlice';
// import trends from "app/main/store/news/trendsSlice";

const indicatorReducers = combineReducers({
	scenarios,
	competition,
	location,
	monitor_social,
	evolution,
	dispute,
	voters,
	partidos
});

export default indicatorReducers;
