import { authRoles } from 'app/auth';

const ResearchNavigation = {
	id: 'pesquisas',
	title: 'Pesquisar',
	type: 'collapse',
	icon: 'search',
	children: [
		{
			id: 'pesquisa-individual',
			title: 'Indivíduo',
			type: 'item',
			auth: authRoles.researcher,
			icon: 'person_search',
			url: '/pesquisa/individual'
		},
		{
			id: 'pesquisa-candidatos',
			title: 'Candidatos',
			type: 'item',
			auth: authRoles.researcher,
			icon: 'find_in_page',
			url: '/pesquisa/oponentes'
		}
		// {
		// 	id: 'pesquisa-segmento',
		// 	title: 'Segmento',
		// 	type: 'item',
		// 	icon: 'storefront',
		// 	url: '/pesquisa/segmento'
		// },
		// {
		// 	id: 'pesquisa-dossies',
		// 	title: 'Dossiês Arquivados',
		// 	type: 'item',
		// 	url: '/pesquisa/dossies',
		// 	auth: authRoles.researcher,
		// 	icon: 'folder_special'
		// }
	]
};

export default ResearchNavigation;
