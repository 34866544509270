import { lazy } from 'react';
// import OpponentsResearch from "./OpponentsResearch";
import { authRoles } from '../../../../auth';

const OpponentsResearch = lazy(() => import('./OpponentsResearch'));

const OpponentsResearchConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/pesquisa/oponentes',
			// eslint-disable-next-line react/react-in-jsx-scope
			element: <OpponentsResearch />
		}
	]
};

export default OpponentsResearchConfig;
