import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import {
	updateActionRangeComparativo
} from 'app/main/store/entidade/analiseComparativaSlice/actionRangeComparativoSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import {
	setScopoNewsInSelectedMonitorSocialData,
	setStakeHolders,
	updateNewsFontsInSelectedMonitorSocialData,
	updateRequestedFontsInSelectedMonitorSocialData
} from 'app/main/store/entidade/entityStatusSlice';
import { tokenHeaders, urlBackend } from '../../data/LocationContext';
import { IncluiParticipantesMarketshare, setParticipantes } from '../entidade/politico/participantesSlice';
import { setRemoteLoaded, setRemoteLoading } from '../../../store/fuse/environmentSlice';

const pathGetMonitoresSociais = '/api/monitor_social/data/';
const pathAddMonitorSocial = '/api/entidade/add/';

export const getEntityMonitoresSociais = createAsyncThunk(
	'entidade/indicators/monitor_social/getMonitoresList',
	async (entId, { dispatch }) => {
		dispatch(setRemoteLoading());
		const response = await axios.get(`${urlBackend}${pathGetMonitoresSociais}?id=${entId}`, tokenHeaders());

		const { success, message, data } = await response.data;

		if (success) {
			// dispatch(setLoadedMonitorSocial(true));
			dispatch(setRemoteLoaded());
			return isJSON(data) ? JSON.parse(data) : data;
		}
		dispatch(setRemoteLoaded());
		return [];
	}
);

function isJSON(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

export const getMonitorSocialId = createAsyncThunk(
	'entidade/indicators/monitor_social/getMonitorId',
	async (monId, { dispatch, getState }) => {
		dispatch(setRemoteLoading());
		const response = await axios.get(`${urlBackend}${pathGetMonitoresSociais}?id_monitor=${monId}`, tokenHeaders());

		const { success, data, message } = await response.data;

		if (message) {
			dispatch(
				showMessage({
					message,
					autoHideDuration: 5000,
					variant: 'info'
				})
			);
		}

		if (success) {
			const parsedData = isJSON(data) ? JSON.parse(data) : data;
			// console.log(parsedData)

			// const mktPlayersLimit = getState().entidade.entityStatus.data?.marketshare_players_limit
			// 	? getState().entidade.entityStatus.data.marketshare_players_limit
			// 	: getState().entidade.entityStatus.stakeholders.limit
			// 	? getState().entidade.entityStatus.stakeholders.limit
			// 	: 12;

			if (parsedData) {
				const { players } = parsedData;
				const mktPlayersLimit = parsedData.marketshare_players_limit;

				if (players) {
					const monitoringPlayersIds = players
						.filter(obj => obj.monitoring === true)
						.map(obj => obj?.concorrente?.id)
						.filter(Boolean);

					dispatch(
						setStakeHolders({
							limit: mktPlayersLimit,
							monitoring: monitoringPlayersIds.length,
							remaining: mktPlayersLimit - monitoringPlayersIds.length,
							ids: monitoringPlayersIds
						})
					);

					dispatch(setParticipantes(players));
					dispatch(IncluiParticipantesMarketshare(players));
					delete parsedData.players;
				}
			}
			dispatch(updateActionRangeComparativo(parsedData.action_range));

			dispatch(setMonitorSocialData(parsedData));
		}
		dispatch(setRemoteLoaded());
	}
);

export const troggleMonitorSocialPlayer = createAsyncThunk(
	'entidade/indicators/monitor_social/troggleSocialMonitorPlayer',
	async (params, { dispatch, getState }) => {
		const { tp, id, monitorSocialId } = params;
		dispatch(setRemoteLoading());
		const response = await axios.get(
			`${urlBackend}/api/entidade/troggleMonitorSocialPlayer/?${tp}=${id}&monitorSocialId=${monitorSocialId}`,
			tokenHeaders()
		);
		const { success, data } = response.data;
		const parsedData = JSON.parse(data);

		// const mktPlayersLimit = getState().entidade.entityStatus.data?.marketshare_players_limit
		// 	? getState().entidade.entityStatus.data.marketshare_players_limit
		// 	: getState().entidade.SelectedEntity.marketshare_players_limit;

		const mktPlayersLimit = getState().entidade.entityStatus.stakeholders.limit;

		if (parsedData) {
			console.log('ATUALIZANDO LIMITES MKT PLYRS');
			const monitoringPlayersIds = parsedData
				.filter(obj => obj.monitoring === true)
				.map(obj => obj?.concorrente?.id)
				.filter(Boolean);

			dispatch(
				setStakeHolders({
					limit: mktPlayersLimit,
					monitoring: monitoringPlayersIds.length,
					remaining: mktPlayersLimit - monitoringPlayersIds.length,
					ids: monitoringPlayersIds
				})
			);

			dispatch(IncluiParticipantesMarketshare(parsedData));
		}
		dispatch(setRemoteLoaded());
	}
);

export const removeSocialMonitorPlayer = createAsyncThunk(
	'entidade/indicators/monitor_social/removeSocialMonitorPlayer',
	async (params, { dispatch, getState }) => {
		const { tp, id, monitorSocialId } = params;
		dispatch(setRemoteLoading());
		const response = await axios.get(
			`${urlBackend}/api/entidade/remove/?${tp}=${id}&monitorSocialId=${monitorSocialId}`,
			tokenHeaders()
		);
		const { success, data, message } = response.data;

		const parsedData = JSON.parse(data);

		const mktPlayersLimit = getState().entidade.entityStatus.data?.marketshare_players_limit
			? getState().entidade.entityStatus.data.marketshare_players_limit
			: getState().entidade.SelectedEntity.marketshare_players_limit;

		if (mktPlayersLimit && parsedData) {
			const monitoringPlayersIds = parsedData
				.filter(obj => obj.monitoring === true)
				.map(obj => obj?.concorrente?.id)
				.filter(Boolean);

			dispatch(
				setStakeHolders({
					limit: mktPlayersLimit,
					monitoring: monitoringPlayersIds.length,
					remaining: mktPlayersLimit - monitoringPlayersIds.length,
					ids: monitoringPlayersIds
				})
			);

			dispatch(IncluiParticipantesMarketshare(parsedData));
			if (message) {
				dispatch(
					showMessage({
						message,
						autoHideDuration: 4500,
						anchorOrigin: {
							vertical: 'top', // top bottom
							horizontal: 'center' // left center right
						},
						variant: success ? 'success' : 'warning'
					})
				);
			}
		}
		dispatch(setRemoteLoaded());
	}
);

export const excludeSocialMonitorPlayer = createAsyncThunk(
	'entidade/indicators/monitor_social/excludeMonitorSocialPlayer',
	async (params, { dispatch, getState }) => {
		dispatch(setRemoteLoading());
		const { tp, id, monitorSocialId } = params;
		const response = await axios.get(
			`${urlBackend}/api/entidade/exclude/?${tp}=${id}&monitorSocialId=${monitorSocialId}`,
			tokenHeaders()
		);
		const { success, data, message } = response.data;
		const parsedData = JSON.parse(data);

		const mktPlayersLimit = getState().entidade.entityStatus.data?.marketshare_players_limit
			? getState().entidade.entityStatus.data.marketshare_players_limit
			: getState().entidade.SelectedEntity.marketshare_players_limit;

		if (mktPlayersLimit && parsedData) {
			const monitoringPlayersIds = parsedData
				.filter(obj => obj.monitoring === true)
				.map(obj => obj?.concorrente?.id)
				.filter(Boolean);

			dispatch(
				setStakeHolders({
					limit: mktPlayersLimit,
					monitoring: monitoringPlayersIds.length,
					remaining: mktPlayersLimit - monitoringPlayersIds.length,
					ids: monitoringPlayersIds
				})
			);

			dispatch(IncluiParticipantesMarketshare(parsedData));
			if (message) {
				dispatch(
					showMessage({
						message,
						autoHideDuration: 4500,
						anchorOrigin: {
							vertical: 'top', // top bottom
							horizontal: 'center' // left center right
						},
						variant: success ? 'success' : 'warning'
					})
				);
			}
		}
		dispatch(setRemoteLoaded());
	}
);

export const addSocialMonitorPlayer = createAsyncThunk(
	'entidade/indicators/monitor_social/addMonitorSocialPlayer',
	async (params, { dispatch, getState }) => {
		dispatch(setRemoteLoading());
		const { tp, ctx, dt } = params;
		const pth = `${urlBackend}${pathAddMonitorSocial}?tp=${tp}&ctx=${ctx}`;
		const response = await axios.post(pth, dt, tokenHeaders());
		const { success, message, data } = response.data;

		if (success) {
			const parsedData = JSON.parse(data);

			const mktPlayersLimit = getState().entidade.entityStatus.data
				? getState().entidade.entityStatus.data.marketshare_players_limit
				: getState().entidade.SelectedEntity.marketshare_players_limit;

			if (mktPlayersLimit && parsedData) {
				const monitoringPlayersIds = parsedData
					.filter(obj => obj.monitoring === true)
					.map(obj => obj?.concorrente?.id)
					.filter(Boolean);

				dispatch(
					setStakeHolders({
						limit: mktPlayersLimit,
						monitoring: monitoringPlayersIds.length,
						remaining: mktPlayersLimit - monitoringPlayersIds.length,
						ids: monitoringPlayersIds
					})
				);

				dispatch(IncluiParticipantesMarketshare(parsedData));
				if (message) {
					dispatch(
						showMessage({
							message,
							autoHideDuration: 2500,
							anchorOrigin: {
								vertical: 'top', // top bottom
								horizontal: 'center' // left center right
							},
							variant: success ? 'success' : 'warning'
						})
					);
				}
			}
		} else if (message) {
			dispatch(setRemoteLoaded());
			dispatch(
				showMessage({
					message,
					autoHideDuration: 4000,
					anchorOrigin: {
						vertical: 'top', // top bottom
						horizontal: 'center' // left center right
					},
					variant: 'error'
				})
			);
		}
		dispatch(setRemoteLoaded());
	}
);

export const gerenciaTutelaMonitorSocial = createAsyncThunk(
	'entidade/indicators/monitor_social/gerenciaTutelaMonitorSocial',
	async (params, { dispatch, getState }) => {
		const { tp, id, tutor, monId } = params;
		dispatch(setRemoteLoading());
		const response = await axios.get(
			`${urlBackend}/api/entidade/tutela/?${tp}=${id}&tutor=${tutor}&monId=${monId}`,
			tokenHeaders()
		);
		const { success, data, message } = response.data;
		const parsedData = JSON.parse(data);

		const mktPlayersLimit = getState().entidade.entityStatus.data?.marketshare_players_limit
			? getState().entidade.entityStatus.data.marketshare_players_limit
			: getState().entidade.SelectedEntity.marketshare_players_limit;

		if (mktPlayersLimit && parsedData) {
			const monitoringPlayersIds = parsedData
				.filter(obj => obj.monitoring === true)
				.map(obj => obj?.concorrente?.id)
				.filter(Boolean);

			dispatch(
				setStakeHolders({
					limit: mktPlayersLimit,
					monitoring: monitoringPlayersIds.length,
					remaining: mktPlayersLimit - monitoringPlayersIds.length,
					ids: monitoringPlayersIds
				})
			);

			dispatch(IncluiParticipantesMarketshare(parsedData));
			dispatch(setRemoteLoaded());
			if (message) {
				dispatch(
					showMessage({
						message,
						autoHideDuration: 7000,
						anchorOrigin: {
							vertical: 'top', // top bottom
							horizontal: 'center' // left center right
						},
						variant: success ? 'success' : 'warning'
					})
				);
			}
		}
	}
);

// para atualizar keywords - good and bad ones
export const updateMonitorSocialNews = createAsyncThunk(
	'entidade/indicators/monitor_social/updateScopoNews',
	async (params, { dispatch, getState }) => {
		const { entityInEditingMode } = getState().fuse.backendStatus;

		dispatch(setRemoteLoading());

		const response = await axios.post(`${urlBackend}/api/news/update_keywords/`, params, tokenHeaders());
		const data = await response.data;
		console.log(data);

		if (entityInEditingMode) {
			dispatch(setScopoNewsInSelectedMonitorSocialData(data));
		} else {
			dispatch(setMonitorSocialNewsData(data));
		}

		dispatch(setRemoteLoaded());
		return data;
	}
);

export const updateMonitorNewsFonts = createAsyncThunk(
	'entidade/indicators/monitor_social/updateScopoNewsFonts',
	async (params, { dispatch, getState }) => {
		const { entityInEditingMode } = getState().fuse.backendStatus;
		dispatch(setRemoteLoading());
		const { mon_id, fonts } = params;
		const response = await axios.post(
			`${urlBackend}/api/monitor_social/manage_news_fonts/?mon_id=${mon_id}`,
			fonts,
			tokenHeaders()
		);
		const data = await response.data;
		if (entityInEditingMode) {
			dispatch(updateNewsFontsInSelectedMonitorSocialData(data.data));
			dispatch(updateRequestedFontsInSelectedMonitorSocialData(data.requested));
		} else {
			dispatch(updateMonitorSocialNewsFonts(data.data));
			dispatch(updatedMonitorSocialRequestedFonts(data.requested));
		}

		dispatch(setRemoteLoaded());
		return data.data;
	}
);

export const requestNewsFontInclusion = createAsyncThunk(
	'entidade/indicators/monitor_social/updateScopoNewsFonts',
	async (params, { dispatch, getState }) => {
		const { entityInEditingMode } = getState().fuse.backendStatus;
		const { mon_id, new_source } = params;
		const response = await axios.post(
			`${urlBackend}/api/monitor_social/manage_news_fonts/?mon_id=${mon_id}&request_inclusion=true`,
			new_source,
			tokenHeaders()
		);
		const { success, message, requested, data } = await response.data;

		if (message) {
			dispatch(
				showMessage({
					message,
					autoHideDuration: 5000,
					variant: success ? 'success' : 'error'
				})
			);
		}

		if (success) {
			if (entityInEditingMode) {
				dispatch(updateNewsFontsInSelectedMonitorSocialData(data));
				dispatch(updateRequestedFontsInSelectedMonitorSocialData(requested));
			} else {
				dispatch(updatedMonitorSocialRequestedFonts(requested));
				dispatch(updateMonitorSocialNewsFonts(data));
			}
		}
	}
);

export const removeRequestedNewsFont = createAsyncThunk(
	'entidade/indicators/monitor_social/removeRequestedNewsFont',
	async (params, { dispatch }) => {
		const { mon_id, title } = params;
		const response = await axios.get(
			`${urlBackend}/api/monitor_social/manage_news_fonts/?mon_id=${mon_id}&request_exclusion=${title}`,
			tokenHeaders()
		);

		const { success, message, requested, data } = await response.data;

		if (message) {
			dispatch(
				showMessage({
					message,
					autoHideDuration: 5000,
					variant: success ? 'success' : 'error'
				})
			);
		}

		if (success) {
			dispatch(updateMonitorSocialNewsFonts(data));
			dispatch(updatedMonitorSocialRequestedFonts(requested));
		}
	}
);

export const changeSelectedMonitorSocialId = newId => {
	return dispatch => {
		dispatch(setSelectedMonitorSocialId(newId));
	};
};

const monitorAdapter = createEntityAdapter({});

export const {
	selectAll: selectMonitores,
	removeAll: removeMonitores,
	selectEntities: selectMonitoresEntities,
	selectById: selectMonitorById
} = monitorAdapter.getSelectors(state => state.entidade.indicators.monitor_social);

const initialState = {
	loaded: false,
	selectedMonitorId: null,
	bufferedMonitorId: null,
	data: null,
	bufferedMonitorData: null
};

const monitorSocialSlice = createSlice({
	name: 'entidade/indicators/monitor_social',
	initialState: monitorAdapter.getInitialState(initialState),
	reducers: {
		setMonitoresSociais: (state, action) => {
			monitorAdapter.setAll(state, action.payload);
			state.loaded = true;
		},
		setSelectedMonitorSocialId: (state, action) => {
			state.selectedMonitorId = action.payload;
		},
		removeSelectedMonitorSocialId: (state, action) => {
			state.selectedMonitorId = null;
		},
		setLoadedMonitorSocial: (state, action) => {
			state.loaded = action.payload;
		},
		setMonitorSocialData: (state, action) => {
			state.data = action.payload;
		},
		setMonitorSocialNewsData: (state, action) => {
			state.data.scopo_news = action.payload;
		},
		updateMonitorSocialNewsFonts: (state, action) => {
			state.data.scopo_news.scopo_fonts = action.payload;
		},
		updatedMonitorSocialRequestedFonts: (state, action) => {
			state.data.requested_news_fonts = action.payload;
		},
		bufferMonitorSocial: (state, action) => {
			state.bufferedMonitorId = state.selectedMonitorId;
			state.bufferedMonitorData = state.data;
		},
		unBufferMonitorSocial: (state, action) => {
			state.selectedMonitorId = state.bufferedMonitorId;
			state.bufferedMonitorData = state.data;
		},
		clearSocialMonitors: () => monitorAdapter.getInitialState(initialState)
	},
	extraReducers: {
		[getEntityMonitoresSociais.fulfilled]: (state, action) => {
			monitorAdapter.setAll(state, action.payload);
			state.loaded = true;
		},
		[getEntityMonitoresSociais.rejected]: (state, action) => {
			state = initialState;
			monitorAdapter.removeAll(state);
		}
	}
});

export const {
	setMonitoresSociais,
	setSelectedMonitorSocialId,
	removeSelectedMonitorSocialId,
	setLoadedMonitorSocial,
	setMonitorSocialData,
	setMonitorSocialNewsData,
	updateMonitorSocialNewsFonts,
	updatedMonitorSocialRequestedFonts,
	clearSocialMonitors
} = monitorSocialSlice.actions;

export default monitorSocialSlice.reducer;
