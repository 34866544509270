import React, { useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';

const Root = styled(Tooltip)(({ theme, active }) => ({
	width: 70,
	minWidth: 70,
	flex: '0 0 auto',
	...(active && {
		'&:after': {
			position: 'absolute',
			top: 8,
			right: 0,
			bottom: 8,
			content: "''",
			width: 4,
			borderTopLeftRadius: 4,
			borderBottomLeftRadius: 4,
			backgroundColor: theme.palette.primary.main
		}
	})
}));

const StyledUreadBadge = styled('div')(({ theme, value }) => ({
	position: 'absolute',
	minWidth: 18,
	height: 18,
	top: 4,
	left: 10,
	borderRadius: 9,
	padding: '0 5px',
	fontSize: 11,
	textAlign: 'center',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: theme.palette.secondary.main,
	color: theme.palette.secondary.contrastText,
	boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.35)',
	zIndex: 10
}));

const StyledStatus = styled('div')(({ theme, value }) => ({
	position: 'absolute',
	width: 12,
	height: 12,
	bottom: 4,
	left: 44,
	border: `2px solid ${theme.palette.background.default}`,
	borderRadius: '50%',
	zIndex: 10,

	...(value === 'online' && {
		backgroundColor: '#4CAF50'
	}),

	...(value === 'do-not-disturb' && {
		backgroundColor: '#F44336'
	}),

	...(value === 'away' && {
		backgroundColor: '#FFC107'
	}),

	...(value === 'offline' && {
		backgroundColor: '#646464'
	})
}));

const ContactButton = ({ contact, selectedContactId, onClick }) => {
	const { recentLoggedIn } = useSelector(({ chatPanel }) => chatPanel.contacts);
	return (
		<Root
			title={contact.first_name ? contact.first_name : contact.username}
			placement="left"
			active={selectedContactId === contact.usuario_id ? 1 : 0}
		>
			<Button
				onClick={() => onClick(contact.usuario_id)}
				className={clsx('contactButton', selectedContactId === contact.usuario_id && 'active')}
			>
				{contact.unread_messages > 0 && <StyledUreadBadge>{contact.unread_messages}</StyledUreadBadge>}
				{/* <StyledUreadBadge>5</StyledUreadBadge> */}

				<StyledStatus
					className={recentLoggedIn.includes(contact.usuario_id) && 'animate-ping'}
					value={contact.connection_status}
				/>

				<Avatar src={contact.photoURL} alt={contact.username}>
					{!contact.photoURL || contact.photoURL === '' ? contact.username : ''}
				</Avatar>
			</Button>
		</Root>
	);
};

export default ContactButton;
