import { lazy } from 'react';
import { diffDates } from '@fullcalendar/react';
import { authRoles } from '../../../auth';
// import ResetPasswordMsg from "app/main/auth/forgot-password/forgot-password-reset/ResetPasswordMsg";
// import ForgotPassword from "./ForgotPassword";

const ForgotPassword = lazy(() => import('./ForgotPassword'));
const ResetPasswordMsg = lazy(() => import('./forgot-password-reset/ResetPasswordMsg'));

const ForgotPasswordConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/forgotPassword',
			children: [
				{
					path: '',
					exact: true,
					element: <ForgotPassword />
				},
				{
					path: 'reset',
					element: <ResetPasswordMsg />
				}
			]
		}
	]
};

export default ForgotPasswordConfig;
