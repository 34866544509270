import { lazy } from 'react';
import { authRoles} from "../../../../auth";
const TokenFail = lazy(()=>import("./TokenFail"));

const TokenFailConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: '/register/tokenFail',
            element: <TokenFail />
        }
    ]
}

export default TokenFailConfig