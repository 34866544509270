import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { tokenHeaders, urlBackend } from 'app/main/data/LocationContext';
import { showMessage } from 'app/store/fuse/messageSlice';
import idbKeyval from '../../scopoTempDb';

export const getMapMarkers = createAsyncThunk('kpis/maps/markers/getMarkers', async (entId, { dispatch }) => {
	dispatch(setMarkersLoading(true));

	const response = await axios.get(`${urlBackend}/api/candidato/get_votation_points/?id=${entId}`, tokenHeaders());

	const { success, message, data } = await response.data;

	if (success) {
		return data;
	}
});

export const getMonSocialSNSMap = createAsyncThunk('kpis/maps/markers/getSNSHeatMap', async (monId, { dispatch }) => {
	dispatch(setMarkersLoading(true));
	const cacheSNSMon = `cacheSNSMon_${monId}`;
	const response = await axios.get(`${urlBackend}/api/monitor_social/get_map_sns_points/?mon_id=${monId}`,  tokenHeaders());
	const { success, message, data } = await response.data;
	if (success) {
		const dataStore = data;
		dataStore.id = cacheSNSMon;
		await idbKeyval
			.set(dataStore)
			.then(() => console.log('último SNS Armazenado em cache'))
			.catch(err => console.log('Erro ao armazenar SNS em cache:', err));
		return data;
	}
});

const markupsInitialState = {
	loading: false,
	sns_heat: null,
	data: null
};

const markersSlice = createSlice({
	name: 'kpis/map/markers',
	initialState: markupsInitialState,
	reducers: {
		setMarkersLoading: (state, action) => {
			state.loading = action.payload;
		},
		setMarkersData: (state, action) => {
			state.data = action.payload;
		},
		setOfflineMarkersData: (state, action) => {
			state.data = action.payload.data;
		},
		clearMarkers: () => markupsInitialState,
		restoreHeatmapToSns: (state, action) => {
			state.data.heat_list = state.sns_heat;
		}
	},
	extraReducers: {
		[getMapMarkers.fulfilled]: (state, action) => {
			state.data = action.payload;
			state.loading = false;
		},
		[getMapMarkers.rejected]: (state, action) => {
			state = markupsInitialState;
		},
		[getMonSocialSNSMap.fulfilled]: (state, action) => {
			state.data = action.payload;
			state.loading = false;
			state.sns_heat = action.payload.heat_list;
		},
		[getMonSocialSNSMap.rejected]: (state, action) => {
			state = markupsInitialState;
		}
	}
});

export const {
	setMarkersLoading,
	setMarkersData,
	setOfflineMarkersData,
	restoreHeatmapToSns,
	clearMarkers } = markersSlice.actions;

export default markersSlice.reducer;
