import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lastSent: {
    userId: null,
    timeStamp: null
  },
  lastReceived: {
    userId: null,
    timeStamp: null
  }
}

const getTimestamp = () => {
  return new Date.getTime();
}

const messagesSlice = createSlice({
  name: 'chatPanel/messages',
  initialState,
  reducers: {
    addLastSent: (state, action) =>{
      console.log(action)
      state.lastSent = {
        userId: action.payload,
        timeStamp: getTimestamp
      }
    },
    removeLastSent: (state, action)=>{
      state.lastSent = {
        userId: null,
        timeStamp: null
      }
    },
    addLastReceived: (state, action) => {
      state.lastReceived = {
        userId: action.payload.userId,
        timeStamp: action.payload.timeStamp
      }
    }
  }
});

export const {addLastSent,removeLastSent, addLastReceived} = messagesSlice.actions;

export default messagesSlice.reducer