import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { tokenHeaders, urlBackend } from 'app/main/data/LocationContext';

const pathTrends = `${urlBackend}/api/trends/trending/`;
export const getTrends = createAsyncThunk('entidade/indicators/trends/getTrends', async (_, thunkAPI) => {
	const { dispatch } = thunkAPI;
	dispatch(setTrendsLoading(true));
	const response = await axios.get(pathTrends, tokenHeaders());
	return JSON.parse(response.data);
});

const initialState = {
	loading: false,
	data: null,
	last_updated: null
};

const TrendsSlice = createSlice({
	name: 'entidade/indicators/trends',
	initialState,
	reducers: {
		setTrendsLoading: (state, action) => {
			state.loading = action.payload;
		},
		setTrendsData: (state, action) => {
			state.loading = false;
			state.data = action.payload;
			state.last_updated = new Date();
		},
		clearTrends: (state, action) => {
			state = initialState;
		}
	},
	extraReducers: {
		[getTrends.fulfilled]: (state, action) => {
			state.loading = false;
			state.data = action.payload;
		},
		[getTrends.rejected]: (state, action) => {
			state = initialState;
		}
	}
});

export const { setTrendsLoading, clearTrends, setTrendsData } = TrendsSlice.actions;

export default TrendsSlice.reducer;
