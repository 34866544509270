import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { batch } from 'react-redux';
import { tokenHeaders, urlBackend } from '../../data/LocationContext';
// import { setScopoNewsInSelectedMonitorSocialData as setMonitorSocialData } from '../entidade/entityStatusSlice';
import { setNewsSources, setRequestedSources } from './newsSourcesSlice';
import { showMessage } from '../../../store/fuse/messageSlice';

function isJSON(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

function parseServerNews(serverHits, dispatch) {
	const arrNews = [];
	serverHits.hits.map(i =>
		arrNews.push({
			id: i._id,
			score: i._score,
			call: i.highlight.call ? i.highlight.call[0] : i._source.call,
			date: i._source.last_updated,
			source: i._source.source,
			source_icon: i._source.source_icon,
			title: i.highlight.title ? i.highlight.title[0] : i._source.title,
			url: i._source.url,
			text: i.highlight.text ? i.highlight.text[0] : '',
			img: i._source?.image_urls?.url
		})
	);
	const scores = arrNews.map(i => i.score);

	dispatch(
		setNewsScores({
			min: Math.min(...scores),
			max: Math.max(...scores),
			avg: scores.reduce((sum, item) => sum + item) / scores.length
		})
	);
	return arrNews;
}

export const getClippings = createAsyncThunk('news/clipping/getClippings', async (params, { dispatch, getState }) => {
	// mon_id, entity_id, usuario_id
	const { tp, id } = params;
	const { removeHeadlines } = getState().news.clipping;
	const response = await axios.get(
		`${urlBackend}/api/news/get_news/?${tp}=${id}&remove_headlines=${removeHeadlines}`
	);
	const { data, success, message } = response.data;
	const news_data = isJSON(data) ? JSON.parse(data) : data;

	const { hits } = news_data || {};

	const arrNews = parseServerNews(hits, dispatch);

	if (message) {
		dispatch(
			showMessage({
				message,
				autoHideDuration: 7000,
				anchorOrigin: {
					vertical: 'top', // top bottom
					horizontal: 'right' // left center right
				},
				variant: success ? 'success' : 'error'
			})
		);
	}
	return arrNews;
});

export const changeManchetesInClipping = createAsyncThunk(
	'news/clipping/changeManchetesInClipping',
	async (bolMancheteInclusion, { dispatch, getState }) => {
		dispatch(handleHeadLinesInclude(bolMancheteInclusion));
		const { scopoNewsId } = getState().news.clipping;
		const response = await axios.get(
			`${urlBackend}/api/news/get_news/?scopo_news_id=${scopoNewsId}&remove_headlines=${bolMancheteInclusion}`
		);
		const { data, success, message } = response.data;
		const news_data = isJSON(data) ? JSON.parse(data) : data;
		const { hits } = news_data || {};
		return parseServerNews(hits, dispatch);
	}
);

export const markClippingArticlesAsRead = createAsyncThunk(
	'news/clipping/markClippingArticlesAsRead',
	async (read_list, { dispatch, getState }) => {
		const { scopoNewsId } = getState().news.clipping;

		const dataRead = {
			scopoNewsId,
			read_list
		};

		const response = await axios.post(`${urlBackend}/api/news/include_read_articles/`, dataRead, tokenHeaders());

		const { data, success } = response.data;
		const news_data = isJSON(data) ? JSON.parse(data) : data;
		const { hits } = news_data || {};
		const arrNews = parseServerNews(hits, dispatch);
		console.log('arrNews', arrNews);
		return arrNews
	}
);

// update keywords
export const updateMonitorSocialNews = createAsyncThunk(
	'news/clipping/updateScopoNews',
	async (params, { dispatch, getState }) => {
		const { entityInEditingMode } = getState().fuse.backendStatus;
		const response = await axios.post(`${urlBackend}/api/news/update_keywords/`, params, tokenHeaders());
		const data = await response.data;
		if (entityInEditingMode) {
			// dispatch(setMonitorSocialData(data));
			console.log('Desativado para evitar importação cíclica. Revisar')
		} else {
			const { scopo_fonts } = data;
			dispatch(setNewsSources(scopo_fonts));
			delete data.scopo_fonts;
			dispatch(setNewsData(data));
		}
	}
);

// check if entity has monitores sociais and update
export const checkUpdateEntityMonSocial = createAsyncThunk(
	'news/clipping/checkUpdateEntityMonSocial',
	async (entity_id, { dispatch, getState }) => {
		const response = await axios.get(`${urlBackend}/api/monitor_social/data/?id=${entity_id}`);
		const { success, data } = response.data;
		if (success) {
			return JSON.parse(data);
		}
	}
);

export const defineUsuarioClippingSettings = createAsyncThunk(
	'news/clipping/defineUsuarioClippingSettings',
	async (params, { dispatch, getState }) => {
		// tps = mon_id, entity_id, usuario_id
		const { tp, id } = params;
		if (tp === 'usuario_id') {
			dispatch(setMonitoresSociais(null));
		} else if (tp === 'entity_id') {
			dispatch(checkUpdateEntityMonSocial(id));
		}

		const response = await axios.get(`${urlBackend}/api/news/get_news_settings/?${tp}=${id}`);

		const { data } = response;

		dispatch(setNewsSettings(data));
	}
);

// define selected clipping user
export const defineUsuarioClipping = createAsyncThunk(
	'news/clipping/defineUsuarioClipping',
	async (clipping_id, { dispatch, getState }) => {
		const { entitiesClippingUsers } = getState().news.clipping;

		const selectedUserClipping = entitiesClippingUsers.find(u => u.id === clipping_id);

		if (!selectedUserClipping || selectedUserClipping.origem === 'usuario') {
			dispatch(setMonitoresSociais(null));
		} else {
			dispatch(checkUpdateEntityMonSocial(selectedUserClipping.sid));
		}

		const response = await axios.get(
			`${urlBackend}/api/news/get_news_settings/?${selectedUserClipping.stp}=${selectedUserClipping.sid}`
		);

		const { data } = response;

		// tps = mon_id, entity_id, usuario_id
		batch(() => {
			dispatch(setSelectedClippingUserId(clipping_id));
			dispatch(setSelectedClippingUserImg(selectedUserClipping.img));
			dispatch(getClippings({ tp: selectedUserClipping.stp, id: selectedUserClipping.sid }));
		});

		return data;
	}
);

// handle new Clipping News source request
export const requestNewClippingSource = createAsyncThunk(
	'news/clipping/requestNewClippingSource',
	async (formData, { dispatch, getState }) => {
		const response = await axios.post(
			`${urlBackend}/api/news/handle_news_font_inclusion/`,
			formData,
			tokenHeaders()
		);
		const { success, message, data } = await response.data;

		if (message) {
			dispatch(
				showMessage({
					message,
					autoHideDuration: 5000,
					anchorOrigin: {
						vertical: 'top', // top bottom
						horizontal: 'center' // left center right
					},
					variant: success ? 'success' : 'error'
				})
			);
		}

		if (success) {
			dispatch(setRequestedSources(data));
			return data;
		}
	}
);

// get settings to monitor clippings
export const getMonitorClippings = createAsyncThunk(
	'news/clipping/getMonitorClippings',
	async (params, { dispatch, getState }) => {
		const { tp, id } = params;
		dispatch(setSelectedClippingMonitorId(id));
		const response = await axios.get(`${urlBackend}/api/news/get_news_settings/?${tp}=${id}`);
		const { data } = response;

		dispatch(getClippings({ tp, id }));

		return data;
	}
);

export const updateNewsQuantityOnServer = createAsyncThunk(
	'news/clipping/updateNewsQuantityOnServer',
	async (nrNews, { dispatch, getState }) => {
		// const { scopoNewsId, selectedClippingUserId, newsCount, entitiesClippingUsers } = getState().news.clipping;
		const { scopoNewsId, removeHeadlines } = getState().news.clipping;
		// const userFeed = Object.values(entitiesClippingUsers).filter(u => u.id === selectedClippingUserId);

		const newData = {
			id: scopoNewsId,
			news_count: nrNews,
			remove_headlines: removeHeadlines
		};

		const response = await axios.post(`${urlBackend}/api/news/update_news_quantity/`, newData, tokenHeaders());
		const { settings, noticias } = response.data;

		const parsedSettings = JSON.parse(settings);
		const parsedNoticias = JSON.parse(noticias);

		const { hits } = parsedNoticias || {};
		const arrNews = parseServerNews(hits, dispatch);

		dispatch(setClippings(arrNews));

		return parsedSettings;
	}
);

export const handleChangeClippingSourceId = createAsyncThunk(
	'news/clipping/handleChangeClippingSourceId',
	async (params, { dispatch, getState }) => {
		const { source_id, requisition } = params;
		const { scopoNewsId } = getState().news.clipping;
		const data = {
			news_id: scopoNewsId,
			source_id,
			requisition
		};
		const response = await axios.post(
			`${urlBackend}/api/news/handle_subscription_news_source/`,
			data,
			tokenHeaders()
		);
		const { success, ids } = response.data;
		return ids;
	}
);

export const updateNewsKeywords = createAsyncThunk(
	'news/clipping/updateNewsKeywords',
	async (params, { dispatch, getState }) => {
		const { keywords, negative_keywords } = params;
		const { scopoNewsId } = getState().news.clipping;

		const data = {
			keywords,
			negative_keywords,
			news_id: scopoNewsId,
			return_news: true
		};

		const response = await axios.post(`${urlBackend}/api/news/update_keywords/`, data, tokenHeaders());

		const { settings, noticias } = response.data;

		console.log('noticias', noticias);

		const parsedSettings = JSON.parse(settings);
		const parsedNoticias = isJSON(noticias) ? JSON.parse(noticias) : noticias;

		const { hits } = parsedNoticias || {};

		const arrNews = parseServerNews(hits, dispatch);
		dispatch(setClippings(arrNews));
		// dispatch(setClippings(parsedSettings));
		return parsedSettings;
	}
);

export const updateNewsDatesRange = createAsyncThunk(
	'news/clipping/updateNewsDatesRange',
	async (params, { dispatch, getState }) => {
		const { days_range, update_until, updated_since } = params;
		const { scopoNewsId } = getState().news.clipping;

		batch(() => {
			dispatch(setNewsUntil(update_until));
			dispatch(setNewsSince(updated_since));
			dispatch(setDaysRange(days_range));
		});

		const newParams = {
			scopoNewsId,
			days_range,
			update_until
		};

		const response = await axios.post(`${urlBackend}/api/news/update_news_range/`, newParams, tokenHeaders());
		const data = await response.data;
		console.log(data);
		const parseData = JSON.parse(data);
		const { hits } = parseData || {};
		const arrNews = parseServerNews(hits, dispatch);

		console.log('parseData');
		console.log(parseData);
		console.log('arrNews');
		console.log(arrNews);
		return arrNews;
	}
);

const clippingAdapter = createEntityAdapter({});

export const {
	selectAll: selectClippings,
	removeAll: removeClippings,
	selectById: selectClipping
} = clippingAdapter.getSelectors(state => state.news.clipping);

export const minNewsDatesDistance = 10;
export const maxNewsRangeDays = 365;
export const maxNewsCount = 400;
export const minNewsCount = 10;

const initialState = {
	scopoNewsId: null,
	loading: false,
	removeHeadlines: true,
	selectedClippingId: null,
	searchText: '',
	newsSince: null,
	newsUntil: 'now',
	daysRange: 30,
	newsCount: 50,
	monitoringTerms: null,
	negativeTerms: null,
	scores: null,
	entitiesSelectOption: 'monitored', // monitored and active
	entitiesClippingUsers: [],
	selectedClippingUserId: null,
	selectedClippingUserImg: 'https://ecom-scopo.sfo2.digitaloceanspaces.com/shared_images/unknown-avatar_128px.png',
	followingSources: [],
	monitoresSociais: null,
	selectedClippingMonitorId: null,
	error: null
};

const clippingSlice = createSlice({
	name: 'news/clipping',
	initialState: clippingAdapter.getInitialState(initialState),
	reducers: {
		setClippings: (state, action) => {
			clippingAdapter.setAll(state, action.payload);
		},
		setNewsSettings: (state, action) => {
			const { danger_terms, days_range, news_count, scopo_fonts, terms_to_monitor, update_until, id } =
				action.payload;
			state.loading = false;
			state.scopoNewsId = id;
			state.newsUntil = update_until;
			state.daysRange = days_range;
			state.newsCount = news_count;
			state.monitoringTerms = terms_to_monitor;
			state.negativeTerms = danger_terms;
			state.followingSources = Object.values(scopo_fonts).map(i => i.id);
		},
		setNewsData: (state, action) => {
			state.loading = false;
			state.newsSince = action.payload.update_since;
			state.newsUntil = action.payload.update_until;
			state.daysRange = action.payload.days_range;
			state.monitoringTerms = action.payload.terms_to_monitor;
			state.negativeTerms = action.payload.danger_terms;
		},
		appendClippings: (state, action) => {
			clippingAdapter.addMany(state, action.payload);
		},
		setMonitoringTerms: (state, action) => {
			state.monitoringTerms = action.payload;
		},
		setNegativeTerms: (state, action) => {
			state.negativeTerms = action.payload;
		},
		setNewsSince: (state, action) => {
			state.newsSince = action.payload;
		},
		setNewsUntil: (state, action) => {
			state.newsUntil = action.payload;
		},
		setDaysRange: (state, action) => {
			state.daysRange = action.payload;
		},
		setNewsScores: (state, action) => {
			state.scores = action.payload;
		},
		setNewsCount: (state, action) => {
			state.newsCount = action.payload;
		},
		setClippingSearchText: {
			reducer: (state, action) => {
				state.searchText = action.payload;
			},
			prepare: event => ({ payload: event.target.value || '' })
		},
		resetClipping: (state, action) => {
			clippingAdapter.getInitialState(initialState);
		},
		setEntitiesClippingUsers: (state, action) => {
			state.entitiesClippingUsers = action.payload;
		},
		setSelectedClippingUserId: (state, action) => {
			state.selectedClippingUserId = action.payload;
		},
		setSelectedClippingUserImg: (state, action) => {
			state.selectedClippingUserImg = action.payload;
		},
		handleHeadLinesInclude: (state, action) => {
			state.removeHeadlines = action.payload;
		},
		setEntitiesSelectOption: (state, action) => {
			state.entitiesSelectOption = action.payload;
		},
		setMonitoresSociais: (state, action) => {
			state.monitoresSociais = action.payload;
		},
		setSelectedClippingMonitorId: (state, action) => {
			state.selectedClippingMonitorId = action.payload;
		}
	},
	extraReducers: {
		[updateMonitorSocialNews.pending]: (state, action) => {
			state.loading = true;
		},
		[updateMonitorSocialNews.rejected]: (state, action) => {
			clippingAdapter.getInitialState(initialState);
		},
		[updateMonitorSocialNews.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[getClippings.pending]: (state, action) => {
			state.loading = true;
		},
		[getClippings.fulfilled]: (state, action) => {
			state.loading = false;
			clippingAdapter.setAll(state, action.payload);
		},
		[getClippings.rejected]: (state, action) => {
			// clippingAdapter.getInitialState(initialState);
			clippingAdapter.removeAll(state);
			state.loading = false;
			state.error = action.error.message;
		},
		[markClippingArticlesAsRead.pending]: (state, action) => {
			state.loading = true;
		},
		[markClippingArticlesAsRead.fulfilled]: (state, action) => {
			state.loading = false;
			clippingAdapter.setAll(state, action.payload);
		},
		[markClippingArticlesAsRead.rejected]: (state, action) => {
			clippingAdapter.removeAll(state);
			state.loading = false;
			state.error = action.error.message;
		},
		[defineUsuarioClipping.pending]: (state, action) => {
			state.loading = true;
		},
		[defineUsuarioClipping.rejected]: (state, action) => {
			// clippingAdapter.getInitialState(initialState);
			clippingAdapter.removeAll(state);
			state.loading = false;
			state.error = action.error.message;
		},
		[changeManchetesInClipping.pending]: (state, action) => {
			state.loading = true;
		},
		[changeManchetesInClipping.rejected]: (state, action) => {
			state.loading = false;
			state.removeHeadlines = !state.removeHeadlines;
		},
		[changeManchetesInClipping.fulfilled]: (state, action) => {
			state.loading = false;
			clippingAdapter.setAll(state, action.payload);
		},
		[defineUsuarioClipping.fulfilled]: (state, action) => {
			const {
				danger_terms,
				days_range,
				news_count,
				scopo_fonts,
				terms_to_monitor,
				update_until,
				update_since,
				id
			} = action.payload;
			state.loading = false;
			state.scopoNewsId = id;
			state.newsUntil = update_until;
			state.newsSince = update_since;
			state.daysRange = days_range;
			state.newsCount = news_count;
			state.monitoringTerms = terms_to_monitor;
			state.negativeTerms = danger_terms;
			state.followingSources = Object.values(scopo_fonts).map(i => i.id);
		},
		[getMonitorClippings.pending]: (state, action) => {
			state.loading = true;
		},
		[getMonitorClippings.fulfilled]: (state, action) => {
			const {
				danger_terms,
				days_range,
				news_count,
				scopo_fonts,
				terms_to_monitor,
				update_until,
				update_since,
				id
			} = action.payload;
			state.loading = false;
			state.scopoNewsId = id;
			state.newsUntil = update_until;
			state.newsSince = update_since;
			state.daysRange = days_range;
			state.newsCount = news_count;
			state.monitoringTerms = terms_to_monitor;
			state.negativeTerms = danger_terms;
			state.followingSources = Object.values(scopo_fonts).map(i => i.id);
		},
		[getMonitorClippings.rejected]: (state, action) => {
			state.loading = false;
			state.monitoresSociais = null;
			state.selectedClippingMonitorId = null;
		},
		[updateNewsQuantityOnServer.pending]: (state, action) => {
			state.loading = true;
		},
		[updateNewsQuantityOnServer.fulfilled]: (state, action) => {
			const { danger_terms, days_range, news_count, scopo_fonts, terms_to_monitor, update_until, id } =
				action.payload;
			state.loading = false;
			state.scopoNewsId = id;
			state.newsUntil = update_until;
			state.daysRange = days_range;
			state.newsCount = news_count;
			state.monitoringTerms = terms_to_monitor;
			state.negativeTerms = danger_terms;
			state.followingSources = Object.values(scopo_fonts).map(i => i.id);
			// clippingAdapter.setAll(state, action.payload);
		},
		[updateNewsQuantityOnServer.rejected]: (state, action) => {
			state.loading = false;
		},
		[checkUpdateEntityMonSocial.fulfilled]: (state, action) => {
			state.monitoresSociais = action.payload;
		},
		[checkUpdateEntityMonSocial.rejected]: (state, action) => {
			state.monitoresSociais = null;
		},
		[updateNewsKeywords.pending]: (state, action) => {
			state.loading = true;
		},
		[updateNewsKeywords.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[handleChangeClippingSourceId.fulfilled]: (state, action) => {
			state.followingSources = action.payload;
		},
		[updateNewsDatesRange.pending]: (state, action) => {
			state.loading = true;
		},
		[updateNewsDatesRange.rejected]: (state, action) => {
			state.loading = false;
		},
		[updateNewsDatesRange.fulfilled]: (state, action) => {
			state.loading = false;
			clippingAdapter.setAll(state, action.payload);
		}
	}
});

export const {
	setClippings,
	setNewsData,
	appendClippings,
	setMonitoringTerms,
	setNegativeTerms,
	setNewsSince,
	setNewsUntil,
	setNewsScores,
	setClippingSearchText,
	resetClipping,
	setEntitiesClippingUsers,
	setSelectedClippingUserId,
	setSelectedClippingUserImg,
	setNewsCount,
	handleHeadLinesInclude,
	setEntitiesSelectOption,
	setMonitoresSociais,
	setSelectedClippingMonitorId,
	setNewsSettings,
	setDaysRange
} = clippingSlice.actions;

export default clippingSlice.reducer;
