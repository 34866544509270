// This slice is used to get previous candidates that already have disputes
// in mongodb. To register new candidates/concorrentes, use the research.candidade-research

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { tokenHeaders, urlBackend } from 'app/main/data/LocationContext';
import { showMessage } from 'app/store/fuse/messageSlice';
import { candidatoInitialState } from '../../politico/candidatoSlice';
import { getMonitorSocialId } from '../../../IndicatorsSlices/monitorSocialSlice';
import { closeDialogConcorrenteByName } from '../../../../../store/fuse/dialogSlice';

// const researchConcorrentePath = `${urlBackend}/api/research/candidato_info/?`;
const researchConcorrentePath = `${urlBackend}/api/research/concorrente_info/?`;

export const IncludeUpdateMarketsharePlayer = createAsyncThunk(
	'entidade/analise_comparativa/concorrente/incluiAtualizaPlayer',
	async (data, { dispatch, getState }) => {
		const jsonData = JSON.stringify(data);
		const config = {
			...tokenHeaders(),
			method: 'POST',
			url: `${urlBackend}/api/entidade/marketshare_player/`,
			data: jsonData
		};
		const response = await axios(config);
		console.log(response);

		const { selectedMonitorId } = getState().entidade.indicators.monitor_social;

		if (selectedMonitorId) {
			dispatch(getMonitorSocialId(selectedMonitorId));
		}
	}
);

export const getConcorrenteData = createAsyncThunk(
	'entidade/analise_comparativa/concorrente/getConcorrenteData',
	async (param, { dispatch, getState }) => {
		const key = Object.keys(param)[0];
		const value = Object.values(param)[0];

		const players =
			getState().entidade.politico.participantes.ids.length > 0
				? getState().entidade.politico.participantes.entities
				: getState()?.entidade?.indicators?.monitor_social?.data?.players;

		const concorrentesIds = players
			? Object.values(players)
					.map(obj => obj?.concorrente?.id)
					.filter(Boolean)
			: [];

		const candidato_id = getState()?.entidade?.politico?.candidato?.id;

		// verify and close any open dialog
		const { dialogConcorrenteByNameOpen } = getState().fuse.dialog;

		const { concorrente } = getState()?.entidade?.analise_comparativa;
		console.log('CONCORRENTE:', concorrente);

		// just to give a little space between close one dialog to open a modal
		if (dialogConcorrenteByNameOpen) {
			dispatch(closeDialogConcorrenteByName());

			if (concorrente && !concorrente.loading) {
				dispatch(setConcorrenteLoading(true));
			}

			if (concorrente && !concorrente.modalOpen) {
				dispatch(setModalConcorrenteOpen(true));
				// setTimeout(() => {
				// 	dispatch(setModalConcorrenteOpen(true));
				// }, 300);
			}
		} else {
			if (!concorrente.loading) {
				dispatch(setConcorrenteLoading(true));
			}
			if (!concorrente.modalOpen) {
				dispatch(setModalConcorrenteOpen(true));
			}

		}

		const config = tokenHeaders();
		config.url =
			candidato_id === 'new'
				? `${researchConcorrentePath}${key}=${value}`
				: `${researchConcorrentePath}${key}=${value}&not_new=true`;

		const response = await axios(config);
		const { success, data, message, further_action, error_severity } = response.data;

		const newData = JSON.parse(data);

		if (message) {
			dispatch(
				showMessage({
					message,
					autoHideDuration: 5000,
					anchorOrigin: {
						vertical: 'top', // top bottom
						horizontal: 'center' // left center right
					},
					variant: error_severity || (success ? 'success' : 'error')
				})
			);
		}

		if (further_action) {
			dispatch(setFurtherAction(further_action));
			if (further_action === 'manage_access') {
				dispatch(setConcorrenteEditable(true));
			} else if (further_action === 'request_access') {
				dispatch(setConcorrenteEditable(false));
			}
		}

		return newData;
	}
);

const initialState = {
	loading: false,
	modalOpen: false,
	editable: false,
	further_action: null,
	data: null
};

const concorrenteSlice = createSlice({
	name: 'entidade/analise_comparativa/concorrente',
	initialState,
	reducers: {
		setConcorrenteLoading: (state, action) => {
			state.loading = action.payload;
		},
		setModalConcorrenteOpen: (state, action) => {
			state.modalOpen = action.payload;
		},
		setConcorrenteData: (state, action) => {
			state.data = action.payload;
		},
		setConcorrenteEditable: (state, action) => {
			state.editable = action.payload;
		},
		setFurtherAction: (state, action) => {
			state.further_action = action.payload;
		},
		resetConcorrente: (state, action) => initialState,
		novoConcorrente: {
			reducer: (state, action) => {
				state.data = action.payload;
			},
			prepare: () => ({
				payload: candidatoInitialState
			})
		}
	},
	extraReducers: {
		[getConcorrenteData.fulfilled]: (state, action) => {
			state.data = action.payload;
			// state.loading = false;
			// state.modalOpen = true
		},
		[getConcorrenteData.rejected]: (state, action) => {
			state.loading = false;
			state.data = null;
		}
	}
});

export const {
	setConcorrenteLoading,
	setModalConcorrenteOpen,
	setConcorrenteData,
	setConcorrenteEditable,
	setFurtherAction,
	resetConcorrente,
	novoConcorrente
} = concorrenteSlice.actions;

export default concorrenteSlice.reducer;
