import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { tokenHeaders, getDuiId, urlBackend } from '../../../data/LocationContext';
import { showMessage } from '../../../../store/fuse/messageSlice';

const urlFillData = `${urlBackend}/api/notifications/`;

// userIsLoggedin checks if the user is logged in the frontend/backend
// tokenStatusOnServer - checks if the token is registered and associated with an
// user
const initialState = {
	browserIsCompatible: false,
	asWorker: false,
	asToken: false,
	isLoaded: false,
	isAuthorized: false,
	userIsLoggedIn: false,
	token: null,
	tokenStatusOnServer: {
		isRegistered: false,
		isAssociatedWithUser: false
	}
};

export const handleFcmTokenOnServer = token => {
	const urlCheckToken = `${urlFillData}check_token/`;
	console.log(urlCheckToken);

	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	};

	const body = JSON.stringify({
		token,
		uid: getDuiId()
	});
	axios
		.post(urlCheckToken, body, tokenHeaders())
		.then(response => {
			console.log(response);
			const dt1 = {
				isRegistered: true,
				isAssociatedWithUser: false
			};
			return dispatch => {
				dispatch(setTokenStatusOnServer(dt1));
			};
		})
		.catch(error => {
			console.log('deu merda', error);

			return dispatch => {
				dispatch(setTokenStatusOnServer(initialState.tokenStatusOnServer));
			};

			// return dispatch => dispatch(showMessagse({
			//     message: error,
			//     autoHideDuration: 7000,
			//     anchorOrigin: {
			//         vertical  : 'top',//top bottom
			//         horizontal: 'center'//left center right
			//     },
			//     variant: 'error'//success error info warning null
			//
			// }))
		});
};

export const associateTokenUser = token => {
	const urlAssociateToken = `${urlFillData}associate_token/`;
	const body = JSON.stringify({ token });
	axios.post(urlAssociateToken, body, tokenHeaders()).then(response => console.log(response));
};

export const dessociateTokenUser = token => {
	const urlDessociateToken = `${urlFillData}dessociate_token/`;
	const body = JSON.stringify({ token });
	axios.post(urlDessociateToken, body, tokenHeaders()).then(response => console.log(response));
};

const firebasePushSlice = createSlice({
	name: 'firebasePush',
	initialState,
	reducers: {
		setWorker: (state, action) => {
			state.asWorker = action.payload;
		},
		setTokenStatus: (state, action) => {
			state.asToken = action.payload;
		},
		setLoaded: (state, action) => {
			state.isLoaded = action.payload;
		},
		setAuthorized: (state, action) => {
			state.isAuthorized = action.payload;
		},
		setToken: (state, action) => {
			state.token = action.payload;
		},
		setBrowserCompatible: (state, action) => {
			state.browserIsCompatible = action.payload;
		},
		setUserIsLoggedIn: (state, action) => {
			state.userIsLoggedIn = action.payload;
		},
		setTokenStatusOnServer: (state, action) => {
			state.tokenStatusOnServer = action.payload;
		},
		resetFirebasePushStat: (state, action) => {
			state = initialState;
		}
	},
	extraReducers: {}
});

export const {
	setWorker,
	setTokenStatus,
	setLoaded,
	setAuthorized,
	setToken,
	setBrowserCompatible,
	setUserIsLoggedIn,
	setTokenStatusOnServer
} = firebasePushSlice.actions;

export default firebasePushSlice.reducer;
