import FuseAnimate from '@fuse/core/FuseAnimate';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Icon from '@mui/material/Icon';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import {ThumbUpAlt} from "@mui/icons-material";


const useStyles = makeStyles(theme => ({
    root: {
        background: `linear-gradient(to right, #122230 0%, #0b141d 100%)`,
        color: theme.palette.primary.contrastText
    }
}));

function RegisterSuccess() {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32')}>
            <div className="flex flex-col items-center justify-center w-full">
                <FuseAnimate animation="transition.expandIn">
                    <Card className="w-full max-w-384 rounded-8">
                        <CardContent className="flex flex-col items-center justify-center p-32">
                            <div className="m-32">
                                <ThumbUpAlt style={{color: "green", fontSize: "60px"}}/>
                            </div>

                            <Typography variant="h4" className="text-center mb-16">
                                Cadastro confirmado com sucesso!!!
                            </Typography>


                            <Typography className="text-center mb-16 w-full" color="textSecondary">
                                Agora você pode voltar pode acessar o painel e cadastrar seu negócio.
                            </Typography>


                            <Typography className="text-center w-full" color="textSecondary">
                                Caso tente cadastrar uma empresa que já esteja no sistema, um pedido de inclusão será enviado ao adminstrador do negócio.
                            </Typography>

                            <div className="flex flex-col items-center justify-center pt-32 pb-24">
                                <Link className="font-medium" to="/login">
                                    Voltar para o login
                                </Link>
                            </div>
                        </CardContent>
                    </Card>
                </FuseAnimate>
            </div>
        </div>
    );
}

export default RegisterSuccess;
