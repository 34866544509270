import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { authRoles } from '../../../auth';

const ClippingApp = lazy(() => import('./ClippingApp'));

const ClippingAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/app/clipping/:tp',
			element: <ClippingApp />
		},
		{
			path: '/app/clipping',
			element: <Navigate to="/app/clipping/all" />
		}
	]
};

export default ClippingAppConfig;
