import React, { lazy } from 'react';
import { authRoles } from '../../../auth';
// import { Navigate } from "react-router-dom";

const WarRoom = lazy(() => import('./WarRoom'));

const WarRoomConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/war-room',
			element: <WarRoom />
		}
	]
};

export default WarRoomConfig;
