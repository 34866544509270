import {lazy} from 'react';
import React from 'react';
import { authRoles } from "../../../auth";

const Convites = lazy(()=>import('./Convites'));
const AcceptOperatorInvite = lazy(()=>import('app/main/scopo-apps/equipe/AcceptOperatorInvite'))

const ConvitesConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  auth: authRoles.user,
  routes : [
    {
      path: "/convites",
      element: <Convites/>
    },
    {
      path: "convites/aceitar/:id",
      element: <AcceptOperatorInvite />
    }
  ]
}

export default ConvitesConfig;