import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { authRoles } from '../../auth';
import SocialNetworksControl from './SocialNetworksControl';

const SocialNetworksConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.user,
	routes : [
		{
			path: 'apps/social/:sn',
			element: <SocialNetworksControl />
		},
		{
			path: 'apps/social/view',
			element: <Navigate to="apps/social/view" />
		}
	]
};

export default SocialNetworksConfig;
