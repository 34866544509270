import { configureStore, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { urlBackend, tokenHeaders } from '../../../data/LocationContext';
import { showMessage } from '../../../../store/fuse/messageSlice';
import idbKeyval from '../../scopoTempDb';

export const getPoliticalEvaluations = createAsyncThunk(
	'entidade/politico/evaluations/getPoliticalEvaluations',
	async (monId, { dispatch }) => {
		const response = await axios.get(
			`${urlBackend}/api/evaluation/concorrentes/?id_monitor=${monId}`,
			tokenHeaders()
		);
		const { success, data, message } = response.data;

		if (message) {
			dispatch(showMessage(message));
		}
		if (success) {
			const politicalEvalCache = `politicalEvalCacheMonId_${monId}`;
			const dataStore = JSON.parse(data);
			dataStore.id = politicalEvalCache;
			await idbKeyval
				.set(dataStore)
				.then(() => console.log('Avaliações atualizadas'))
				.catch(err => showMessage(err));
			return JSON.parse(data);
		}
	}
);

const initialState = {
	loading: false,
	data: null
};

const politicalEvaluationsSlice = createSlice({
	name: 'entidade/politico/evaluations',
	initialState,
	reducers: {
		setPoliticalEvaluationLoading: (state, action) => {
			state.loading = action.payload;
		},
		resetPoliticalEvaluationData: () => initialState,
		setPoliticalEvaluationData: (state, action) => {
			state.loading = false;
			state.data = action.payload.data;
		}
	},
	extraReducers: {
		[getPoliticalEvaluations.pending]: (state, action) => { state.loading=true },
		[getPoliticalEvaluations.fulfilled]: (state, action) => {
			state.loading = false;
			state.data = action.payload;
		},
		[getPoliticalEvaluations.rejected]: (state, action) => {
			state = initialState;
		}
	}
});

export const { setPoliticalEvaluationLoading,
	resetPoliticalEvaluationData,
	setPoliticalEvaluationData } =
	politicalEvaluationsSlice.actions;

export default politicalEvaluationsSlice.reducer;
