//for individual research
export const GET_ASYNC_DATA = 'GET_ASYNC_DATA';
export const GET_PROGRESS = 'GET_PROGRESS';
export const GET_PROGRESS_CELULARES = 'GET_PROGRESS_CELULARES';
export const GET_PROGRESS_EMPRESAS = 'GET_PROGRESS_EMPRESAS';
export const GET_PROGRESS_FIXOS = 'GET_PROGRESS_FIXOS';
export const GET_PROGRESS_PESSOAS = 'GET_PROGRESS_PESSOAS';
export const GET_PROGRESS_POLITICO = 'GET_PROGRESS_POLITICO';
export const GET_PROGRESS_PROCESSOS = 'GET_PROGRESS_PROCESSOS';
export const GET_PROGRESS_TESTE = 'GET_PROGRESS_TESTE';
export const UPDATE_DATA = 'UPDATE_DATA';
export const UPDATE_EMPRESAS = 'UPDATE_EMPRESAS';
export const RESET_INDIVIDUAL_RESEARCH = 'RESET_INDIVIDUAL_RESEARCH';
export const RESET_INDIVIDUAL_PROGRESS = 'RESET_INDIVIDUAL_PROGRESS';

