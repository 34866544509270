import React, { lazy } from 'react';
import { authRoles } from '../../../auth';

const FaqPage = lazy(() => import('./FaqPage'));

const FaqPageConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: 'docs/faq',
			element: <FaqPage />
		}
	]
};

export default FaqPageConfig;
