import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { urlBackend, tokenHeaders } from 'app/main/data/LocationContext';
import { showMessage } from 'app/store/fuse/messageSlice';
import FuseUtils from '@fuse/utils';
import { setRemoteLoaded } from '../../../../store/fuse/environmentSlice';
import { getEntitiesList } from '../entityStatusSlice';
import { updateNrCandidatesLeft } from '../../../../store/fuse/backendStatusSlice';

const concorrentePath = `${urlBackend}/api/research/concorrente_info/?`;
const includeConcorrenteEntity = `${urlBackend}/api/entidade/add/?tp=candidato`;

export const candidatoInitialState = {
	id: 'new',
	nome: '',
	nm_urna: '',
	nr_urna: '',
	foto: '',
	image_url: 'https://ecom-scopo.sfo2.digitaloceanspaces.com/shared_images/perfilHomem.jpeg',
	cpf: '',
	titulo_eleitor: '',
	data_nascimento: '01/01/1970',
	idade: '',
	nacionalidade: 1,
	pais_nascimento: 'BR',
	estado_nascimento: '',
	municipio_nascimento: '',
	partido: '',
	genero: 'ND',
	estado_civil: 0,
	instrucao: 0,
	ocupacao: 0,
	position: '',
	raca: 1,
	referencia_social: { id: null, tipo: 'politico', nm: '', origem_name: '', social_network: [] },
	ballot: '',
	disputa_eleitoral: '',
	// marketshare: null,
	uf: '',
	cidade: '',
	email: '',
	list_emails: [],
	exerce_funcao: false,
	exerce_cargo_disputa: false,
	reeleicao: false,
	primeira_disputa: true
};

export const incluiNovoCandidato = createAsyncThunk(
	'entidade/politico/candidato/incluiNovoCandidato',
	async (dados, { dispatch, getState }) => {
		const response = await axios.post(includeConcorrenteEntity, dados, tokenHeaders());
		const { success, message, data, error_severity } = response.data;
		const { nr_candidates_left } = getState().fuse.backendStatus;

		console.log('SENT DATA: ', dados);

		if (message) {
			let varianteAlerta = success ? 'success' : 'error';
			if (error_severity) {
				varianteAlerta = error_severity;
			}
			dispatch(
				showMessage({
					message,
					autoHideDuration: 5000,
					anchorOrigin: {
						vertical: 'top', // top bottom
						horizontal: 'center' // left center right
					},
					variant: varianteAlerta
				})
			);
		}

		if (success) {
			dispatch(getEntitiesList());
			dispatch(updateNrCandidatesLeft(nr_candidates_left - 1));
			dispatch(setRemoteLoaded());
			await new Promise(resolve => setTimeout(resolve, 2000));
			console.log(data);
			return data;
		}
	}
);

export const testeInclusion = createAsyncThunk(
	'entidade/politico/candidato/testeInclusion',
	async (dados, { dispatch }) => {
		return new Promise(resolve => {
			console.log(dados);
			setTimeout(() => {
				dispatch(setRemoteLoaded());
				resolve({ data: { EntityId: 108 } });
			}, 5000);
		});
	}
);

export const getConcorrente = createAsyncThunk(
	'entidade/politico/candidato/getConcorrente',
	async (param, { dispatch, getState }) => {
		const config = tokenHeaders();
		const candidato_id = getState().entidade.politico.candidato.id;
		config.method = 'get';
		config.url =
			candidato_id === 'new'
				? `${concorrentePath}${param.tp}=${param.id}`
				: `${concorrentePath}${param.tp}=${param.id}&not_new=true`;
		const response = await axios(config);
		const { success, message, data, error_severity } = response.data;

		if (message) {
			let varianteAlerta = success ? 'success' : 'error';
			if (error_severity) {
				varianteAlerta = error_severity;
			}
			dispatch(
				showMessage({
					message,
					autoHideDuration: 5000,
					anchorOrigin: {
						vertical: 'top', // top bottom
						horizontal: 'center' // left center right
					},
					variant: varianteAlerta
				})
			);
		}

		if (success) {
			return JSON.parse(data);
		}
	}
);

const candidatoSlice = createSlice({
	name: 'entidade/politico/candidato',
	initialState: candidatoInitialState,
	reducers: {
		resetCandidato: () => candidatoInitialState,
		updateFromForm: (state, action) => action.payload,
		setCandidatoData: (state, action) => action.payload,
		novoCandidato: {
			reducer: (state, action) => action.payload,
			prepare: () => ({
				payload: candidatoInitialState
			})
		}
	},

	extraReducers: {
		[getConcorrente.fulfilled]: (state, action) => action.payload,
		[getConcorrente.rejected]: (state, action) => candidatoInitialState
	}
});

export const { novoCandidato, updateFromForm, setCandidatoData, resetCandidato } = candidatoSlice.actions;

export default candidatoSlice.reducer;
