// import { db } from 'plotly.js/src/traces/carpet/attributes';

import { useState } from 'react';
import { useSelector } from 'react-redux';

let dbPromise = createDbPromise();

function createDbPromise() {
	// const { version } = useSelector(({ fuse }) => fuse.backendStatus);
	// const currentDbVersion = version.replace(/[^0-9]/g, '');
	// const versionNumber = parseInt(currentDbVersion, 10);
	return new Promise((resolve, reject) => {
		const openRequest = indexedDB.open('scopoLocalDb', 3);
		openRequest.onupgradeneeded = event => {
			const db = event.target.result;
			if (!db.objectStoreNames.contains('scopoStore')) {
				db.createObjectStore('scopoStore', { keyPath: 'id' });
			}
			// Resolve here in the event of database upgrade
			resolve(db);
		};

		openRequest.onerror = () => {
			console.error('Database error:', openRequest.error);
			reject(openRequest.error);
		};

		openRequest.onsuccess = () => {
			// Only resolve here if it was not 'onupgradeneeded' event
			if (!openRequest.upgradeNeeded) {
				console.log('Database opened successfully');
				resolve(openRequest.result);
			}
		};
	});
}

export function closeDatabase() {
	return new Promise((resolve, reject) => {
		dbPromise.then(db => {
			db.close();
			dbPromise = createDbPromise(); // Create a new promise for future db operations
			resolve();
		});
	});
}

export function deleteScopoDatabase() {
	return closeDatabase().then(
		() =>
			new Promise((resolve, reject) => {
				const DBDeleteRequest = indexedDB.deleteDatabase('scopoLocalDb');

				DBDeleteRequest.onerror = event => {
					console.error('Error deleting database.');
					reject(event.error);
				};

				DBDeleteRequest.onsuccess = event => {
					console.log('Database deleted successfully');
					resolve(event.result); // should be undefined
				};
			})
	);
}

const idbKeyval = {
	get: key =>
		dbPromise.then(db => {
			return new Promise((resolve, reject) => {
				const tx = db.transaction('scopoStore', 'readonly');
				const store = tx.objectStore('scopoStore');
				const request = store.get(key);
				request.onsuccess = () => resolve(request.result);
				request.onerror = () => reject(request.error);
			});
		}),

	set: val =>
		dbPromise.then(db => {
			return new Promise((resolve, reject) => {
				const tx = db.transaction('scopoStore', 'readwrite');
				const store = tx.objectStore('scopoStore');
				const request = store.put(val);
				request.onsuccess = () => resolve(request.result);
				request.onerror = () => reject(request.error);
			});
		}),

	delete: key =>
		dbPromise.then(db => {
			return new Promise((resolve, reject) => {
				const tx = db.transaction('scopoStore', 'readwrite');
				const store = tx.objectStore('scopoStore');
				const request = store.delete(key);
				request.onsuccess = () => resolve();
				request.onerror = () => reject(request.error);
			});
		}),

	exists: key =>
		dbPromise.then(db => {
			return new Promise((resolve, reject) => {
				const tx = db.transaction('scopoStore', 'readonly');
				const store = tx.objectStore('scopoStore');
				const request = store.get(key);
				request.onsuccess = () => resolve(request.result !== undefined);
				request.onerror = () => reject(request.error);
			});
		}),

	clear: () =>
		dbPromise.then(db => {
			return new Promise((resolve, reject) => {
				const tx = db.transaction('scopoStore', 'readwrite');
				const store = tx.objectStore('scopoStore');
				const request = store.clear();
				request.onsuccess = () => resolve();
				request.onerror = () => reject(request.error);
			});
		}),

	count: () =>
		dbPromise.then(db => {
			return new Promise((resolve, reject) => {
				const tx = db.transaction('scopoStore', 'readonly');
				const store = tx.objectStore('scopoStore');
				const request = store.count();
				request.onsuccess = () => resolve(request.result);
				request.onerror = () => reject(request.error);
			});
		})
};

export default idbKeyval;
