/**
 * Authorization Roles
 *  Improved with scopo functions
 */
const authRoles = {
	admin: ['admin'],
	campaignAdmin: ['admin', 'campaignAdmin'],
	staff: ['admin', 'staff'],
	partner: ['admin', 'staff', 'partner'],
	employee: ['admin', 'staff', 'partner', 'employee'],
	consultant: ['admin', 'consultant'],
	user: ['admin', 'staff', 'partner', 'employee', 'user'],
	agency: ['admin', 'staff', 'partner', 'agency'],
	tester: ['tester'],
	researcher: ['admin', 'researcher'],
	strategy: ['admin', 'strategy', 'campaignAdmin'],
	onlyGuest: []
};

export default authRoles;
