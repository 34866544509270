import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { tokenHeaders, urlBackend } from 'app/main/data/LocationContext';

const pathMedia = `${urlBackend}/api/trends/action_range_news_fonts/`;

// const fontsMediaAdapter = createEntityAdapter({});

export const getSeankey = createAsyncThunk(
	'entidade/analise_comparativa/fonts_media/getMedia',
	async (params, { dispatch, getState }) => {
		dispatch(setMediaFontsLoading(true));
		console.log(params);

		const response = await axios.post(pathMedia, params, tokenHeaders());
		const data = await response.data;

		dispatch(setMediaFontsActionRAnge(params));

		return JSON.parse(data.data);
	}
);

// export const {
//   selectAll: selectFontsMedia,
//   removeAll: removeFontsMedia,
//   selectById: selectMediaById
// } = fontsMediaAdapter.getSelectors((state)=>state.entidade.analise_comparativa.fonts_media);

const initialState = {
	loading: false,
	searchText: '',
	selectedFontId: null,
	action_range: null,
	data: null
};

const fontsMediaLocationSlice = createSlice({
	name: 'entidade/analise_comparativa/fonts_media',
	initialState,
	reducers: {
		setMediaFontsLoading: (state, action) => {
			state.loading = action.payload;
		},
		setMediaFontsActionRAnge: (state, action) => {
			state.action_range = action.payload;
		},
		setSelectedFontId: (state, action) => {
			state.selectedFontId = action.payload;
		},
		resetMediaFonts: () => initialState
	},
	extraReducers: {
		[getSeankey.fulfilled]: (state, action) => {
			state.loading = false;
			state.data = action.payload;
		},
		[getSeankey.rejected]: (state, action) => initialState
	}
});

export const { setMediaFontsLoading, setMediaFontsActionRAnge, setSelectedFontId, resetMediaFonts } =
	fontsMediaLocationSlice.actions;

export default fontsMediaLocationSlice.reducer;
