import axios from 'axios';
import { GET_FILL_DATA, APPEND_FILL_DATA } from './actionTypes';
import { urlBackend } from '../../data/LocationContext';
import { setRemoteLoaded, setRemoteLoading } from '../../../store/fuse/environmentSlice';

const urlFillData = `${urlBackend}/api/company/getFillData/`;
const urlFillDataCandidate = `${urlBackend}/api/candidato/get_fill_data/`;

const getAccessToken = () => {
	return window.localStorage.getItem('jwt_access_token');
};

const tokenHeaders = () => {
	const token = getAccessToken();

	// Headers
	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	};

	if (token) {
		config.headers.Authorization = `Token ${token}`;
	}

	return config;
};

export function getFillData() {
	return dispatch => {
		dispatch(setRemoteLoading(true));
		axios
			.get(urlFillData, tokenHeaders())
			.then(response => {
				dispatch({
					type: GET_FILL_DATA,
					payload: response.data
				});
			})
			.then(() => dispatch(getFillDataCandidate()));
	};
}

export function getFillDataCandidate() {
	return dispatch => {
		axios
			.get(urlFillDataCandidate, tokenHeaders())
			.then(response =>
				dispatch({
					type: APPEND_FILL_DATA,
					payload: response.data
				})
			)
			.then(() => dispatch(setRemoteLoaded()));
	};
}
