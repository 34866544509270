import { combineReducers } from '@reduxjs/toolkit';
import dialog from './dialogSlice';
import message from './messageSlice';
import navbar from './navbarSlice';
import navigation from './navigationSlice';
import settings from './settingsSlice';
import environment from './environmentSlice';
import backendStatus from './backendStatusSlice';
import SocialNetwork from './socialNetworksSlice';

const fuseReducers = combineReducers({
	environment,
	navigation,
	settings,
	navbar,
	message,
	dialog,
	backendStatus,
	SocialNetwork
});

export default fuseReducers;
