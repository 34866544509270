import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  brain: null,
  siglaPartido: null
}

const analysisSlice = createSlice({
  name: 'entidade/analysis/changeAnalysis',
  initialState,
  reducers: {
    setBrain: (state, action) => {
      state.brain = action.payload
    },
    setSiglaPartido: (state, action) => {
      state.siglaPartido = action.payload;
    },
    resetAnalysis: (state, action) => initialState
  },
  extraReducers: {}
})

export const {setBrain,resetAnalysis,setSiglaPartido} = analysisSlice.actions;

export default analysisSlice.reducer;