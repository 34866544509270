import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// import io from 'socket.io-client';
import { batch } from 'react-redux';
import { token } from 'stylis';
import {
	BACKEND_SOCKET_ADDRESS,
	defaultTimeToDisplayAlerts,
	getAccessToken,
	getAccessTokenAsync,
	NOTIFICATION_SOCKET_ADDRESS,
	tokenHeaders,
	urlBackend,
	wsBackend
} from 'app/main/data/LocationContext';
import { getTrendingNews } from 'app/main/store/news/trendingNewsSlice';
import { addNotification } from 'app/fuse-layouts/shared-components/notificationPanel/store/dataSlice';
import FuseUtils from '@fuse/utils';
import { showMessage } from './messageSlice';
import { setTrendsData, setTrendsLoading } from '../../main/store/news/trendsSlice';
import { getEntityScenarios } from '../../main/store/IndicatorsSlices/scenariosSlice';
import {
	getEntitiesList,
	setEntitiesList,
	setSelectedEntityLoaded,
	setSelectedEntityLoading,
	setSelectedMonitorSocialId
} from '../../main/store/entidade/entityStatusSlice';
import { setDataRealTime } from '../../main/store/kpis/realTimeSnsSlice';
import {
	updateContact,
	selectContactById,
	setNewRecentContactOnlineId,
	increaseUserUnreadMessages,
	updateContactsList,
	atualizaContato
} from '../../fuse-layouts/shared-components/chatPanel/store/contactsSlice';
import notificationSound from '../../main/components/sounds/notification-sound.mp3';
import newMessageSound from '../../main/components/sounds/newMessage.mp3';
import knockSound from '../../main/components/sounds/knocknock.mp3';

import { setActiveChat } from '../../fuse-layouts/shared-components/chatPanel/store/chatSlice';
import { logoutUser, updateUserRole } from '../../auth/store/userSlice';
import { setCandidatoData } from '../../main/store/entidade/politico/candidatoSlice';
import { setEntityState } from '../../main/store/entidade/SelectedEntitySlice';
import { setChatList } from '../../main/store/entidade/chatListSlice';
import { getMonitorSocialId, setMonitoresSociais } from '../../main/store/IndicatorsSlices/monitorSocialSlice';
import { setBookmarks, setBookmarksLoadedStatus } from '../../main/scopo-apps/research/store/bookmarksSlice';
import { setRemoteLoaded, setRemoteLoading } from './environmentSlice';
import { setOperatorCredentials, setOperators, setSelectedOperatorId } from '../../main/store/entidade/operatorsSlice';
import { setCompetition } from '../../main/store/IndicatorsSlices/competitionSlice';
import {
	changeLoadingLocationStatus,
	setDataLocation,
	setRangeLocation
} from '../../main/store/IndicatorsSlices/locationIndicatorsSlice';
import { setEvolutionLoading } from '../../main/store/IndicatorsSlices/evolutionSlice';
import { setEntitiesClippingUsers } from '../../main/store/news/clippingSlice';
import { setNewsSourcesLoading, setNewsSourcesLoadingAll } from '../../main/store/news/newsSourcesSlice';
import idbKeyval from '../../main/store/scopoTempDb';
import NotificationModel from '../../fuse-layouts/shared-components/notificationPanel/model/NotificationModel';

const backendPath = `${urlBackend}/api/controle/backend/`;

const backendServicesList = [
	'backendStatus',
	'smsOnline',
	'esNewsServiceOnline',
	'BingNewsSearchOnline',
	'TwitterAPI',
	'MetaAPI'
];

// transfering websocks a slice middleware

// 6500ms + 1000ms. 7.5s
const timeToRestartWebsocket = defaultTimeToDisplayAlerts + 1000;

// para notificacoes gerais do backend
let timeoutNotificationId;
let notificationSocket = null;
export const socketMiddleware = store => next => action => {
	// const userData = store.getState().auth.user.data;

	// if (!action) {
	// 	return;
	// }

	const somNovaMsg = () => {
		const audio = new Audio('https://monitor.scopo.online/static/media/newMessage.mp3');
		audio.play();
	};
	const somAlguemEntrou = () => {
		const audio = new Audio('https://monitor.scopo.online/static/media/knocknock.mp3');
		audio.play();
	};

	const audioElement = new Audio(notificationSound);

	const timeToWaitNewAttemptConn = attempt => {
		switch (attempt) {
			case 0:
				return timeToRestartWebsocket;
			case 3:
				return timeToRestartWebsocket + timeToRestartWebsocket / 2;
			case 4:
				return timeToRestartWebsocket * 2;
			case attempt >= 5:
				return defaultTimeToDisplayAlerts * 3;
			default:
				return timeToRestartWebsocket;
		}
	};

	function handleNewScopoNotification(received_data) {
		// const received_data = JSON.parse(notification_str);
		console.log('NEW SCOPO NOTIFICATION', received_data);
		const { chatPanel } = store.getState();
		const userData = store.getState().auth.user.data;
		if (received_data.whoId !== userData.id) {
			const contact_id = received_data.whoId;
			if (chatPanel.contacts.ids.includes(contact_id)) {
				if (received_data.type === 'userMessageSent' && received_data.toId === userData.id) {
					if (!chatPanel.chat) {
						store.dispatch(increaseUserUnreadMessages(contact_id));
						audioElement.play().catch(error => console.log(error));
					} else if (received_data.chatId === chatPanel.chat.id) {
						console.log('Já estou em conversa com este usuário. Não faça nada');
					} else {
						store.dispatch(increaseUserUnreadMessages(contact_id));
						audioElement.play().catch(error => console.log(error));
					}
				}
			}
		} else {
			console.log('User signed in another location or notification from me');
		}
	}

	function handleUserStatusUpdate(updatedUserStatus) {
		const { backendStatus } = store.getState().fuse;
		const handleAuthReceivedNotification = (newAuth, src, nrItems) => {
			let msgAuth = 'autorizações recebidas no sistema';

			if (newAuth) {
				msgAuth = `Você recebeu autorização para ${src} no sistema. Disponíveis: ${
					nrItems === 1010 ? 'Ilimitadas' : nrItems
				}`;
			} else {
				msgAuth =
					nrItems === 1010
						? (msgAuth = `Você recebeu autorização para gestão ilimitada de ${src}s no sistema`)
						: `Você recebeu ${nrItems} ${src}${nrItems > 1 ? 's' : ''} ${
								nrItems > 1 ? 'adicionais' : 'adicional'
						  } no sistema.`;
			}

			// store.dispatch(
			// 	showMessage({
			// 		message: msgAuth,
			// 		autoHideDuration: 7000,
			// 		anchorOrigin: { vertical: 'top', horizontal: 'left' },
			// 		variant: 'success'
			// 	})
			// );

			store.dispatch(
				addNotification({
					id: FuseUtils.generateGUID(),
					message: msgAuth,
					options: { variant: 'success' }
				})
			);
		};
		if (updatedUserStatus.canAddCandidates) {
			if (!backendStatus.userCanAddCandidates) {
				handleAuthReceivedNotification(true, 'Candidato', updatedUserStatus.nr_candidates_left);
			} else if (
				backendStatus.nr_candidates_left &&
				backendStatus.nr_candidates_left < updatedUserStatus.nr_candidates_left
			) {
				const receivedDiff = updatedUserStatus.nr_candidates_left - backendStatus.nr_candidates_left;
				handleAuthReceivedNotification(false, 'Candidato', receivedDiff);
			}
		}

		if (updatedUserStatus.userCanResearch) {
			if (!backendStatus.userCanResearch) {
				handleAuthReceivedNotification(true, 'Pesquisa', updatedUserStatus.nr_researches_left);
			} else if (backendStatus.nr_researches_left < updatedUserStatus.nr_researches_left) {
				const diffResearch = updatedUserStatus.nr_researches_left - backendStatus.nr_researches_left;
				handleAuthReceivedNotification(false, 'Pesquisa', diffResearch);
			}
		}

		if (updatedUserStatus.userCanSendMessages) {
			if (!backendStatus.userCanSendMessages) {
				handleAuthReceivedNotification(true, 'Mensagem', updatedUserStatus.nr_messages_left);
			} else if (backendStatus.nr_messages_left < updatedUserStatus.nr_messages_left) {
				const diffMsgs = updatedUserStatus.nr_messages_left - backendStatus.nr_messages_left;
				handleAuthReceivedNotification(false, 'Mensagem', diffMsgs);
			}
		}

		batch(() => {
			store.dispatch(updateNrMessagesLeft(updatedUserStatus.nr_messages_left));
			store.dispatch(updateNrResearchesLeft(updatedUserStatus.nr_researches_left));
			store.dispatch(updateNrCandidatesLeft(updatedUserStatus.nr_candidates_left));
			store.dispatch(toggleUserCanAddCandidates(updatedUserStatus.canAddCandidates));
			store.dispatch(toggleUserCanResearch(updatedUserStatus.userCanResearch));
			store.dispatch(toggleUserCanSendMessages(updatedUserStatus.userCanSendMessages));
			store.dispatch(updateUserRole(updatedUserStatus.roles));
		});
	}

	// Backend websock
	const startBackendSocket = () => {
		let retryTimeoutID = null;
		const attemptsLimit = 5;
		const socket = new WebSocket(BACKEND_SOCKET_ADDRESS);

		socket.onopen = () => {
			const { chatPanel } = store.getState();
			console.log('Connected to backend status');
			batch(() => {
				store.dispatch(setBackendStatus('Connected'));
				store.dispatch(setSocketRef(socket));
				store.dispatch(setSocketRetryAttempts(0));
			});
		};

		socket.onerror = errorEvent => {
			const { socketRetryAttempts, pushMode } = store.getState().fuse.backendStatus;
			if (pushMode) {
				console.log('Erro na conexão do websocket do backend', errorEvent);
				console.log(`vou tentar conectar de novo em ${timeToWaitNewAttemptConn(socketRetryAttempts)} ms`);

				// Store the timeout ID
				retryTimeoutID = setTimeout(() => {
					startBackendSocket();
					store.dispatch(setSocketRetryAttempts(socketRetryAttempts + 1));
				}, timeToWaitNewAttemptConn(socketRetryAttempts));
			}
			if (socketRetryAttempts > attemptsLimit) {
				// Clear the timeout if it exists
				if (retryTimeoutID !== null) {
					clearTimeout(retryTimeoutID);
					retryTimeoutID = null;
				}

				store.dispatch(setPushMode(false));
			}
		};

		socket.onclose = () => {
			const { socketRetryAttempts, pushMode } = store.getState().fuse.backendStatus;
			console.log('Disconnected from backend status');
			store.dispatch(setBackendStatus('Disconnected'));
			store.dispatch(setSocketRef(null));
			store.dispatch(
				showMessage({
					message:
						'As notificações de status no Backend foram interrompidas. Tentando reestabelecer em alguns segundos',
					autoHideDuration: defaultTimeToDisplayAlerts,
					anchorOrigin: {
						vertical: 'bottom', // top bottom
						horizontal: 'center' // left center right
					},
					variant: 'warning'
				})
			);
			// start new socket after 7.5 seconds
			console.log(socketRetryAttempts);
			store.dispatch(setSocketRetryAttempts(socketRetryAttempts + 1));
			if (socketRetryAttempts > attemptsLimit) {
				// Clear the timeout if it exists
				if (retryTimeoutID !== null) {
					clearTimeout(retryTimeoutID);
					retryTimeoutID = null;
				}
				store.dispatch(setPushMode(false));
			}
			if (pushMode) {
				retryTimeoutID = setTimeout(() => {
					startBackendSocket();
					store.dispatch(setSocketRetryAttempts(socketRetryAttempts + 1));
				}, timeToWaitNewAttemptConn(socketRetryAttempts));
			}
		};

		socket.onmessage = e => {
			const { chatPanel } = store.getState();
			const { soundNotification } = store.getState().fuse.backendStatus;
			const data = JSON.parse(e.data);
			const smMonitorOnline = store.getState().fuse.backendStatus['sm-monitor'];
			const actualState = store.getState().state;
			const usuarioAtual = store.getState().auth.user.data;

			console.log(e);
			console.log(data);

			if ('status' in data && data.status.type === 'status') {
				const { status } = data;

				if (status.show_message) {
					store.dispatch(
						showMessage({
							message: status.message,
							autoHideDuration: defaultTimeToDisplayAlerts,
							anchorOrigin: {
								vertical: 'top', // top bottom
								horizontal: 'right' // left center right
							},
							variant: status.variante // success error info warning null
						})
					);
				}

				if (status.service in backendServicesList) {
					store.dispatch(toggleBackendService({ service: status.service, status: status.status }));
				}

				console.log('STATUS');
				console.log(status);
				console.log('STATUS');

				const { contacts } = store.getState().chatPanel;
				const { entities, ids } = contacts;
				switch (status.service) {
					case 'sm-monitor':
						console.log('LAST STATUS MONITOR', smMonitorOnline);
						store.dispatch(toggleBackendService({ service: 'sm-monitor', status: status.status }));
						break;
					case 'trends':
						store.dispatch(setTrendsLoading(true));
						setTimeout(() => {
							// here the status is a new json with trends, not true or false
							store.dispatch(setTrendsData(status.status));
						}, 1500);
						break;
					case 'scenarios':
						if (
							store
								.getState()
								.entidade.indicators.scenarios.requested.some(item => item.id === status.status)
						) {
							console.log('Buscando novos cenários');
							store.dispatch(
								getEntityScenarios({
									mon_id: store.getState().entidade.indicators.monitor_social.selectedMonitorId
								})
							);
						}
						break;
					case 'esNewsServiceOnline':
						if (status.status) {
							store.dispatch(getTrendingNews());
						}
						break;
					case 'new_candidate_included':
						if (status.status) {
							store.dispatch(getEntitiesList());
						} else {
							store.dispatch(showMessage({ message: 'Erro ao adicionar candidato' }));
						}
						// store.dispatch({
						// 	type: 'entidade/entityStatus/getEntityStatusData',
						// 	payload: true
						// });
						break;
					case 'LastHourSns':
						console.log(status.status);
						store.dispatch(setDataRealTime(JSON.parse(status.status)));
						break;
					case 'UserIsLoggedIn':
						// eslint-disable-next-line no-case-declarations
						const friendStatus = JSON.parse(status.status);
						console.log('STATUS UserIsLoggedIn');
						console.log(friendStatus);

						if (friendStatus.id !== usuarioAtual.id && ids.includes(friendStatus.id)) {
							console.log(status.status);
							const abiguinho = entities[friendStatus.id];
							console.log('LOGGGOUUU Abigo', abiguinho);
							store.dispatch(
								updateContact({
									...abiguinho,
									is_online: true,
									connection_status: friendStatus.connection_status,
									last_seen: new Date(),
									mood: friendStatus.mood
								})
							);
							store.dispatch(setNewRecentContactOnlineId(status.status.id));
							if (soundNotification) {
								somAlguemEntrou();
							}
						}
						break;
					case 'UserStatusUpdate':
						if (status.status.id !== usuarioAtual.id && ids.includes(status.status.id)) {
							const abiguinho = entities[status.status.id];
							store.dispatch(
								updateContact({
									...abiguinho,
									is_online: status.status.is_online,
									connection_status: status.status.is_online ? status.status.status : 'offline',
									last_seen: new Date(),
									mood: status.status.mood
								})
							);
							store.dispatch(setNewRecentContactOnlineId(status.status.id));
							somAlguemEntrou();
						}
						break;
					case 'disconnect_users':
						console.log('DESLOGUE OS MALAS', store.status);
						break;
					default:
						console.log(`status service received: ${status}`);
						console.log(status.service, status.status, status);
				}
			}
		};
	};

	// authenticating in django with knox
	const startNotificationsSocket = async () => {
		const attemptsLimit = 15;
		const userData = store.getState().auth.user.data;
		const userToken = await getAccessTokenAsync();
		const wsNotificationURI = `${NOTIFICATION_SOCKET_ADDRESS}${userData.displayName}/?token=${userToken}`;
		console.log(`Notificações ${userData.displayName} ativas`);
		notificationSocket = new WebSocket(wsNotificationURI);

		let checkBrothersOnlineInterval;

		notificationSocket.onopen = () => {
			const usuarioAtual = store.getState().auth.user.data;
			store.dispatch(
				showMessage({
					message: `Notificações de ${usuarioAtual.displayName} Ativas`,
					autoHideDuration: defaultTimeToDisplayAlerts,
					anchorOrigin: { vertical: 'top', horizontal: 'center' },
					variant: 'success'
				})
			);

			batch(() => {
				store.dispatch(setScopoNotificationsStatus('Connected'));
				store.dispatch(setSocketNotifications(notificationSocket));
				// Send a message every 10 minutes

				store.dispatch(setRemoteLoading());
				store.dispatch(setSelectedEntityLoading());
				store.dispatch(setBookmarksLoadedStatus(false));
				store.dispatch(setSocketRetryAttempts(0));
			});

			checkBrothersOnlineInterval = setInterval(() => {
				const brothers = store.getState().chatPanel.contacts.entities;
				const brothersOnline = Object.keys(brothers)
					.filter(key => brothers[key].is_online)
					.map(id => Number(id));
				if (notificationSocket.readyState === WebSocket.OPEN) {
					notificationSocket.send(
						JSON.stringify({
							type: 'check_if_users_are_online',
							content: brothersOnline
						})
					);
				}
			}, 5 * 60 * 1000);
		};

		notificationSocket.onerror = errorEvent => {
			const { socketRetryAttempts, pushMode } = store.getState().fuse.backendStatus;
			const usuarioAtual = store.getState().auth.user.data;
			console.log('Erro ao conectar em Scopo Notifications', errorEvent);

			store.dispatch(setSocketRetryAttempts(socketRetryAttempts + 1));

			if (socketRetryAttempts > attemptsLimit) {
				if (timeoutNotificationId !== null) {
					clearTimeout(timeoutNotificationId);
					timeoutNotificationId = null;
				}
				store.dispatch(setPushMode(false));
			} else if (pushMode) {
				timeoutNotificationId = setTimeout(() => {
					startNotificationsSocket();
					store.dispatch(setSocketRetryAttempts(socketRetryAttempts + 1));
				}, timeToWaitNewAttemptConn(socketRetryAttempts));
			} else {
				clearTimeout(timeoutNotificationId);
				clearInterval(checkBrothersOnlineInterval);
			}
			store.dispatch(setRemoteLoaded());
		};

		notificationSocket.onclose = () => {
			const { socketRetryAttempts, pushMode } = store.getState().fuse.backendStatus;
			const usuarioAtual = store.getState().auth.user.data;
			console.log('Disconnected from Scopo Notifications');
			store.dispatch(setScopoNotificationsStatus('Disconnected'));
			store.dispatch(setSocketNotifications(null));
			clearInterval(checkBrothersOnlineInterval);

			store.dispatch(setSocketRetryAttempts(socketRetryAttempts + 1));

			if (socketRetryAttempts > attemptsLimit) {
				if (timeoutNotificationId !== null) {
					clearTimeout(timeoutNotificationId);
					timeoutNotificationId = null;
				}
				store.dispatch(setPushMode(false));
			}

			if (pushMode && usuarioAtual.id) {
				timeoutNotificationId = setTimeout(() => {
					startNotificationsSocket();
					store.dispatch(setSocketRetryAttempts(socketRetryAttempts + 1));
				}, timeToWaitNewAttemptConn(socketRetryAttempts));
			} else if (timeoutNotificationId !== null) {
				clearTimeout(timeoutNotificationId);
				timeoutNotificationId = null;
			}
		};

		notificationSocket.onmessage = async e => {
			const { soundNotification } = store.getState().fuse.backendStatus;
			const usuarioAtual = store.getState().auth.user.data;
			const parsedData = JSON.parse(e.data);
			const { entities = {}, ids = [], selectedContactId = null } = store?.getState()?.chatPanel?.contacts ?? {};
			console.log('MESSAGE NOTIFICATIONS', parsedData);
			store.dispatch(setNewNotification(true));

			if (parsedData.show_message) {
				store.dispatch(
					showMessage({
						message: parsedData.status.message,
						autoHideDuration: defaultTimeToDisplayAlerts,
						anchorOrigin: {
							vertical: 'top', // top bottom
							horizontal: 'right' // left center right
						},
						variant: parsedData.status.variante // success error info warning nul
					})
				);
			}

			if ('contacts_status' in parsedData) {
				console.log('contacts_status');
				const { contacts_status } = parsedData;
				if (contacts_status.contacts_list) {
					// fornece lista de contatos do usuario

					const listaEntidades = JSON.parse(contacts_status.entities_list);

					batch(() => {
						store.dispatch(setRemoteLoaded());
						store.dispatch(setSelectedEntityLoaded());
						store.dispatch(setBookmarksLoadedStatus(true));
						store.dispatch(updateContactsList(JSON.parse(contacts_status.contacts_list)));
						store.dispatch(setEntitiesList(listaEntidades));
						store.dispatch(setBookmarks(JSON.parse(contacts_status.user_bookmarks)));
					});

					const usrDt = {
						id: `usuario_id_${userData.id}`,
						sid: userData.id,
						stp: 'usuario_id',
						origem: 'usuario',
						tp: 'usuario',
						ctx: 'usuario',
						nm: userData.firstName ? `${userData.firstName} ${userData.lastName}` : userData.firstName,
						img: userData.photoURL
					};

					const entLst = listaEntidades
						.filter(j => j.show_entity_in_dropdown === true)
						.map(i => ({
							id: `entity_id_${i.id}`,
							sid: i.id,
							stp: 'entity_id',
							origem: i.origem_entidade,
							tp: i.tp_entidade,
							ctx: i.ctx_entidade,
							nm: i.short_name,
							img: i.entity_image
						}));

					entLst.unshift(usrDt);
					store.dispatch(setEntitiesClippingUsers(entLst.flat()));
				} else if (contacts_status.chat_status) {
					// fornece mensagens armazenadas do chat
					store.dispatch(setActiveChat(JSON.parse(contacts_status.chat_status)));
					console.log('UPDATE THE CHAT STATUS');
				} else if (contacts_status.users_to_disconnect) {
					console.log('users_to_disconnect', contacts_status.users_to_disconnect);
					// eslint-disable-next-line array-callback-return
					contacts_status.users_to_disconnect.map(usrid => {
						console.log('usrid', usrid);
						const abiguinho = entities[usrid];
						store.dispatch(
							updateContact({
								...abiguinho,
								is_online: false,
								connection_status: 'offline'
							})
						);
					});
				} else if (
					contacts_status.status.type === 'update' &&
					contacts_status.status.service === 'updateUnreadMsgs'
				) {
					console.log('ATUALIZE NAO LIDAS!');
					console.log(contacts_status.status.from_id);
					if (!selectedContactId || selectedContactId !== contacts_status.status.from_id) {
						const abiguinho = entities[contacts_status.status.from_id];
						// const unread = entities[contacts_status.status.to_id];
						store.dispatch(
							updateContact({
								...abiguinho,
								unread_messages: abiguinho.unread_messages + 1
							})
						);
						if (soundNotification) {
							somNovaMsg();
						}
					}
				} else {
					console.log('Outra notificação relativa aos contatos');
					console.log(contacts_status);
				}
			} else if ('send_user_status' in parsedData) {
				console.log('send_user_status', parsedData);
				console.log('send_user_status', parsedData);
			} else if ('midia_status' in parsedData) {
				const dataStore = parsedData.midia_status;
				const parsedDataStore = JSON.parse(dataStore);
				parsedDataStore.id = 'fontesMonitoradas';
				await idbKeyval
					.set(parsedDataStore)
					.then(() => {
						store.dispatch(setNewsSourcesLoadingAll(false));
						store.dispatch(
							addNotification(
								NotificationModel({
									message: 'Fontes de monitoramento atualizadas',
									options: { variant: 'success' }
								})
							)
						);
					})
					.catch(err => {
						console.log(err);
						store.dispatch(setNewsSourcesLoadingAll(false));
					});
			} else if (parsedData.status.type === 'update') {
				if (parsedData.status.service === 'UserStatusUpdate') {
					handleUserStatusUpdate(parsedData.status.status);
				} else if (parsedData.status.service === 'updateUnreadMsgs') {
					if (!selectedContactId || selectedContactId !== parsedData.status.from_id) {
						const abiguinho = entities[parsedData.status.from_id];
						store.dispatch(
							updateContact({
								...abiguinho,
								unread_messages: abiguinho.abiguinho + 1
							})
						);
					}
					if (soundNotification) {
						somNovaMsg();
					}
				} else if (parsedData.status.service === 'selectedInstanceUpdate') {
					// Atualiando para buscar por sock
					console.log('selectedInstanceUpdate');
					console.log('selectedInstanceUpdate');
					console.log('selectedInstanceUpdate');
					console.log('selectedInstanceUpdate');
					const conteudo = JSON.parse(parsedData.status.content);
					const chatlist = JSON.parse(parsedData.status.chatlist);
					const monitoresSociais = JSON.parse(parsedData.status.monitoresSociais);
					const { monitor_principal_id } = parsedData.status;
					const { monitor_range } = parsedData.status;
					const location_info = JSON.parse(parsedData.status.location_info);
					const operadores = JSON.parse(parsedData.status.operadores);
					const { operador_id } = parsedData.status;
					const operador_roles = JSON.parse(parsedData.status.operador_roles);
					const competition = JSON.parse(parsedData.status.competition);
					if (conteudo.origem === 'candidato') {
						store.dispatch(setCandidatoData(conteudo.entidade.concorrente));
						delete conteudo.entidade.concorrente;
					}
					// dispatch(changeLoadingLocationStatus(true));
					// 				dispatch(setEvolutionLoading(true));
					// 				dispatch(setRemoteLoading());

					console.log('CHEGAAAAA');
					console.log(monitor_range);
					console.log(monitor_principal_id);
					console.log('CHEGAAAAA');
					console.log(competition);
					console.log('CHEGAAAAA');

					// batch(() => {
					// 	store.dispatch(setRemoteLoaded());
					// 	store.dispatch(setEvolutionLoading(false));
					// 	store.dispatch(changeLoadingLocationStatus(false));
					// 	store.dispatch(setEntityState(conteudo));
					// 	store.dispatch(setChatList(chatlist));
					// 	store.dispatch(setMonitoresSociais(monitoresSociais));
					// 	store.dispatch(setSelectedMonitorSocialId(monitor_principal_id));
					// 	store.dispatch(setOperators(operadores));
					// 	store.dispatch(setSelectedOperatorId(operador_id));
					// 	store.dispatch(setOperatorCredentials(operador_roles));
					// 	store.dispatch(setCompetition(competition));
					// 	store.dispatch(setRangeLocation(monitor_range));
					// 	store.dispatch(setSelectedMonitorSocialId(monitor_principal_id));
					// 	store.dispatch(getMonitorSocialId(monitor_principal_id));
					// 	store.dispatch(setDataLocation(location_info));
					// });
				}
			} else {
				console.log('outro tipo de solicitacao e status');
				console.log(parsedData);
				handleNewScopoNotification(parsedData);
			}

			setTimeout(() => store.dispatch(setNewNotification(false)), 3000);
		};
	};

	if (action.type === 'backendStatus/startWebSocket') {
		startBackendSocket();
	} else if (action.type === 'backendStatus/stopWebSocket') {
		const { socketRef } = store.getState().fuse.backendStatus;
		if (socketRef) {
			socketRef.close();
			store.dispatch(setSocketRef(null));
		}
	} else if (action.type === 'backendStatus/startNotificationsSocket') {
		startNotificationsSocket();
	} else if (action.type === 'backendStatus/stopNotificationsSocket') {
		const { socketNotifications } = store.getState().fuse.backendStatus;
		if (socketNotifications) {
			socketNotifications.close();
			store.dispatch(setScopoNotificationsStatus('Disconnected'));
			store.dispatch(setSocketNotifications(null));
		}
	}

	next(action);
};

export const sendUserWSMessage = message => {
	if (notificationSocket && notificationSocket.readyState === WebSocket.OPEN) {
		console.log('ENVIANDO MSG PELO SOCKET DO USUARIO');
		notificationSocket.send(JSON.stringify(message));
	} else {
		console.log('WebSocket is not open. Ready state:', notificationSocket.readyState);
	}
};

export const getBackendStatus = createAsyncThunk(
	'backendStatus/getBackendStatus',
	async (_, { dispatch, getState }) => {
		const response = await axios.get(backendPath, tokenHeaders());
		const { data } = await response;
		const { backendStatus } = getState().fuse;
		const mergedData = { ...backendStatus, ...data };
		if (data.esNewsServiceOnline === true) {
			// const { backendStatus } = getState().fuse;
			const { trendingNews } = getState().news;
			// just get in status if previous status was false with no news data.
			if (!trendingNews.data && !trendingNews.loading && backendStatus.esNewsServiceOnline === false) {
				dispatch(
					addNotification({
						id: FuseUtils.generateGUID(),
						message: 'Carregando principais notícias',
						options: { variant: 'info' }
					})
				);
				dispatch(getTrendingNews());
			}
		}
		console.log('backendStatus', data);
		console.log('backendStatus', mergedData);

		return mergedData;
	}
);

// dados em backend em backend/controle/api/viewsets/backend
const initialState = {
	backendStatus: 'Disconnected',
	socketRef: null,
	scopoNotificationsStatus: 'Disconnected',
	socketNotifications: null,
	newNotification: false,
	deepScopoOnline: false,
	smsOnline: false,
	esNewsServiceOnline: false,
	BingNewsSearchOnline: true,
	TwitterAPI: true,
	MetaAPI: true,
	userCanAddCandidates: false,
	userCanSendMessages: false,
	userCanResearch: false,
	nr_candidates_left: 0,
	nr_messages_left: 0,
	nr_researches_left: 0,
	entityInManagementMode: false, // used to avoid redux resetting when troggling candidate search
	entityInEditingMode: false, // used to avoid redux resetting when troggling candidate search
	entityInOpponentsPage: false,
	previousManagementInFocusMonitorId: null, // used to reload previous Monitor Social after leaving Entidades management
	previousManagementInFocusEntityId: null,
	searchEntityMode: false,
	'sm-monitor': true,
	pushMode: true, // to determine if websockets or restapi will be used to update data
	socketRetryAttempts: 0, // to avoid infinite calls to the backend
	soundNotification: true,
	version: null
};

const backendStatusSlice = createSlice({
	name: 'backendStatus',
	initialState,
	reducers: {
		toggleEsNewsService: (state, action) => {
			state.esNewsServiceOnline = action.payload;
		},
		toggleBackendService: (state, action) => {
			const { service, status } = action.payload;
			state[service] = status;
		},
		setBackendStatus: (state, action) => {
			state.backendStatus = action.payload;
		},
		setSocketRef: (state, action) => {
			state.socketRef = action.payload;
		},
		setScopoNotificationsStatus: (state, action) => {
			state.scopoNotificationsStatus = action.payload;
		},
		setSocketNotifications: (state, action) => {
			state.socketNotifications = action.payload;
		},
		updateNrCandidatesLeft: (state, action) => {
			state.nr_candidates_left = action.payload;
		},
		updateNrResearchesLeft: (state, action) => {
			state.nr_researches_left = action.payload;
		},
		updateNrMessagesLeft: (state, action) => {
			state.nr_messages_left = action.payload;
		},
		toggleUserCanAddCandidates: (state, action) => {
			state.userCanAddCandidates = action.payload;
		},
		toggleUserCanResearch: (state, action) => {
			state.userCanResearch = action.payload;
		},
		toggleUserCanSendMessages: (state, action) => {
			state.userCanSendMessages = action.payload;
		},
		setEntityInManagementMode: (state, action) => {
			state.entityInManagementMode = action.payload;
		},
		setPreviousManagementInFocusMonitorId: (state, action) => {
			state.previousManagementInFocusMonitorId = action.payload;
		},
		setPreviousManagementInFocusEntityId: (state, action) => {
			state.previousManagementInFocusEntityId = action.payload;
		},
		setEntityInEditingMode: (state, action) => {
			state.entityInEditingMode = action.payload;
		},
		setSearchEntityMode: (state, action) => {
			state.searchEntityMode = action.payload;
		},
		setEntityInOpponentsPage: (state, action) => {
			state.entityInOponentsPage = action.payload;
		},
		setNewNotification: (state, action) => {
			state.newNotification = action.payload;
		},
		setPushMode: (state, action) => {
			state.pushMode = action.payload;
			state.socketRetryAttempts = 0;
		},
		setSocketRetryAttempts: (state, action) => {
			state.socketRetryAttempts = action.payload;
		},
		setSoundNotification: (state, action) => {
			state.soundNotification = action.payload;
		},
		setMonitorVersion: (state, action) => {
			state.version = action.payload;
		},
		resetBackendStatus: state => initialState
	},
	extraReducers: {
		[getBackendStatus.fulfilled]: (state, action) => action.payload,
		[getBackendStatus.rejected]: state => initialState
	}
});

export const {
	toggleEsNewsService,
	toggleBackendService,
	setBackendStatus,
	setSocketRef,
	setScopoNotificationsStatus,
	setSocketNotifications,
	resetBackendStatus,
	updateNrCandidatesLeft,
	toggleUserCanAddCandidates,
	updateNrResearchesLeft,
	updateNrMessagesLeft,
	toggleUserCanResearch,
	toggleUserCanSendMessages,
	setEntityInManagementMode,
	setPreviousManagementInFocusMonitorId,
	setPreviousManagementInFocusEntityId,
	setSearchEntityMode,
	setEntityInEditingMode,
	setEntityInOpponentsPage,
	setNewNotification,
	setPushMode,
	setSocketRetryAttempts,
	setMonitorVersion,
	setSoundNotification
} = backendStatusSlice.actions;

export default backendStatusSlice.reducer;
