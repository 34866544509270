import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core/FusePageCarded';
import FuseAnimate from '@fuse/core/FuseAnimate';
import TermsRightSideIndex from '../TermsRightSideIndex';
// import PrivacyContent from '../privacy/PrivacyContent';

// import { useTranslation } from 'react-i18next';

const Root = styled(FusePageCarded)({
	'& .FusePageCarded-header': {},
	'& .FusePageCarded-toolbar': {},
	'& .FusePageCarded-content': {},
	'& .FusePageCarded-sidebarHeader': {},
	'& .FusePageCarded-sidebarContent': {}
});

// const TempDoc = () => {
// 	return (
// 		<p>docs</p>
// 	)
// }

function TermsPage() {
	const pageLayout = useRef(null);
	const routeParams = useParams();
	const [SelectedTerm, setSelectedTerm] = useState(null);
	const [title, setTitle] = useState('Temos SM-Scopo');
	const [navigation, setNavigation] = useState(null);
	const [tabPrivacy, setTabPrivacy] = useState(0);

	// const SelectedTerm = lazy(() => import('./termsContentFull'));
	useEffect(() => {
		const { tp } = routeParams;

		switch (tp) {
			case 'access':
				setSelectedTerm(lazy(() => import('./termsContentFull')));
				setTitle('Termos de Acesso');
				setNavigation('termos');
				break;
			case 'privacy':
				setSelectedTerm(lazy(() => import('../privacy/TabbedPrivacyContent')));
				setTitle('Privacidade');
				setNavigation('privacidade');
				setTabPrivacy(0);
				break;
			case 'data-management':
				setSelectedTerm(lazy(() => import('../privacy/TabbedPrivacyContent')));
				setTitle('Privacidade');
				setNavigation('privacidade');
				setTabPrivacy(1);
				break;
			case 'opt-out':
				setSelectedTerm(lazy(() => import('../privacy/TabbedPrivacyContent')));
				setTitle('Privacidade');
				setNavigation('privacidade');
				setTabPrivacy(2);
				break;

			default:
				setSelectedTerm(lazy(() => import('./termsContentFull')));
		}

	}, [routeParams]);

	return (
		<Root
			header={
				<div className="flex flex-col flex-1">
					<div className="flex items-center py-24">
						<Hidden lgUp>
							<IconButton
								onClick={ev => pageLayout.current.toggleRightSidebar()}
								aria-label="open right sidebar"
								size="large"
							>
								<Icon>menu</Icon>
							</IconButton>
						</Hidden>
						<div className="flex-1">
							<FuseAnimate animation="transition.expandIn">
								<img
									className="w-200 ml-32 mt-8 mb-32"
									src="assets/images/logos/ScopoOrangeHeader.png"
									alt="logo"
								/>
							</FuseAnimate>
						</div>
					</div>
				</div>
			}
			contentToolbar={
				<div className="px-24 w-full text-center">
					<Typography variant="h5" color="primary">
						{title}
					</Typography>
				</div>
			}
			content={<div className="p-24">{SelectedTerm ? <SelectedTerm ptab={tabPrivacy} /> : <FuseLoading />}</div>}
			rightSidebarHeader={
				<div className="flex items-center justify-center h-full">
					<img
						className="w-80"
						src="https://ecom-scopo.sfo2.digitaloceanspaces.com/logos/Scopo_JustIcon.png"
						alt="sm_scopo"
					/>
				</div>
			}
			rightSidebarContent={
				<div className="p-24">
					<TermsRightSideIndex navigation={navigation} />
				</div>
			}
			innerScroll
			ref={pageLayout}
		/>
	);
}

export default TermsPage;
