import React, { useEffect, useRef, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import { darken } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import { FormControlLabel } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import NET from 'vanta/dist/vanta.net.min';
import Button from '@mui/material/Button';
import { motion } from 'framer-motion';
import TextField from '@mui/material/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { username } from 'auth0-lock/lib/field';
import Stack from '@mui/material/Stack';
import { submitLogin } from 'app/auth/store/loginSlice';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { checkIsBackendIsOnline, getDuiId, urlBackend } from '../../data/LocationContext';
import { setAuthorized, setBrowserCompatible } from '../../components/pushNotifications/store/firebasePushSlice';
import ScopoFirebasePush from '../../components/pushNotifications/ScopoFirebasePush';
import _ from '../../../../@lodash';
import { setLockSystem } from '../../../store/fuse/environmentSlice';
import { setMonitorVersion } from '../../../store/fuse/backendStatusSlice';
import idbKeyval from '../../store/scopoTempDb';
// import { showMessage } from "../../../store/fuse/messageSlice";
// import * as THREE from 'three';
// import BIRDS from 'vanta/dist/vanta.birds.min';
// import { useForm } from '@fuse/hooks';

const schema = yup.object().shape({
	username: yup.string().min(3, 'Mínimo de 3 caracteres').max(15, 'Coloque apenas o nome de usuário...'),
	password: yup.string().required('Digite sua senha').min(6, 'A senha tem no mínimo 6 caracteres'),
	remember: yup.boolean()
});

const defaultValues = {
	username: '',
	password: '',
	remember: false
};

const useStyles = makeStyles(theme => ({
	root: {
		background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
			theme.palette.primary.dark,
			0.5
		)} 100%)`,
		color: theme.palette.primary.contrastText,
		position: 'relative'
	},
	version: {
		position: 'absolute',
		top: 5,
		left: 5
	}
}));

// 2. Firebase notification handling
const useFirebaseNotifications = dispatch => {
	const checkNotificationPromise = () => {
		try {
			Notification.requestPermission().then();
		} catch (e) {
			return false;
		}
		return true;
	};
	const handlePermission = permission => {
		if (Notification.permission === 'denied' || Notification.permission === 'default') {
			dispatch(setAuthorized(false));
		} else {
			dispatch(setAuthorized(true));
		}
	};
	if (!('Notification' in window)) {
		console.log('This browser does not support notifications.');
		dispatch(setBrowserCompatible(false));
		return false;
	}
	console.log('This browser SUPPORTS notifications.');
	dispatch(setBrowserCompatible(true));
	if (checkNotificationPromise()) {
		Notification.requestPermission().then(permission => {
			handlePermission(permission);
		});
	} else {
		Notification.requestPermission().then(permission => handlePermission(permission));
	}
	return true;
};

// 3. Login submit handling
const handleLoginSubmit = (dispatch, data, ipInfo) => {
	if (data.remember) {
		localStorage.setItem('svScpCrd', true);
		localStorage.setItem('usrScp', data.username);
		localStorage.setItem('pwdScp', data.password);
	} else {
		localStorage.removeItem('svScpCrd');
		localStorage.removeItem('usrScp');
		localStorage.removeItem('pwdScp');
	}
	let submitdata = data;
	submitdata.ipInfo = ipInfo;

	dispatch(submitLogin(submitdata));
};

// 4. Backend online checking
const msToRecheck = 8000;
const handleBackendOnlineCheck = navigate => {
	// Return the interval ID so it can be cleared
	return setInterval(() => {
		checkIsBackendIsOnline().then(isOnline => {
			if (!isOnline) {
				navigate('/maintenance');
			}
		});
	}, msToRecheck);
};

const fetchUserIP = async cancelToken => {
	try {
		const response = await axios.get('https://geolocation-db.com/json/', {
			cancelToken
		});

		return response.data;
	} catch (error) {
		if (axios.isCancel(error)) {
			console.log('Request canceled', error.message);
		} else {
			console.error(error);
		}
		return null;
	}
};

const Login = () => {
	// hooks setup
	const { control, formState, handleSubmit, setValue, getValues, reset } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});
	const { isValid, dirtyFields, errors } = formState;
	const dispatch = useDispatch();
	const login = useSelector(({ auth }) => auth.login);
	const [ipInfo, setIpInfo] = useState(null);
	const [isNotificationEnabled, setIsNotificationEnabled] = useState(false);
	const formRef = useRef(null);
	const [vantaEffect, setVantaEffect] = useState(0);
	const myRef = useRef(null);
	const actualDU = getDuiId();
	const navigate = useNavigate();
	const [version, setVersion] = useState(null);

	// styles
	const classes = useStyles();

	// checking version and reloading user browser - caches when changes
	useEffect(() => {
		fetch(`${urlBackend}/api/controle/get_version/`)
			.then(response => response.json())
			.then(smscopo_version => {
				// console.log(smscopo_version);
				const storedVersion = window.localStorage.getItem('page_version');
				dispatch(setMonitorVersion(smscopo_version));
				if (!storedVersion || storedVersion !== smscopo_version) {
					// If no version is stored, or if a new version is detected

					window.localStorage.setItem('page_version', smscopo_version);
					// clearing JWT Token
					window.localStorage.removeItem('jwt_access_token');
					// Refresh the page
					idbKeyval.clear().then(() => window.location.reload());
					// window.location.reload();
				} else {
					setVersion(smscopo_version);
				}
			});
	}, []);

	// useEffect that checks the backend online status
	useEffect(() => {
		const intervalId = handleBackendOnlineCheck(navigate);
		// cleanning the interval
		return () => {
			clearInterval(intervalId);
			setVantaEffect(0);
		};
	}, []);

	// useEffect for the Firebase Notifications
	useEffect(() => {
		const firebaseNotificationEnabled = useFirebaseNotifications(dispatch);
		setIsNotificationEnabled(firebaseNotificationEnabled);
	}, []);

	// pegando o IP e dados de quem acessa
	useEffect(() => {
		const { CancelToken } = axios;
		const source = CancelToken.source();

		(async () => {
			const newIpInfo = await fetchUserIP(source.token);

			setIpInfo(newIpInfo ?? null);
		})();

		return () => {
			// This cancels the axios request when the component is unmounted
			source.cancel();
		};
	}, []);

	const onSubmit = (data, e) => {
		handleLoginSubmit(dispatch, data, ipInfo);
	};

	const onError = (errs, e) => console.log(errs, e);

	useEffect(() => {
		if (login.error && (login.error.username || login.error.password)) {
			formRef.current.updateInputsWithError({
				...login.error
			});
		}
		if (!vantaEffect) {
			setVantaEffect(
				NET({
					el: myRef.current,
					mouseControls: true,
					touchControls: true,
					gyroControls: false,
					minHeight: 200.0,
					minWidth: 200.0,
					scale: 1.0,
					scaleMobile: 1.0,
					color: 0xff4800,
					backgroundColor: 0x112230,
					points: 6.0,
					maxDistance: 19.0,
					spacing: 18.0
				})
			);
		}
	}, [login.error, vantaEffect]);

	useEffect(() => {
		const { CancelToken } = axios;
		const source = CancelToken.source();

		// checking - enabling FirebaseNotifications
		const firebaseNotificationEnabled = useFirebaseNotifications(dispatch);
		setIsNotificationEnabled(firebaseNotificationEnabled);

		let isMounted = true;
		dispatch(setLockSystem(false));
		const saveScopoCredentials = localStorage.getItem('svScpCrd');
		if (saveScopoCredentials) {
			setValue('remember', true);
			const userScopoCred = localStorage.getItem('usrScp');
			const userPwdCred = localStorage.getItem('pwdScp');
			if (userScopoCred && isMounted) {
				setValue('username', userScopoCred);
			}
			if (userScopoCred && userPwdCred) {
				fetchUserIP(source).then(r => {
					if (isMounted) {
						dispatch(
							submitLogin({
								username: userScopoCred,
								password: userPwdCred,
								IpInfo: r
							})
						);
					}
				});
			}
		}
		if (!login.success && localStorage.getItem('jwt_access_token') !== null) {
			const previousToken = localStorage.getItem('jwt_access_token');
			// rest of the logic for handling jwt token
		}
		return () => {
			isMounted = false;
		};
	}, []);

	const copyright = process.env.REACT_APP_COPYRIGHT;

	return (
		<>
			<div
				id="Foreground"
				ref={myRef}
				className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 p-24 md:flex-row md:p-0')}
			>
				<Typography id="version" sx={{ color: '#ef4b0a', marginLeft: 1 }} variant="caption">
					vs. {version}
				</Typography>

				<div className="flex flex-col flex-grow-0 items-center text-white p-16 text-center md:p-128 md:items-start md:flex-shrink-0 md:flex-1 md:text-left">
					<FuseAnimate animation="transition.expandIn">
						<img className="w-256 mb-32" src="assets/images/logos/ScopoOrangeHeader.png" alt="logo" />
					</FuseAnimate>

					<div className="mb-32">
						<FuseAnimate animation="transition.slideUpIn" delay={300}>
							<Typography variant="h4" color="inherit" className="font-semibold">
								Sistema de Monitoramento em I.A.
							</Typography>
						</FuseAnimate>
					</div>

					<FuseAnimate delay={600}>
						<Typography paragraph variant="subtitle1" color="inherit" className="max-w-512 mt-16">
							O SM-Scopo é um Sistema de Pesquisa, Análise Estratégica e Ações específicas para diversos
							cenários na internet e em redes-sociais.
						</Typography>
					</FuseAnimate>

					<FuseAnimate delay={900}>
						<Typography paragraph variant="subtitle1" color="inherit" className="max-w-512 mt-16">
							Através de um conjunto integrado de ferramentas, neurobots e inteligência-artificial,
							posicionamos seu sua imagem de maneira eficiente, aumentando projeção e resultados em
							diversos contextos e atividades.
						</Typography>
					</FuseAnimate>

					<FuseAnimate delay={1200}>
						<Typography paragraph variant="subtitle1" color="inherit" className="max-w-512 mt-16">
							Contate um de <a href="http://www.neoplace.com.br">nossos representantes</a> para agendar
							uma apresentação.
						</Typography>
					</FuseAnimate>
				</div>

				<FuseAnimate animation={{ translateX: [0, '100%'] }}>
					<Card
						component={motion.div}
						initial={{ x: 200 }}
						animate={{ x: 0 }}
						transition={{ bounceDamping: 0 }}
						className="w-full max-w-400 mx-auto m-16 md:m-0 rounded-20 md:rounded-none"
						square
						layout
					>
						<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={login.loading}>
							<CircularProgress color="inherit" />
						</Backdrop>

						<CardContent className="flex flex-col items-center justify-center p-16 sm:p-32 md:p-48 md:pt-128 ">
							<Typography variant="h5" className="md:w-full mb-32">
								ACESSAR O PAINEL
							</Typography>

							<form
								name="loginForm"
								noValidate
								className="flex flex-col justify-center w-full mt-8"
								ref={formRef}
								onSubmit={handleSubmit(onSubmit, onError)}
							>
								<Controller
									name="username"
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											className="mb-16"
											label="Nome de Usuário"
											autoFocus
											type="username"
											autoComplete="current-username"
											error={!!errors.username}
											helperText={errors?.username?.message}
											variant="outlined"
											required
											fullWidth
										/>
									)}
								/>

								<Controller
									name="password"
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											className="mb-16"
											label="Senha"
											type="password"
											autoComplete="current-password"
											error={!!errors.password}
											helperText={errors?.password?.message}
											variant="outlined"
											required
											fullWidth
										/>
									)}
								/>

								<div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between">
									<Controller
										name="remember"
										control={control}
										render={({ field }) => (
											<FormControl>
												<FormControlLabel
													label="Lembrar login"
													control={<Checkbox {...field} checked={getValues('remember')} />}
												/>
											</FormControl>
										)}
									/>

									<Link className="font-normal" to="/forgotPassword">
										Esqueceu a Senha?
									</Link>
								</div>

								<Button
									variant="contained"
									color="primary"
									className="w-full mx-auto mt-16"
									aria-label="LOG IN"
									disabled={_.isEmpty(dirtyFields) || !isValid || !version}
									// disabled={true}
									type="submit"
								>
									ACESSAR
								</Button>
							</form>

							<div className="my-24 flex items-center justify-center">
								<Divider className="w-32" />
								<span className="mx-8 font-semibold">OU</span>
								<Divider className="w-32" />
							</div>

							<Button variant="contained" color="secondary" size="small" className="w-192 mb-8">
								Entrar com Google
							</Button>
							<Button variant="contained" color="primary" size="small" className="w-192 mb-8">
								Entrar com Facebook
							</Button>

							{/* <LoginButton */}
							{/* 	scope="email" */}
							{/* 	color="secondary" */}
							{/* 	onCompleted={handleFacebookResponse} */}
							{/* 	onError={onError} */}
							{/* 	className="w-192 mb-8" */}
							{/* > */}
							{/* 	{({ handleClick, isWorking, isLogged, profile }) => ( */}
							{/* 		<div onClick={handleClick}> */}
							{/* 			{isWorking */}
							{/* 				? 'Loading...' */}
							{/* 				: isLogged */}
							{/* 				? `Hello, ${profile.name}` */}
							{/* 				: 'Login with Facebook'} */}
							{/* 		</div> */}
							{/* 	)} */}
							{/* </LoginButton> */}

							<div className="flex flex-col items-center justify-center pt-32 pb-24">
								<span className="font-normal">Ainda não tem conta?</span>
								<Typography
									sx={{ color: '#f66011 !important' }}
									variant="button"
									component={Link}
									to="/register"
								>
									Criar Conta
								</Typography>
							</div>
							<div className="absolute bottom-2 w-full text-center">
								<Stack direction="row" spacing={2} justifyContent="center">
									<Typography
										sx={{ color: '#141f2a !important' }}
										variant="body2"
										component={Link}
										to="/terms/privacy"
									>
										Privacidade
									</Typography>

									<Typography
										sx={{ color: '#141f2a !important' }}
										variant="body2"
										component={Link}
										to="/terms/access"
									>
										Termos de Acesso
									</Typography>

									<Typography
										sx={{ color: '#141f2a !important' }}
										variant="body2"
										component={Link}
										to="/terms/data-management"
									>
										LGPD
									</Typography>
								</Stack>
							</div>
						</CardContent>
					</Card>
				</FuseAnimate>
			</div>

			{isNotificationEnabled && <ScopoFirebasePush />}
		</>
	);
};

export default Login;
