import React, { lazy } from 'react';

const knowledgeBase = lazy(() => import('../../knowledge-base/KnowledgeBasePage'))

const DocsConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: 'docs/',
			element: <knowledgeBase />
		}
	]
};

export default DocsConfig;