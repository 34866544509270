/* eslint import/no-extraneous-dependencies: off */
import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import axios from 'axios';
import history from '@history';
import _ from '@lodash';
import { setInitialSettings, setDefaultSettings } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import auth0Service from 'app/services/auth0Service';
import firebaseService from 'app/services/firebaseService';
import jwtService from 'app/services/jwtService';
import settingsConfig from 'app/fuse-configs/settingsConfig';
import { urlBackend, tokenHeaders } from 'app/main/data/LocationContext';
import { cleanBookmarks } from 'app/main/scopo-apps/research/store/bookmarksSlice';
import { resetEntityStatus } from 'app/main/store/entidade/entityStatusSlice';
import { resetEntityType } from 'app/main/store/entidade/SelectedEntitySlice';
import { cleanOperators } from 'app/main/store/entidade/operatorsSlice';
import { clearDispute } from 'app/main/store/IndicatorsSlices/disputeSlice';
import { cleanNetworks, removeAllSocialNetworks } from 'app/main/store/entidade/socialNetworkSlice';
import { justGetUserChatList, removeChat } from 'app/fuse-layouts/shared-components/chatPanel/store/chatSlice';
import { getInvitesList, clearInvites } from 'app/main/store/account/invitesSlice';
import { batch } from 'react-redux';
import { loggedOut } from './loginSlice';
import { clearSocialMonitors } from '../../main/store/IndicatorsSlices/monitorSocialSlice';

export const setUserDataAuth0 = tokenData => async dispatch => {
	const user = {
		role: ['admin'],
		from: 'auth0',
		data: {
			displayName: tokenData.username || tokenData.name,
			photoURL: tokenData.picture,
			email: tokenData.email,
			settings:
				tokenData.user_metadata && tokenData.user_metadata.settings ? tokenData.user_metadata.settings : {},
			shortcuts:
				tokenData.user_metadata && tokenData.user_metadata.shortcuts ? tokenData.user_metadata.shortcuts : []
		}
	};

	return dispatch(setUserData(user));
};

export const setUserDataFirebase = (user, authUser) => async dispatch => {
	if (
		user &&
		user.data &&
		user.data.settings &&
		user.data.settings.theme &&
		user.data.settings.layout &&
		user.data.settings.layout.style
	) {
		// Set user data but do not update
		return dispatch(setUserData(user));
	}

	// Create missing user settings
	return dispatch(createUserSettingsFirebase(authUser));
};

export const createUserSettingsFirebase = authUser => async (dispatch, getState) => {
	const guestUser = getState().auth.user;
	const fuseDefaultSettings = getState().fuse.settings.defaults;
	const { currentUser } = firebase.auth();

	/**
	 * Merge with current Settings
	 */
	const user = _.merge({}, guestUser, {
		uid: authUser.uid,
		from: 'firebase',
		role: ['admin'],
		data: {
			displayName: authUser.displayName,
			email: authUser.email,
			settings: { ...fuseDefaultSettings }
		}
	});
	currentUser.updateProfile(user.data);

	dispatch(updateUserData(user));

	return dispatch(setUserData(user));
};

export const setUserData = user => async (dispatch, getState) => {
	/*
  You can redirect the logged-in user to a specific route depending on his role
  */

	if (user.loginRedirectUrl) {
		settingsConfig.loginRedirectUrl = user.loginRedirectUrl; // for example 'apps/academy'
	}

	/*
  Set User Settings
  */
	if (user.data && user.data.settings) {
		dispatch(setDefaultSettings(user.data.settings));
	}

	if (!('displayName' in user)) {
		user.displayName = user.username;
	}

	const lastShortcuts = getState().auth.user.data.shortcuts;

	const electionsToken = user?.data?.smscopo_token;

	if (user.data && user.data.shortcuts && lastShortcuts !== user.data.shortcuts) {
		const data = {
			shortcuts: user.data.shortcuts
		};
		axios
			.post(`${urlBackend}/api/usuario/change_shortcuts/`, data, tokenHeaders())
			.then(r => console.log(r))
			.catch(err => console.error(err));
	}

	dispatch(setUser(user));

	dispatch(getInvitesList());

	// if(electionsToken){
	//   dispatch(checkElectionsConn(electionsToken))
	// }

	// dispatch(justGetUserChatList());
};

export const updateUserSettings = settings => async (dispatch, getState) => {
	const oldUser = getState().auth.user;
	const user = _.merge({}, oldUser, { data: { settings } });

	console.log('UPDATING USER DATA settings', settings);
	dispatch(updateUserData(user));

	return dispatch(setUserData(user));
};

export const updateUserShortcuts = shortcuts => async (dispatch, getState) => {
	const { user } = getState().auth;
	const newUser = {
		...user,
		data: {
			...user.data,
			shortcuts
		}
	};

	dispatch(updateUserData(newUser));

	return dispatch(setUserData(newUser));
};

export const updateUserCompanies = companies => async (dispatch, getState) => {
	console.log(companies);
	const { user } = getState().auth;
	const updatedUser = {
		...user,
		companies
	};
	return dispatch(setUserData(updatedUser));
};

export const updateUserPhoto = photoUrl => async (dispatch, getState) => {
	const { user } = getState().auth;
	const newUser = {
		...user,
		data: {
			...user.data,
			photoURL: photoUrl
		}
	};
	return dispatch(setUserData(newUser));
};

export const logoutUser = () => async (dispatch, getState) => {
	console.log('logging out');
	const { user } = getState().auth;

	if (!user.role || user.role.length === 0) {
		// is guest
		return null;
	}

	dispatch({ type: 'backendStatus/stopNotificationsSocket' });

	history.push({
		pathname: '/'
	});

	localStorage.removeItem('svScpCrd');
	localStorage.removeItem('usrScp');
	localStorage.removeItem('pwdScp');

	switch (user.from) {
		case 'firebase': {
			firebaseService.signOut();
			break;
		}
		case 'auth0': {
			auth0Service.logout();
			break;
		}
		default: {
			jwtService.logout();
		}
	}

	dispatch(setInitialSettings());

	axios.post(`${urlBackend}/api/auth/logout`, {}, tokenHeaders()).then(_ => {
		batch(() => {
			dispatch(cleanBookmarks());
			dispatch(clearInvites());
			dispatch(resetEntityStatus());
			dispatch(resetEntityType());
			dispatch(cleanOperators());
			dispatch(removeChat());
			dispatch(cleanNetworks());
			dispatch(removeAllSocialNetworks());
			dispatch(clearSocialMonitors());
			dispatch(clearDispute());
			dispatch(loggedOut());
			dispatch(userLoggedOut());
		});
	});

	// dispatch(setElectionConn(false,null,null));
	// dispatch(clearSmConnection());

	return dispatch(userLoggedOut());
};

export const registerNewCompany = (user, company) => async (dispatch, getState) => {
	if (!user.role || user.role.length === 0) {
		// is guest
		return;
	}
	axios
		.post(
			`${urlBackend}/api/company/cadastro/`,
			{
				company
			},
			this.tokenHeaders()
		)
		.then(response => {
			console.log(response);
			if (response.data.user) {
				dispatch(setUserData(user));
				dispatch(showMessage({ message: 'Empresa atualizada com sucesso!' }));
			} else {
				dispatch(showMessage({ message: 'Não houve inclusão' }));
			}
		})
		.catch(error => dispatch(showMessage({ message: error.message })));
};

export const updateUserData = user => async (dispatch, getState) => {
	if (!user.role || user.role.length === 0) {
		// is guest
		return;
	}
	switch (user.from) {
		case 'firebase': {
			firebaseService
				.updateUserData(user)
				.then(() => {
					dispatch(showMessage({ message: 'User data saved to firebase' }));
				})
				.catch(error => {
					dispatch(showMessage({ message: error.message }));
				});
			break;
		}
		case 'auth0': {
			auth0Service
				.updateUserData({
					settings: user.data.settings,
					shortcuts: user.data.shortcuts
				})
				.then(() => {
					dispatch(showMessage({ message: 'User data saved to auth0' }));
				})
				.catch(error => {
					dispatch(showMessage({ message: error.message }));
				});
			break;
		}
		default: {
			jwtService
				.updateUserData(user)
				.then(() => {
					dispatch(showMessage({ message: 'Dados atualizados com sucesso!' }));
					dispatch(setUserData(user));
					return true;
				})
				.catch(error => {
					dispatch(showMessage({ message: error.message }));
					return false;
				});
			break;
		}
	}
};

export const updateCompanyData = (user, company) => async (dispatch, getState) => {
	if (!user.role || user.role.length === 0) {
		// is guest
		return;
	}
	switch (user.from) {
		case 'firebase': {
			console.log('In Firebase');
			firebaseService
				.updateUserData(user)
				.then(() => {
					dispatch(showMessage({ message: 'User data saved to firebase' }));
				})
				.catch(error => {
					dispatch(showMessage({ message: error.message }));
				});
			break;
		}
		case 'auth0': {
			console.log('In Auth0');
			auth0Service
				.updateUserData({
					settings: user.data.settings,
					shortcuts: user.data.shortcuts
				})
				.then(() => {
					dispatch(showMessage({ message: 'User data saved to auth0' }));
				})
				.catch(error => {
					dispatch(showMessage({ message: error.message }));
				});
			break;
		}
		default: {
			console.log(company);
			jwtService
				.updateCompanyData(company)
				.then(user => {
					console.log(user);
					dispatch(setUserData(user));
				})
				.then(() => {
					dispatch(showMessage({ message: 'Empresa atualizada com sucesso!' }));
					return true;
				})
				.catch(error => {
					console.log('error ', error);
					dispatch(showMessage({ message: error.message }));
					return false;
				});
			break;
		}
	}
};

const initialState = {
	role: [], // guest
	data: {
		id: null,
		displayName: null,
		photoURL: 'https://smscopo.sfo2.digitaloceanspaces.com/smscopo/static/images/unknown-avatar.png',
		email: null,
		shortcuts: ['war-room'],
		smscopo_token: null,
		status: 'offline',
		mood: ''
	},
	companies: [],
	localCandidates: [],
	chatList: []
};

export const setUserRole = newUserRole => (dispatch, getState, state) => {
	dispatch(setUserData(newUserRole));
};

export function restoreUserDefaultRoles() {
	return dispatch => {
		axios.get(`${urlBackend}/api/account/?only_roles`, tokenHeaders()).then(response => {
			dispatch(updateUserRole(response.data));
		});
	};
}

export const updateUserMood = mood => {
	const url_mood = `${urlBackend}/api/usuario/change_mood/`;
	const data = { mood };
	return dispatch => {
		axios.post(url_mood, data, tokenHeaders()).then(response => {
			dispatch(updateMood(response.data));
		});
	};
};

export const updateUserConnectionStatus = newStatus => {
	const url_status = `${urlBackend}/api/usuario/change_status/`;
	const data = { personalized_status: newStatus };
	return dispatch => {
		axios.post(url_status, data, tokenHeaders()).then(response => {
			console.log(response.data);
			dispatch(updateConnectionStatus(response.data));
		});
	};
};

const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
		setUser: (state, action) => action.payload,
		userLoggedOut: (state, action) => initialState,
		updateUserRole: (state, action) => {
			state.role = action.payload;
		},
		updateUsrCompanies: (state, action) => {
			state.companies = action.payload;
		},
		updateMood: (state, action) => {
			state.data.mood = action.payload;
		},
		updateConnectionStatus: (state, action) => {
			state.data.status = action.payload;
		},
		updateUserChatList: (state, action) => {
			state.chatList = action.payload;
		},
		updateLocalCandidates: (state, action) => {
			state.localCandidates = action.payload;
		}
	},
	extraReducers: {}
});

export const {
	setUser,
	userLoggedOut,
	updateUserRole,
	updateUsrCompanies,
	updateMood,
	updateConnectionStatus,
	updateUserChatList,
	updateLocalCandidates
} = userSlice.actions;

export default userSlice.reducer;
