import axios from 'axios';

import publicIp from 'public-ip';
import { GET_UFS, GET_CITIES, UPDATE_UF, UPDATE_CITY, CLEAR_CITIES, GET_WEATHER } from './actionTypes.js';

import { urlBackend, XRapidAPI_Key } from '../../data/LocationContext';

// const publicIp = require('public-ip');

const urlEstados = 'https://monitor.scopo.online/api/estados/';
const urlBuscaCepHost = 'consulta-de-cep1.p.rapidapi.com';

const userIP = await publicIp.v4();

const getAccessToken = () => {
	return window.localStorage.getItem('jwt_access_token');
};

const tokenHeaders = () => {
	const token = getAccessToken();

	// Headers
	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	};

	if (token) {
		config.headers.Authorization = `Token ${token}`;
	}

	return config;
};

export function getCepAddr(cep) {
	const options = {
		method: 'GET',
		url: `https://${urlBuscaCepHost}/cep/${cep}`,
		headers: {
			'X-RapidAPI-Host': urlBuscaCepHost,
			'X-RapidAPI-Key': XRapidAPI_Key
		}
	};

	axios
		.request(options)
		.then(function (response) {
			console.log(response.data);
			return response.data;
		})
		.catch(function (error) {
			console.error(error);
			return false;
		});
}

export const getCepAddrA = async cep => {
	console.log(cep);
	const options = {
		method: 'GET',
		url: 'https://consulta-de-cep1.p.rapidapi.com/cep/80250104',
		headers: {
			'X-RapidAPI-Host': 'consulta-de-cep1.p.rapidapi.com',
			'X-RapidAPI-Key': '97d578395dmsh21994f765800d2dp17d224jsn2d29e69435c1'
		}
	};
	console.log(options);
	const response = await axios.get(options);
	console.log(response);
	return await response.data;
};

export function getUFs() {
	return dispatch => {
		axios.get(urlEstados).then(resp =>
			dispatch({
				type: GET_UFS,
				payload: resp.data.results.map(state => ({
					value: state.uf,
					label: state.nome
				}))
			})
		);
	};
}

export function updateUF(newUF) {
	return {
		type: UPDATE_UF,
		payload: newUF
	};
}

export function getCities(CitiesList) {
	return {
		type: GET_CITIES,
		payload: CitiesList
	};
}

export function updateCity(newCity) {
	return {
		type: UPDATE_CITY,
		payload: newCity
	};
}

export function clearCities() {
	return {
		type: CLEAR_CITIES
	};
}

export const getWeatherByIP = () => {
	return dispatch => {
		axios
			.get(`${urlBackend}/api/weather?ip=${userIP}`, tokenHeaders())
			.then(resp =>
				dispatch({
					type: GET_WEATHER,
					payload: resp.data.results
				})
			)
			.catch(function (error) {
				console.log(error);
			});
	};
};

export const getWeatherByCity = (uf, city) => {
	return dispatch => {
		axios
			.get(`${urlBackend}/api/weather?uf=${uf}&cod_city=${city}`, tokenHeaders())
			.then(resp =>
				dispatch({
					type: GET_WEATHER,
					payload: resp.data.results
				})
			)
			.catch(err => console.log(err));
	};
};

export const generateNewReportCandidate = (candidate_id, tipo) => {
	axios
		.get(`${urlBackend}/api/candidato/gera_relatorio/?candidate_id=${candidate_id}&tipo=${tipo}`, tokenHeaders())
		.then(resp => console.log(resp))
		.catch(err => console.log(err));
};
