import React, { lazy } from 'react';
import { authRoles } from '../../../auth';

const LockPage = lazy(() => import('./LockPage'));

const LockPageConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: true
				},
				footer: {
					display: true
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/lock',
			element: <LockPage />
		}
	]
};

export default LockPageConfig;
