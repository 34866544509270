import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ScopoLoader } from 'app/main/components/utils';
import { setRemoteLoaded } from 'app/store/fuse/environmentSlice';
// import backendStatusWebSock from 'app/main/components/backend/backendStatusWebSock';
import {
	getBackendStatus,
	setEntityInEditingMode,
	setEntityInManagementMode,
	setPreviousManagementInFocusEntityId,
	setPreviousManagementInFocusMonitorId,
	setSearchEntityMode
} from 'app/store/fuse/backendStatusSlice';
import { getTrends } from 'app/main/store/news/trendsSlice';
import { getEntityMonitoresSociais, getMonitorSocialId } from '../../main/store/IndicatorsSlices/monitorSocialSlice';
import { getEvolutionEntityIndicators } from '../../main/store/IndicatorsSlices/evolutionSlice';

// import ws from "ws";
// import * as ws from "react-dom/test-utils";
// import { wsBackend } from "app/main/data/LocationContext";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
	'& .username, & .email': {
		transition: theme.transitions.create('opacity', {
			duration: theme.transitions.duration.shortest,
			easing: theme.transitions.easing.easeInOut
		})
	},

	'& .avatar': {
		background: theme.palette.background.default,
		transition: theme.transitions.create('all', {
			duration: theme.transitions.duration.shortest,
			easing: theme.transitions.easing.easeInOut
		}),
		bottom: 0,
		'& > img': {
			borderRadius: '50%'
		}
	}
}));

function UserNavbarHeader(props) {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const entidade = useSelector(state => state.entidade);
	const environment = useSelector(({ fuse }) => fuse.environment);
	const entityIndicators = entidade.indicators;
	// const entityIndicators = useSelector(({ entidade }) => entidade.indicators);
	const kpiMarkers = useSelector(({ kpis }) => kpis.maps.markers);
	const { backendStatus } = useSelector(({ fuse }) => fuse);
	const location = useLocation();

	// to load backendStatusSock in state, once
	// transfered backendStatusWebsock.js to a slice middleware
	// in backendStatusSlice
	// backendStatusWebSock();

	const entBasicInitialState = {
		image:
			user.data.photoURL && user.data.photoURL !== ''
				? user.data.photoURL
				: 'https://smscopo.sfo2.digitaloceanspaces.com/smscopo/static/images/unknown-avatar.png',
		header: user.data.displayName,
		subheader: user.data.email,
		subheader2: null
	};

	const [entityBasicInfo, setEntityBasicInfo] = useState(entBasicInitialState);

	String.prototype.toProperCase = function () {
		return this.replace(/\w\S*/g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	};

	const checkLongName = nmStr => {
		if (nmStr.length > 21) {
			const arrayNm = nmStr.split(' ');
			return `${arrayNm[0].toProperCase()} ${arrayNm[1].toProperCase()}`;
		}
		return nmStr.toProperCase();
	};

	function checkSubheader() {
		if (entidade.SelectedEntity.origem === 'pj') {
			if (entidade.SelectedEntity.data && entidade.SelectedEntity.data.cnpj) {
				return entidade.SelectedEntity.data.cnpj;
			}
		} else if (entidade.SelectedEntity.origem === 'pf') {
			if (entidade.SelectedEntity.data && entidade.SelectedEntity.data.cpf) {
				return entidade.SelectedEntity.data.cpf;
			}
		} else {
			return entidade.SelectedEntity.context;
		}
	}

	// disable the sm-scopo loading, depending from
	// indicators loading status
	useEffect(() => {
		const allLoadOff = arr => arr.every(item => item === false);
		const loadingValuesArr = [
			entityIndicators.location.loading,
			entityIndicators.evolution.loading,
			entityIndicators.dispute.loading,
			entityIndicators.voters.loading,
			kpiMarkers.loading
		];
		if (environment.remoteLoading === true) {
			if (allLoadOff(loadingValuesArr)) {
				dispatch(setRemoteLoaded(true));
			}
		}
	}, [
		entityIndicators.location.loading,
		entityIndicators.evolution.loading,
		entityIndicators.dispute.loading,
		entityIndicators.voters.loading,
		kpiMarkers.loading
	]);

	useEffect(() => {
		if (!entidade.SelectedEntity.id) {
			// setEntityBasicInfo({...entityBasicInfo, image: user.data.photoURL })
			setEntityBasicInfo(entBasicInitialState);
		} else {
			setEntityBasicInfo({
				image: entidade.SelectedEntity.img,
				header: checkLongName(entidade.SelectedEntity.name),
				subheader: entidade.SelectedEntity.tp,
				subheader2: checkSubheader()
			});
		}
		dispatch(getBackendStatus());
	}, [entidade.SelectedEntity]);

	useEffect(() => {
		dispatch(getTrends());
	}, []);

	// volta ao estado da entidade selecionada depois de sair de Entidades
	useEffect(() => {
		console.log('Current path:', location.pathname);

		if (
			!location.pathname.includes('/entidade') &&
			!location.pathname.includes('/entidades') &&
			!location.pathname.includes('/pesquisa') &&
			!environment.locked
		) {
			if (backendStatus.entityInEditingMode) {
				dispatch(setEntityInEditingMode(false));
			}

			if (backendStatus.entityInManagementMode) {
				dispatch(setEntityInManagementMode(false));
			}

			if (backendStatus.searchEntityMode) {
				dispatch(setSearchEntityMode(false));
			}

			if (backendStatus.previousManagementInFocusMonitorId) {
				dispatch(getMonitorSocialId(backendStatus.previousManagementInFocusMonitorId));
				dispatch(getEvolutionEntityIndicators(['mon_id', backendStatus.previousManagementInFocusMonitorId]));
				dispatch(setPreviousManagementInFocusMonitorId(null));
			}
		}
	}, [location]);

	// useEffect(() => {
	//   if(backendStatus.esNewsServiceOnline){
	//     dispatch(getTrendingNews())
	//   }
	//
	// }, [backendStatus.esNewsServiceOnline]);

	return (
		<StyledAppBar
			position="static"
			color="primary"
			className="user relative flex flex-col items-center justify-center pt-24 pb-64 mb-32 z-0 shadow-0"
		>
			<Typography className="username text-18 whitespace-nowrap font-semibold mb-4" color="inherit">
				{/* {user.data.displayName} */}
				{entityBasicInfo.header}
			</Typography>
			<Typography className="email text-13 opacity-50 whitespace-nowrap font-medium" color="inherit">
				{entityBasicInfo.subheader}
			</Typography>

			{entityBasicInfo.subheader2 ? (
				<Typography variant="caption" gutterBottom className="ml-3.5 opacity-50" color="inherit">
					<i>{entityBasicInfo.subheader2}</i>
				</Typography>
			) : null}

			<div className="flex items-center justify-center absolute bottom-0 -mb-44">
				{environment.remoteLoading ? (
					<ScopoLoader className="h-72 w-72 p-8 box-content" />
				) : (
					<Avatar className="avatar w-72 h-72 p-8 box-content" alt="user photo" src={entityBasicInfo.image} />
				)}
			</div>
		</StyledAppBar>
	);
}

export default UserNavbarHeader;
