import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { tokenHeaders, urlBackend } from 'app/main/data/LocationContext';
import { getEntityNetworks } from 'app/main/store/entidade/socialNetworkSlice';
import { setCandidatoData } from "./politico/candidatoSlice";

const initialState = {
	origem: null,
	tp: null,
	context: null,
	id: null,
	img: null,
	name: null,
	brain: null,
	data: null,
	marketshare: null,
	scopo_news: null,
	dados_partidarios: null,
	marketshare_players_limit: 12
};

// export function updateEntityType(data){
//     return dispatch => {
//         dispatch(setEntStateType(data));
//     }
// }

export function updateEntityState(origem, tp, context, id, img, name, brain) {
	return dispatch => {
		dispatch(setEntityState({ origem, tp, context, id, img, name, brain }));
	};
}

export const updateSelectedEntityMarketSharePlayersLimit = ({ dispatch, getState }) => {};

export const getEntityDataById = createAsyncThunk(
	'entidade/SelectedEntity/data/getEntityDataById',
	async (entidade_id, { dispatch, getState }) => {
		const config = tokenHeaders();
		config.method = 'get';
		config.url = `${urlBackend}/api/entidade/data/?id=${entidade_id}`;
		const response = await axios(config);
		const { data } = response.data;
		let parsedData = JSON.parse(data);
		if (response.data.success) {
			dispatch(getEntityNetworks(entidade_id));
			// dispatch(setScopoNews(parsedData.scopo_news));
			dispatch(setMarketPlayersLimit(parsedData.marketshare_players_limit));
			if (parsedData.origem === 'candidato') {
				dispatch(setCandidatoData(parsedData.entidade.concorrente));
				delete parsedData.entidade.concorrente;
			}
		}
		return parsedData;
	}
);

export const updateEntityNewsKeywords = createAsyncThunk(
	'entidade/SelectedEntity/data/updateNewsKeywords',
	async (newData, { dispatch }) => {
		const response = await axios.post(`${urlBackend}/api/news/update_keywords/`, newData, tokenHeaders());

		const data = await response.data;

		console.log(response)
		console.log(data)

		dispatch(setScopoNews(response.data));

		return data;
	}
);

export const updateEntityNewsNumber = createAsyncThunk(
	'entidade/SelectedEntity/data/updateNewsNumber',
	async (newData, { dispatch }) => {
		const response = await axios.post(`${urlBackend}/api/news/update_news_quantity/`, newData, tokenHeaders());

		const data = await response.data;
		dispatch(setScopoNews(response.data));
		return data;
	}
);

export const updateEntityNewsDatesRange = createAsyncThunk(
	'entidade/SelectedEntity/data/updateNewsNumber',
	async (newData, { dispatch }) => {
		const response = await axios.post(`${urlBackend}/api/news/update_news_range/`, newData, tokenHeaders());
		const data = await response.data;
		dispatch(setScopoNews(response.data));
		return data;
	}
);

const selectedEntitySlice = createSlice({
	name: 'actualSelection',
	initialState,
	reducers: {
		setEntityState: (state, action) => {
			const { origem, tp, context, id, img, name, brain, signed_for_updates, days_delay_to_update, image } =
				action.payload;
			state.origem = origem;
			state.tp = tp;
			state.context = context;
			state.id = id;
			state.img = img || image;
			state.name = name;
			state.brain = brain;
			state.signed_for_updates = signed_for_updates;
			state.days_delay_to_update = days_delay_to_update;
		},
		setEntStateType: (state, action) => action.payload,
		resetEntityType: (state, action) => initialState,
		setScopoNews: (state, action) => {
			state.scopo_news = action.payload;
		},
		setMarketPlayersLimit: (state, action) => {
			state.marketshare_players_limit = action.payload;
		}
	},
	extraReducers: {
		[getEntityDataById.fulfilled]: (state, action) => {
			state.data = action.payload.entidade;
		}
	}
});

export const {
	setEntStateType,
	resetEntityType,
	setEntityState,
	setScopoNews,
	setMarketPlayersLimit
} = selectedEntitySlice.actions;
export default selectedEntitySlice.reducer;
