import React, { memo, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { getChat } from './store/chatSlice';
import {
	getContacts,
	getUpdatedContact,
	resetContactList,
	selectContactById,
	selectContacts, setSelectedContactId,
	updateContact
} from './store/contactsSlice';
import { openChatPanel } from './store/stateSlice';
import ContactButton from './ContactButton';
import { tokenHeaders, urlBackend } from '../../../main/data/LocationContext';

const Root = styled(FuseScrollbars)(({ theme }) => ({
	background: theme.palette.background.paper
}));

function ContactList(props) {
	const dispatch = useDispatch();
	const contacts = useSelector(selectContacts);
	const { socketNotifications } = useSelector(({ fuse }) => fuse.backendStatus);
	const selectedContactId = useSelector(({ chatPanel }) => chatPanel.contacts.selectedContactId);
	const user = useSelector(({ auth }) => auth.user);
	const contactListScroll = useRef(null);
	const selectedContact = useSelector(state => selectContactById(state, selectedContactId));
	const chatPanel = useSelector(state => state.chatPanel);

	const [lastDiagLength, setLastDialogLength] = useState(
		chatPanel.chat && chatPanel.chat.dialog ? chatPanel.chat.dialog.length : 0
	);

	const selectedChatPanel = chatPanel.chat && chatPanel.chat.dialog ? chatPanel.chat.dialog : null;

	// const [webSockNotifications, setWebSockNotifications] = useState(null)

	// const { sendMessage, lastJsonMessage, readyState } = useWebSocket(
	// 	`${wsBackend}notifications/${user.data.displayName}/`,
	// 	{ share: true }
	// );

	// const connectionStatus = {
	// 	[ReadyState.CONNECTING]: 'Connecting',
	// 	[ReadyState.OPEN]: 'Open',
	// 	[ReadyState.CLOSING]: 'Closing',
	// 	[ReadyState.CLOSED]: 'Closed',
	// 	[ReadyState.UNINSTANTIATED]: 'Uninstantiated'
	// }[readyState];

	// console.log(connectionStatus);
	// console.log(lastJsonMessage);
	// console.log(sendMessage);
	// console.log(selectedContactId);

	// aumentando unread msgs

	// useEffect(() => {
	// 	if (connectionStatus === 'Open') {
	// 		if (selectedChatPanel && lastDiagLength !== selectedChatPanel.length) {
	// 			console.log('UHHA. New notifications');
	// 			console.log(chatPanel);
	// 			if (chatPanel.messages.lastSent.userId) {
	// 				const msg_to_send = {
	// 					type: 'userMessageSent',
	// 					origem: 'usuario',
	// 					content: {
	// 						from: user.data.id,
	// 						to: chatPanel.contacts.selectedContactId,
	// 						chatId: chatPanel.chat.id
	// 					}
	// 				};
	// 				console.log(JSON.stringify(msg_to_send))
	// 				// sendMessage(JSON.stringify(msg_to_send));
	// 			}
	// 		}
	// 		// console.log(lastJsonMessage, sendMessage, readyState);
	// 	}
	// }, [selectedChatPanel, lastDiagLength]);
	//
	const handleContactClick = contactId => {
		console.log(contactId);
		console.log(selectedContactId);
		const idToOpen = selectedContactId || contactId;

		if (contactId) {
			if (socketNotifications.readyState === WebSocket.OPEN) {
				socketNotifications.send(
					JSON.stringify({
						type: 'getUserChat',
						origem: user.data.displayName,
						content: { usuario_id: idToOpen }
					})
				);
				dispatch(setSelectedContactId(contactId));
				dispatch(openChatPanel());
				scrollToTop();
			}
		} else {
			console.log('Notifications Socket Not Ready');
			console.log(socketNotifications.readyState);
		}

		// if (idToOpen) {
		// 	axios
		// 		.get(`${urlBackend}/api/usuario/reset_unread_messages/?sender_id=${contactId}`, tokenHeaders())
		// 		.then(r => {
		// 			console.log('updating. Get updated contact');
		// 			// todo: review here to update only one contacct
		// 			// dispatch(getContacts());
		// 			// dispatch(getUpdatedContact(idToOpen));
		// 			// dispatch(getUpdatedContact(contactId));
		// 		})
		// 		.then(r => {
		// 			console.log('urras');
		// 			dispatch(openChatPanel());
		// 			dispatch(getChat({ contactId }));
		// 		})
		// 		.catch(err => console.error(err));
		//
		// 	scrollToTop();
		// }
	};

	const scrollToTop = () => {
		contactListScroll.current.scrollTop = 0;
	};

	const container = {
		show: {
			transition: {
				staggerChildren: 0.05
			}
		}
	};

	const item = {
		hidden: { opacity: 0, scale: 0.6 },
		show: { opacity: 1, scale: 1 }
	};

	return (
		<Root
			className="flex shrink-0 flex-col overflow-y-auto py-8 overscroll-contain"
			ref={contactListScroll}
			option={{ suppressScrollX: true, wheelPropagation: false }}
		>
			{contacts.length > 0 && (
				<>
					<motion.div variants={container} initial="hidden" animate="show" className="flex flex-col shrink-0">
						{user &&
							user.chatList &&
							user.chatList.map(chat => {
								const contact = contacts.find(_contact => _contact.id === chat.contactId);
								if (contact) {
									return (
										<motion.div variants={item} key={contact.id}>
											<ContactButton
												contact={contact}
												selectedContactId={selectedContactId}
												onClick={handleContactClick}
											/>
										</motion.div>
									);
								}
							})}
						<Divider className="mx-24 my-8" />

						{contacts.map(contact => {
							const chatContact = user.chatList
								? user.chatList.find(_chat => _chat.contactId === contact.id)
								: null;
							return !chatContact ? (
								<motion.div variants={item} key={contact.id}>
									<ContactButton
										contact={contact}
										selectedContactId={selectedContactId}
										onClick={handleContactClick}
									/>
								</motion.div>
							) : (
								''
							);
						})}
					</motion.div>
				</>
			)}
		</Root>
	);
}

export default memo(ContactList);
