import React from 'react';
// import {Facebook, Twitter} from "@material-ui/icons";
import { useSelector } from 'react-redux';
import { TwitterIcon } from '../components/LocalIcons';

const statusFB = false;
const statusTwitter = false;

const statusConnected = {
	title: 'Conectado',
	bg: '#105314',
	fg: '#FFFFFF'
};

const statusDisconnected = {
	title: 'Conectar',
	bg: '#d74a0c',
	fg: '#FFFFFF'
};

// const twitterIcon = TwitterIcon()

const SocialStatus = network => {
	switch (network) {
		case 'facebook':
			return statusFB ? statusConnected : statusDisconnected;
		case 'twitter':
			return statusTwitter ? statusConnected : statusDisconnected;
		default:
			return statusDisconnected;
	}
};

const SocialNavigation = {
	id: 'social-conf',
	title: 'CONEXÕES SOCIAIS',
	type: 'collapse',
	icon: 'share',
	url: '',
	children: [
		{
			id: 'panel-socialNetworks',
			title: 'Configurações',
			type: 'item',
			url: '',
			icon: 'room_preferences'
		},
		{
			id: 'facebook-socialNetworks',
			title: 'Facebook',
			type: 'item',
			url: '/apps/social/facebook',
			icon: 'facebook',
			badge: statusDisconnected
		},
		{
			id: 'twitter-socialNetworks',
			title: 'Twitter',
			type: 'item',
			url: '/apps/social/twitter',
			icon: TwitterIcon,
			badge: statusDisconnected
		}
	]
};

export default SocialNavigation;
