import { authRoles} from "../../../../auth";
import RegisterSuccess from "./RegisterSuccess";

const RegisterSuccessConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: '/register/success',
            element: <RegisterSuccess />
        }
    ]
}

export default RegisterSuccessConfig