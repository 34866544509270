// Internet Explorer 11 requires polyfills and partially supported by this project.
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
// import 'typeface-roboto';
import React from 'react';
import './i18n';
import './styles/app-base.css';
import './styles/app-components.css';
import './styles/app-utilities.css';
// import ReactDOM from 'react-dom';
// import { render } from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from 'app/App';
import 'typeface-muli';

import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
// import { smscopoInfoASCII } from './app/main/data/LocationContext';

// React 17 - Before React18
// const container = document.getElementById('root');
// render(<App />, container);

// React 18
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

reportWebVitals(console.log);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register();
