import { lazy } from 'react';
import { authRoles} from "../../../auth";
// import Register from "./Register";
const Register = lazy(()=> import('./Register')) ;

const RegisterConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            exact: true,
            path: '/register',
            // component: Register
            element: <Register/>
        }
    ]
};

export default RegisterConfig;