import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { motion } from 'framer-motion';
import Fab from '@mui/material/Fab';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import { AirplanemodeInactiveTwoTone } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import DocumentationButton from './DocumentationButton';

function PoweredByLinksScopo() {
	const { backendStatus, scopoNotificationsStatus, newNotification, pushMode } = useSelector(
		({ fuse }) => fuse.backendStatus
	);
	const { remoteLoading, offlineMode } = useSelector(({ fuse }) => fuse.environment);

	// const [userNewNotifications, setUserNewNotifications] = useState(false);

	const container = {
		show: {
			transition: {
				staggerChildren: 0.1
			}
		}
	};

	const item = {
		hidden: { opacity: 0, scale: 0.6 },
		show: { opacity: 1, scale: 1 }
	};

	// if (socketNotifications) {
	// 	socketNotifications.onmessage = (e) => {
	// 		console.log(e.data)
	// 		setUserNewNotifications(true);
	// 		setTimeout(() => setUserNewNotifications(false), 3000);
	// 	};
	// }

	return (
		<motion.div variants={container} initial="hidden" animate="show" className="flex items-center align-middle">
			<Stack direction="row" spacing={2}>
				<DocumentationButton className="invisible md:visible mx-1" />

				<div className={`p-1 flex items-center ${remoteLoading && 'animate-pulse'}`}>
					<Tooltip
						title={
							backendStatus === 'Connected'
								? 'Conectado no sistema de notificações do backend'
								: 'Desconectado do sistema de notificações de Backend. A navegação pode ficar mais lenta ou prejudicada'
						}
						placement="top"
					>
						{backendStatus === 'Connected' ? (
							offlineMode ? (
								<AirplanemodeInactiveTwoTone color="success" sx={{ width: '24px' }} />
							) : (
								<PowerIcon color="success" sx={{ width: '24px' }} />
							)
						) : offlineMode ? (
							<AirplanemodeInactiveTwoTone color="error" sx={{ width: '24px' }} />
						) : (
							<PowerOffIcon color="error" sx={{ width: '24px' }} />
						)}
					</Tooltip>
				</div>

				<div
					className={`p-1 flex items-center ${remoteLoading && 'animate-pulse'}`}
					style={!pushMode ? { background: '#b64b1c' } : {}}
				>
					<Tooltip
						title={
							scopoNotificationsStatus === 'Connected'
								? 'Sistema de comunicação/notificações do usuário ativo.'
								: 'Notificações do usuário inativas. Mensagens e atualizações podem não ser recebidas. Tente mudar de navegador ou faça novo login.'
						}
						placement="top"
					>
						{scopoNotificationsStatus === 'Connected' ? (
							<LinkIcon
								className={newNotification && 'animate-bounce'}
								color="success"
								sx={{ width: '24px' }}
							/>
						) : (
							<LinkOffIcon color="error" sx={{ width: '24px' }} />
						)}
					</Tooltip>
				</div>
			</Stack>
		</motion.div>
	);
}

export default PoweredByLinksScopo;
