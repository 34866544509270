import React from 'react';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

function DocumentationButton({ className }) {
	return (
		<Button
			component={Link}
			to="/docs/knowledge-base"
			role="button"
			className={clsx(className)}
			variant="contained"
			color="primary"
			sx={{
				backgroundColor: 'transparent',
				'&:hover': {
					backgroundColor: 'rgba(0, 0, 0, 0.04)', // Para efeito hover leve (opcional)
				},
			}}
		>
			<Icon sx={{ width: '20px' }}>import_contacts</Icon>
			<span className="ml-2 mr-2">Documentação</span>
		</Button>
	);
}

export default DocumentationButton;
