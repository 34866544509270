import { combineReducers } from '@reduxjs/toolkit';
import analysis from "app/main/store/entidade/analysisSlice";
import SelectedEntity from "./SelectedEntitySlice";
import operators from "./operatorsSlice";
import OperatorDialog from "./OperatorDialogSlice";
import chatList from "./chatListSlice";
import indicators from "../IndicatorsSlices";
import social_networks from "./socialNetworkSlice";
import entityStatus from "app/main/store/entidade/entityStatusSlice";
import analise_comparativa from "./analiseComparativaSlice";
import politico from "./politico"

// import concorrentes from "../kpis/concorrentesSlice"

const entityReducers = combineReducers(
  {
          politico,
          analise_comparativa,
          analysis,
          chatList,
          indicators,
          operators,
          OperatorDialog,
          SelectedEntity,
          entityStatus,
          // concorrentes,
          social_networks
  }
)

export default entityReducers