import { combineReducers } from '@reduxjs/toolkit';
import trendingNews from './trendingNewsSlice';
import trends from './trendsSlice';
import clipping from './clippingSlice';
import newsSources from './newsSourcesSlice';

const newsReducer = combineReducers({
	trendingNews,
	trends,
	clipping,
	newsSources
});

export default newsReducer;
