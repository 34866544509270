import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
	range: 'nacional',
	country: 'Brasil',
	region: null,
	state: null,
	city: null,
	neighborhood: null,
	selected_location: 'Brasil'
};

export const updateActionRangeComparativo = ar => {
	let actionRange = { ...ar }; // shallow copy
	let selected_location = 'Brasil';

	switch (ar.range) {
		case 'nacional':
			selected_location = ar.country;
			break;
		case 'federal':
			selected_location = ar.country;
			break;
		case 'regional':
			selected_location = ar.region;
			break;
		case 'estadual':
			selected_location = ar.state;
			break;
		case 'municipal':
			selected_location = ar.city;
			break;
		default:
			selected_location = 'Brasil';
	}

	actionRange.selected_location = selected_location;

	// todo: Review: Analise comparativa em candidato resetando para federal/Brasil
	// gambiarra. For some reason some function is calling the default location
	// on compartative state to federal/Brasil.

	// return dispatch => dispatch(setActionRangeComparativoState(actionRange))

	if (ar.range !== 'federal') {
		return dispatch => dispatch(setActionRangeComparativoState(actionRange));
	}
	return false;
};

const actionRangeComparativoSlice = createSlice({
	name: 'entidade/analise-comparativa/action-range',
	initialState,
	reducers: {
		setActionRangeComparativoState: (state, action) => action.payload,
		resetActionRangeComparativoState: () => initialState
	}
});

export const { setActionRangeComparativoState, resetActionRangeComparativoState } = actionRangeComparativoSlice.actions;

export default actionRangeComparativoSlice.reducer;
