import { configureStore, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { tokenHeaders, urlBackend } from 'app/main/data/LocationContext';

const pathToGetBookmarks = '/api/research/get_user_research_bookmarks/';
const pathToFastIncludeExcludeBookmarks = '/api/research/update_research_bookmark/';

const bookmarkAdapter = createEntityAdapter({});

export const getUserBookmarks = createAsyncThunk('research/bookmarks/getBookmarks', async () => {
	const response = await axios.get(`${urlBackend}${pathToGetBookmarks}`, tokenHeaders());
	return response.data;
});

export const fastBookmarkIncludeExclude = createAsyncThunk(
	'research/bookmarks/fastBookmarkInclude',
	async ({ name, document_type, document }) => {
		const data = { name, document_type, document };
		const response = await axios.post(`${urlBackend}${pathToFastIncludeExcludeBookmarks}`, data, tokenHeaders());
		return response.data;
		// return axios.post(`${urlBackend}${pathToFastIncludeExcludeBookmarks}`, data, tokenHeaders());
	}
);

export const {
	selectAll: selectBookmarks,
	selectById: selectBookmarkId,
	addOne: addOneBookmark
} = bookmarkAdapter.getSelectors(state => state.research.bookmarks);

const resetState = {
	ids: [],
	entities: [],
	selectedBookmarkId: null,
	searchText: '',
	loaded: false
};

const bookmarkSlice = createSlice({
	name: 'research/bookmarks',
	initialState: bookmarkAdapter.getInitialState({
		selectedBookmarkId: null,
		searchText: '',
		loaded: false
	}),
	reducers: {
		setBookmarks: (state, action) => {
			bookmarkAdapter.setAll(state, action.payload);
			state.loaded = true;
		},
		setBookmarkId: (state, action) => {
			state.selectedBookmarkId = action.payload;
		},
		removeBookmarkId: (state, action) => {
			state.selectedBookmarkId = null;
		},
		setBookmarksLoadedStatus: (state, action) => {
			state.loaded = state.payload;
		},
		cleanBookmarks: (state, action) => {
			bookmarkAdapter.removeAll(state);
			bookmarkAdapter.getInitialState(resetState);
		},
		setBookmarkSearchText: {
			reducer: (state, action) => {
				state.searchText = action.payload;
			}
		},
		bookmarkUpdated: (state, action) => {
			addOneBookmark(state, action.payload);
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getUserBookmarks.fulfilled, (state, action) => {
				bookmarkAdapter.setAll(state, action.payload);
				state.loaded = true;
			})
			.addCase(fastBookmarkIncludeExclude.fulfilled, (state, action) => {
				bookmarkAdapter.setAll(state, action.payload);
			});
	}
	// extraReducers: {
	// 	[getUserBookmarks.fulfilled]: bookmarkAdapter.setAll,
	// 	[fastBookmarkIncludeExclude.fulfilled]: bookmarkAdapter.setAll
	// }
});

export const {
	setBookmarks,
	setBookmarkId,
	removeBookmarkId,
	setBookmarksLoadedStatus,
	cleanBookmarks, setBookmarkSearchText } =
	bookmarkSlice.actions;

export default bookmarkSlice.reducer;
