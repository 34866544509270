import { log } from 'util';
import React, { memo, useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import format from 'date-fns/format';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useLogin, useFacebook, LoginButton } from 'react-facebook';
import { Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Cookies from 'js-cookie';
import withReducer from 'app/store/withReducer';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseLoading from '@fuse/core/FuseLoading';
import reducer from './store';
import { getData } from './store/dataSlice';
import { toggleQuickPanel } from './store/stateSlice';
import { getWeatherByCity, getWeatherByIP } from '../../../main/store/actions/localActions';
import WidgetNow from '../widgets/WidgetNow';
import WidgetWeather from '../widgets/WidgetWeather';
import { handleFacebookConnection } from '../../../store/fuse/socialNetworksSlice';
import { setMonitorVersion, setPushMode, setSoundNotification } from '../../../store/fuse/backendStatusSlice';
import idbKeyval, { deleteScopoDatabase } from '../../../main/store/scopoTempDb';
import { setOfflineMode } from '../../../store/fuse/environmentSlice';
// import WidgetWeather from "../../../main/apps/dashboards/project/widgets/WidgetWeather";

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
	'& .MuiDrawer-paper': {
		width: 300
	}
}));

function checkIndexedDbExists(dbName: string): Promise<boolean> {
	return new Promise((resolve, reject) => {
		let req = window.indexedDB.open(dbName);
		let existed = true;

		req.onupgradeneeded = () => {
			existed = false;
		};
		req.onsuccess = () => {
			req.result.close();

			// If it didn't previously exist, delete db
			// as we only wanted to check existence
			if (!existed) {
				req = window.indexedDB.deleteDatabase(dbName);
				req.onerror = reject;
				req.onsuccess = () => resolve(existed);
			}

			resolve(existed);
		};
		req.onerror = reject;
	});
}

function QuickPanel(props) {
	const dispatch = useDispatch();
	const { state, data } = useSelector(({ quickPanel }) => quickPanel);
	const userData = useSelector(({ auth }) => auth.user.data);
	const [checked, setChecked] = useState('notifications');
	const { weather } = props;
	const { soundNotification, pushMode, backendStatus, scopoNotificationsStatus } = useSelector(
		({ fuse }) => fuse.backendStatus
	);
	const [soundStatus, setSoundOStatus] = useState(soundNotification);
	const [pushConn, setPushConn] = useState(pushMode);

	const [cleaningCacheStatus, setCleaningCacheStatus] = useState('idle');
	const [cleaningCache, setCleaningCache] = useState(false);
	const [asCache, setAsCache] = useState(false);

	// const [offlineMode, setOfflineMode] = useState(true);
	const { offlineMode } = useSelector(({ fuse }) => fuse.environment);

	const dbName = 'scopoLocalDb';

	// const { login, status, isLoading, error} = useLogin();
	const { isLoading, init, error } = useFacebook();

	const timerRef = useRef();

	const handleToggle = value => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	async function handleFacebook() {
		console.log('FAICE');

		// const api = await init({
		// 	appId: '253032921939778',
		// 	xfbml: true,
		// 	version: 'v15.0'
		// });

		const api = await init();
		const FB = await api.getFB(); // Get original FB object

		// FB.getLoginStatus((response)=>{
		// 	console.log(response);
		// })

		// todo: Implement facebook app
		// FB.login(
		// 	response => {
		// 		console.log(response);
		// 	},
		// 	{
		// 		scope: 'id,name',
		// 		return_scopes: true
		// 	}
		// );
		//
		// FB.login(
		// 	response => {
		// 	console.log(response);
		// });

		// todo: Implement facebook app

		// try {
		// 	const response = await login({
		// 		scope: 'email',
		// 	});
		//
		// 	console.log(response.status);
		// 	console.log(response);
		// } catch (err) {
		// 	console.log(err.message);
		// }

		// try {
		// 	const response = await FB.login({
		// 		scope: 'email'
		// 	});
		//
		// 	console.log(response.status);
		// 	console.log(response);
		// } catch (err) {
		// 	console.log(err.message);
		// }

		// dispatch(handleFacebookConnection('facebook'));
	}

	const handleSound = e => {
		setSoundOStatus(e);
	};

	const handlePushMode = ev => {
		const lastStat = pushMode;
		setPushConn(ev);
		dispatch(setPushMode(ev));
		if (lastStat === false) {
			if (backendStatus !== 'Connected') {
				dispatch({ type: 'backendStatus/startWebSocket' });
			}
			if (scopoNotificationsStatus !== 'Connected') {
				dispatch({ type: 'backendStatus/startNotificationsSocket' });
			}
		}
	};

	const handleOfflineMode = ev => {
		console.log(ev.target.value);
		const offLineCookie = Cookies.get('ScopoOfflineMode');
		if (offlineMode) {
			dispatch(setOfflineMode(false));
			if (offLineCookie) {
				Cookies.remove('ScopoOfflineMode');
			}
		} else {
			dispatch(setOfflineMode(true));
			Cookies.set('ScopoOfflineMode', true, { expires: 7 });
		}
	};

	async function clearScopoCache() {
		try {
			const result = await deleteScopoDatabase();
			console.log('Database deleted successfully', result);
			window.location.reload();
		} catch (err) {
			console.error('Error deleting database:', err);
		}
	}

	const handleClearCache = () => {
		console.log('limpando scopo cache..');

		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}

		// if (cleaningCacheStatus !== 'idle') {
		// 	setCleaningCacheStatus('idle');
		// 	setCleaningCache(false);
		// 	return;
		// }

		idbKeyval.clear().then(r => console.log('Cleared', r));
		setCleaningCacheStatus('progress');
		setCleaningCache(true);

		clearScopoCache().then(r => console.log('limpando scopo cache', r));

		timerRef.current = setTimeout(() => {
			setCleaningCacheStatus('success');
			setCleaningCache(false);
			setAsCache(false);
		}, 3000);
	};

	useEffect(() => {
		idbKeyval
			.count()
			.then(count => {
				if (count > 0) {
					if (!asCache) {
						setAsCache(true);
					}
				} else if (asCache) {
					setAsCache(true);
				}
				console.log(`Number of items in scopoStore: ${count}`);
			})
			.catch(err => {
				console.error('Error counting items:', err);
			});

		// eslint-disable-next-line no-unused-expressions
		() => clearTimeout(timerRef.current);
	}, []);

	useEffect(() => {
		if (pushConn !== pushMode) {
			setPushConn(pushMode);
		}
	}, [pushMode]);

	useEffect(() => {
		if (soundNotification !== soundStatus) {
			dispatch(setSoundNotification(soundStatus));
		}
	}, [soundStatus]);

	useEffect(() => {
		if (userData.id) {
			const { uf, city } = userData;
			if (uf && city) {
				props.getWeatherByCity(uf, city);
			} else {
				props.getWeatherByIP();
			}
		} else {
			getWeatherByIP();
		}
	}, [userData]);

	return (
		<StyledSwipeableDrawer
			open={state}
			anchor="right"
			onOpen={ev => {}}
			onClose={ev => dispatch(toggleQuickPanel())}
			disableSwipeToOpen
		>
			<FuseScrollbars>
				<div className="w-full p-12">
					<WidgetNow />
				</div>

				<Divider />
				<WidgetWeather weather={weather} />
				<Divider />

				{/* <List> */}
				{/* 	<ListSubheader component="div">Eventos</ListSubheader> */}
				{/* 	<ListItem key="no_events"> */}
				{/* 		<ListItemText primary="Sem Eventos" secondary="Nenhum evento agendado" /> */}
				{/* 	</ListItem> */}
				{/* 	/!* {data && *!/ */}
				{/* 	/!*   data.events.map((event) => ( *!/ */}
				{/* 	/!*     <ListItem key={event.id}> *!/ */}
				{/* 	/!*       <ListItemText primary={event.title} secondary={event.detail} /> *!/ */}
				{/* 	/!*     </ListItem> *!/ */}
				{/* 	/!*   ))} *!/ */}
				{/* </List> */}

				<Divider />
				<List>
					<ListSubheader component="div">Anotações</ListSubheader>
					<ListItem key="no_annotation">
						<ListItemText
							primary="Análise em Progresso"
							secondary="Cruzamentos de análise em finalização"
						/>
					</ListItem>

					{data &&
						data.notes.map(note => (
							<ListItem key={note.id}>
								<ListItemText primary={note.title} secondary={note.notes} />
							</ListItem>
						))}
				</List>
				<Divider />
				<List>
					<ListSubheader component="div">Dados de Contato</ListSubheader>
					<ListItem>
						<ListItemIcon className="min-w-40">
							<Icon>phone-incoming</Icon>
						</ListItemIcon>
						<ListItemText primary={data && data.cellphone ? data.cellphone : 'nenhum'} />
					</ListItem>
					<ListItem>
						<ListItemIcon className="min-w-40">
							<Icon>mail-open</Icon>
						</ListItemIcon>
						<ListItemText primary={data && data.email ? data.email : 'nenhum'} />
					</ListItem>
				</List>
				<Divider />
				<List>
					<ListSubheader component="div">Configurações Rápidas</ListSubheader>
					<ListItem>
						<ListItemIcon className="min-w-40">
							<Icon>notifications</Icon>
						</ListItemIcon>
						<ListItemText primary="Alertas" />
						<ListItemSecondaryAction>
							<Switch color="secondary" onChange={_ => handleSound(!soundStatus)} checked={soundStatus} />
						</ListItemSecondaryAction>
					</ListItem>
					<ListItem>
						<Tooltip title="Push agiliza a conexão com o servidor e permite notificações com bots e usuários">
							<ListItemIcon className="min-w-40">
								<Icon>link</Icon>
							</ListItemIcon>
						</Tooltip>
						<ListItemText primary="Push" />
						<ListItemSecondaryAction>
							<Switch
								color="secondary"
								onChange={_ => handlePushMode(!pushConn)}
								checked={pushConn}
								// checked={checked.indexOf('email-notification') !== -1}
							/>
						</ListItemSecondaryAction>
					</ListItem>
					<ListItem>
						{cleaningCache ? (
							// <FuseLoading delay={3000} />
							<Fade
								in={cleaningCache}
								style={{
									transitionDelay: cleaningCache ? '800ms' : '0ms'
								}}
								unmountOnExit
							>
								<CircularProgress />
							</Fade>
						) : (
							<ListItemIcon className="min-w-40">
								<Icon>delete_forever</Icon>
							</ListItemIcon>
						)}
						<ListItemText primary="Limpar Cache" />
						<Switch color="secondary" checked={asCache} onChange={handleClearCache} disabled={!asCache} />
					</ListItem>
					<ListItem>
						<ListItemIcon className="min-w-40">
							<Icon>{offlineMode ? 'airplanemode_inactive' : 'airplanemode_active'}></Icon>
						</ListItemIcon>
						<ListItemText primary="Modo Offline" />
						<Switch
							color="secondary"
							checked={offlineMode}
							onChange={handleOfflineMode}
							disabled={!asCache}
						/>
					</ListItem>
					<ListItem>
						<ListItemIcon className="min-w-40">
							<Icon>phone</Icon>
						</ListItemIcon>
						<ListItemText primary="Notificar Celular" />
						<ListItemSecondaryAction>
							<Switch
								color="secondary"
								onChange={handleToggle('habilitar_elections')}
								checked={data && data.notify_celular ? data.notify_celular : false}
								// checked={checked.indexOf('habilitar_elections') !== -1}
							/>
						</ListItemSecondaryAction>
					</ListItem>
					<ListItem>
						<ListItemIcon className="min-w-40">
							<Icon>adb</Icon>
						</ListItemIcon>
						<ListItemText primary="Monitorar Bots" />
						<ListItemSecondaryAction>
							<Switch
								color="secondary"
								// onChange={handleToggle('monitor_bots')}
								onChange={_ => handleFacebook()}
								checked={checked.indexOf('monitor_bots') !== -1}
							/>
						</ListItemSecondaryAction>
					</ListItem>
				</List>
			</FuseScrollbars>
		</StyledSwipeableDrawer>
	);
}

const quickPanelWithReducer = withReducer('quickPanel', reducer)(memo(QuickPanel));

const mapStateToProps = state => {
	return {
		weather: state.locations.weather
	};
};

const mapDispatchToProps = dispatch => bindActionCreators({ getWeatherByIP, getWeatherByCity }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(quickPanelWithReducer);
