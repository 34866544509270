import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// import { showMessage } from "../../../store/fuse/messageSlice";
import FuseUtils from '@fuse/utils';
import { addNotification } from 'app/fuse-layouts/shared-components/notificationPanel/store/dataSlice';
import { tokenHeaders, urlBackend } from '../../data/LocationContext';

const pathCompatition = '/api/strategy/get_competition/?';

export const getEntityCompetitions = createAsyncThunk(
	'entidade/indicators/competition/getCompetition',
	async (entId, { dispatch }) => {
		const response = await axios.get(`${urlBackend}${pathCompatition}id=${entId}`, tokenHeaders());
		const { success, message, as_competition, tipo, data } = await response.data;
		if (as_competition || !success) {
			const mstTemplate = {
				id: FuseUtils.generateGUID(),
				message,
				options: { variant: 'success' }
			};
			dispatch(addNotification(mstTemplate));
		}
		if (success) {
			dispatch(setLoadedCompetitionState(true));
			return data;
		}
		dispatch(clearCompetition);
		return [];
	}
);

const competitionAdapter = createEntityAdapter({});

export const {
	selectAll: selectCompetitions,
	removeAll: removeCompetitions,
	selectEntities: selectCompetitionsEntities,
	selectById: selectCompetitionById
} = competitionAdapter.getSelectors(state => state.entidade.indicators.competition);

const resetState = {
	loaded: false,
	selectedCompetitionId: null
};

const competitionSlice = createSlice({
	name: 'entidade/indicators/competition',
	initialState: competitionAdapter.getInitialState(resetState),
	reducers: {
		setCompetition: (state, action) => {
			competitionAdapter.setAll(state, action.payload);
		},
		setSelectedCompetitionId: (state, action) => {
			state.selectedCompetitionId = action.payload;
		},
		removeSelectedCompetitionId: (state, action) => {
			state.selectedCompetitionId = null;
		},
		setLoadedCompetitionState: (state, action) => {
			state.loaded = action.payload;
		},
		clearCompetition: (state, action) => resetState,
		removeAllCompetition: state => {
			competitionAdapter.removeAll(state);
		}
	},
	extraReducers: {
		[getEntityCompetitions.fulfilled]: (state, action) => {
			competitionAdapter.setAll(state, action.payload);
			state.loaded = true;
		},
		[getEntityCompetitions.rejected]: () => null
	}
});

export const {
	setCompetition,
	setSelectedCompetitionId,
	removeSelectedCompetitionId,
	setLoadedCompetitionState,
	clearCompetition,
	removeAllCompetition
} = competitionSlice.actions;

export default competitionSlice.reducer;
