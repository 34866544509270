import Badge from '@mui/material/Badge';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import withReducer from 'app/store/withReducer';
import reducer from './store';
import { selectNotifications } from './store/dataSlice';
import { toggleNotificationPanel } from './store/stateSlice';
import { updateNavigationItem } from '../../../store/fuse/navigationSlice';
// import FirebasePushNotifications from "../pushNotifications/FirebasePushNotifications";
import ScopoFirebasePush from '../../../main/components/pushNotifications/ScopoFirebasePush';

function NotificationPanelToggleButton(props) {
	const notifications = useSelector(selectNotifications);
	const dispatch = useDispatch();
	const firebasePushLoaded = useSelector(({ firebasePush }) => firebasePush.isLoaded);
	const browserIsPushCompatible = useSelector(({ firebasePush }) => firebasePush.browserIsCompatible);
	const loginSuccess = useSelector(({ auth }) => auth.login.success);

	useEffect(() => {
		const newAlert = {
			title: 'Avisos',
			badge: {
				title: notifications.length,
				bg: notifications.length === 0 ? '#044f01' : '#7a1313',
				fg: '#FFFFFF'
			}
		};
		dispatch(updateNavigationItem('alerts', newAlert));
	}, [notifications]);

	// function handleFirebaseNotifications(){
	//
	//   function checkNotificationPromise() {
	//     try {
	//       Notification.requestPermission().then();
	//     } catch(e) {
	//       return false;
	//     }
	//
	//     return true;
	//   }
	//
	//   function handlePermission(permission){
	//     if(Notification.permission === 'denied' || Notification.permission === 'default') {
	//         dispatch(setAuthorized(false));
	//     } else {
	//         dispatch(setAuthorized(true));
	{
		/*    } */
	}
	{
		/*  } */
	}
	{
		/*  if (!('Notification' in window)){ */
	}
	//     console.log("This browser does not support notifications.");
	//     dispatch(setBrowserCompatible(false));
	//     return false;
	//   } else{
	{
		/*    console.log("This browser SUPPORTS notifications."); */
	}
	{
		/*    dispatch(setBrowserCompatible(true)); */
	}
	{
		/*    if(checkNotificationPromise()) { */
	}
	//       Notification.requestPermission()
	//         .then((permission) => {
	//           handlePermission(permission);
	//         })
	//     } else {
	//       Notification.requestPermission()
	//         .then(permission=>handlePermission(permission))
	//     }
	//     return true
	//   }
	// }

	return (
		<IconButton className="w-40 h-40" onClick={ev => dispatch(toggleNotificationPanel())} size="large">
			<ScopoFirebasePush />
			<Badge color="secondary" variant="dot" invisible={notifications.length === 0}>
				{firebasePushLoaded ? props.childrenOn : props.childrenOff}
			</Badge>
		</IconButton>
	);
}

NotificationPanelToggleButton.defaultProps = {
	childrenOn: <Icon>notifications_active</Icon>,
	childrenOff: <Icon>notifications </Icon>
};

export default withReducer('notificationPanel', reducer)(NotificationPanelToggleButton);
