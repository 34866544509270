import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { FacebookProvider, useLogin } from 'react-facebook';
import axios from 'axios';
import { urlBackend } from '../../main/data/LocationContext';



export const handleFacebookConnection = createAsyncThunk(
	'fuse/SocialNetwork/facebook',
	async (sn, { dispatch, getState }) => {

		console.log('No slice socialSetworks');



		const facebookConnected = getState().fuse.SocialNetwork.facebook;

		if (facebookConnected) {
			console.log('DESCONECTE');
			return { network: 'facebook', status: false };
		}

		try {
			const response = await login({
				scope: 'id,name,picture{url},location,email'
			});
			console.log('Pegando FB')
			console.log(response.status);
		} catch (e) {
			console.log('Erro FB')
			console.log(e);
		}

		// const conn = await axios.get(`${urlBackend}dj-rest-auth/facebook/connect/`);

		console.log('O QUE VEIO???');
		// console.log(conn);

		return { network: 'facebook', status: true };
	}
);

const initialState = {
	facebook: null,
	twitter: null
};

// example updateNetwork('facebook', 'active')

const socialNetworksStatus = createSlice({
	name: 'socialNetworksStatus',
	initialState,
	reducers: {
		updateNetworkStatus: (state, action) => {
			state[action.payload.network] = action.payload.status;
		},
		disconnectNetworks: state => initialState
	},
	// extraReducers: {
	// 	[handleFacebookConnection.fulfilled]: (state, action) => {
	// 		state[action.payload.network] = action.payload.status;
	// 	},
	// 	[handleFacebookConnection.rejected]: (state, action) => {
	// 		state[action.payload.network] = null;
	// 	}
	// }
});

export const { updateNetworkStatus, disconnectNetworks } = socialNetworksStatus.actions;

export default socialNetworksStatus.reducer;
