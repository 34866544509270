import React, { lazy } from 'react';
import { authRoles } from '../../../auth';

const Avisos = lazy(() => import('./Avisos'));

const AvisosConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/avisos',
			element: <Avisos />
		}
	]
};

export default AvisosConfig;
