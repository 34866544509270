import React from 'react';
import { Icon, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

function WidgetWeather(props) {
  function GetRightIcon(txt){
    switch (txt) {
      case 'cloudly_day':
        return 'cloudy';
      case 'storm':
        return 'lightning'
      case 'rain':
        return 'rainy'
      case 'clear_day':
        return 'sun'
      case 'snow':
        return 'snowy'
      default:
        return txt;
    }
  }

  if(!props.weather){
    return (
      <div>
        loading
      </div>
    )
  }

  return (
    <Paper className="w-full rounded-8 shadow p-12">
      <div className="flex items-center justify-between px-4 pt-4">
        <div className="flex items-center px-12">
          <Icon color="action">location_on</Icon>
          <Typography className="text-16 mx-8">
            {props.weather.city ? props.weather.city : 'Sem Localização' }
          </Typography>
        </div>
        <IconButton aria-label="more">
          <Icon>more_vert</Icon>
        </IconButton>
      </div>
      <div className="flex items-center justify-center p-16 pb-32">
        <Icon className="meteocons text-40 ltr:mr-8 rtl:ml-8" color="action">
          {GetRightIcon(props.weather.condition_slug)}
        </Icon>
        <Typography className="text-44 mx-8" color="textSecondary">
          {props.weather.temp}
        </Typography>
        <Typography className="text-48 font-300" color="textSecondary">
          °
        </Typography>
        <Typography className="text-44 font-300" color="textSecondary">
          C
        </Typography>
      </div>
      <Divider />
      <div className="flex justify-between items-center p-16">
        <div className="flex items-center">
          <Icon className="meteocons text-14" color="action">
            windy
          </Icon>
          <Typography className="mx-4">
            {props.weather.wind_speedy ? props.weather.wind_speedy.split(" ")[0].trim() : '0' }
          </Typography>
          <Typography color="textSecondary">
            {props.weather.wind_speedy ? props.weather.wind_speedy.split(" ")[1].trim() : 'NN' }
          </Typography>
        </div>

        <div className="flex items-center">
          <Icon className="meteocons text-14" color="action">
            compass
          </Icon>
          <Typography className="mx-4">
            {/*{props.widget.locations[props.widget.currentLocation].windDirection}*/}
            SW
          </Typography>
        </div>
        <div className="flex items-center">
          <Icon className="meteocons text-14" color="action">
            rainy
          </Icon>
          <Typography className="mx-4">
            {/*{props.widget.locations[props.widget.currentLocation].rainProbability}*/}
            {`${props.weather.humidity} %`}
          </Typography>
        </div>
      </div>
      <Divider />
      <div className="w-full py-16">
        {props.weather.forecast.slice(0,4).map(day => (
          <div className="flex items-center justify-between w-full py-16 px-24" key={day.date}>
            <Typography className="text-15">{day.weekday}</Typography>
            <div className="flex items-center">
              <Icon className="meteocons text-24 ltr:mr-16 rtl:ml-16" color="action">
                {GetRightIcon(day.condition)}
              </Icon>
              <Typography className="text-20">{day.min}-{day.max}</Typography>
              <Typography className="text-20" color="textSecondary">
                &deg;C
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </Paper>
  );
}

export default React.memo(WidgetWeather);