import React, { lazy } from 'react';
import { authRoles } from '../../../auth';

const ChatApp = lazy(() => import('./ChatApp'));

const ChatAppScopoConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: 'entidade/chat',
			element: <ChatApp />
		}
	]
};

export default ChatAppScopoConfig;
