import { configureStore, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { urlBackend, tokenHeaders } from 'app/main/data/LocationContext';
import { showMessage } from 'app/store/fuse/messageSlice';

export const palletColors = [
	'#49563a',
	'#71015a',
	'#6638f0',
	'#342504',
	'#f7ab06',
	'#cd2e61',
	'#bf8d37',
	'#ef4b0a',
	'#8d9742',
	'#264901',
	'#14af9c',
	'#49caeb',
	'#4c84f3'
];

export const IncluiParticipantesMarketshare = dataList => {
	const idsMonitorados = dataList
		.filter(obj => obj.monitoring === true)
		.map(obj => obj.id)
		.filter(Boolean);

	const idsConcorrentes = dataList
		.filter(obj => obj.concorrente?.id != null)
		.map(obj => obj.concorrente?.id)
		.filter(Boolean);

	const idsConcorrentesMonitorados = dataList
		.filter(obj => obj.monitoring === true)
		.map(obj => obj?.concorrente?.id)
		.filter(Boolean);

	const stakeHoldersLst = dataList.map((item, index) => {
		const nm = item.concorrente ?
			item.concorrente.nm_urna ? item.concorrente.nm_urna : item.concorrente.nome : item.nome
		return {
			stakeholderId: item.id,
			stakeholder: nm,
			color: palletColors[index % palletColors.length],
			active: item.monitoring,
			monitoring: item.monitoring,
			as_avails: Object.values(item.sns_transfers).length > 0
		};
	});

	return dispatch => {
		dispatch(setMonitoredIds(idsMonitorados));
		dispatch(setMonitoredConcorrentesIds(idsConcorrentesMonitorados));
		dispatch(setConcorrentesIds(idsConcorrentes));
		dispatch(setParticipantes(dataList));
		dispatch(updateStakeholdersState(stakeHoldersLst));
	};
};

// export const handleCheckStakeholder

const participantesAdapter = createEntityAdapter({});

export const {
	selectAll: selectParticipantes,
	removeAll: removeParticipantes,
	selectById: selectParticipanteById
} = participantesAdapter.getSelectors(state => state.entidade.politico.participantes);

const resetState = {
	selectedParticipanteId: null,
	searchText: '',
	monitoredIds: [],
	concorrentesIds: [],
	monitoredConcorrentesIds: [],
	stakeholderState: [],
	tpTransSNS: 0,
	bufferedParticipantes: null
};

const participantesSlice = createSlice({
	name: 'entidade/politico/participantes',
	initialState: participantesAdapter.getInitialState(resetState),
	reducers: {
		setSelectedParticipanteId: (state, action) => {
			state.selectedConcorrenteId = action.payload;
		},
		removeSelectedParticipanteId: (state, action) => {
			state.selectedConcorrenteId = null;
		},
		setParticipanteSearchText: {
			reducer: (state, action) => {
				console.log(action.payload);
				state.searchText = action.payload;
			},
			prepare: event => ({ payload: event.target.value || '' })
		},
		setParticipantes: participantesAdapter.setAll,
		setMonitoredIds: (state, action) => {
			state.monitoredIds = action.payload;
		},
		setConcorrentesIds: (state, action) => {
			state.concorrentesIds = action.payload;
		},
		setMonitoredConcorrentesIds: (state, action) => {
			state.monitoredConcorrentesIds = action.payload;
		},
		appendMonitoredId: (state, action) => {
			state.monitoredIds.append(action.payload);
		},
		resetMonitoredIds: (state, action) => {
			state.monitoredIds = [];
			state.monitoredConcorrentesIds = [];
			state.setConcorrentesIds = [];
		},
		cleanParticipantes: (state, action) => participantesAdapter.getInitialState(resetState),
		removeTodosParticipantes: state => {
			participantesAdapter.removeAll(state);
		},
		bufferParticipantes: state => {
			state.bufferedParticipantes = { ...state.entities };
			state.bufferedIds = [...state.ids];
		},
		cleanBufferParticipantes: state => {
			state.bufferedParticipantes = null;
		},
		updateParticipante: participantesAdapter.updateOne,
		updateStakeholdersState: (state, action) => {
			state.stakeholderState = action.payload;
		},
		toggleStakeholderChecked: (state, action) => {
			const stakeholder = state.stakeholderState.find(
				item => item.stakeholderId === parseInt(action.payload, 10)
			);
			if (stakeholder) {
				stakeholder.active = !stakeholder.active;
			}
		},
		setTPTransSNS: (state, action) => {
			state.tpTransSNS = action.payload;
		}
	}
});

export const {
	updateParticipante,
	removeTodosParticipantes,
	cleanParticipantes,
	setParticipantes,
	setParticipanteSearchText,
	removeSelectedParticipanteId,
	setSelectedParticipanteId,
	setMonitoredIds,
	setMonitoredConcorrentesIds,
	setConcorrentesIds,
	appendMonitoredId,
	resetMonitoredIds,
	bufferParticipantes,
	cleanBufferParticipantes,
	updateStakeholdersState,
	toggleStakeholderChecked,
	setTPTransSNS
} = participantesSlice.actions;

export default participantesSlice.reducer;
