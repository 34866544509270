import { configureStore, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { batch } from 'react-redux';
import { tokenHeaders, urlBackend } from '../../data/LocationContext';
import { showMessage } from '../../../store/fuse/messageSlice';
// import { getState } from 'auth0-lock/lib/store';

const pathGetOperators = '/api/operators/get_operators/?';

// there is two operations for cadastro.  ['invite', 'update']
const pathCadastroOperators = '/api/operators/cadastro/?operation=';

const pathRemoveOperator = '/api/operators/remove_operator/?operator_id=';

// making only one function to get operators from any entity
// using a dict entInfo = {tipo:[empresa,candidato,partido,ent], id:entId

/**
 * old one. Now there's just entities in backend
export const getOperators = createAsyncThunk(
  'entidade/operators/getOperators',
    async(entInfo) => {
      const entityOpUrl = () => {
        const ent_type = Object.keys(entInfo)[0]
        const ent_id= Object.values(entInfo)[0]
        return `${urlBackend}${pathGetOperators}${ent_type}=${ent_id}`;
      }
      const response = await axios.get(entityOpUrl(),tokenHeaders())
      console.log(await response.data);
      return await response.data;
    }
)* */

export const getOperators = createAsyncThunk(
	'entidade/operators/getOperators',
	async (params, { dispatch, getState }) => {
		const { id, tp } = params;
		const response = await axios.get(`${urlBackend}${pathGetOperators}id=${id}&tp=${tp}`, tokenHeaders());
		const { success, data } = response.data;
		if (success) {
			const parsedData = JSON.parse(data);
			const userId = getState().auth.user.data.id;
			const userOp = parsedData.find(user => user.usuario_id === userId);

			batch(() => {
				dispatch(setSelectedOperatorId(userOp.id));
				dispatch(setOperatorCredentials(userOp.role));
				dispatch(setOperatorsFunctions(userOp.functions_list));
			});

			return parsedData;
		}
		// return response.data.data;
	}
);

export const operatorAsyncRegister = createAsyncThunk(
	'entidade/operators/register',
	async ({ data, entity, action }, { dispatch, getState }) => {
		const newData = { ...data, ...entity };
		const url_action = `${urlBackend}${pathCadastroOperators}${action}`;
		const body = JSON.stringify(newData);
		return axios.post(url_action, body, tokenHeaders());
	}
);

export const operatorAsyncRemove = createAsyncThunk(
	'entidade/operators/delete',
	async (operator_id, { dispatch, getState }) => {
		console.log(operator_id);
		const url_remove = `${urlBackend}${pathRemoveOperator}${operator_id}`;
		return axios.get(url_remove, tokenHeaders());
	}
);

// Making only one function to deal with operators invites/updates from any entity
// ['invite', 'update']
export function operatorRegister(data, entity, action) {
	const newData = { ...data, ...entity };
	const url_action = `${urlBackend}${pathCadastroOperators}${action}`;
	const body = JSON.stringify(newData);
	return dispatch => {
		axios
			.post(url_action, body, tokenHeaders())
			.then(response => {
				if (response.data.success) {
					// dispatch(getOperators(entity));
					console.log(response.data);
					dispatch(
						showMessage({
							message: response.data.message, // text or html
							autoHideDuration: 6000, // ms
							anchorOrigin: {
								vertical: 'top', // top bottom
								horizontal: 'center' // left center right
							},
							variant: 'success' // success error info warning null
						})
					);
				} else {
					dispatch(
						showMessage({
							message: response.data.message, // text or html
							autoHideDuration: 6000, // ms
							anchorOrigin: {
								vertical: 'top', // top bottom
								horizontal: 'center' // left center right
							},
							variant: 'error' // success error info warning null
						})
					);
				}
			})
			.catch(err => console.log(err));
	};
}

export function removeOperator(op_id, cpny_id) {
	// const op_id = parseInt(id);
	console.log(op_id);
	const url_remove_op = `${urlBackend}/api/company/remove_company_operator/?operator_id=${op_id}`;
	return dispatch => {
		axios
			.get(url_remove_op, tokenHeaders())
			.then(response => {
				console.log(response.data);
				if (response.data.success) {
					// dispatch(setCompanyOperators(response.data.operators));
					// dispatch(getCompanyOperators(cpny_id));
					dispatch(
						showMessage({
							message: response.data.message, // text or html
							autoHideDuration: 6000, // ms
							anchorOrigin: {
								vertical: 'top', // top bottom
								horizontal: 'center' // left center right
							},
							variant: 'success' // success error info warning null
						})
					);
				} else {
					dispatch(
						showMessage({
							message: response.data.message, // text or html
							autoHideDuration: 6000, // ms
							anchorOrigin: {
								vertical: 'top', // top bottom
								horizontal: 'center' // left center right
							},
							variant: 'error' // success error info warning null
						})
					);
				}
			})
			.catch(err => console.log(err));
	};
}

export function changeOpResp(op_id, cpny_id) {
	const url_change_op_resp = `${urlBackend}/api/company/change_operator_responsability/?operator_id=${op_id}`;
	return dispatch => {
		axios
			.get(url_change_op_resp, tokenHeaders())
			.then(response => {
				console.log(response.data);
				if (response.data.success) {
					// dispatch(setCompanyOperators(response.data.operators));
					// dispatch(getCompanyOperators(cpny_id));
					dispatch(
						showMessage({
							message: response.data.message, // text or html
							autoHideDuration: 6000, // ms
							anchorOrigin: {
								vertical: 'top', // top bottom
								horizontal: 'center' // left center right
							},
							variant: 'success' // success error info warning null
						})
					);
				} else {
					dispatch(
						showMessage({
							message: response.data.message, // text or html
							autoHideDuration: 6000, // ms
							anchorOrigin: {
								vertical: 'top', // top bottom
								horizontal: 'center' // left center right
							},
							variant: 'error' // success error info warning null
						})
					);
				}
			})
			.catch(e => console.log(e));
	};
}

const operatorsAdapter = createEntityAdapter({});

export const {
	selectAll: selectOperators,
	removeAll: removeOperators,
	selectById: selectOperatorById
} = operatorsAdapter.getSelectors(state => state.entidade.operators);

const resetState = {
	ids: [],
	entities: [],
	selectedOperatorId: null,
	searchText: '',
	credentials: null,
	functions_list: []
};

const operatorsSlice = createSlice({
	name: 'entidade/operators',
	initialState: operatorsAdapter.getInitialState({
		selectedOperatorId: null,
		searchText: '',
		credentials: null,
		functions_list: []
	}),
	reducers: {
		setSelectedOperatorId: (state, action) => {
			state.selectedOperatorId = action.payload;
		},
		setOperatorCredentials: (state, action) => {
			state.credentials = action.payload;
		},
		setOperatorsFunctions: (state, action) => {
			state.functions_list = action.payload;
		},
		removeSelectedOperatorId: (state, action) => {
			state.selectedOperatoratorId = null;
		},
		setOperatorSearchText: {
			reducer: (state, action) => {
				console.log(action.payload);
				state.searchText = action.payload;
			},
			prepare: event => ({ payload: event.target.value || '' })
		},
		setOperators: (state, action) => {
			operatorsAdapter.setAll(state, action.payload);
		},
		setEntityOperators: (state, action) => action.payload,
		cleanOperators: (state, action) =>
			operatorsAdapter.getInitialState({ selectedOperatorId: null, searchText: '', credentials: null }),
		removeAllOperators: state => {
			operatorsAdapter.removeAll(state);
		},
		updateOperatorStatus: operatorsAdapter.updateOne
	},
	extraReducers: {
		[getOperators.fulfilled]: operatorsAdapter.setAll
	}
});

export const {
	setSelectedOperatorId,
	setOperatorCredentials,
	setOperatorsFunctions,
	removeSelectedOperatorId,
	setOperatorSearchText,
	cleanOperators,
	setEntityOperators,
	setOperators,
	removeAllOperators,
	updateOperatorStatus
} = operatorsSlice.actions;

export default operatorsSlice.reducer;
