import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { closeChatPanel } from './stateSlice';
import { tokenHeaders, urlBackend } from '../../../../main/data/LocationContext';

export const getContacts = createAsyncThunk('chatPanel/contacts/getContacts', async params => {
	// const response = await axios.get('/api/chat/contacts', { params });
	const urlContacts = `${urlBackend}/api/account/?only_team`;
	const response = await axios.get(urlContacts, tokenHeaders());
	return response.data;
});

export const getUpdatedContact = createAsyncThunk('chatPanel/contacts/getUpdatedContact', async contact_id => {
	const response = await axios.get(`${urlBackend}/api/account/?get_updated_contact=${contact_id}`, tokenHeaders());
	// console.log(await response);
	return response.data;
});

export function setNewRecentContactOnlineId(contact_id) {
	return dispatch => {
		dispatch(addRecentLoginId(contact_id));
		setTimeout(() => dispatch(removeRecentLoginId(contact_id)), 10000);
	};
}

export function increaseUserUnreadMessages(u_id) {
	return dispatch => {
		axios
			.get(`${urlBackend}/api/usuario/increase_unread_messages/?sender_id=${u_id}`, tokenHeaders())
			.then(r => {
				if (r.data.success) {
					dispatch(getUpdatedContact(u_id));
				}
			})
			.catch(err => console.error(err));
	};
}

const contactsAdapter = createEntityAdapter({
	// Specify a comparison function for sorting
	sortComparer: (a, b) => {
		if (b.is_online === a.is_online) {
			return new Date(b.last_seen) - new Date(a.last_seen);
		}
		return b.is_online - a.is_online;
	}
});

export const { selectAll: selectContacts, selectById: selectContactById } = contactsAdapter.getSelectors(state =>
	state && state.chatPanel ? state.chatPanel?.contacts : undefined
);

const initialState = {
	selectedContactId: null,
	recentLoggedIn: []
};

const contactsSlice = createSlice({
	name: 'chatPanel/contacts',
	initialState: contactsAdapter.getInitialState(initialState),
	reducers: {
		setSelectedContactId: (state, action) => {
			state.selectedContactId = action.payload;
		},
		removeSelectedContactId: (state, action) => {
			state.selectedContactId = null;
		},
		addRecentLoginId: (state, action) => {
			state.recentLoggedIn = [...state.recentLoggedIn, action.payload];
		},
		removeRecentLoginId: (state, action) => {
			state.recentLoggedIn = state.recentLoggedIn.filter(id => id !== action.payload);
		},
		resetContactList: (state, action) => {
			contactsAdapter.removeAll(state);
			contactsAdapter.getInitialState(initialState);
		},
		updateContactsList: (state, action) => {
			contactsAdapter.setAll(state, action.payload);
		},
		updateContact: contactsAdapter.upsertOne,
		atualizaContato: (state, action) => {
			contactsAdapter.updateOne(state, {
				id: action.payload.id,
				updates: action.payload.updates
			});
		}
	},
	extraReducers: {
		[getContacts.fulfilled]: contactsAdapter.setAll,
		[closeChatPanel]: (state, action) => {
			state.selectedContactId = null;
		},
		[getUpdatedContact.fulfilled]: contactsAdapter.upsertOne
	}
});

export const {
	setSelectedContactId,
	removeSelectedContactId,
	addRecentLoginId,
	removeRecentLoginId,
	resetContactList,
	updateContact,
	atualizaContato,
	updateContactsList
} = contactsSlice.actions;

export default contactsSlice.reducer;
