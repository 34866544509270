import React, { useState } from 'react';
// import createGenerateClassName from '@mui/styles/createGenerateClassName';
// import jssPreset from '@mui/styles/jssPreset';
// import { create } from 'jss';
// import jssExtend from 'jss-plugin-extend';
// import rtl from 'jss-rtl';
import Provider from 'react-redux/es/components/Provider';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import br from 'date-fns/locale/pt-BR';
import { StyledEngineProvider } from '@mui/material/styles';
import AppContext from './AppContext';
import routes from './fuse-configs/routesConfig';
import store from './store';
import ScopoNotificationsContext from './main/data/ScopoNotificationsContext';
import { ScopoProvider, ScopoContext } from './main/data/ScopoContext';
// import ScopoNotifications from './main/components/ScopoNotifications';
// import useScopoNotifications from './main/components/useScopoNotifications';

const scopoInicialState = {
	entityId: '',
	stakeholders: '',
	master: 'Paul'
};
const withAppProviders = Component => props => {
	const WithProvidersComponent = () => {
		const [scopoState, setScopoState] = useState(scopoInicialState);
		return (
			<AppContext.Provider value={{ routes }}>
				<Provider store={store}>
					<StyledEngineProvider injectFirst>
						<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={br}>
							<ScopoContext.Provider value={{ scopoState, setScopoState }}>
								<Component {...props} />
							</ScopoContext.Provider>
						</LocalizationProvider>
					</StyledEngineProvider>
				</Provider>
			</AppContext.Provider>
		);
	}
	return WithProvidersComponent;
};

export default withAppProviders;
