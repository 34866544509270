import React, { useEffect, useState } from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from 'react-select';
import Divider from '@mui/material/Divider';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { logoutUser, updateUserConnectionStatus, updateUserMood } from 'app/auth/store/userSlice';
import { useDebounce } from '@fuse/hooks';
import StatusIcon from '../../main/apps/chat/StatusIcon';
import { statusArr } from '../../main/data/LocationContext';
import { bloqueiaSistema, setLockSystem } from '../../store/fuse/environmentSlice';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2)
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1)
	}
}));

const StyledStatus = styled('div')(({ theme, value }) => ({
	position: 'absolute',
	width: 14,
	height: 14,
	bottom: 0,
	right: 0,
	border: `2px solid ${theme.palette.background.default}`,
	borderRadius: '50%',
	zIndex: 10,

	...(value === 'online' && {
		backgroundColor: '#4CAF50'
	}),

	...(value === 'do-not-disturb' && {
		backgroundColor: '#F44336'
	}),

	...(value === 'away' && {
		backgroundColor: '#FFC107'
	}),

	...(value === 'offline' && {
		backgroundColor: '#646464'
	})
}));

const RelativeButton = styled(Button)(({ theme }) => ({
	position: 'relative', // add this
	minWidth: '40px', // original min-w-40
	minHeight: '40px', // original min-h-40
	padding: theme.spacing(0, 0), // original px-0 md:px-16 py-0 md:py-6
	[theme.breakpoints.up('md')]: {
		padding: theme.spacing(2)
	}
}));

const AvatarContainer = styled('div')(({ theme }) => ({
	position: 'relative',
	margin: theme.spacing(0, 1), // original mx-4
	[theme.breakpoints.up('md')]: {
		margin: theme.spacing(0, 1)
	}
}));

function UserMenu(props) {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const { control, handleSubmit, watch } = useForm({ defaultValues: user.data });
	const form = watch();
	const [moodState, setMoodState] = useState(user.data.mood);
	const [userStatus, setUserStatus] = useState(user.data.status);
	const navigate = useNavigate();
	const [userMenu, setUserMenu] = useState(null);
	const [openStatus, setOpenStatus] = React.useState(false);

	const userMenuClick = event => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	const handleClickOpenStatus = () => {
		setOpenStatus(true);
		setUserMenu(null);
	};
	const handleCloseStatus = () => {
		setOpenStatus(false);
	};

	function handleChangeMood(e) {
		setMoodState(e.target.value);
	}

	function handleStatus(e) {
		setUserStatus(e.target.value);
	}

	const updateUserStatus = useDebounce(usrSts => {
		dispatch(updateUserConnectionStatus(usrSts));
		// dispatch(updateUserData(_form));
	}, 600);

	function handleLock() {
		dispatch(bloqueiaSistema()).then(() => {
			navigate('/lock');
		});
		// dispatch(updateUserConnectionStatus('away'));

	}

	useEffect(() => {
		if (!user.data.id) {
			return;
		}

		if (moodState !== user.data.mood) {
			dispatch(updateUserMood(moodState));
		}

		if (userStatus !== user.data.status) {
			updateUserStatus(userStatus);
		}
	}, [moodState, userStatus]);


	useEffect(() => {
		if (userStatus !== user.data.status) {
			setUserStatus(user.data.status);
		}
	}, [user.data.status]);

	return (
		<>
			<RelativeButton onClick={userMenuClick} color="inherit">
				<div className="hidden md:flex flex-col ml-4 mr-0 items-end">
					<Typography component="span" className="font-semibold flex">
						{user.data && user.data.displayName ? user.data.displayName : 'Anônimo'}
					</Typography>
					<Typography className="text-11 font-medium capitalize" color="textSecondary">
						{user.role.toString()}
						{(!user.role || (Array.isArray(user.role) && user.role.length === 0)) && 'Guest'}
					</Typography>
				</div>

				<AvatarContainer>
					<StyledStatus value={userStatus} />

					{user.data.photoURL ? (
						<Avatar
							className="md:mx-4"
							alt="user photo"
							src={
								user.data && user.data.photoURL
									? user.data.photoURL
									: 'https://ecom-scopo.sfo2.digitaloceanspaces.com/shared_images/unknown-avatar_128px.png'
							}
						/>
					) : (
						<Avatar className="md:mx-4">
							{user.data && user.data.displayName ? user.data.displayName[0] : 'Anônimo'}
						</Avatar>
					)}
				</AvatarContainer>
			</RelativeButton>

			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'py-8'
				}}
			>
				{!user.role || user.role.length === 0 ? (
					<>
						<MenuItem component={Link} to="/login" role="button">
							<ListItemIcon className="min-w-40">
								<Icon>lock</Icon>
							</ListItemIcon>
							<ListItemText primary="Login" />
						</MenuItem>
						<MenuItem component={Link} to="/register" role="button">
							<ListItemIcon className="min-w-40">
								<Icon>person_add</Icon>
							</ListItemIcon>
							<ListItemText primary="Register" />
						</MenuItem>
					</>
				) : (
					<>
						{/* <MenuItem component={Link} to="/pages/profile" onClick={userMenuClose} role="button"> */}
						<MenuItem component={Link} to="/account/settings" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>account_circle</Icon>
							</ListItemIcon>
							<ListItemText primary="Meu Perfil" />
						</MenuItem>
						{/* <MenuItem component={Link} to="/lock" onClick={userMenuClose} role="button"> */}
						<MenuItem onClick={handleLock} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>lock</Icon>
							</ListItemIcon>
							<ListItemText primary="Block" />
						</MenuItem>
						<MenuItem
							onClick={() => {
								dispatch(logoutUser());
								userMenuClose();
							}}
						>
							<ListItemIcon className="min-w-40">
								<Icon>exit_to_app</Icon>
							</ListItemIcon>
							<ListItemText primary="Logout" />
						</MenuItem>
						<Divider variant="middle" />
						<Typography align="center" variant="caption" color="textSecondary" ml={2}>
							Status
						</Typography>
						<MenuItem onClick={handleClickOpenStatus} role="button">
							<ListItemIcon className="min-w-40">
								<StatusIcon status={userStatus} />
							</ListItemIcon>
							<ListItemText primary={userStatus} />
						</MenuItem>
					</>
				)}
			</Popover>

			<BootstrapDialog onClose={handleCloseStatus} aria-labelledby="customized-dialog-title" open={openStatus}>
				<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
					Alterar Status
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleCloseStatus}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: theme => theme.palette.grey[500]
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent dividers>
					<form>
						<FormControl component="fieldset" className="w-full mb-16">
							<Controller
								name="mood"
								control={control}
								render={({ field }) => (
									<TextField
										{...field}
										label="Mood"
										className="w-full"
										// value={moodState}
										margin="normal"
										multiline
										variant="outlined"
										onBlur={handleChangeMood}
									/>
								)}
							/>
						</FormControl>
						<FormControl component="fieldset" className="w-full mb-16">
							<FormLabel component="legend">Status</FormLabel>
							<Controller
								name="status"
								control={control}
								render={({ field }) => (
									<RadioGroup {...field} aria-label="Status" name="status">
										{statusArr.map(status => (
											<FormControlLabel
												key={status.value}
												value={status.value}
												onChange={handleStatus}
												control={<Radio />}
												label={
													<div className="flex items-center">
														<StatusIcon status={status.value} />
														<span className="mx-8">{status.title}</span>
													</div>
												}
											/>
										))}
									</RadioGroup>
								)}
							/>
						</FormControl>
					</form>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleCloseStatus}>
						Fechar
					</Button>
				</DialogActions>
			</BootstrapDialog>
		</>
	);
}

export default UserMenu;
