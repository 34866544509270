import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import ChatPanel from 'app/fuse-layouts/shared-components/chatPanel/ChatPanel';
import QuickPanel from 'app/fuse-layouts/shared-components/quickPanel/QuickPanel';
import NotificationPanel from 'app/fuse-layouts/shared-components/notificationPanel/NotificationPanel';

function RightSideLayout1(props) {
	const { scopoNotificationsStatus } = useSelector(({ fuse }) => fuse.backendStatus);
	return (
		<>
			{scopoNotificationsStatus === 'Connected' && <ChatPanel />}

			<QuickPanel />

			<NotificationPanel />
		</>
	);
}

export default memo(RightSideLayout1);
