import React, { useState, lazy } from 'react';
// import { Navigate } from 'react-router-dom';
// import InDevCandidatoCPFModel from "app/main/inDev/InDevCandidatoCPFModel";

const InDev = lazy(() => import('./InDev'));
const InDev2 = lazy(() => import('app/main/inDev/InDev2'));
const CandidatoCard = lazy(() => import('app/main/inDev/InDevCandidatoCPFModel'));
const InDev3News = lazy(() => import('app/main/inDev/InDevNews'));
const InDevClipping = lazy(() => import('app/main/inDev/IndevClipping'));
const InDevFeelingsGrp = lazy(() => import('app/main/inDev/inDevFeelingsGrp'));
const InDevDialogs = lazy(() => import('app/main/inDev/inDevDialogs'));
const ChurningTable = lazy(() => import('app/main/scopo-apps/churning/ChurningApp'))

const InDevConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: true
				},
				toolbar: {
					display: true
				},
				footer: {
					display: true
				},
				leftSidePanel: {
					display: true
				},
				rightSidePanel: {
					display: true
				}
			}
		}
	},
	routes: [
		{
			path: '/inDev/draft/:id',
			element: <CandidatoCard />
		},
		{
			path: '/inDev/dialogs',
			element: <InDevDialogs />
		},
		{
			path: '/inDev/tables',
			element: <ChurningTable />
		}
	]
};

export default InDevConfig;
