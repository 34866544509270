import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {
	FormControlLabel,
	FormGroup,
	ListItemButton,
	ListItemText,
	Paper,
	Slide,
	Switch,
	Tooltip
} from '@mui/material';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';
import InputAdornment from '@mui/material/InputAdornment';
import Icon from '@mui/material/Icon';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Autocomplete from '@mui/material/Autocomplete';
import { useCallback, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { AccountCircle, Input, Search } from '@mui/icons-material';
import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CssBaseline from '@mui/material/CssBaseline';
import axios from 'axios';
import FuseUtils from '@fuse/utils';
import { closeDialogConcorrenteByName, openDialogConcorrenteByName } from '../../../store/fuse/dialogSlice';
import SearchListTable from './SearchListTable';
import { getConcorrenteData } from '../../../main/store/entidade/analiseComparativaSlice/concorrenteSlice/concorrenteSlice';
import { tokenHeaders, urlBackend } from '../../../main/data/LocationContext';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function SearchCandidateByName(props) {
	const { dialogConcorrenteByNameOpen } = useSelector(({ fuse }) => fuse.dialog);
	const { positions, partidos } = useSelector(({ fillData }) => fillData?.data?.political ?? {});
	const { estados } = useSelector(({ fillData }) => fillData?.data ?? {});
	const [nome, setNome] = useState('');
	const [exactSearch, setExactSearch] = useState(false);
	const [incluiNomeUrna, setIncluiNomeUrna] = useState(true);
	const [buscaPorAno, setBuscaPorAno] = useState(false);
	const [anoPleito, setAnoPleito] = useState(2020);
	const [cargoSelecionado, setCargoSelecionado] = useState('');
	const [partidoSelecionado, setPartidoSelecionado] = useState('');
	const [ufSelecionada, setUfSelecionada] = useState('');
	const [municipioSelecionado, setMunicipioSelecionado] = useState('');
	const [listCities, setListCities] = useState(['Curitiba']);

	// const [searchParams, setSearchParams] = useState({
	// 	nome,
	// 	tp: exactSearch ? 'exact' : 'parcial',
	// 	inclui_nm_urna: incluiNomeUrna
	// });

	const [searchParams, setSearchParams] = useState(null);

	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(closeDialogConcorrenteByName());
	};

	const handleExactSearch = e => {
		setExactSearch(e.target.checked);
	};

	const handleIncluiNmUrna = e => {
		setIncluiNomeUrna(e.target.checked);
	};

	const handleBuscaPorAno = e => {
		setBuscaPorAno(e.target.checked);
	};

	const handleSetCargo = e => {
		setCargoSelecionado(e.target.value);
	};

	const handlePartidoSelecionado = e => {
		setPartidoSelecionado(e.target.value);
	};

	const handleUfSelecionada = e => {
		setUfSelecionada(e.target.value);
	};

	const handleSetParams = () => {
		const tp = exactSearch ? 'exact' : 'partial';
		setSearchParams({
			nome,
			tp,
			inclui_nm_urna: incluiNomeUrna
		});
	};

	const handleGetCandidateInfo = cpf => {
		dispatch(getConcorrenteData({ cpf }));
		console.log(cpf);
	};

	useEffect(() => {
		if (ufSelecionada && ufSelecionada !== '') {
			axios.get(`${urlBackend}/api/municipios/justCities/?uf=${ufSelecionada}`, tokenHeaders()).then(r => {
				console.log(r.data);
				setListCities(r.data.map(item => item.nome));
			});
		}
	}, [ufSelecionada]);

	return (
		<Dialog fullScreen open={dialogConcorrenteByNameOpen} onClose={handleClose} TransitionComponent={Transition}>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
						<CloseIcon />
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							Pesquisar Candidato por Nome
						</Typography>
					</IconButton>
				</Toolbar>
			</AppBar>

			<div className="flex flex-col sm:flex-row relative w-full h-full">
				<div id="table" className="ml-12 w-full md:w-8/12">
					<SearchListTable params={searchParams || null} handleGetCandidateInfo={handleGetCandidateInfo} />
				</div>
				<div
					id="searchBox"
					className="w-full relative my-64 md:w-4/12 p-12 max-w-[340px] min-w-[260px] sm:mt-0 sm:absolute sm:top-12 sm:right-12 md:top-24 md:right-24"
				>
					<Paper className="rounded-16 shadow w-full bg-gray-100 p-12 mr-24" elevation={4}>
						<div id="header" className="p-12 w-full flex items-center justify-center text-center ">
							<Typography variant="subtitle1" className="mx-12 text-center">
								Parâmetros de Busca
							</Typography>
						</div>
						<Divider />
						<Box className="flex items-end p-3 m-3">
							{/* <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: '8px', margin: '8px' }}> */}
							<AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
							<TextField
								id="input-with-sx"
								label="Nome"
								variant="standard"
								onChange={e => setNome(e.target.value)}
								className="flex flex-grow min-w-3/4 pr-8"
							/>
							<FormGroup className="items-center text-center align-middle ml-8">
								<Tooltip title="Procura pelo nome exato. Se não estiver marcado, busca por qualquer parte do nome">
									<FormControlLabel
										control={
											<Switch
												checked={exactSearch}
												onChange={handleExactSearch}
												size="small"
												inputProps={{ 'aria-labelledby': 'exact-search-input' }}
											/>
										}
										label="exato"
									/>
								</Tooltip>
							</FormGroup>
						</Box>
						<Box className="flex items-end p-3 mr-3 ml-3 mt-24 mb-24 ">
							<Tooltip title="Insere o nome de urna na busca">
								<FormControlLabel
									control={
										<Switch
											checked={incluiNomeUrna}
											onChange={handleIncluiNmUrna}
											size="small"
											inputProps={{ 'aria-labelledby': 'nm_urna-search-input' }}
										/>
									}
									label="nome urna"
								/>
							</Tooltip>

							<Tooltip title="Busca por ano específico">
								<FormControlLabel
									control={
										<Switch
											checked={buscaPorAno}
											onChange={handleBuscaPorAno}
											size="small"
											inputProps={{ 'aria-labelledby': 'busca-pro-ano-checked' }}
										/>
									}
									label="Busca ano"
								/>
							</Tooltip>
							<TextField
								label="ano"
								variant="standard"
								type="number"
								inputProps={{ min: 1998, max: 2022 }}
								value={anoPleito}
								onChange={e => {
									if (+e.target.value >= 1998 && +e.target.value <= 2022) {
										setAnoPleito(e.target.value);
									}
								}}
								disabled={!buscaPorAno}
							/>
						</Box>
						<div className="flex items-start mt-24 mb-24 w-full">
							<FormControl variant="standard" fullwidth className="w-1/2 pr-8">
								<InputLabel id="filtrar-cargo-label">Filtrar Cargo</InputLabel>
								<Select
									labelId="filtrar-cargo-label"
									id="filtrar-cargo"
									value={cargoSelecionado}
									onChange={handleSetCargo}
									label="Filtrar Cargo"
									variant="standard"
								>
									<MenuItem value="">
										<em>Sem Filtros</em>
									</MenuItem>
									{positions &&
										Object.values(positions).map(opt => (
											<MenuItem key={opt.cod_tse} value={opt.cod_tse}>
												{opt.position}
											</MenuItem>
										))}
								</Select>
							</FormControl>
							<FormControl variant="standard" fullwidth className="w-1/2">
								<InputLabel id="filtrar-partido-label">Filtrar Partido</InputLabel>
								<Select
									labelId="filtrar-partido-label"
									id="filtrar-partido"
									value={partidoSelecionado}
									onChange={handlePartidoSelecionado}
									label="Filtrar Partido"
								>
									<MenuItem value="">
										<em>Sem Filtros</em>
									</MenuItem>
									{positions &&
										Object.values(partidos).map(opt => (
											<MenuItem key={opt.nr} value={opt.sigla}>
												{opt.sigla}
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</div>
						<div className="flex items-start mt-24 mb-24 w-full">
							<FormControl variant="standard" className="w-2/6 pr-8">
								<InputLabel id="filtrar-uf-label">Filtrar UF</InputLabel>
								<Select
									labelId="filtrar-uf-label"
									id="filtrar-uf"
									value={ufSelecionada}
									onChange={handleUfSelecionada}
									label="Filtrar UF"
								>
									<MenuItem value="">
										<em>Sem Filtros</em>
									</MenuItem>
									{estados &&
										Object.values(estados).map(opt => (
											<MenuItem key={opt.uf} value={opt.uf}>
												{opt.uf}
											</MenuItem>
										))}
								</Select>
							</FormControl>
							<Autocomplete
								id="filtrar-municipio"
								value={municipioSelecionado || ''}
								options={listCities}
								disabled={!listCities}
								className="flex w-4/6"
								onChange={(event, newvalue) => {
									setMunicipioSelecionado(newvalue);
								}}
								renderInput={params => (
									<TextField className="w-4/6" {...params} variant="standard" label="Filtro Cidade" />
								)}
							/>
						</div>
						<Divider className="mt-12" />
						<div
							id="subheader"
							className="w-full h-full flex items-center justify-center text-center mt-20"
						>
							<Button endIcon={<Search />} variant="outlined" onClick={handleSetParams}>
								Buscar
							</Button>
						</div>
					</Paper>
				</div>
			</div>
		</Dialog>
	);
}

export default SearchCandidateByName;
