import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { authRoles } from '../../../auth';

const StakeholdersApp = lazy(() => import('./StakeholdersApp'));

const StakeholdersConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: 'stakeholders/:tp',
			element: <StakeholdersApp />
		},
		{
			path: 'stakeholders',
			element: <Navigate to="/stakeholders/all" />
		}
	]
};

export default StakeholdersConfig;
