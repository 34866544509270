import React from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import { bindActionCreators } from '@reduxjs/toolkit';
import axios from 'axios';
import FuseUtils from '@fuse/utils';
import { showMessage } from 'app/store/fuse/messageSlice';
import { getUpdatedContact } from 'app/fuse-layouts/shared-components/chatPanel/store/contactsSlice';
import config from '../../../services/firebaseService/firebaseServiceConfig';
import {
	addNotification,
	includeNotificationOnServer
} from '../../../fuse-layouts/shared-components/notificationPanel/store/dataSlice';
import { setLoaded, setToken, setTokenStatus, setTokenStatusOnServer, setWorker } from './store/firebasePushSlice';
import { getDuiId, noAuthBackendHeader, urlBackend, wsBackend } from '../../data/LocationContext';
// import UIfx from 'uifx'
// import notificationSound from '../sounds/notification-sound.wav';

const NotificationTypes = ['default', 'success', 'warning', 'info'];

const urlFillData = `${urlBackend}/api/notifications/`;

// const beep = new UIfx({asset: notificationSound});
// const audioElement = new Audio(notificationSound);

// const scopoNotifications = new WebSocket(`${wsBackend}notifications/`);

class ScopoFirebasePush extends React.Component {
	constructor(props) {
		super(props);
		const { usuario } = props;

		this.state = {
			userId: null,
			firstNotificationSent: false,
			messagingApp: null,
			lastNotification: null,
			tokenStatusOnServer: {
				isRegistered: false,
				isAssociatedWithUser: false
			}
		};

		this.enableWorker = this.enableWorker.bind(this);
		this.newTokenStatus = this.newTokenStatus.bind(this);
		this.newToken = this.newToken.bind(this);
		this.setLoaded = this.setLoaded.bind(this);
		this.addNewNotification = this.addNewNotification.bind(this);
		this.setMessagingApp = this.setMessagingApp.bind(this);
		this.setLastNotification = this.setLastNotification.bind(this);
		this.getTokenStatusOnServer = this.getTokenStatusOnServer.bind(this);
		this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
	}

	// for monitor-scopo ws direct-chat
	// enableScopoWsSocket = () => {
	//   const {usuario} = this.props;
	//   if(usuario.displayName){
	//     console.log('enabling directchat on Monitor Scopo')
	//     this.setState({
	//       ...this.state,
	//       scopoWsSocket: new WebSocket(
	//         `${wsBackend}dm_chat/${usuario.displayName}/`
	//       )
	//     })
	//   }
	// }

	componentDidMount() {
		window.addEventListener('beforeunload', this.handleBeforeUnload);

		function getApp() {
			if (!firebase.apps.length) {
				return firebase.initializeApp(config);
			}
			return firebase.app(); // if already initialized, use that one
		}
		const app = getApp();
		const messaging = app.messaging();

		// the userLoggedIn is assigned when the user is connected
		// in the loginSlice -> jwtService.js
		navigator.serviceWorker
			.register('firebase-messaging-sw.js')
			.then(registration => {
				// console.log('firebase-message-sw :ServiceWorker registration successful with scope: ', registration.scope);
				this.enableWorker(true);
				messaging
					.getToken()
					.then(token => {
						// console.log(token);
						this.newTokenStatus(true);
						this.newToken(token);
						this.setMessagingApp(messaging);
						this.setLoaded(true);
					})
					.catch(err => {
						this.newTokenStatus(false);
						this.newToken(false);
						this.setMessagingApp(null);
						this.setMessagingApp(null);
						this.setLoaded(false);
						console.log('error getting token:', err);
					});
			})
			.catch(err => {
				this.enableWorker(false);
				console.log('firebase-message-sw: ServiceWorker registration failed: ', err);
			});
	}

	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
		// const { scopoWsSocket } = this.state;
		// console.log(scopoWsSocket)

		if (this.props.usuario && this.props.usuario.id) {
			if (this.state.userId !== this.props.usuario.id) {
				this.setState({
					...this.state,
					userId: this.props.usuario.id
				});
			}
		}

		if (this.props.firebasePush !== prevProps.firebasePush) {
			// console.log(this.props.firebasePush);
			// console.log( prevProps.firebasePush);

			// console.log(prevState);
			// console.log(this.state);

			const fbp = this.props.firebasePush;

			if (fbp.userIsLoggedIn) {
				// console.log('SECOND Check on server')
				this.getTokenStatusOnServer(fbp.token);
			} else if (fbp.asToken && fbp.asWorker && fbp.browserIsCompatible && fbp.isAuthorized && fbp.isLoaded) {
				// console.log('FIRST Check on server');
				this.getTokenStatusOnServer(fbp.token);
			}
		}
	}

	componentWillUnmount() {
		console.log('WILLL UNMOUNT!!!');
		window.removeEventListener('beforeunload', this.handleBeforeUnload);
		// todo: Remove the token on backend
		// this.setLoaded(false);
		// this.dissociateTokenUsr();


		// Cleanup the messaging app
		if (!this.props.usuario.id){
			if (
				this.state.messagingApp &&
				this.props.login.success &&
				!this.props.login.loading &&
				!this.props.locked
			) {
				console.log('Cleanup the messaging app');
				this.state.messagingApp.onMessage(null);
			}
		}
	}

	enableWorker = newWorkerStatus => {
		// eslint-disable-next-line no-shadow
		const { setWorker } = this.props;
		setWorker(newWorkerStatus);
	};

	newTokenStatus = status => {
		// eslint-disable-next-line no-shadow
		const { setTokenStatus } = this.props;
		console.log(status);
		setTokenStatus(status);
	};

	newToken = token => {
		// eslint-disable-next-line no-shadow
		const { setToken } = this.props;
		setToken(token);
	};

	setLoaded = status => {
		// eslint-disable-next-line no-shadow
		const { setLoaded } = this.props;
		setLoaded(status);
	};

	getTokenStatusOnServer = token => {
		console.log(token);
		const { setTokenStatusOnServer, showMessage } = this.props;
		const urlCheckToken = `${urlFillData}check_token/`;

		const body = JSON.stringify({
			token,
			uid: getDuiId()
		});
		axios
			.post(urlCheckToken, body, noAuthBackendHeader)
			.then(response => {
				// console.log(response);
				return dispatch => {
					this.setState({ ...this.state, tokenStatusOnServer: response.data });
					dispatch(setTokenStatusOnServer(response.data));
				};
			})
			.catch(error => {
				console.log('deu merda!!', error);
				// return dispatch => {
				//   dispatch(setTokenStatusOnServer(initialState.tokenStatusOnServer))
				// }

				// return showMessage({
				//     message: error,
				//     autoHideDuration: 7000,
				//     anchorOrigin: {
				//         vertical  : 'top',//top bottom
				//         horizontal: 'center'//left center right
				//     },
				//     variant: 'error'//success error info warning null
				// })
			});
	};

	addNewNotification = (data, id) => {
		console.log(data);
		// eslint-disable-next-line no-shadow
		const { addNotification, includeNotificationOnServer } = this.props;
		const { body, title, image, url } = data;
		let titulo = title;
		switch (title) {
			case 'update':
				titulo = 'info';
				break;
			default:
				titulo = title;
		}
		const variant = NotificationTypes.includes(titulo) ? titulo : 'default';

		// eslint-disable-next-line no-shadow
		const checkId = id => {
			if (id) {
				return id;
			}
			return FuseUtils.generateGUID();
		};

		const msgTemplate = {
			id: checkId(id),
			message: body,
			options: { variant, url }
		};
		// addNotification(NotificationModel(msgTemplate));
		addNotification(msgTemplate);
		// includeNotificationOnServer(msgTemplate);
	};

	setMessagingApp = messagingApp => {
		this.setState({ messagingApp });
	};

	setLastNotification = notification => {
		this.setState({ lastNotification: notification });
	};

	// funcao para  deslogar o usuario se fechar o browser
	handleBeforeUnload = () => {
		if (this.state.messagingApp) {
			this.state.messagingApp.onMessage(null);
		}
	};

	render() {
		if (this.state.messagingApp) {
			console.log('MessagingApp Loaded');
			const onMessageListener = () =>
				new Promise(resolve => {
					this.state.messagingApp.onMessage(payload => {
						resolve(payload);
					});
				});

			onMessageListener()
				.then(payload => {
					// # Notify the user about the invitation on backend - empresa.viewsets 1112
					console.log('FOREGROUND MESSAGE');
					console.log(payload);

					if ('notification' in payload) {
						console.log('Handle notication!');
						// todo: add an image if exists in notification
						this.setLastNotification(payload.notification);
						this.addNewNotification(payload.notification, payload.messageId ? payload.messageId : null);
					} else {
						console.log('Handle message notication!');
						this.setLastNotification(payload.data);
						this.addNewNotification(payload.data, payload.fcmMessageId ? payload.fcmMessageId : null);
					}
				})
				.catch(err => console.log('erro no listener: ', err));
		}

		return <></>;
	}
}

const mapStateToProps = state => {
	return {
		firebasePush: state.firebasePush,
		usuario: state.auth.user.data,
		userContactListIds: state.chatPanel.contacts.ids,
		selectedContactId: state.chatPanel.contacts.selectedContactId,
		login: state.auth.login,
		locked: state.fuse.environment.locked
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			setWorker,
			setTokenStatus,
			setLoaded,
			setToken,
			addNotification,
			includeNotificationOnServer,
			setTokenStatusOnServer,
			showMessage,
			getUpdatedContact
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(ScopoFirebasePush);
