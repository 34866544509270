import { combineReducers } from '@reduxjs/toolkit';
import action_range from './actionRangeComparativoSlice';
import concorrente from './concorrenteSlice/concorrenteSlice';
import partidos from './partidosComparacaoSlice/partidosComparacaoSlice';
import votacao_selecionado from './partidosComparacaoSlice/votacaoSelecionadoSlice';
import fonts_media from './fontsMediaLocationSlice';

const analiseComparativaReducers = combineReducers({
	action_range,
	concorrente,
	partidos,
	votacao_selecionado,
	fonts_media
});

export default analiseComparativaReducers;
