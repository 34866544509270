import { createSlice } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import firebaseService from 'app/services/firebaseService';
import jwtService from 'app/services/jwtService';
import { setUserIsLoggedIn } from 'app/main/components/pushNotifications/store/firebasePushSlice';
import { setUserData } from './userSlice';
import { setQuickPanelData } from '../../fuse-layouts/shared-components/quickPanel/store/dataSlice';

export const submitLogin =
	({ username, password, ipInfo }) =>
	async dispatch => {
		dispatch(loginLoading(true));
		return jwtService
			.signInWithUsernameAndPassword(username, password, ipInfo)
			.then(user => {
				let userData = user;
				console.log('userData');
				console.log(userData);
				console.log('userData');
				console.log('userData');
				const quickPanelData = { ...userData.quick_panel };
				delete userData.quick_panel;
				dispatch(setUserData(userData));
				dispatch(setUserIsLoggedIn(true));
				dispatch(setQuickPanelData(quickPanelData));
				return dispatch(loginSuccess());
			})
			.catch(error => {
				const error_msg = error === 'Incorrect Credentials' ? 'Credenciais Incorretas' : error;
				dispatch(
					showMessage({
						message: error_msg,
						autoHideDuration: 7000,
						anchorOrigin: {
							vertical: 'top', // top bottom
							horizontal: 'center' // left center right
						},
						variant: 'error' // success error info warning null
					})
				);
				return dispatch(loginError(error));
			});
	};

export const submitLoginWithFireBase =
	({ email, password }) =>
	async dispatch => {
		if (!firebaseService.auth) {
			console.warn("Firebase Service didn't initialize, check your configuration");

			return () => false;
		}
		return firebaseService.auth
			.signInWithEmailAndPassword(email, password)
			.then(() => {
				return dispatch(loginSuccess());
			})
			.catch(error => {
				const emailErrorCodes = [
					'auth/email-already-in-use',
					'auth/invalid-email',
					'auth/operation-not-allowed',
					'auth/user-not-found',
					'auth/user-disabled'
				];
				const passwordErrorCodes = ['auth/weak-password', 'auth/wrong-password'];
				const response = [];

				if (emailErrorCodes.includes(error.code)) {
					response.push({
						type: 'email',
						message: error.message
					});
				}

				if (passwordErrorCodes.includes(error.code)) {
					response.push({
						type: 'password',
						message: error.message
					});
				}

				if (error.code === 'auth/invalid-api-key') {
					dispatch(showMessage({ message: error.message }));
				}

				return dispatch(loginError(response));
			});
	};

const initialState = {
	success: false,
	errors: [],
	loading: false
};

const loginSlice = createSlice({
	name: 'auth/login',
	initialState,
	reducers: {
		loginSuccess: (state, action) => {
			state.success = true;
			state.errors = [];
			state.loading = false;
		},
		loginError: (state, action) => {
			state.success = false;
			state.errors = action.payload;
			state.loading = false;
		},
		loginLoading: (state, action) => {
			state.loading = action.payload;
		},
		loggedOut: (state, action) => initialState
	},
	extraReducers: {}
});

export const { loginSuccess, loginError, loginLoading, loggedOut } = loginSlice.actions;

export default loginSlice.reducer;
