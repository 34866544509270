import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { urlBackend } from 'app/main/data/LocationContext';

const urlFillData = `${urlBackend}/api/notifications/`;

export const getAccessToken = () => {
	return window.localStorage.getItem('jwt_access_token');
};

export const tokenHeaders = () => {
	const token = getAccessToken();
	// Headers
	let config = {
		headers: {
			'Content-Type': 'application/json'
		}
	};
	if (token) {
		config.headers['Authorization'] = `Token ${token}`;
	}
	return config;
};

export const getNotificationsOld = createAsyncThunk('notificationPanel/data/getData', async () => {
	const response = await axios.get('/api/notification-panel/data');
	const data = await response.data;
	return data;
});

export const getNotifications = createAsyncThunk('notificationPanel/data/getData', async () => {
	const response = await axios.get(urlFillData, tokenHeaders());
	const data = await response.data;
	console.log(data);

	// let newPushMsgsOld = [];
	// data.forEach((item,index,arr)=>(newPushMsgsOld.push({id: item['notification_id'],
	//   message: item['notification'],
	//   options: {variant: item['tipo'], url_action: item['url_action'], url:item['url']}})))

	const newPushMsgs = data
		.filter(element => {
			return element !== null;
		})
		.map(i =>
			i.read
				? null
				: {
						id: i.id,
						message: i.notification,
						options: { variant: i.tipo, url_action: i.url_action, url: i.url }
				  }
		);

	console.log(newPushMsgs);

	return newPushMsgs.filter(el => el !== null);
});

export const includeNotificationOnServer = notification => {
	const urlIncludeNotification = `${urlFillData}include_push_notification/`;
	const body = JSON.stringify(notification);
	// console.log(urlIncludeNotification);
	axios.post(urlIncludeNotification, body, tokenHeaders()).then(response => console.log(response));
};

export const markPushNotificationAsRead = notification_id => {
	const urlMarkAsRead = `${urlFillData}mark_msg_push_asread/`;
	const body = JSON.stringify({ id: notification_id });
	axios.post(urlMarkAsRead, body, tokenHeaders()).then(response => console.log(response));
};

export const markAllPushNotAsRead = listIds => {
	const urlMarkAllAsRead = `${urlFillData}mark_list_msgpush_asread/`;
	const body = JSON.stringify({ ids: listIds });
	axios.post(urlMarkAllAsRead, body, tokenHeaders()).then(response => console.log(response));
};

const notificationsAdapter = createEntityAdapter({});

const initialState = notificationsAdapter.upsertMany(notificationsAdapter.getInitialState(), []);

export const { selectAll: selectNotifications, selectById: selectNotificationsById } =
	notificationsAdapter.getSelectors(state => state.notificationPanel.data);

const dataSlice = createSlice({
	name: 'notificationPanel/data',
	initialState,
	reducers: {
		dismissItem: (state, action) => notificationsAdapter.removeOne(state, action.payload),
		dismissAll: (state, action) => notificationsAdapter.removeAll(state),
		addNotification: (state, action) => notificationsAdapter.addOne(state, action.payload)
	},
	extraReducers: {
		[getNotifications.fulfilled]: (state, action) => notificationsAdapter.addMany(state, action.payload)
	}
});

export const { dismissItem, dismissAll, addNotification } = dataSlice.actions;

export default dataSlice.reducer;
