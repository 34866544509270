import { lazy } from 'react';

const MaintenancePage = lazy(() => import('./MaintenancePage'));

const MaintenancePageConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: 'maintenance',
			element: <MaintenancePage />
		}
	]
};

export default MaintenancePageConfig;
