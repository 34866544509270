import React, { useLayoutEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import axios from 'axios';
import { XRapidAPI_Key } from '../data/LocationContext';

const urlBuscaCepHost = 'viacep.p.rapidapi.com';

export const ScopoLoader = props => (
	<ContentLoader
		speed={3}
		width={190}
		height={190}
		viewBox="0 0 190 190"
		backgroundColor="#252e3e"
		foregroundColor="#ff4800"
		{...props}
	>
		<path d="M 89.6 14.6 C 78.8 16.7 70.8 21 62.8 29 c -3.9 3.8 -7.4 7 -7.8 7 c -0.5 0 -1.9 2.6 -3.2 5.7 c -1.9 5 -2.3 7.6 -2.3 18.3 v 12.5 l 4.2 8.6 c 6.9 14 18.7 23 34.8 26.4 c 9.3 1.9 12.7 1.9 21.9 -0.5 c 8.8 -2.2 17.6 -6.6 23.6 -11.7 c 4 -3.4 4.4 -5.3 1.2 -5.3 c -0.9 0 -3.4 -1.8 -5.7 -3.9 l -4 -4 l -5.5 3.5 c -13.8 8.7 -29 8.4 -39.6 -0.9 c -4.6 -4.1 -6.5 -6.8 -7.1 -10.2 c -0.1 -1.1 -1 -4.9 -1.8 -8.5 c -5.7 -25 18.6 -44.6 41.6 -33.6 c 3 1.4 6.9 3.9 8.7 5.6 c 1.7 1.7 3.6 2.9 4.1 2.6 c 1.5 -1 11.6 -13.8 11.4 -14.4 c -0.2 -0.4 -1.1 -0.7 -2.1 -0.8 c -1 -0.1 -3.7 -1.3 -6.1 -2.8 c -6.3 -4 -12.6 -6.5 -20.4 -8.2 c -8.1 -1.7 -10 -1.6 -19.1 0.2 z" />
		<path d="M 89.5 36.4 c -14.9 6.9 -19.7 24.8 -10.3 38.4 c 3.6 5.3 4.5 5 1.8 -0.6 c -4.8 -9.8 -3.1 -20.2 4.4 -27.6 c 9 -9 19.3 -10.7 30.6 -4.8 c 3.5 1.8 6.5 3 6.7 2.8 c 0.2 -0.2 -1.5 -2 -3.8 -3.9 c -8 -6.9 -20.1 -8.7 -29.4 -4.3 z M 54.9 111.2 c -4 5.7 -9.3 12.8 -11.9 15.8 c -6.7 7.9 -8.6 10.9 -10.9 16.9 c -1.8 4.8 -1.9 5.7 -0.6 8.1 c 1.6 3 3.3 4 7.2 4 c 5.5 0 10.6 -5.9 24.8 -29.3 c 2.6 -4.4 6.3 -9.9 8.2 -12.4 c 1.9 -2.5 3.3 -4.6 3.1 -4.7 c -0.2 -0.1 -2.5 -1.8 -5.3 -3.8 c -2.7 -1.9 -5.5 -3.8 -6.2 -4.1 c -0.7 -0.4 -3.9 3.3 -8.4 9.5 z" />
	</ContentLoader>
);

export function useWindowSize() {
	const [size, setSize] = useState([0, 0]);
	useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);
	return size;
}

export function validateEmail(email) {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export async function handleCEP(cep_str) {
	const cleanCep = cep_str.replace(/\D/g, '');
	const options = {
		method: 'GET',
		url: `https://${urlBuscaCepHost}/${cleanCep}/json`,
		headers: {
			'X-RapidAPI-Host': urlBuscaCepHost,
			'X-RapidAPI-Key': XRapidAPI_Key
		}
	};

	if (cleanCep.length !== 8) {
		return false;
	}

	const response = await axios.request(options);

	if (response.status === 200) {
		return response.data;
	}
	console.log(response.status);
	return false;
}
