import { styled } from '@mui/material/styles';
import React, { memo, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useRoutes, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
// import { LicenseInfo } from '@mui/x-license-pro';
import FuseDialog from '@fuse/core/FuseDialog';
import FuseMessage from '@fuse/core/FuseMessage';
import FuseSuspense from '@fuse/core/FuseSuspense';
import AppContext from 'app/AppContext';
import SettingsPanel from 'app/fuse-layouts/shared-components/SettingsPanel';
import FooterLayout1 from './components/FooterLayout1';
import FooterScopo from './components/FooterScopo';
import LeftSideLayout1 from './components/LeftSideLayout1';
import NavbarWrapperLayout1 from './components/NavbarWrapperLayout1';
import RightSideLayout1 from './components/RightSideLayout1';
import ToolbarLayout1 from './components/ToolbarLayout1';
import SearchCandidateByName from '../../shared-components/dialogs/political/SearchCandidateByName';
import ConcorrenteInfoDialog from '../../shared-components/dialogs/political/ConcorrenteInfoDialog';
import SocialNetworksControl from '../../main/social-networks/SocialNetworksControl';
import { logoutUser } from '../../auth/store/userSlice';
import { defaultTimeToDisplayAlerts, activateFusionChartsLicense } from '../../main/data/LocationContext';
import { setLastPath, setOfflineMode } from '../../store/fuse/environmentSlice';
import { updateNavigationItem } from '../../store/fuse/navigationSlice';
import { getNewsSources } from '../../main/store/news/newsSourcesSlice';
import MuiXLicense from '../../main/data/MuiXLicense';
// import useScopoNotifications from '../../main/components/useScopoNotifications';

const Root = styled('div')(({ theme, config }) => ({
	...(config.mode === 'boxed' && {
		clipPath: 'inset(0)',
		maxWidth: `${config.containerWidth}px`,
		margin: '0 auto',
		boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
	}),
	...(config.mode === 'container' && {
		'& .container': {
			maxWidth: `${config.containerWidth}px`,
			width: '100%',
			margin: '0 auto'
		}
	})
}));

function Layout1(props) {
	// FusionChartsLicense
	activateFusionChartsLicense();

	// @mui/x-license-pro license
	// rendered in the <MuiXLicense /> component

	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const smMonitorValue = useSelector(({ fuse }) => fuse.backendStatus['sm-monitor']);
	const appContext = useContext(AppContext);
	const location = useLocation();
	const { routes } = appContext;
	const dispatch = useDispatch();
	const { locked, offlineMode } = useSelector(({ fuse }) => fuse.environment);
	const { user } = useSelector(({ auth }) => auth);
	const operators_ids = useSelector(({ entidade }) => entidade.operators.ids);
	const trendingNewsData = useSelector(({ news }) => news.trendingNews.data);
	const clippingNewsIds = useSelector(({ news }) => news.clipping.ids);

	const team_members_limit = useSelector(
		({ entidade }) => entidade.indicators.monitor_social?.data?.team_members_limit
	);
	const [teamMembersNr, setTeamMembersNr] = useState(0);

	let inactivityTimeout = null;
	const navigate = useNavigate();

	// const { socketNotifications } = useScopoNotifications()

	const timeToRedirect = defaultTimeToDisplayAlerts + 500;

	const blockAccess = () => {
		console.log('Tá vivo?');

		if (
			location &&
			location.pathname &&
			(location.pathname === '/login' || location.pathname !== '/lock' || location.pathname !== '/maintenance')
		) {
			clearTimeout(inactivityTimeout);
		}

		if (location && location.pathname && location.pathname !== '/lock' && location.pathname !== '/maintenance') {
			dispatch(setLastPath(location.pathname));
			navigate('/lock');
		}
	};

	// Set up the inactivity timeout to start counting from the time page loads

	// todo: reenable and verify lockup function

	// Reset the inactivity timeout every time the user interacts with the page
	// window.addEventListener('mousemove', setUpInactivityTimeout);
	// window.addEventListener('keydown', setUpInactivityTimeout);

	function setUpInactivityTimeout() {
		// Clear any existing timers
		if (inactivityTimeout) {
			clearTimeout(inactivityTimeout);
		}

		if (!locked && user.data.id) {
			// Set a new timer that will log the user off after 10 minutes
			inactivityTimeout = setTimeout(blockAccess, 10 * 60 * 1000); // 10 minutes in milliseconds
		}
	}

	useEffect(() => {
		// setUpInactivityTimeout();
		const offLineCookie = Cookies.get('ScopoOfflineMode');
		if (offLineCookie) {
			// the value of offLineCookie is true or false
			dispatch(setOfflineMode(true));
		} else {
			dispatch(setOfflineMode(false));
		}

		dispatch(getNewsSources());

		return () => {
			// Clean up event listeners to avoid multiple listeners being attached
			// window.removeEventListener('mousemove', setUpInactivityTimeout);
			// window.removeEventListener('keydown', setUpInactivityTimeout);
		};
	}, []);

	useEffect(() => {
		if (user.data.id && !user.data.firstName) {
			navigate('/account/settings/me');
		}
	}, [user.data]);

	useEffect(() => {
		if (smMonitorValue) {
			console.log('ONLINE');
			console.log('Current path:', location.pathname);
			if (location.pathname === '/maintenance' && location.pathname === '/login') {
				navigate(-1);
			}
		} else {
			console.log('OFFLINE!!!!');
			console.log('Current path:', location.pathname);
			if (location.pathname !== '/maintenance' && location.pathname !== '/lock') {
				setTimeout(() => {
					navigate('/maintenance');
				}, timeToRedirect);
			}
		}
	}, [smMonitorValue]);

	useEffect(() => {
		let badgeColor = '#7a7a79';
		if (operators_ids.length !== teamMembersNr || team_members_limit) {
			if (operators_ids.length === 0) {
				badgeColor = '#7a7a79';
			} else if (team_members_limit) {
				// limite de 2 terços
				const alertLimit1 = team_members_limit / 2;
				const alertLimit2 = parseInt((team_members_limit / 3) * 2, 10);

				if (operators_ids.length <= alertLimit1) {
					badgeColor = '#044f01';
				} else if (operators_ids.length > alertLimit2 && operators_ids.length <= team_members_limit) {
					badgeColor = '#e35c00';
				} else {
					badgeColor = '#7a1313';
				}
			} else {
				badgeColor = '#044f01';
			}
			setTeamMembersNr(operators_ids.length);
			dispatch(
				updateNavigationItem('equipe-entidade', {
					id: 'equipe-entidade',
					title: 'Equipe',
					type: 'item',
					url: 'entidade/equipe',
					icon: 'contacts',
					badge: {
						title: operators_ids.length,
						bg: badgeColor,
						fg: '#FFFFFF'
					}
				})
			);
		}
	}, [operators_ids, team_members_limit]);

	useEffect(() => {
		const nrTrendings = trendingNewsData ? trendingNewsData.length : 0;
		const nrClippings = clippingNewsIds ? clippingNewsIds.length : 0;

		const sumNrNoticias = nrTrendings + nrClippings;

		const badgeNewsColor = () => {
			if (sumNrNoticias === 0 || offlineMode) {
				return '#7a7a79';
			}
			if (sumNrNoticias === 0 && !offlineMode) {
				return '#e35c00';
			}
			return '#044f01';
		};

		const badgeNewsIcon = () => {
			if (offlineMode) {
				return 'cloud_off';
			}
			return 'rss_feed';
		};

		dispatch(
			updateNavigationItem('news-clipping', {
				id: 'news-clipping',
				title: 'Notícias',
				type: 'item',
				icon: badgeNewsIcon(),
				url: '/app/clipping',
				badge: {
					title: `${sumNrNoticias}`,
					bg: badgeNewsColor(),
					fg: '#FFFFFF'
				}
			})
		);
	}, [trendingNewsData, clippingNewsIds, offlineMode]);

	return (
		<Root id="fuse-layout" config={config} className="w-full flex">
			<MuiXLicense />
			{config.leftSidePanel.display && <LeftSideLayout1 />}

			<div className="flex flex-auto min-w-0">
				{config.navbar.display && config.navbar.position === 'left' && <NavbarWrapperLayout1 />}

				<main id="fuse-main" className="flex flex-col flex-auto min-h-screen min-w-0 relative z-10">
					{config.toolbar.display && (
						<ToolbarLayout1 className={config.toolbar.style === 'fixed' && 'sticky top-0'} />
					)}

					{/* <div className="sticky top-0 z-99"> */}
					{/*  <SettingsPanel /> */}
					{/* </div> */}

					<div className="flex flex-col flex-auto min-h-0 relative z-10">
						<FuseDialog />

						<FuseSuspense>{useRoutes(routes)}</FuseSuspense>

						{props.children}
					</div>

					{config.footer.display && (
						// <FooterLayout1 className={config.footer.style === 'fixed' && 'sticky bottom-0'} />
						<FooterScopo className={config.footer.style === 'static' && 'sticky bottom-0'} />
					)}
				</main>

				{config.navbar.display && config.navbar.position === 'right' && <NavbarWrapperLayout1 />}
			</div>

			{config.rightSidePanel.display && <RightSideLayout1 />}
			<FuseMessage />
			<SearchCandidateByName />
			<ConcorrenteInfoDialog />
			<SocialNetworksControl />
		</Root>
	);
}

export default memo(Layout1);
