// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*----------------------------------------------------------------*/
/*  Print
/*----------------------------------------------------------------*/
@media all {
    /* Never show page breaks in normal view */
    .page-break-after,
    .page-break-before {
        display: none;
    }
}

@media print {
    /* html and body tweaks */
    html, body {
        height: auto !important;
        overflow: initial !important;
        background: none
    }

    /* Page breaks */
    .page-break-after {
        display: block;
        page-break-after: always;
        position: relative;
    }

    .page-break-before {
        display: block;
        page-break-before: always;
        position: relative;
    }

    /* General styles */
    #fuse-toolbar,
    #fuse-footer,
    #fuse-navbar,
    #fuse-settings-presets,
    #fuse-layout .ps > .ps__rail-x,
    #fuse-layout .ps > .ps__rail-y {
        display: none !important;
    }

    #fuse-layout .ps {
        overflow: visible !important;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/print.css"],"names":[],"mappings":"AAAA,mEAAmE;AACnE;mEACmE;AACnE;IACI,0CAA0C;IAC1C;;QAEI,aAAa;IACjB;AACJ;;AAEA;IACI,yBAAyB;IACzB;QACI,uBAAuB;QACvB,4BAA4B;QAC5B;IACJ;;IAEA,gBAAgB;IAChB;QACI,cAAc;QACd,wBAAwB;QACxB,kBAAkB;IACtB;;IAEA;QACI,cAAc;QACd,yBAAyB;QACzB,kBAAkB;IACtB;;IAEA,mBAAmB;IACnB;;;;;;QAMI,wBAAwB;IAC5B;;IAEA;QACI,4BAA4B;IAChC;AACJ","sourcesContent":["/*----------------------------------------------------------------*/\n/*  Print\n/*----------------------------------------------------------------*/\n@media all {\n    /* Never show page breaks in normal view */\n    .page-break-after,\n    .page-break-before {\n        display: none;\n    }\n}\n\n@media print {\n    /* html and body tweaks */\n    html, body {\n        height: auto !important;\n        overflow: initial !important;\n        background: none\n    }\n\n    /* Page breaks */\n    .page-break-after {\n        display: block;\n        page-break-after: always;\n        position: relative;\n    }\n\n    .page-break-before {\n        display: block;\n        page-break-before: always;\n        position: relative;\n    }\n\n    /* General styles */\n    #fuse-toolbar,\n    #fuse-footer,\n    #fuse-navbar,\n    #fuse-settings-presets,\n    #fuse-layout .ps > .ps__rail-x,\n    #fuse-layout .ps > .ps__rail-y {\n        display: none !important;\n    }\n\n    #fuse-layout .ps {\n        overflow: visible !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
