import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { tokenHeaders, urlBackend } from '../../data/LocationContext';

const newsSourcesAdapter = createEntityAdapter({});

export const getNewsSources = createAsyncThunk('news/sources/getNewsSources', async dispatch => {
	const response = await axios.get(`${urlBackend}/api/news/`);
	const { data } = await response;
	return JSON.parse(data);
});

export const getRequestedNewsSources = createAsyncThunk(
	'news/sources/getRequestedNewsSources',
	async (dispatch, getState) => {
		const { data } = await axios.get(`${urlBackend}/api/news/get_all_requested_news_sources/`);
		return data;
	}
);

export const excludeRequestedNewsSource = createAsyncThunk(
	'news/sources/exclude_requested_news_source',
	async (request_id, { dispatch, getState }) => {
		const { requested } = getState().news.newsSources;
		const response = await axios.get(
			`${urlBackend}/api/news/exclude_requested_news_source/?request_id=${request_id}`,
			tokenHeaders()
		);

		// as ids somam 1000, para possibilitar inclusão conjunta na gestão de
		// fontes nas entidades (gambi)
		const updatedId = parseInt(request_id, 10) + 1000;

		dispatch(setRequestedSources(requested.filter(i => i.id !== updatedId)));
	}
);

export const {
	selectAll: selectNewsSource,
	removeAll: removeNewsSource,
	selectById: selectNewsSourceById
} = newsSourcesAdapter.getSelectors(state => state.news.sources);

const initialRequestedFields = {
	icon_src: 'https://ecom-scopo.sfo2.digitaloceanspaces.com/ecom-scopo/images/newspaper_transparent.png',
	nome: '',
	url: '',
	country: 'Brasil',
	state: '',
	city: ''
};

const initialState = {
	loading: false,
	loadingAll: false,
	requested: [],
	searchText: '',
	requestedFields: initialRequestedFields
};

const newsSourcesSlice = createSlice({
	name: 'news/sources',
	initialState: newsSourcesAdapter.getInitialState(initialState),
	reducers: {
		setNewsSources: (state, action) => {
			newsSourcesAdapter.setAll(state, action.payload);
		},
		setRequestedSources: (state, action) => {
			state.requested = action.payload;
		},
		setNewsSourcesSearchText: {
			reducer: (state, action) => {
				state.searchText = action.payload;
			},
			prepare: event => ({ payload: event.target.value || '' })
		},
		setNewsSourcesLoading: (state, action) => {
			state.loading = action.payload;
		},
		setNewsSourcesLoadingAll: (state, action) => {
			state.loadingAll = action.payload;
		},
		resetNewsSources: state => {
			newsSourcesAdapter.removeAll(state);
			newsSourcesAdapter.getInitialState(initialState);
		},
		setRequestedFields: (state, action) => {
			state.requestedFields = action.payload;
		},
		resetRequestedFields: state => {
			state.requestedFields = initialRequestedFields;
		}
	},
	extraReducers: {
		[getNewsSources.pending]: (state, action) => {
			state.loading = true;
		},
		[getNewsSources.fulfilled]: (state, action) => {
			state.loading = false;
			newsSourcesAdapter.setAll(state, action.payload);
		},
		[getNewsSources.rejected]: (state, action) => {
			newsSourcesAdapter.removeAll(state);
			newsSourcesAdapter.getInitialState(initialState);
		},
		[getRequestedNewsSources.pending]: (state, action) => {
			state.loading = true;
		},
		[getRequestedNewsSources.rejected]: (state, action) => {
			state.loading = false;
			state.requested = [];
		},
		[getRequestedNewsSources.fulfilled]: (state, action) => {
			state.loading = false;
			state.requested = action.payload;
		}
	}
});

export const {
	setNewsSources,
	setNewsSourcesSearchText,
	resetNewsSources,
	setRequestedSources,
	setNewsSourcesLoading,
	setNewsSourcesLoadingAll,
	setRequestedFields,
	resetRequestedFields
} = newsSourcesSlice.actions;

export default newsSourcesSlice.reducer;
