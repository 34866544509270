import { authRoles } from 'app/auth';

const NeurobotsNavigation = {
	id: 'Neurobots',
	title: 'Neurobots',
	type: 'collapse',
	auth: authRoles.strategy,
	icon: 'adb',
	url: '/neurobots',
	children: [
		{
			id: 'neurobots-panel',
			title: 'Painel',
			type: 'collapse',
			auth: authRoles.strategy,
			icon: 'desktop_mac',
			children: [
				{
					id: 'neurobots-actions',
					title: 'Ações',
					type: 'item',
					auth: authRoles.strategy,
					icon: 'psychology',
					url: '/neurobots/actions'
				},
				{
					id: 'neurobots-ajustes',
					title: 'Ajustes',
					type: 'item',
					auth: authRoles.strategy,
					icon: 'settings',
					url: '/neurobots/settings'
				},
				{
					id: 'neurobots-attrib',
					title: 'Atribuir',
					type: 'item',
					auth: authRoles.strategy,
					icon: 'folder_shared',
					url: '/neurobots/attrib'
				},
				{
					id: 'neurobots-interactions',
					title: 'Interações',
					type: 'item',
					auth: authRoles.strategy,
					icon: 'device_hub',
					url: '/neurobots/view'
				},
				{
					id: 'neurobots-training',
					title: 'Treinar',
					type: 'item',
					auth: authRoles.strategy,
					icon: 'model_training',
					url: '/neurobots/training'
				},
				{
					id: 'neurobots-view',
					title: 'Visualizar',
					type: 'item',
					auth: authRoles.strategy,
					icon: 'preview',
					url: '/neurobots/view'
				}
			]
		},
		{
			id: 'neurobots-reports',
			title: 'Relatórios',
			type: 'item',
			icon: 'recent_actors',
			auth: authRoles.strategy,
			url: '/neurobots/reports'
		}
	]
};

export default NeurobotsNavigation;
