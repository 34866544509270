import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { updateUserChatList } from 'app/auth/store/userSlice';
import { tokenHeaders, urlBackend } from 'app/main/data/LocationContext';
import { setSelectedContactId } from './contactsSlice';
import { closeChatPanel } from './stateSlice';

const pathChat = '/api/chat/dm/';
const url_chat = `${urlBackend}${pathChat}`;

// export const getChatOld = createAsyncThunk(
//   'chatPanel/chat/getChat',
//   async ({ contactId }, { dispatch, getState }) => {
//     // const { id: userId } = getState().chatPanel.user;
//     const { id: userId } = getState().auth.user.data;
//     const response = await axios.get('/api/chat/get-chat', {
//       params: {
//         contactId,
//         userId,
//       },
//     });
//     const { chat, userChatList } = await response.data;
//     dispatch(setSelectedContactId(contactId));
//     dispatch(updateUserChatList(userChatList));
//     return chat;
//   }
// );
//
// export const sendMessageOld = createAsyncThunk(
//   'chatPanel/chat/sendMessage',
//   async ({ messageText, chatId, contactId }, { dispatch, getState }) => {
//     const response = await axios.post('/api/chat/send-message', { chatId, messageText, contactId });
//
//     const { message, userChatList } = await response.data;
//
//     dispatch(updateUserChatList(userChatList));
//
//     return message;
//   }
// );

export const getChat = createAsyncThunk('chatPanel/chat/getChat', async ({ contactId }, { dispatch, getState }) => {
	const data = { receiver: contactId };
	const response = await axios.post(url_chat, data, tokenHeaders());
	const { chat, userChatList } = await response.data;
	console.log(chat);
	console.log(userChatList);
	dispatch(setSelectedContactId(contactId));
	dispatch(updateUserChatList(userChatList));
	return chat;
});

export const justGetUserChatList = () => {
	return dispatch => {
		axios
			.get(url_chat, tokenHeaders())
			.then(response => {
				console.log(response.data);
				dispatch(updateUserChatList(response.data.userChatList));
			})
			.catch(err => console.log(err));
	};
};

export const sendMessage = createAsyncThunk(
	'chatPanel/chat/sendMessage',
	async ({ messageText, chatId, contactId }, { dispatch, getState }) => {
		const urlSendMsg = `${urlBackend}${pathChat}${chatId}/`;
		const data = {
			messageText,
			contactId
		};
		const response = await axios.patch(urlSendMsg, data, tokenHeaders());

		const { message, userChatList } = await response.data;

		dispatch(updateUserChatList(userChatList));

		return message;
	}
);

const chatSlice = createSlice({
	name: 'chatPanel/chat',
	initialState: null,
	reducers: {
		removeChat: (state, action) => null,
		includeMessageOnChat: (state, action) => {
			if (state) {
				state.dialog = [...state.dialog, action.payload];
			}
		},
		setActiveChat: (state, action) => action.payload
	},
	extraReducers: {
		[getChat.fulfilled]: (state, action) => action.payload,
		[sendMessage.fulfilled]: (state, action) => {
			state.dialog = [...state.dialog, action.payload];
		},
		[closeChatPanel]: (state, action) => null
	}
});

export const { removeChat, includeMessageOnChat, setActiveChat } = chatSlice.actions;

export default chatSlice.reducer;
