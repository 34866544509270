import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { authRoles } from '../../../auth';

const AccountSettings = lazy(() => import('./AccountSettings'));

const AccountSettingsConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/account/settings/:id',
			element: <AccountSettings />
		},
		{
			path: '/account/settings',
			element: <Navigate to="/account/settings/me" />
		}
	]
};

export default AccountSettingsConfig;
