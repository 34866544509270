import {GET_UFS, UPDATE_UF, GET_CITIES, CLEAR_CITIES, UPDATE_CITY, GET_WEATHER} from "../actions/actionTypes";

export const localInitialState = {
    ufs: null,
    cities: null,
    selectedUf: null,
    selectedCity: null,
    weather: null
}

export default function (state=localInitialState, action) {
    switch (action.type){
        case GET_UFS:
            return{
                ...state,
                ufs: action.payload
            }
        case UPDATE_UF:
            return{
                ...state,
                selectedUf: action.payload,
            }
        case GET_CITIES:
            return{
                ...state,
                cities: action.payload
            }
        case UPDATE_CITY:
            return {
                ...state,
                selectedCity: action.payload
            }
        case CLEAR_CITIES:
            return{
                ...state,
                cities: null,
                selectedCity: null
            }
        case GET_WEATHER:
            return{
                ...state,
                weather: action.payload
            }
        default:
            return state
    }
}