import React, { lazy } from 'react';
import TermsPage from './termsPage';
import { Navigate } from 'react-router-dom';
// import { authRoles } from '../../../../auth';

const Termos = lazy(() => import('./termsPage'));

const termsPageConfig = {
	settings: {
		layout: {
			config: {
				mode: 'fullwidth',
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				},
				footer: {
					display: true,
					style: 'fixed',
					position: 'below'
				}
			}
		}
	},
	routes: [
		{
			path: '/terms/:tp',
			element: <TermsPage />
		},
		{
			path: '/terms',
			element: <Navigate to="/terms/access" />
		}
	]
};

export default termsPageConfig;
