import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { tokenHeaders, urlBackend } from 'app/main/data/LocationContext';
import { updateNavigationItem } from 'app/store/fuse/navigationSlice';
import { setSelectedEntityData } from 'app/main/store/entidade/entityStatusSlice';

const invitesAdapter = createEntityAdapter({});

const nrPendInvites = data => {
	const count = data.reduce((accumulator, object) => {
		if (object.invite_status === 'C') {
			accumulator += 1;
		}
		return accumulator;
	}, 0);
	return count;
};

export const acceptInvitesArray = createAsyncThunk(
	'auth/invite/acceptInvitesList',
	async (arrayInvites, { dispatch }) => {
		const data = { array_of_invites_to_accept: arrayInvites };
		const response = await axios.post(`${urlBackend}/api/account/`, data, tokenHeaders());
		if (response.data) {
			dispatch(setSelectedEntityData(response.data.entidades));
		}
		dispatch(getInvitesList());
		return response.data;
	}
);

export const removeInvitesArray = createAsyncThunk(
	'auth/invite/removeInvitesList',
	async (arrayToRemove, { dispatch }) => {
		const data = { array_of_invites_to_remove: arrayToRemove };
		const response = await axios.post(`${urlBackend}/api/account/`, data, tokenHeaders());

		if (response.data) {
			dispatch(setSelectedEntityData(response.data.entidades));
		}
		dispatch(getInvitesList());
		return response.data;
	}
);

export const getInvitesList = createAsyncThunk('auth/invites/getInvitesList', async (_, { dispatch }) => {
	const response = await axios.get(`${urlBackend}/api/account/?list_operator_invites`, tokenHeaders());
	if (response.data) {
		const nrInvites = nrPendInvites(response.data);
		dispatch(
			updateNavigationItem('convites', {
				id: 'convites',
				title: 'Convites',
				type: 'item',
				url: '/convites',
				icon: 'how_to_reg',
				badge: {
					title: nrInvites,
					bg: nrInvites > 0 ? '#F44336' : '#044f01',
					fg: '#FFFFFF'
				}
			})
		);
	}
	return response.data;
});

export const {
	selectAll: selectInvites,
	selectById: selectInviteById,
	removeAll: removeInvites
} = invitesAdapter.getSelectors(({ auth }) => auth.invites);

const invitesSlice = createSlice({
	name: 'auth/invites',
	initialState: invitesAdapter.getInitialState({
		searchText: ''
	}),
	reducers: {
		setInvites: (state, action) => (state = action.payload),
		clearInvites: state => {
			invitesAdapter.removeAll(state);
		},
		setInviteText: {
			reducer: (state, action) => {
				state.searchText = action.payload;
			},
			prepare: event => ({ payload: event.target.value || '' })
		}
	},
	extraReducers: {
		[getInvitesList.fulfilled]: (state, action) => {
			invitesAdapter.setAll(state, action.payload);
		},
		[getInvitesList.rejected]: () =>
			invitesAdapter.getInitialState({
				searchText: ''
			}),
		[acceptInvitesArray.fulfilled]: (state, action) => {
			invitesAdapter.setAll(state, action.payload.invites);
		}
	}
});

export const { setInvites, clearInvites, setInviteText } = invitesSlice.actions;

export default invitesSlice.reducer;
