import React from 'react';
import { LicenseInfo } from '@mui/x-license-pro';

const muiXLicensePro = process.env.REACT_APP_MUI_PRO_LICENSE;

LicenseInfo.setLicenseKey(muiXLicensePro);

export default function MuiXLicense() {
	return null;
}
