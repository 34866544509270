import { createSlice } from '@reduxjs/toolkit';

export const bloqueiaSistema = () => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(setLockSystem(true));
			resolve();
		});
	};
};

const initialState = {
	status: 'idle',
	remoteLoading: false,
	selectedTab: 0,
	modalNewsSourcesOpen: false,
	locked: false,
	offlineMode: false,
	lastPath: '/war-room',
	pageLayoutDimensions: {
		width: 0,
		height: 0
	}
};

const environmentSlice = createSlice({
	name: 'environment',
	initialState,
	reducers: {
		remoteLoadingToggle: (state, action) => {
			state.remoteLoading = !state.remoteLoading;
		},
		setRemoteLoading: (state, action) => {
			state.status = 'loading';
			state.remoteLoading = true;
		},
		changeModalNewsSources: (state, action) => {
			state.modalNewsSourcesOpen = action.payload;
		},
		setRemoteLoaded: (state, action) => {
			state.status = 'idle';
			state.remoteLoading = false;
		},
		setSelectedTab: (state, action) => {
			state.selectedTab = action.payload;
		},
		setLockSystem: (state, action) => {
			state.locked = action.payload;
		},
		setOfflineMode: (state, action) => {
			state.offlineMode = action.payload;
		},
		setLastPath: (state, action) => {
			state.lastPath = action.payload;
		},
		setPageLayoutDimensions: (state, action) => {
			state.pageLayoutDimensions = action.payload;
		}
	}
});

export const {
	remoteLoadingToggle,
	setRemoteLoading,
	setRemoteLoaded,
	setSelectedTab,
	setLockSystem,
	changeModalNewsSources,
	setOfflineMode,
	setLastPath,
	setPageLayoutDimensions
} = environmentSlice.actions;

export default environmentSlice.reducer;
