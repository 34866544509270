import { combineReducers } from '@reduxjs/toolkit';
import login from './loginSlice';
import register from './registerSlice';
import user from './userSlice';
// import sm_elections from "../../main/scopo-apps/elections/store/index";
import invites from "app/main/store/account/invitesSlice";

const authReducers = combineReducers({
  user,
  invites,
  login,
  register,
});

export default authReducers;
