import { combineReducers } from '@reduxjs/toolkit';
import candidato from 'app/main/store/entidade/politico/candidatoSlice';
import participantes from 'app/main/store/entidade/politico/participantesSlice';
import evaluations from './politicalEvaluationsSlice';

const politicoReducer = combineReducers({
	candidato,
	participantes,
	evaluations
});

export default politicoReducer;
