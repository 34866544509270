import React, { memo } from 'react';
import AppBar from '@mui/material/AppBar';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { red } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { motion } from 'framer-motion';
import { selectFooterTheme } from 'app/store/fuse/settingsSlice';
import PoweredByLinksScopo from 'app/fuse-layouts/shared-components/PoweredByLinksScopo';
import DocumentationButton from '../../shared-components/DocumentationButton';

function FooterScopo(props) {
	const footerTheme = useSelector(selectFooterTheme);

	const fabStyle = {
		position: 'absolute',
		bottom: 16,
		right: 16,
		'& a': {
			bgcolor: red[600],
			color: '#00FF00'
		}
	};

	const theme = useTheme();
	const item = {
		hidden: { opacity: 0, scale: 0.6 },
		show: { opacity: 0.5, scale: 0.6 }
	};

	return (
		<ThemeProvider theme={footerTheme}>
			<AppBar
				id="fuse-footer"
				className={clsx('relative z-20 shadow-md', props.className)}
				color="default"
				style={{ backgroundColor: 'rgba(2,3,14,0.65)', height: '32px' }}
			>
				<Toolbar className="min-h-24 flex items-center overflow-x-auto px-2 md:min-h-28">
					<Tooltip variants={item} title="Neoplace Tecnologias" placement="top">
						<IconButton
							className="w-26 h-26 px-1 ml-4"
							component={motion.a}
							variants={item}
							href="https://neoplace.com.br"
							target="_blank"
							rel="noreferrer noopener"
							role="button"
							size="large"
						>
							<img
								src="https://ecom-scopo.sfo2.digitaloceanspaces.com/shared_images/Neoplace-Just-Logo_50px.png"
								alt="Neoplace"
								width="24"
							/>
						</IconButton>
					</Tooltip>
					<span className="text-[12px] italic mt-12 mr-0 sm:mt-2 sm:mr-16 md:mt-4">
						© 2021-2027 <span className="text-orange-800">SM SCOPO</span>
					</span>

					<div className="invisible mx-0 sm:visible flex grow shrink-0 sm:mx-16 pt-4 pb-4 justify-end">
						<PoweredByLinksScopo />
					</div>
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
}

export default memo(FooterScopo);
