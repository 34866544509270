import React, { lazy } from 'react';
import { authRoles } from '../../../auth';

const KnowledgeBasePage = lazy(() => import('./KnowledgeBasePage'));

const KnowledgeBasePageConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: 'docs/knowledge-base',
			element: <KnowledgeBasePage />
		}
	]
};

export default KnowledgeBasePageConfig;
