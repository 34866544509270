import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { tokenHeaders, urlBackend } from '../../data/LocationContext';
import { showMessage } from '../../../store/fuse/messageSlice';

const pathGetNetworks = '/api/social_network/get_list/?';

export const getEntityNetworks = createAsyncThunk(
	'entidade/social_networks/getEntityNetworks',
	async (entId, { dispatch }) => {
		const response = await axios.get(`${urlBackend}${pathGetNetworks}id=${entId}`, tokenHeaders());
		const parsedData = JSON.parse(response.data);
		const { success, message, data } = await parsedData;

		if (message) {
			dispatch(
				showMessage({
					message,
					autoHideDuration: 8000, // ms
					anchorOrigin: {
						vertical: 'bottom', // top bottom
						horizontal: 'center' // left center right
					},
					variant: success ? 'success' : 'error' // success error info warning null
				})
			);
		}

		if (success) {
			return data;
		}
	}
);

export const getSocialNetworkImage = createAsyncThunk(
	'entidade/social_networks/getEntityImage',
	async (socialNetwork, { dispatch }) => {
		const { network, username } = socialNetwork;
		console.log(network, username);
		const config = tokenHeaders();
		config.method = 'get';
		config.url = `${urlBackend}/api/social_network/${network}/?get_img=${username}`;
		const response = await axios(config);
		console.log(await response);
		return response.data;
	}
);

const socialNetworkAdapter = createEntityAdapter({});

export const {
	selectAll: selectNetworks,
	selectById: selectNetworkById,
	addOne: addNetwork,
	updateOne: updateNetwork,
	removeOne: removeNetwork,
	removeAll: removeAllNetworks
} = socialNetworkAdapter.getSelectors(state => state.entidade.social_networks);

const socialNetworkSlice = createSlice({
	name: 'entidade/social_networks',
	initialState: socialNetworkAdapter.getInitialState({
		selectedNetworkId: null,
		searchText: ''
	}),
	reducers: {
		setSelectedNetworkId: (state, action) => {
			state.selectedNetworkId = action.payload;
		},
		removeSelectedNetworkId: (state, action) => {
			state.selectedNetworkId = null;
		},
		setNetworkSearchText: {
			reducer: (state, action) => {
				state.searchText = action.payload;
			}
		},
		setNetworks: (state, action) => action.payload,
		cleanNetworks: (state, action) =>
			socialNetworkAdapter.getInitialState({ selectedNetworkId: null, searchText: '' }),
		removeAllSocialNetworks: state => {
			socialNetworkAdapter.removeAll(state);
		}
	},
	extraReducers: {
		[getEntityNetworks.fulfilled]: socialNetworkAdapter.setAll
	}
});

export const {
	setSelectedNetworkId,
	removeSelectedNetworkId,
	setNetworkSearchText,
	setNetworks,
	cleanNetworks,
	removeAllSocialNetworks
} = socialNetworkSlice.actions;

export default socialNetworkSlice.reducer;
